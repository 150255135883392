function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { reduce, toString, toNumber, find, compact, isArray, map, includes, isEmpty, get } from 'lodash';
function test(rule, value) {
  switch (rule.operator) {
    case '=':
      return rule.operator_value === toString(value);
    case 'is':
      return rule.operator_value === toString(value);
    case 'is_not':
      return rule.operator_value !== toString(value);
    case 'greater_than':
      return toNumber(rule.operator_value) < toNumber(value);
    case 'less_than':
      return toNumber(rule.operator_value) > toNumber(value);
    case 'contains':
      return includes(value, rule.operator_value);
    case 'does_not_contain':
      return !includes(value, rule.operator_value);
    case 'is_blank':
      return isEmpty(value);
    case 'is_not_blank':
      return !isEmpty(value);
    default:
      return false;
  }
}
function getValue(values, form, question_id) {
  var section = find(form.sections, function (sec) {
    return find(sec.questions, {
      id: question_id
    }) !== undefined;
  });
  if (!section || !values) {
    return null;
  }
  var question = find(section.questions, {
    id: question_id
  });
  var value = get(values, "".concat(section.id, ".").concat(question_id), null);
  if (!value) {
    return value;
  }
  switch (question.input_type) {
    case 'checkbox':
      {
        var val = compact(map(value, function (v, key) {
          if (v) {
            var opt = find(question.input_options, {
              id: key
            });
            if (opt) {
              return opt.option_label;
            }
          }
          return null;
        }));
        if (val.length === 1) {
          return val[0];
        }
        return val;
      }
    case 'radio':
      {
        var opt = find(question.input_options, {
          id: value
        });
        if (opt) {
          return opt.option_label;
        }
        return null;
      }
    case 'select':
      {
        if (isArray(value)) {
          return map(value, 'option_label');
        }
        return value.option_label;
      }
    default:
      return value;
  }
}
function testAnd(form, rules, values) {
  return reduce(rules, function (result, rule) {
    var value = getValue(values, form, rule.question_id);
    return result && test(rule, value);
  }, true);
}
function testOr(form, rules, values) {
  return reduce(rules, function (result, rule) {
    var value = getValue(values, form, rule.question_id);
    return result || test(rule, value);
  }, false);
}
export default function conditionalDisplay() {
  var formData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var values = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var hiddenFields = reduce(formData.sections, function (acc, section) {
    return reduce(section.questions, function (accu, question) {
      if (isEmpty(question.conditional_display)) {
        return accu;
      }
      var result = true;
      result = testAnd(formData, question.conditional_display.and_conditions, values);
      if (!result || isEmpty(question.conditional_display.and_conditions) && !isEmpty(question.conditional_display.or_conditions)) {
        result = testOr(formData, question.conditional_display.or_conditions, values);
      }
      if (question.conditional_display.show_or_hide === 'show' && result || question.conditional_display.show_or_hide === 'hide' && !result) {
        return accu;
      }
      return [].concat(_toConsumableArray(accu), [question.id]);
    }, acc);
  }, []);
  return hiddenFields;
}