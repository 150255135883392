import { ACCOUNT_TOKEN } from "./constants";
export default function initializeInMoment(props) {
  var userCreated = props.userCreated,
    userRole = props.userRole,
    userLocation = props.userLocation,
    _props$config = props.config,
    noSurveyedCookie = _props$config.noSurveyedCookie,
    surveyImmediately = _props$config.surveyImmediately,
    productName = _props$config.productName;
  var setupScript = document.createElement('script');
  var beacon = document.createElement('script');
  var wootricSettings = {
    product_name: productName,
    account_token: ACCOUNT_TOKEN,
    created_at: userCreated,
    modal_footprint: 'compact',
    properties: {
      user_role: userRole,
      user_location: userLocation
    }
  };
  setupScript.type = 'text/javascript';
  setupScript.id = 'wootric-settings';
  setupScript.async = true;
  setupScript.innerHTML = "\n        wootric_no_surveyed_cookie = ".concat(noSurveyedCookie, ";\n        wootric_survey_immediately = ").concat(surveyImmediately, ";\n        ").concat(typeof window !== 'undefined' ? "window.wootricSettings = ".concat(JSON.stringify(wootricSettings)) : '', "\n      ");
  beacon.type = 'text/javascript';
  beacon.id = 'wootric-beacon';
  beacon.async = true;
  beacon.src = 'https://cdn.wootric.com/wootric-sdk.js';
  beacon.onload = function () {
    if (typeof window !== 'undefined') {
      window.wootric('run');
    }
  };
  if (document.getElementById('wootric-settings') == null) document.body.appendChild(setupScript);
  if (document.getElementById('wootric-beacon') == null) document.body.appendChild(beacon);
}