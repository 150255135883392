import moment from 'moment';
import Notifier from 'common/helpers/Notifier';
import { coreApi } from 'src/api/config';

const mapAssistanceRequestToAddress = (assistanceRequest) => {
  if (!assistanceRequest.address_state) {
    return [];
  }

  return [{
    address_type: assistanceRequest.address_type,
    city: assistanceRequest.address_city,
    state: assistanceRequest.address_state,
    line_1: assistanceRequest.address_line_1,
    line_2: assistanceRequest.address_line_2,
    county: assistanceRequest.address_county,
    postal_code: assistanceRequest.address_postal_code,
    country: assistanceRequest.address_country,
    latitude: assistanceRequest.address_latitude,
    longitude: assistanceRequest.address_longitude,
  }];
};

const mapAssistanceRequestToPerson = (assistanceRequest) => ({
  id: null,
  first_name: assistanceRequest.person_first_name,
  last_name: assistanceRequest.person_last_name,
  full_name: `${assistanceRequest.person_first_name} ${assistanceRequest.person_last_name}`,
  date_of_birth: moment(assistanceRequest.person_date_of_birth).unix(),
  middle_name: assistanceRequest.person_middle_name,
  preferred_name: assistanceRequest.person_preferred_name,
  title: assistanceRequest.person_title,
  suffix: assistanceRequest.person_suffix,
  race: assistanceRequest.person_race,
  gender: assistanceRequest.person_gender,
  ethnicity: assistanceRequest.person_ethnicity,
  sexuality: assistanceRequest.person_sexuality,
  sexuality_other: assistanceRequest.person_sexuality_other,
  citizenship: assistanceRequest.person_citizenship,
  marital_status: assistanceRequest.person_marital_status,
  gross_monthly_income: assistanceRequest.person_gross_monthly_income,
  phone_numbers: assistanceRequest.person_phone_numbers,
  email_addresses: assistanceRequest.person_email_addresses,
  military: assistanceRequest.person_military,
});

const mapAssistanceRequestToCase = (assistanceRequest) => ({
  id: null,
  outcome: null,
  state: 'assistance_requested',
  description: assistanceRequest.description,
  person: mapAssistanceRequestToPerson(assistanceRequest),
  group: assistanceRequest.provider,
  service_type: assistanceRequest.service,
  created_at: assistanceRequest.created_at,
  updated_at: assistanceRequest.updated_at,
});

const onError = (error) => Notifier.handleErrors(error);

export const populateAssistanceRequestCase = async (caseToPopulate, assistanceRequest = {}) => {
  let kase = { ...caseToPopulate };
  let addresses = [];

  try {
    if (!kase.id) {
      await Promise.all([
        coreApi.populateRelationship('service', 'service', assistanceRequest),
        coreApi.populateRelationship('provider', 'provider', assistanceRequest),
      ]);

      kase = mapAssistanceRequestToCase(assistanceRequest);
      addresses = mapAssistanceRequestToAddress(assistanceRequest);
    } else {
      await Promise.all([
        kase.outcome ? coreApi.populateRelationship('outcome', 'outcome', kase) : null,
        coreApi.populateRelationship('person', 'person', kase),
        coreApi.populateRelationship('service_type', 'service', kase),
        coreApi.populateRelationship('group', 'provider', kase),
      ]);

      ([{ data: { data: addresses } }] = await Promise.all([
        coreApi.query('address', { person: kase.person.id }),
        coreApi.populateRelationship('person.contact_preference', 'contact_preference', kase),
      ]));
    }

    kase.status = null;
    if (kase.state === 'assistance_requested') {
      kase.status = kase.outcome ? 'closed' : 'pending';
    } else {
      kase.status = 'processed';
    }

    kase.requestor = kase.person;
    kase.requestor.addresses = addresses;
    kase.contact = kase.person;

    return kase;
  } catch (error) {
    return onError(error);
  }
};
