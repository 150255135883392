import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { validateReduxForm } from 'common/form';
import { tracker, validations } from '@unite-us/client-utils';
import {
  Button,
  TextField,
} from '@unite-us/ui';
import { updateServiceCase } from 'actions/Case/Contact/Group';
import { OverlaySpinner } from 'common/spinners';
import callOrLog from 'src/common/utils/callOrLog';
import getDetailDescriptionEvent from 'common/utils/EventTracker/utils/getDetailDescriptionEvent';
import './stylesheets/detail-view.scss';

const fields = [
  'description',
];

class DetailSummaryForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const {
      detailObj,
      initializeForm,
    } = this.props;
    initializeForm({ description: detailObj.description });
  }

  onSubmit(formInputs) {
    const { closeModal, submit, detailObj } = this.props;
    return submit(formInputs)
      .then(() => {
        closeModal();
        callOrLog(() => this.context.eventTracker(
          getDetailDescriptionEvent(detailObj),
          null,
          tracker.getDetailObj(detailObj),
        ));
      });
  }

  render() {
    const {
      descriptionSubtitle,
      fields: {
        description,
      },
      handleSubmit,
      submitting,
    } = this.props;

    return (
      <form className="content-with-actions" onSubmit={handleSubmit(this.onSubmit)}>
        <OverlaySpinner show={submitting} text="Submitting Description..." />
        <div className="content-container">
          <TextField
            afterLabelContent={descriptionSubtitle}
            ref={this.props.registerField}
            field={description}
            id="notes"
            label="Description"
            validations={validations.isRequired}
            required
          />
        </div>
        <div className="actions">
          <span className="action-item">
            <Button
              id="description-cancel-btn"
              label="Cancel"
              onClick={this.props.closeModal}
              disabled={submitting}
            />
          </span>
          <span className="action-item">
            <Button
              id="description-submit-btn"
              onClick={handleSubmit(this.onSubmit)}
              primary
              disabled={submitting}
              label="Submit"
            />
          </span>
        </div>
      </form>
    );
  }
}

DetailSummaryForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  detailObj: PropTypes.object.isRequired,
  descriptionSubtitle: PropTypes.node,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initializeForm: PropTypes.func.isRequired,
  registerField: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

DetailSummaryForm.defaultProps = {
  descriptionSubtitle: null,
};

DetailSummaryForm.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const {
    session: {
      groupId,
    },
  } = state;

  return {
    groupId,
    initialValues: {
      description: ownProps.notes,
    },
  };
}

export default validateReduxForm(
  {
    form: 'editDetailField',
    fields,
  },
  mapStateToProps,
  { updateServiceCase },
)(DetailSummaryForm);
