import React, { useRef, useState, useContext } from 'react';
import { PropTypes } from 'prop-types';
import {
  hasNCCorrectPaymentWording,
  hasPaysClaimsCodes,
  hasPayerInvoicesRole,
} from 'src/common/utils/FeatureFlags/flags';
import { get, isEmpty, startCase, toLower } from 'lodash';
import { Link } from 'react-router';
import { FileThumbnail, TrackerContext } from '@unite-us/client-utils';
import { AuditTrail } from 'src/common/AuditTrail';
import { Card, CardDetail, CardSubHeader } from 'src/common/Card';
import { useArchiveRecord, useUnarchiveRecord, useFeatureFlag } from 'common/hooks';
import { INVOICE } from 'common/utils/EventTracker/utils/eventConstants';
import { useUpdateRecord, useInvalidateQueries } from 'src/api/APIHooks';
import { connect } from 'react-redux';
import InvoiceStatusIndicator from 'src/pages/invoices/utils/InvoiceStatusIndicator';
import InsuranceStatusIndicator from 'components/Referrals/ReferralDetail/components/InsuranceStatusIndicator';
import { Icon, Button, Dialog } from '@unite-us/ui';
import { Spinner } from 'src/common/spinners';
import callOrLog from 'src/common/utils/callOrLog';
import formatDate from 'src/common/utils/Dates/formatDate';
import formatShortDate from 'src/common/utils/Dates/formatShortDate';
import { INSURANCE_STATUSES } from 'src/components/Insurance/constants';
import {
  ColumnHeader,
  DataCell,
  DataRow,
  HeaderRow,
  Table,
  TableBody,
} from 'src/common/tables/V2';
import formatFileType from 'src/pages/invoices/utils/formatFileType';
import { usePSActive } from 'src/pages/invoices/hooks/updateProvidedService';
import * as env from 'src/config/env/env.config';
import Notifier from 'common/helpers/Notifier';
import { browserHistory } from 'common/utils/browserHistory';
import { Checkbox, SecondaryButton, PrimaryButton } from 'src/common/TailwindComponents';
import { theme } from 'src/../tailwind.config';
import { useSubmitInvoice, useSubmitInvoiceToPayer, usePayerUpdateInvoiceStatus } from '../hooks/useUpdateInvoice';
import AuthorizationDetails from './AuthorizationDetails';
import InvoiceRejectionForm from './InvoiceRejectionForm';
import InvoiceDisputeForm from './InvoiceDisputeForm';
import DisputeResolutionForm from './DisputeResolutionForm';
import FixInvoiceResponseForm from './FixInvoiceResponseForm';
import SubmitInvoiceButton from './SubmitInvoiceButton';
import ServiceDetails from './ServiceDetails';
import {
  CBO_ADMIN_ARCHIVABLE_STATUSES,
  NETWORK_LEAD_ARCHIVABLE_STATUSES,
  REJECTED_OPTIONS,
  DISPUTABLE_STATUSES,
  PAYER_DISPUTED_STATUSES,
} from '../constants';
import { useMetafields } from '../hooks';
import isMostRecentInvoice from '../utils/isMostRecentInvoice';
import prepareVersion from '../utils/prepareVersion';
import dateDisputed from '../utils/dateDisputed';
import dateDisputeResolved from '../utils/dateDisputeResolved';
import useInvoiceFixVersion from '../hooks/useInvoiceFixVersion';
import InvoiceDifferenceIndicator from './InvoiceDifferenceIndicator';
import usePayerWQInteractiveView from '../hooks/usePayerWQInteractiveView';

export const InvoiceDetailSingleCard = ({
  createdAt,
  currentEmployee,
  disableSubmitButton,
  displayAmount,
  feeSchedule,
  files,
  groupId,
  insurance,
  invoice,
  invoicesFromProvidedServiceId,
  isFetching,
  isLoading,
  isNetworkLead,
  isCBOProvider,
  metadata,
  networkId,
  onDisableSubmitButtonChange,
  placeOfService,
  previousInvoices,
  procedureCodes,
  procedureCodeModifiers,
  providerAddress,
  serviceDescription,
  showClaimsCodes,
  showFixAndMakeNewInvoice,
  showNCCorrectPaymentWording,
  showPayerInvoices,
  totalAmountInvoiced,
  totalAmountPaid,
  useInvoiceUserRole,
  useNcHopDisputeProcess,
  versions,
  zCodes,
  payerWQInteractiveView,
  insuranceLoaded,
}) => {
  const [checked, setChecked] = useState(invoice.payment_received ? invoice.payment_received : false);
  const [loading, setIsLoading] = useState(false);
  const pays3693AddLinkToClientFacesheetInIwq = useFeatureFlag('pays-3693-add-link-to-client-facesheet-in-iwq');

  const metafields = useMetafields(invoice, groupId);
  const { updateRecord } = useUpdateRecord('invoice');
  const invalidateQueries = useInvalidateQueries();

  const eventTracker = useContext(TrackerContext);
  const { payerWQInteractiveView: payerOnInvoiceHasWQInteractiveView } = usePayerWQInteractiveView(invoice.payer_id);

  const submitToNetworkLeadWrapper = useSubmitInvoice(invoice.id);
  const submitToNetworkLead = () => {
    submitToNetworkLeadWrapper();
    callOrLog(() => eventTracker(
      INVOICE.cboClickedApprove,
      { current_network: networkId },
    ));
  };

  const submitToPayerWrapper =
    useSubmitInvoiceToPayer(invoice.id, 'submitted_to_payer', 'Your invoice was submitted to the payer.');
  const submitToPayer = () => {
    submitToPayerWrapper();
    callOrLog(() => eventTracker(
      INVOICE.nlClickedSubmit,
      { current_network: networkId },
    ));
  };

  const transmitToPayerWrapper =
    useSubmitInvoiceToPayer(invoice.id, 'transmitted_to_payer', 'Your invoice was transmitted to the payer.');
  const transmitToPayer = () => {
    transmitToPayerWrapper();
    callOrLog(() => eventTracker(
      INVOICE.nlClickedSubmit,
      { current_network: networkId },
    ));
  };

  const payerAcceptInvoiceWrapper = usePayerUpdateInvoiceStatus(invoice.id, 'accepted_by_payer');
  const payerAcceptInvoice = () => {
    payerAcceptInvoiceWrapper();
    callOrLog(() => eventTracker(
      INVOICE.payerAcceptInvoice,
      { current_network: networkId },
    ));
  };

  const payerCompleteInvoicePaymentWrapper = usePayerUpdateInvoiceStatus(invoice.id, 'paid');
  const payerCompleteInvoicePayment = () => {
    payerCompleteInvoicePaymentWrapper();
    callOrLog(() => eventTracker(
      INVOICE.payerRejectInvoice,
      { current_network: networkId },
    ));
  };
  const rejectionModalRef = useRef();
  const disputeModalRef = useRef();
  const fixResponseModalRef = useRef();
  const disputeResolutionModalRef = useRef();
  const fixAndMakeNewModalRef = useRef();

  const openRejectionModal = () => {
    rejectionModalRef.current.openDialog();
  };

  const closeRejectionModal = () => {
    rejectionModalRef.current.closeDialog();
  };

  const openDisputeModal = () => {
    disputeModalRef.current.openDialog();
  };

  const closeDisputeModal = () => {
    disputeModalRef.current.closeDialog();
  };

  const openDisputeResolutionModal = () => {
    disputeResolutionModalRef.current.openDialog();
  };

  const closeDisputeResolutionModal = () => {
    disputeResolutionModalRef.current.closeDialog();
  };

  const openFixResponseModal = () => {
    fixResponseModalRef.current.openDialog();
  };

  const closeFixResponseModal = () => {
    fixResponseModalRef.current.closeDialog();
  };

  const openFixAndMakeNewInvoiceModal = () => {
    fixAndMakeNewModalRef.current.openDialog();
  };

  const closeFixAndMakeNewInvoiceModal = () => {
    fixAndMakeNewModalRef.current.closeDialog();
  };

  const rejected = REJECTED_OPTIONS.includes(invoice.invoice_status);
  const resolved = get(invoice, 'invoice_dispute_resolution_reason.id');
  const disputed = !isEmpty(get(invoice, 'invoice_dispute_reason.id'));

  const handleUpdateRecord = async (value) => {
    await updateRecord(invoice.id, {
      payment_received: value,
    });
    invalidateQueries('invoice');
  };

  const isMostRecent = isMostRecentInvoice(invoicesFromProvidedServiceId, invoice);
  const canShowFixAndMakeNewInvoiceButton = isCBOProvider && isMostRecent;

  const payerAcceptedInvoice = invoice.invoice_status === 'accepted_by_payer';
  const payerClosedInvoice = PAYER_DISPUTED_STATUSES.includes(invoice.invoice_status);
  const fullPayerWQView = payerWQInteractiveView && showPayerInvoices;

  const fixVersionInvoices = useInvoiceFixVersion(invoice, showPayerInvoices);
  const archivableStatuses = isNetworkLead ? NETWORK_LEAD_ARCHIVABLE_STATUSES : CBO_ADMIN_ARCHIVABLE_STATUSES;
  const canFixInvoice = payerClosedInvoice && !!resolved && isEmpty(fixVersionInvoices);
  const showArchive = useFeatureFlag('pays-1837-temp-invoices-archive');
  const isArchived = !!get(invoice, 'user_archives.0.id');

  const canDispute = !isArchived && DISPUTABLE_STATUSES.includes(invoice.invoice_status);
  const previouslyDisputedInvoice = !isEmpty(get(invoice, 'invoice_dispute_resolution_reason'));
  const currentlyInDispute = get(invoice, 'under_dispute');

  const networkLeadCanReject = isNetworkLead && invoice.invoice_status === 'submitted_to_network_lead';
  const nonNetworkLeadCanReject = !isNetworkLead && invoice.invoice_status === 'submitted_contracted_service_note';
  const userCanReject = networkLeadCanReject || nonNetworkLeadCanReject;
  const canReject = !isArchived && userCanReject;

  const archiveInvoice = useArchiveRecord('invoice', {
    onSuccess: () => {
      invalidateQueries('invoice');
      Notifier.dispatch('success', 'Invoice archived.');
    },
  });
  const unarchiveInvoice = useUnarchiveRecord('invoice', {
    onSuccess: () => {
      invalidateQueries('invoice');
      Notifier.dispatch('success', 'Invoice unarchived.');
    },
  });

  const canArchiveInvoice = showPayerInvoices ? (
      archivableStatuses.includes(invoice.invoice_status) && !currentlyInDispute && !canFixInvoice
    ) : (
      archivableStatuses.includes(invoice.invoice_status) && !disputed
    );

  const handleArchive = () => archiveInvoice({ recordId: invoice.id, employeeId: currentEmployee.id });
  const handleUnarchive = () => unarchiveInvoice({ recordId: invoice.id });

  const handleChange = () => {
    setIsLoading(true);
    setChecked((value) => {
      handleUpdateRecord(!value)
        .then(setIsLoading(false));
      return !value;
    });
    callOrLog(() => eventTracker(
      INVOICE.cboClickedPaid,
      { current_network: networkId },
    ));
  };
  const setProvidedServiceActive = usePSActive(invoice.provided_service.id, groupId, invoice.case_id);

  const routeToCase = async (fixDisputedInvoice) => {
    const caseId = invoice.case_id;
    const contactId = invoice.client_id;
    const provided_service_id = invoice.provided_service.id;

    if (fixDisputedInvoice) {
      callOrLog(() => eventTracker(INVOICE.cboClickedFixInvoiceFromDispute, { current_network: networkId }));
    }

    await setProvidedServiceActive();
    browserHistory.push({
      pathname: `/dashboard/cases/open/${caseId}/contact/${contactId}`,
      state: { editProvidedServiceId: provided_service_id, isEditing: true },
    });
  };

  const onButtonDisableChange = (value) => {
    onDisableSubmitButtonChange(value);
  };

  const showPaymentRecievedButton =
    invoice.invoice_status === 'paid' && !isNetworkLead && useInvoiceUserRole && !showPayerInvoices;

  const providerInformation = (
    <div className="inline-block pr-4 py-4 space-y-2">
      <CardSubHeader className="mb-4 pt-0 pl-4 text-text-blue font-extrabold text-lg">
        Provider Information
      </CardSubHeader>
      <CardDetail label="Name">{invoice.provider_name}</CardDetail>
      <CardDetail label="Address">
        {!isEmpty(providerAddress) && (
        <>
          <div>{providerAddress.line_1}</div>
          <div>{providerAddress.line_2}</div>
          <div>{providerAddress.city}, {providerAddress.state} {providerAddress.postal_code}</div>
        </>
    )}
      </CardDetail>
    </div>
  );

  const showServiceAuthorizationSection = invoice.service_authorization_short_id;

  return (
    <>
      <div className="sticky top-0 z-50 flex px-4 py-4 bg-white border-b border-solid border-dark-fill-blue">
        <div className="flex-grow">
          <button
            className="flex p-2 border border-solid border-dark-fill-blue rounded hover:bg-light-fill-blue"
            type="button"
            onClick={browserHistory.goBack}
          >
            <div className="h-4 w-4">
              <Icon
                className="h-4 w-4 pb-1 stroke-current stroke-1 fill-current text-text-blue"
                icon="IconChevronLeft"
              />
            </div>
            <div className="pl-2 text-text-blue">
              Back
            </div>
          </button>
        </div>
        {/* Invoice Buttons Row */}
        <div className="flex flex-row space-x-6">
          {showArchive && !showPayerInvoices && (
            <div>
              {isArchived ? (
                <SecondaryButton
                  className="items-center"
                  onClick={handleUnarchive}
                  label="Unarchive"
                  icon="IconUndo"
                  id="unarchive-btn"
                />
              ) : (
                <SecondaryButton
                  className="items-center"
                  disabled={!canArchiveInvoice}
                  onClick={handleArchive}
                  label="Archive"
                  icon="IconArchive"
                  id="archive-btn"
                />
              )}
            </div>
          )}
          {
            !showPayerInvoices && (
              <div>
                <SecondaryButton
                  ariaLabel="reject invoice"
                  disabled={!canReject}
                  onClick={openRejectionModal}
                  label="Reject"
                  icon="IconCrossCircle"
                  id="reject-btn"
                />
                <InvoiceRejectionForm
                  invoice={invoice}
                  isNetworkLead={isNetworkLead}
                  close={closeRejectionModal}
                  setRef={rejectionModalRef}
                />
              </div>
            )
          }
          {
            fullPayerWQView && !payerAcceptedInvoice && !payerClosedInvoice && (
              <div className="flex flex-row space-x-6">
                <div>
                  <SecondaryButton
                    ariaLabel="reject invoice"
                    onClick={openRejectionModal}
                    label="Reject"
                    icon="IconCrossCircle"
                    id="reject-btn"
                  />
                  <InvoiceRejectionForm
                    invoice={invoice}
                    isNetworkLead={isNetworkLead}
                    close={closeRejectionModal}
                    setRef={rejectionModalRef}
                    isPayerProvider={showPayerInvoices}
                  />
                </div>
                <PrimaryButton
                  ariaLabel="accept invoice"
                  disabled={disableSubmitButton}
                  onClick={payerAcceptInvoice}
                  label="Accept"
                  icon="V2CheckCircle"
                  id="accept-btn"
                />
              </div>
            )
          }
          {
            showPayerInvoices && payerClosedInvoice && (
              <div className="flex flex-row space-x-6">
                {!!resolved && fullPayerWQView && (
                  <div>
                    <PrimaryButton
                      className="items-center"
                      disabled={!canFixInvoice}
                      onClick={openFixResponseModal}
                      label="Fix response"
                      icon="IconFix"
                      id="fixresponse-btn"
                    />
                    <FixInvoiceResponseForm
                      invoice={invoice}
                      isNetworkLead={false}
                      isPayerProvider
                      close={closeFixResponseModal}
                      setRef={fixResponseModalRef}
                    />
                  </div>
                )}
                {isArchived ? (
                  <SecondaryButton
                    className="items-center"
                    onClick={handleUnarchive}
                    label="Unarchive"
                    icon="IconUndo"
                    id="unarchive-btn"
                  />
                ) : (
                  <SecondaryButton
                    className="items-center"
                    disabled={!canArchiveInvoice}
                    onClick={handleArchive}
                    label="Archive"
                    icon="IconArchive"
                    id="archive-btn"
                  />
                )}
              </div>
            )
          }
          {
            fullPayerWQView && payerAcceptedInvoice && (
              <div className="flex flex-row space-x-6">
                <div>
                  <SecondaryButton
                    ariaLabel="reject invoice"
                    onClick={openRejectionModal}
                    label="Reject"
                    icon="IconCrossCircle"
                    id="reject-btn"
                  />
                  <InvoiceRejectionForm
                    invoice={invoice}
                    isNetworkLead={isNetworkLead}
                    close={closeRejectionModal}
                    setRef={rejectionModalRef}
                    isPayerProvider={showPayerInvoices}
                  />
                </div>
                <PrimaryButton
                  ariaLabel="pay invoice"
                  onClick={payerCompleteInvoicePayment}
                  label="Pay"
                  icon="LogoShield"
                  id="pay-btn"
                  className="px-4"
                />
              </div>
            )
          }
          {isNetworkLead && (
            <div>
              <SecondaryButton
                ariaLabel="dispute invoice"
                disabled={!canDispute || previouslyDisputedInvoice || currentlyInDispute}
                onClick={openDisputeModal}
                label="Dispute"
                icon="V2Warning"
                id="dispute-btn"
              />
              <InvoiceDisputeForm
                invoice={invoice}
                close={closeDisputeModal}
                setRef={disputeModalRef}
                useNcHopDisputeProcess={useNcHopDisputeProcess}
              />
            </div>
          )}
          <div className="border border-r-0 border-solid border-dark-fill-blue" />
          {useInvoiceUserRole && !showPayerInvoices && (
            <SubmitInvoiceButton
              currentEmployee={currentEmployee}
              disableSubmitButton={disableSubmitButton}
              hasPayerWQInteractiveView={payerOnInvoiceHasWQInteractiveView}
              id="submit-btn"
              invoice={invoice}
              isArchived={isArchived}
              isNetworkLead={isNetworkLead}
              submitToPayer={submitToPayer}
              submitToNetworkLead={submitToNetworkLead}
              transmitToPayer={transmitToPayer}
            />
          )}
        </div>
      </div>

      {/* Dispute Details */}
      {
        disputed && (
          <div className="pt-4 flex-1 px-3 py-2">
            <Card borderColor="border-purple" className="mt-2 px-4 py-2 bg-white">
              <div className="col-sm-12">
                <div className="flex">
                  <span className="flex col-sm-10 pl-6 pt-2 text-lg font-extrabold text-text-blue leading-7">
                    <Icon className="fill-current bg-white text-purple h-18 mt-1 pr-3" icon="V2Warning" />
                    Dispute Details
                  </span>
                </div>
                <hr className="pt-2 -mx-8 border-dark-fill-blue" />
              </div>
              <div className="text-text-blue grid grid-cols-2 divide-x divide-solid divide-dark-fill-blue">
                <div className="inline-block px-4 py-4 space-y-2">
                  <CardDetail label="Date Disputed">{!isEmpty(versions) && dateDisputed(versions)}</CardDetail>
                  <CardDetail label="Reason for Dispute">
                    {get(invoice, 'invoice_dispute_reason.display_name')}
                  </CardDetail>
                  {
                    !isEmpty(invoice.dispute_reason_note) && (
                      <CardDetail label="Note">
                        {invoice.dispute_reason_note}
                      </CardDetail>
                    )
                  }
                </div>
              </div>
              {/* Dispute Resolution Details */}
              {
                resolved && (
                  <>
                    <hr className="pt-2 -mx-8 border-dark-fill-blue" />
                    <div className="text-text-blue grid grid-cols-2 divide-x divide-solid divide-dark-fill-blue">
                      <div className="inline-block px-4 py-4 space-y-2">
                        <CardDetail label="Date Resolved">
                          {!isEmpty(versions) && dateDisputeResolved(versions)}
                        </CardDetail>
                        <CardDetail label="Resolution">
                          {get(invoice, 'invoice_dispute_resolution_reason.display_name')}
                        </CardDetail>
                        {!isEmpty(invoice.invoice_dispute_resolution_note) && (
                          <CardDetail label="Note">{invoice.invoice_dispute_resolution_note}</CardDetail>
                        )}
                      </div>
                    </div>
                  </>
                )
              }
              {
                showFixAndMakeNewInvoice ? (
                  <>
                    <hr className="pt-2 -mx-8 border-dark-fill-blue" />
                    <Button
                      className="rounded ml-10 mt-4 mb-2 py-3 px-6"
                      disabled={!isMostRecent}
                      label="Fix And Make New Invoice"
                      onClick={openFixAndMakeNewInvoiceModal}
                      primary
                    />
                    <Dialog
                      modal
                      title="Fix and make new invoice"
                      ref={fixAndMakeNewModalRef}
                      titleStyles={{ color: theme.extend.colors['text-blue'] }}
                      id="dispute-resolution-invoice-modal"
                      onRequestClose={closeFixAndMakeNewInvoiceModal}
                    >
                      <>
                        <div className="text-sm pb-4 space-y-6">
                          <p>
                            This will create a new version of the invoice and
                            move the old version to the Closed Invoices folder.
                          </p>
                          <p>
                            Are you sure you want to create a new version of this invoice?
                          </p>
                        </div>
                        <div className="flex justify-end mt-6 space-x-4">
                          <Button
                            className="capitalize"
                            onClick={closeFixAndMakeNewInvoiceModal}
                            label="cancel"
                          />
                          <Button
                            className="text-white capitalize"
                            onClick={() => routeToCase(true)}
                            label="Yes"
                            primary
                          />
                        </div>
                      </>
                    </Dialog>
                  </>
                ) : null
              }
              {
                isNetworkLead && (
                  <>
                    <hr className="pt-2 -mx-8 border-dark-fill-blue" />
                    <Button
                      className="ml-8 mt-4 mb-2 bg-text-blue text-white capitalize"
                      onClick={openDisputeResolutionModal}
                      label={resolved ? 'Resolved' : 'Resolve Invoice'}
                      disabled={resolved}
                    />
                    <DisputeResolutionForm
                      invoice={invoice}
                      close={closeDisputeResolutionModal}
                      setRef={disputeResolutionModalRef}
                    />
                  </>
                )
              }
            </Card>
          </div>
        )
      }

      {/* Rejection Details */}
      {
        rejected && (
          <div className="pt-4 flex-1 px-3 py-2">
            <Card className="mt-2 px-2 py-2 bg-white">
              <div className="col-sm-12">
                <div className="flex">
                  <span className="flex col-sm-10 pl-4 pt-2 text-xl font-extrabold text-text-blue">
                    <Icon className="fill-current bg-white text-red h-18 mt-1 pr-3" icon="V2Warning" />
                    Rejection Details
                  </span>
                </div>
                <hr className="pt-2 -mx-8 border-dark-fill-blue" />
              </div>
              <div className="grid grid-cols-2 divide-x divide-solid divide-dark-fill-blue">
                <div className="inline-block px-4 py-4 space-y-2">
                  <CardDetail label="Rejection Reason">
                    {get(invoice, 'invoice_rejection_reason.display_name')}
                  </CardDetail>
                  <CardDetail label="Rejection Note">
                    {invoice.rejection_note}
                  </CardDetail>
                </div>
              </div>
              { canShowFixAndMakeNewInvoiceButton && (
                <>
                  <hr className="pt-2 -mx-8 border-dark-fill-blue" />
                  <Button
                    className="rounded ml-8 mt-4 mb-2 py-3 px-6"
                    label="Fix And Make New Invoice"
                    onClick={() => routeToCase(false)}
                    primary
                  />
                </>
              )}
            </Card>
          </div>
        )
      }

      <div className="pt-4 flex-1 px-3 py-2 space-y-6">
        <Card className="mt-2 bg-medium-fill-grey">
          <div className="pt-2 px-8 pl-6 bg-white">
            <div className="flex pb-3">
              <span className="flex w-4/5 pt-2 text-2xl font-extrabold text-text-blue">
                Invoice #{invoice.short_id}
              </span>
              <span className="flex w-1/5 pt-2 text-2xl font-extrabold text-text-blue justify-end">
                {displayAmount}
              </span>
            </div>
            <div className="flex pb-4 items-center">
              <div className="flex text-sm w-4/5">
                Last updated: {formatDate(invoice.updated_at, false)}
                {invoice.invoice_status !== '' ? (
                  <div className="pl-2 flex flex-row items-center">
                    <InvoiceStatusIndicator
                      isNetworkLead={isNetworkLead}
                      invoiceStatus={invoice.invoice_status}
                      isPayer={showPayerInvoices}
                    />
                    <InvoiceDifferenceIndicator
                      invoiceStatus={invoice.invoice_status}
                      invoiceAmountPaid={invoice.amount_paid}
                      invoiceAmountInvoiced={invoice.total_amount_invoiced}
                      invoicedResolved={invoice.invoice_dispute_resolution_date !== null}
                    />
                  </div>
                ) : ''}
              </div>
              { showPaymentRecievedButton && (
                <div className="flex text-sm w-1/5">
                  <Checkbox
                    id={`${invoice.id}-payment-received-checkbox`}
                    className={loading ? 'opacity-50 cursor-not-allowed' : ''}
                    checked={checked}
                    disabled={!useInvoiceUserRole || loading}
                    label={showNCCorrectPaymentWording ? 'Correct Payment Received' : 'Payment Received'}
                    onChange={handleChange}
                  />
                </div>
              )}
            </div>
          </div>
          <hr className="border-dark-fill-blue mb-4" />
          <div className="grid grid-cols-2 divide-x divide-solid divide-dark-fill-blue mb-4">
            <div className="inline-block px-4 py-4 space-y-2 pl-2">
              <CardSubHeader className="mb-4 pt-0 pl-4 text-text-blue font-extrabold text-lg">
                Invoice Details
              </CardSubHeader>
              <CardDetail label="Invoice ID#">{invoice.short_id}</CardDetail>
              <CardDetail label="Invoice Total">{totalAmountInvoiced}</CardDetail>
              {
                totalAmountPaid && (
                  <CardDetail label="Paid Amount">{totalAmountPaid}</CardDetail>
                )
              }
              {!isEmpty(invoice.client_address_postal_code) && (
              <CardDetail
                label="Zip Code (County)"
              >{`${invoice.client_address_postal_code } (${ invoice.client_address_county || '' })`}
              </CardDetail>
              )}
              {invoice?.payment_date && (
              <CardDetail label="Payment Date">
                {formatDate(invoice.payment_date)}
              </CardDetail>
              )}
              {invoice?.response_date && (
              <CardDetail label="Response Date">
                {formatDate(invoice.response_date, false)}
              </CardDetail>
              )}
              <CardDetail label="Date Created">
                {createdAt}
              </CardDetail>
              { isNetworkLead && (
              <CardDetail label="Provider">
                { invoice.provider_name }
              </CardDetail>
              )}
              <CardDetail label="Submitted By">
                { get(invoice, 'submitter.full_name') }
              </CardDetail>
            </div>
            <div className="inline-block px-4 py-4 pl-8 space-y-2">
              <CardSubHeader className="mb-4 pt-0 pl-4 text-text-blue font-extrabold text-lg">
                Client Details
              </CardSubHeader>
              {
                isCBOProvider && pays3693AddLinkToClientFacesheetInIwq ? (
                  <CardDetail label="Client Name">
                    <Link
                      className="text-action-blue"
                      to={`/facesheet/${invoice.client_id}`}
                    >
                      {startCase(toLower(invoice.client_name))}
                    </Link>
                  </CardDetail>
                ) : (
                  <CardDetail label="Client Name">{startCase(toLower(invoice.client_name))}</CardDetail>
                )
              }
              <CardDetail label="Client ID#">{invoice.insurance_external_member_id}</CardDetail>
              <CardDetail label="Health Plan">{startCase(invoice.plan_name)}</CardDetail>
              <CardDetail label="Social Care Coverage Plan">
                {startCase(invoice.social_plan_name)}
                {isMostRecent && insurance && insuranceLoaded && (
                <>
                  <br />
                  {formatShortDate(insurance.enrolled_at)}
                  {insurance.expired_at ?
                    ` - ${formatShortDate(insurance.expired_at)}` :
                    ' - present'}
                </>
              )}
              </CardDetail>
              {isMostRecent && insuranceLoaded && (
              <CardDetail label="Social Care Coverage Status">
                <InsuranceStatusIndicator
                  status={insurance?.insurance_status}
                  ignoreExpiration={feeSchedule.ignore_social_care_expired_dates}
                  showTooltip={false}
                  showInsuranceServiceDateWarning={
                    !feeSchedule.ignore_social_care_expired_dates &&
                    !!(insurance?.expired_at && invoice.service_authorization_approved_ends_at > insurance.expired_at)
                  }
                />
                {insurance?.insurance_status === INSURANCE_STATUSES.not_enrolled && (
                  <div className="mt-2"> The client’s
                    social care coverage ended prior to or during the selected service delivery date(s).
                    Please verify the service date(s) before submitting.
                  </div>
                )}
              </CardDetail>
              )}
            </div>
          </div>
          <hr className="border-dark-fill-blue mb-4" />
          <div className="grid grid-cols-2 divide-x divide-solid divide-dark-fill-blue mb-4">
            {showServiceAuthorizationSection && (
              <div className="inline-block px-4 py-4 space-y-2 pl-2">
                <CardSubHeader className="mb-4 pt-0 pl-4 text-text-blue font-extrabold text-lg">
                  Authorization Details
                </CardSubHeader>
                <AuthorizationDetails
                  invoice={invoice}
                  onDisableSubmitButtonChange={onButtonDisableChange}
                />
              </div>
            )}
            <div className={`inline-block px-4 py-4 space-y-2 pl-${showServiceAuthorizationSection ? '8' : '2'}`}>
              <CardSubHeader className="mb-4 pt-0 pl-4 text-text-blue font-extrabold text-lg">
                Service Details
              </CardSubHeader>
              <ServiceDetails
                invoice={invoice}
                placeOfService={placeOfService}
                procedureCodes={procedureCodes}
                procedureCodeModifiers={procedureCodeModifiers}
                serviceDescription={serviceDescription}
                showClaimsCodes={showClaimsCodes}
                zCodes={zCodes}
              />
            </div>
            <div className="pl-8">
              {!showServiceAuthorizationSection && providerInformation}
            </div>
          </div>
          {showServiceAuthorizationSection && (
          <>
            <hr className="border-dark-fill-blue mb-4" />
            <div className="grid grid-cols-2 divide-x divide-solid divide-dark-fill-blue mb-4 pl-2">
              {providerInformation}
              <div className="inline-block px-4 py-4 m-2 space-y-2" />
            </div>
          </>
        )}
        </Card>
        {/* Supporting Documents */}
        <Card className="mt-3 px-4 pb-3 pt-2 pl-2 bg-white">
          <CardSubHeader>Supporting Documents</CardSubHeader>
          { isFetching || isLoading ? <Spinner /> : (
            <div className="py-4 pl-4 space-y-2">
              {
                files.length ? (
                  <Table className="bg-white border-collapse border-solid border rounded border-dark-fill-blue">
                    <HeaderRow>
                      <ColumnHeader>File Name</ColumnHeader>
                      <ColumnHeader>Type</ColumnHeader>
                      <ColumnHeader>Date Modified</ColumnHeader>
                    </HeaderRow>
                    <TableBody data-testid="invoice-supporting-documents-table-body">
                      {
                        files.map((fileObject) => (
                          <DataRow
                            key={fileObject.id}
                            dataTestId="invoice-supporting-documents-table-row"
                          >
                            <DataCell className="max-w-xs">
                              <a
                                href={`${env.CORE_BASE_URL}${fileObject.path}`} rel="noreferrer" target="_blank"
                                className="flex text-action-blue pt-2 pl-2"
                                key={fileObject.id}
                              >
                                <div className="pr-1">
                                  <FileThumbnail size={20} color="#4467AB" contentType={fileObject.content_type} />
                                </div>
                                <div className="truncate">{fileObject.filename}</div>
                              </a>
                            </DataCell>
                            <DataCell className="max-w-xs">
                              {formatFileType(fileObject.id, metadata, metafields)}
                            </DataCell>
                            <DataCell>{
                              // formats the created at date in the fileObject per https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
                              new Intl
                                .DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
                                .format(fileObject.created_at ? new Date(fileObject.created_at) : new Date())
                              }
                            </DataCell>
                          </DataRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                ) : <div className="pt-1">No supporting documents available.</div>
              }
            </div>
          )}
        </Card>
        <Card className="bg-white px-2 py-4">
          <CardSubHeader>History</CardSubHeader>
          <div className="mt-4 max-w-3xl grid grid-cols-12 gap-4">
            {!isEmpty(versions) && (
              <>
                <div className="col-span-3 font-heavy-font text-text-blue text-14px">Status Updates:</div>
                <div className="col-span-9">
                  <AuditTrail versions={versions.map(prepareVersion)} />
                </div>
              </>
            )}
            <div className="col-span-3 font-heavy-font text-text-blue text-14px">Previous Invoices:</div>
            <div className="col-span-9">
              {previousInvoices.length || invoice.original_version ? (
                <div className="relative flex flex-col space-y-3">
                  {[...(previousInvoices || []), invoice.original_version].map((inv) => (
                    <Link className="text-action-blue underline" to={`/invoices/${inv.id}`} key={inv.id}>
                      {inv.short_id}
                    </Link>
                  ))}
                </div>
              ) : (
                'This invoice is the original'
              )}
            </div>
            {
              !isNetworkLead && !showPayerInvoices && (
                <>
                  <div className="col-span-3 font-heavy-font text-text-blue text-14px">
                    Contracted Service Note:
                  </div>
                  <div className="col-span-9">
                    <Link
                      className="text-action-blue underline"
                      to={`/dashboard/cases/all/${invoice.case_id}/contact/${invoice.client_id}`}
                    >
                      {invoice.fee_schedule_program_name}
                    </Link>
                  </div>
                </>
              )
            }
          </div>
        </Card>
      </div>
    </>
  );
};

InvoiceDetailSingleCard.propTypes = {
  createdAt: PropTypes.string,
  currentEmployee: PropTypes.object.isRequired,
  disableSubmitButton: PropTypes.bool.isRequired,
  displayAmount: PropTypes.string,
  feeSchedule: PropTypes.object,
  files: PropTypes.array,
  groupId: PropTypes.string.isRequired,
  insurance: PropTypes.object,
  invoice: PropTypes.object.isRequired,
  invoicesFromProvidedServiceId: PropTypes.array,
  isFetching: PropTypes.bool,
  isLoading: PropTypes.bool,
  isNetworkLead: PropTypes.bool.isRequired,
  isCBOProvider: PropTypes.bool.isRequired,
  metadata: PropTypes.array.isRequired,
  networkId: PropTypes.string.isRequired,
  onDisableSubmitButtonChange: PropTypes.func.isRequired,
  previousInvoices: PropTypes.array,
  procedureCodes: PropTypes.array,
  procedureCodeModifiers: PropTypes.array,
  providerAddress: PropTypes.object,
  placeOfService: PropTypes.array,
  serviceDescription: PropTypes.string,
  showClaimsCodes: PropTypes.bool,
  showFixAndMakeNewInvoice: PropTypes.bool,
  showNCCorrectPaymentWording: PropTypes.bool,
  showPayerInvoices: PropTypes.bool,
  totalAmountInvoiced: PropTypes.string,
  totalAmountPaid: PropTypes.string,
  useInvoiceUserRole: PropTypes.bool.isRequired,
  useNcHopDisputeProcess: PropTypes.bool,
  versions: PropTypes.array.isRequired,
  zCodes: PropTypes.array,
  payerWQInteractiveView: PropTypes.bool,
  insuranceLoaded: PropTypes.bool,
};

InvoiceDetailSingleCard.defaultProps = {
  createdAt: '',
  displayAmount: '',
  feeSchedule: {},
  files: [],
  invoicesFromProvidedServiceId: [],
  insurance: null,
  isFetching: false,
  isLoading: false,
  placeOfService: [],
  previousInvoices: [],
  procedureCodes: [],
  procedureCodeModifiers: [],
  providerAddress: {},
  serviceDescription: '',
  showClaimsCodes: false,
  showFixAndMakeNewInvoice: false,
  showNCCorrectPaymentWording: false,
  showPayerInvoices: false,
  totalAmountInvoiced: '',
  totalAmountPaid: '',
  useNcHopDisputeProcess: false,
  zCodes: [],
  payerWQInteractiveView: false,
  insuranceLoaded: false,
};

InvoiceDetailSingleCard.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const groupId = state.session.groupId;
  const networkId = state.networks.networkId;
  const showNCCorrectPaymentWording = hasNCCorrectPaymentWording(state);

  return {
    groupId,
    networkId,
    showNCCorrectPaymentWording,
    showClaimsCodes: hasPaysClaimsCodes(state),
    showPayerInvoices: hasPayerInvoicesRole(state),
  };
}

export default connect(mapStateToProps)(InvoiceDetailSingleCard);
