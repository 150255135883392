import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Form, Field } from 'react-final-form';
import { Button, DateField, SelectField } from '@unite-us/ui';
import moment from 'moment';
import { browserHistory } from 'common/utils/browserHistory';
import { InputField, RadioField, RichTextInputField } from 'components/Backoffice/form';
import { EMPTY_DRAFT_FIELD } from 'src/components/Group/Programs/constants';
import { validations } from '@unite-us/client-utils';
import composeValidators from 'src/components/Organization/utils/composeValidators';
import {
  useFindFeeSchedule,
} from 'src/components/Backoffice/api/hooks/v1/feeScheduleHooks';
import { BackButton } from 'common/buttons';
import { Spinner } from 'common/spinners';
import { useUniteUsServices } from 'src/components/Backoffice/api/hooks/v1/serviceHooks';
import {
  createServiceOptions,
} from 'src/pages/backoffice/forms/components/editLogicDataTransformers';
import './FeeScheduleProgramForm.scss';

const PAYMENT_TYPE_OPTIONS = [
  { label: 'Fee for Service', value: 'fee_for_service' },
  { label: 'Per Member Per Month', value: 'per_member_per_month' },
  { label: 'Cost Based Reimbursement', value: 'cost_based_reimbursement' },
  { label: 'Per Diem', value: 'per_diem' },
  { label: 'Funds Distributed', value: 'funds_distributed' },
];
const REQUIRES_UNIT_RATE = ['fee_for_service', 'per_member_per_month', 'per_diem'];
const REQUIRES_UNIT_TYPE = ['fee_for_service', 'per_member_per_month', 'per_diem', 'funds_distributed'];
const REQUIRES_CAP_INFO = ['cost_based_reimbursement'];

const STATE_OPTIONS = [
  { label: 'Active', value: 'active' },
  { label: 'Archived', value: 'archived' },
];

const BOOL_OPTIONS = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

const AUTHORIZATION_OPTIONS = [
  { label: 'Required', value: 'true' },
  { label: 'Not Required', value: 'false' },
];

const getMinDate = (pickedStartDate, feeScheduleStartsAt) => {
  const pickedStartMoment = moment(pickedStartDate, 'MM/DD/YYYY');
  if (pickedStartMoment.isAfter(moment(feeScheduleStartsAt))) {
    return pickedStartMoment.add('12', 'h').format('MM/DD/YYYY');
  }
  return moment(feeScheduleStartsAt).add('12', 'h').format('MM/DD/YYYY');
};

const getMaxDate = (pickedEndDate, feeScheduleEndsAt) => {
  const pickedEndMoment = moment(pickedEndDate, 'MM/DD/YYYY');
  if (pickedEndMoment.isBefore(moment(feeScheduleEndsAt))) {
    return pickedEndMoment.subtract('12', 'h').format('MM/DD/YYYY');
  }
  return moment(feeScheduleEndsAt).subtract('12', 'h').format('MM/DD/YYYY');
};

export const FeeScheduleProgramForm = ({
    initialValues,
    onSubmit,
    feeScheduleId,
    feeScheduleProgramId,
    showPending,
  }) => {
  const { data: feeSchedule } = useFindFeeSchedule(feeScheduleId);
  const isEditing = !isEmpty(feeScheduleProgramId);
  const feeScheduleStartsAt = feeSchedule.starts_at ?? moment.utc().startOf('day').toISOString();
  const feeScheduleEndsAt = feeSchedule.ends_at ?? moment.utc().startOf('day').add('7', 'days').toISOString();
  const payment_type = initialValues.payment_type;
  const [isDisabledUnit, setIsDisabledUnit] = useState(isEmpty(payment_type));
  const [isRequiredUnitRate, setIsRequiredUnitRate] = useState(REQUIRES_UNIT_RATE.includes(payment_type));
  const [isRequiredUnitType, setIsRequiredUnitType] = useState(REQUIRES_UNIT_TYPE.includes(payment_type));
  const [isRequiredCapInfo, setIsRequiredCapInfo] = useState(REQUIRES_CAP_INFO.includes(payment_type));
  const { data: services } = useUniteUsServices();
  const SERVICE_TYPE_OPTIONS = createServiceOptions(services);

  const onCancel = useCallback(() => {
    const basePath = `/backoffice/fee_schedules/${feeScheduleId}`;
    const feeScheduleProgramPath = `${basePath}/fee_schedule_programs/${feeScheduleProgramId}`;
    browserHistory.push({
      pathname: isEditing ? feeScheduleProgramPath : basePath,
    });
  }, [feeScheduleId, isEditing]);

  const title = isEditing ? 'Edit Fee Schedule Program' : 'Add New Program';
  return (
    <div id="fee_schedule_program_form" className="bg-dark-fill-blue">
      <div className="flex p-4 justify-start items-center bg-white">
        <div>
          <BackButton navigateTo={onCancel} />
        </div>
        <h1 className="pl-6 w-9/12 font-bold">{title}</h1>
      </div>
      {showPending && <Spinner />}
      {!showPending && (
      <div className="p-6">
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={({ handleSubmit, values }) => (
            <form
              onSubmit={handleSubmit}
              className={
                'grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-12 ' +
                'pl-10 pt-6 bg-light-border-grey border border-solid rounded overflow-hidden border-dark-border-blue'
              }
            >
              <Field
                name="name"
                validate={composeValidators(validations.isRequired)}
              >
                {(props) => (
                  <InputField
                    className="md:col-span-8 xl:col-span-8 px-0"
                    id="fee-schedule-program__name"
                    label="Fee Schedule Program Name"
                    required
                    dataTestId="fs-program-name-field"
                    {...props}
                  />
                )}
              </Field>
              <div className="md:col-span-4 xl:col-span-4 px-0" />
              <Field
                name="external_code"
              >
                {(props) => (
                  <InputField
                    className="md:col-span-4 xl:col-span-4 px-0"
                    id="fee-schedule-program__external-code"
                    label="External Code"
                    dataTestId="fs-external-code-field"
                    {...props}
                  />
                )}
              </Field>
              <div className="md:col-span-8 xl:col-span-8 px-0" />
              <Field
                name="services"
              >
                {(props) => (
                  <SelectField
                    className="md:col-span-4 xl:col-span-4 px-0 service_type-select_field"
                    id="fee-schedule-program__service-type"
                    label="Service Type"
                    options={SERVICE_TYPE_OPTIONS}
                    value={values?.services}
                    forceObjectValue
                    labelKey="name"
                    valueKey="id"
                    multiple
                    {...props}
                  />
                )}
              </Field>
              <div className="md:col-span-8 xl:col-span-8 px-0" />
              <Field
                name="unit"
                validate={isRequiredUnitType && composeValidators(validations.isRequired)}
              >
                {(props) => (
                  <InputField
                    className="md:col-span-8 xl:col-span-4 px-0"
                    disabled={isDisabledUnit}
                    id="fee-schedule-program__unit"
                    label="Unit Type"
                    required={isRequiredUnitType}
                    dataTestId="fs-program-unit-type-field"
                    {...props}
                  />
              )}
              </Field>
              <Field
                name="unit_rate"
                validate={isRequiredUnitRate && composeValidators(validations.isRequired)}
              >
                {(props) => (
                  <InputField
                    className="md:col-span-4 xl:col-span-4 pl-4 pr-0"
                    disabled={isDisabledUnit}
                    id="fee-schedule-program__unit-rate"
                    label="Unit Rate (cents)"
                    required={isRequiredUnitRate}
                    type="number"
                    dataTestId="fs-program-unit-rate-field"
                    {...props}
                  />
                )}
              </Field>
              <Field
                name="payment_type"
                validate={composeValidators(validations.isRequired)}
                className="md:col-span-8 xl:col-span-8 px-0"
              >
                {(props) => (

                  <SelectField
                    className="md:col-span-4 xl:col-span-4 px-0 service_type-select_field"
                    id="fee-schedule-program__payment-type"
                    label="Payment Type"
                    options={PAYMENT_TYPE_OPTIONS}
                    value={values?.payment_type}
                    onChange={(value) => {
                      setIsDisabledUnit(false);
                      setIsRequiredUnitRate(REQUIRES_UNIT_RATE.includes(value));
                      setIsRequiredUnitType(REQUIRES_UNIT_TYPE.includes(value));
                      setIsRequiredCapInfo(REQUIRES_CAP_INFO.includes(value));
                    }}
                    required
                    {...props}
                  />
                )}
              </Field>
              <div className="md:col-span-8 xl:col-span-8 px-0 flex">
                <Field
                  name="starts_at"
                  validate={(value) => {
                    if (value && values.ends_at) {
                      if (moment(value).isSameOrAfter(values.ends_at)) {
                        return 'Start date should be before end date';
                      }
                      if (moment(value).isBefore(feeScheduleStartsAt)) {
                        return `Earliest start date is
                        ${moment(feeScheduleStartsAt).add('12', 'h').format('MM/DD/YYYY')}`;
                      }
                    }
                    return validations.isRequired(value);
                  }}
                >
                  {(props) => (
                    <DateField
                      className="md:col-span-4 xl:col-span-4 pl-0 pr-4"
                      id="fee-schedule-program__starts-at-field"
                      label="Start Date"
                      minDate={moment(feeScheduleStartsAt).subtract('12', 'h').format('MM/DD/YYYY')}
                      maxDate={getMaxDate(values.ends_at, feeScheduleEndsAt)}
                      required
                      valueFormat="MM/DD/YYYY"
                      {...props}
                    />
                  )}
                </Field>
                <Field
                  name="ends_at"
                  validate={(value) => {
                    if (value && values.starts_at) {
                      if (moment(value).isSameOrBefore(values.starts_at)) {
                        return 'End date should be after start date';
                      }
                      if (moment(value).isAfter(feeScheduleEndsAt)) {
                        return `Latest end date is ${moment(feeScheduleEndsAt).format('MM/DD/YYYY')}`;
                      }
                    }
                    return validations.isRequired(value);
                  }}
                >
                  {(props) => (
                    <DateField
                      className="md:col-span-4 xl:col-span-4 pl-4 pr-0"
                      id="fee-schedule-program__ends-at-field"
                      label="End Date"
                      minDate={getMinDate(values.starts_at, feeScheduleStartsAt)}
                      maxDate={moment(feeScheduleEndsAt).add('12', 'h').format('MM/DD/YYYY')}
                      required
                      valueFormat="MM/DD/YYYY"
                      {...props}
                    />
                  )}
                </Field>
              </div>
              {isEditing && (
                <>
                  <Field
                    name="state"
                    className="md:col-span-8 xl:col-span-8 px-0"
                  >
                    {(props) => (
                      <RadioField
                        className="flex-wrap md:col-span-8 xl:col-span-4 px-0"
                        id="fee-schedule-program__state"
                        label="Status"
                        options={STATE_OPTIONS}
                        {...props}
                      />
                    )}
                  </Field>
                </>
              )}
              <Field
                name="billable"
                validate={composeValidators(validations.isRequired)}
                className="md:col-span-8 xl:col-span-8 px-0"
              >
                {(props) => (
                  <RadioField
                    className="flex-wrap md:col-span-8 xl:col-span-4 px-0"
                    id="fee-schedule-program__billable"
                    label="Billable"
                    options={BOOL_OPTIONS}
                    required
                    dataTestId="fs-program-billable-radio-group"
                    {...props}
                  />
                )}
              </Field>
              <Field
                name="authorization_required"
                validate={composeValidators(validations.isRequired)}
                className="md:col-span-8 xl:col-span-8 px-0"
              >
                {(props) => (
                  <RadioField
                    className="flex-wrap md:col-span-8 xl:col-span-4 px-0"
                    id="fee-schedule-program__authorization"
                    label="Authorization"
                    options={AUTHORIZATION_OPTIONS}
                    required
                    dataTestId="fs-program-auth-radio-group"
                    {...props}
                  />
                )}
              </Field>

              {values.authorization_required === 'true' && (
                <Field
                  name="auto_authorizes"
                  validate={composeValidators(validations.isRequired)}
                  className="md:col-span-8 xl:col-span-8 px-0"
                >
                  {(props) => (
                    <RadioField
                      className="flex-wrap md:col-span-8 xl:col-span-4 px-0"
                      hint="Skip UM approval step"
                      id="fee-schedule-program__auth_authorizes"
                      label="Auto-Approved Authorization"
                      options={BOOL_OPTIONS}
                      dataTestId="fs-program-auto-auth-radio-group"
                      {...props}
                    />
                  )}
                </Field>
              )}
              {values.authorization_required === 'true' && (
                <Field
                  name="can_invoice_above_remaining_authorized_amount"
                  validate={composeValidators(validations.isRequired)}
                  className="md:col-span-8 xl:col-span-8 px-0"
                >
                  {(props) => (
                    <RadioField
                      className="flex-wrap md:col-span-8 xl:col-span-4 px-0"
                      hint="When it's on, show warnings when Invoice Amount exceeds the Authorized Amount.
                      These warnings do not prevent users from submitting. The warnings are displayed to:
                      1. CBO Case Manager when they create a contracted service note
                      2. CBO user, NL and Payer when approving the invoices"
                      id="fee-schedule-program__invoice_above_auth"
                      label="Allow Invoicing Over Authorized Amount"
                      options={BOOL_OPTIONS}
                      dataTestId="fs-program-invoice-above-auth-radio-group"
                      {...props}
                    />
                  )}
                </Field>
              )}
              <Field
                name="description"
                className="md:col-span-8 p-0 px-0"
              >
                {(props) => (
                  <RichTextInputField
                    hint="Write a few sentences about the program."
                    label="Description"
                    dataTestId="fs-program-description-field"
                    {...props}
                  />
                )}
              </Field>
              <Field
                name="eligibility"
                className="md:col-span-8 p-0"
              >
                {(props) => (
                  <RichTextInputField
                    hint="Define any rules on who can be a part of this program."
                    label="Eligibility"
                    dataTestId="fs-program-eligibility-field"
                    {...props}
                  />
                )}
              </Field>
              <Field
                name="cap_information"
                className="md:col-span-8 p-0"
                validate={(value) => isRequiredCapInfo &&
                  validations.isRequired(value === EMPTY_DRAFT_FIELD ? null : value)}
              >
                {(props) => (
                  <RichTextInputField
                    hint="Outline the spending limit."
                    id="fee-schedule-program__cap-information"
                    label="Cap Information"
                    labelClassName="Cap Information"
                    required={isRequiredCapInfo}
                    dataTestId="fs-program-cap-info-field"
                    {...props}
                  />
                )}
              </Field>
              <div className="md:col-span-4 px-0" />
              <Field
                name="billing_limits"
                className="md:col-span-8 p-0"
              >
                {(props) => (
                  <RichTextInputField
                    hint="Determine the maximum amount an organization gets billed."
                    label="Billing Limits"
                    dataTestId="fs-program-billing-limits-field"
                    {...props}
                  />
                )}
              </Field>
              <div className="md:col-span-4 px-0" />

              <div className="flex items-start mt-8 pb-9">
                <Button
                  label="Cancel"
                  onClick={onCancel}
                  data-testid="cancel-add-fs-program-btn"
                />
                <Button
                  className="mr-5 ml-3"
                  type="submit"
                  label={isEditing ? 'Update' : 'Create'}
                  primary
                  data-testid="add-fs-program-btn"
                />
              </div>
            </form>
          )}
        />
      </div>
      )}
    </div>
  );
};

FeeScheduleProgramForm.propTypes = {
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    feeScheduleId: PropTypes.string.isRequired,
    feeScheduleProgramId: PropTypes.string,
    showPending: PropTypes.bool,
};

FeeScheduleProgramForm.defaultProps = {
    initialValues: {},
    showPending: false,
    feeScheduleProgramId: '',
};

export default FeeScheduleProgramForm;
