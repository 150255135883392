import React from 'react';
import PropTypes from 'prop-types';
import { Field, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { InputField } from '@unite-us/ui';
import { validations } from '@unite-us/client-utils';
import ButtonAddField from './ButtonAddField';
import ButtonRemoveField from './ButtonRemoveField';

const EmailAddressesField = ({
  label,
  name,
}) => {
  const { mutators: { push } } = useForm();
  return (
    <div className="relative">
      <ButtonAddField
        data-test-element="add_email"
        className="h-auto"
        isPositioned
        label="Add Email"
        onClick={() => push(name, {})}
      />
      <FieldArray name="email_addresses">
        {({ fields }) => (fields.length ? fields.map((fieldName, index) => (
          <div key={`org-fieldset-${fieldName}`} data-testid="email_addresses_set_row" className="flex">
            <div className="flex w-full">
              <div className="flex w-9/12" data-test-element={`email_${index}`}>
                <Field
                  name={`${fieldName}.email_address`}
                  validate={(value, { email_addresses }) => {
                    if (value && validations.isEmail(value)) {
                      return validations.isEmail(value);
                    }
                    if (
                      email_addresses.length > 1 &&
                      email_addresses
                        .map(({ email_address }) => email_address)
                        .filter((email) => email === value).length > 1
                    ) {
                      return 'Email must be unique';
                    }
                    return undefined;
                  }}
                >
                  {(params) => (
                    <InputField
                      label={label}
                      id={`org-email-${index}`}
                      hideLabel={!!index}
                      {...params}
                    />
                  )}
                </Field>
              </div>
              <ButtonRemoveField
                onClick={() => fields.remove(index)}
                testId={`email_trash_${index}`}
              />
            </div>
          </div>
        )) : <div className="h-16" />)}
      </FieldArray>
    </div>
  );
};

EmailAddressesField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default EmailAddressesField;
