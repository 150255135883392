import PropTypes from 'prop-types';
import React, { Component } from 'react';
import today from 'common/utils/today';
import { validateReduxForm } from 'common/form';
import { validations, NoteDisclosure } from '@unite-us/client-utils';
import {
  DateField,
  RadioField,
  SelectField,
} from '@unite-us/ui';
import _ from 'lodash';
import './LoginInteraction.scss';
import FormInteractionsButtons from './FormInteractionsButtons';
import AttachedToField from './AttachedToField';
import InteractionNoteField from './InteractionNoteField';
import SelectServiceCases from './SelectServiceCases';
import { SENSITIVE_GENERAL_INTERACTION_HINT_TEXT } from './constants';
import NoteVisibility from './NoteVisibility';

const INTERACTION_FORM = 'logInteraction';
const INTERACTION_TYPE_OPTIONS = [
  {
    label: 'Phone',
    value: 'phone_call',
    style: { display: 'inline-block', width: '90px' },
    labelStyle: { width: 'calc(100% - 30px)' },
    iconStyle: { marginRight: '8px' },
  },
  {
    label: 'Email',
    value: 'email',
    style: { display: 'inline-block', width: '80px' },
    labelStyle: { width: 'calc(100% - 30px)' },
    iconStyle: { marginRight: '8px' },
  },
  {
    label: 'In-Person',
    value: 'meeting',
    style: { display: 'inline-block', width: '100px' },
    labelStyle: { width: 'calc(100% - 30px)' },
    iconStyle: { marginRight: '8px' },
  },
];

export class LogInteraction extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onSubmit(values) {
    return this.props.submitFunction(values)
      .then(this.props.resetForm);
  }

  onCancel() {
    this.props.cancelFunction();
    this.props.resetForm();
  }

  render() {
    const {
      durations,
      fields,
      handleSubmit,
      isFromCaseOrReferral,
      isMyProviderSensitive,
      registerField,
      submitFailed,
      submitting,
      showAttachedToField,
      showAttachedToCases,
      showNoteVisibility,
    } = this.props;

    const hideDuration = fields.interaction.type.value === 'email';
    const hintText = isMyProviderSensitive ? SENSITIVE_GENERAL_INTERACTION_HINT_TEXT : '';
    const isGeneralNote = fields.interaction.attached_to.value === 'general';
    const hasCases = fields.validateCases.value;

    const warningMessage = <NoteDisclosure />;

    return (
      <div className="log-interaction">
        <form data-role="log-interaction-form">
          <div className="row">
            <div className="col-sm-4">
              <RadioField
                field={fields.interaction.type}
                label="Interaction Type"
                options={INTERACTION_TYPE_OPTIONS}
              />
            </div>

            <div className="col-sm-4">
              <DateField
                field={fields.interaction.occurred_on}
                id="interactionDate"
                label="Interaction Date"
                ref={registerField}
                validations={validations.isRequired}
                required
                maxDate={today()}
              />
            </div>

            <div className={`col-sm-4 ${hideDuration && 'hidden'}`}>
              <SelectField
                id="interactionDuration"
                className="interaction-duration-select"
                label="Duration"
                searchEnabled={false}
                field={fields.interaction.duration}
                options={durations}
                ref={registerField}
                hidden={hideDuration}
                validations={validations.isRequired}
                required
                shouldSort={false}
                labelKey="display_name"
                valueKey="value"
              />
            </div>
          </div>

          {
            showAttachedToField ? (
              <AttachedToField
                field={fields.interaction.attached_to}
                hidden={!hasCases}
              />
            ) : null
          }

          <InteractionNoteField
            afterLabelContent={warningMessage}
            field={fields.interaction.note}
            hintText={hintText}
            registerField={registerField}
            showHint={(isGeneralNote || !hasCases) && !isFromCaseOrReferral}
          />

          {
            showNoteVisibility ? (
              <NoteVisibility
                registerField={registerField}
                disabled={isMyProviderSensitive}
                field={fields.interaction.is_note_private}
              />
            ) : null
          }

          {
            showAttachedToCases ? (
              <SelectServiceCases
                hidden={
                  !hasCases ||
                  isGeneralNote
                }
                registerField={registerField}
                serviceCases={fields.caseOptions}
                submitFailed={submitFailed}
              />
            ) : null
          }

          <FormInteractionsButtons
            cancelActionHandler={this.onCancel}
            interaction="log-interaction"
            primaryActionHandler={handleSubmit(this.onSubmit)}
            submitting={submitting}
          />
        </form>
      </div>
    );
  }
}

LogInteraction.propTypes = {
  caseOptions: PropTypes.array.isRequired,
  durations: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isFromCaseOrReferral: PropTypes.bool.isRequired,
  isMyProviderSensitive: PropTypes.bool.isRequired,
  registerField: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  submitFunction: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  cancelFunction: PropTypes.func,
  showAttachedToField: PropTypes.bool,
  showAttachedToCases: PropTypes.bool,
  showNoteVisibility: PropTypes.bool.isRequired,
};

LogInteraction.defaultProps = {
  cancelFunction: _.noop,
  showAttachedToField: true,
  showAttachedToCases: true,
};

function mapStateToProps(state, ownProps) {
  const { caseOptions = [] } = ownProps;
  const isMyProviderSensitive = _.get(state, 'globalState.currentProvider.group.sensitive', null);
  const validateCases = !_.isEmpty(ownProps.caseOptions);
  const showNoteVisibility = ownProps.detailType === 'profile';
  const is_note_private = Boolean(showNoteVisibility && isMyProviderSensitive);

  return {
    durations: _.wget(state, 'session.enums.time.duration', []),
    initialValues: {
      caseOptions,
      interaction: {
        attached_to: 'case',
        occurred_on: today(),
        type: 'phone_call',
        is_note_private,
      },
      validateCases,
    },
    isMyProviderSensitive,
    showNoteVisibility,
  };
}

const fields = [
  'interaction.is_note_private',
  'interaction.attached_to',
  'interaction.type',
  'interaction.occurred_on',
  'interaction.duration',
  'interaction.note',
  'caseOptions[].id',
  'caseOptions[].selected',
  'caseOptions[].label',
  'caseOptionSelected',
  'validateCases',
];

export default validateReduxForm({
  form: INTERACTION_FORM,
  fields,
}, mapStateToProps)(LogInteraction);
