function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t["return"] || t["return"](); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import _ from 'lodash';
export function isRequired(value) {
  var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Required';
  if (_.isUndefined(value) || _.isNull(value) || value === '' || _.isNaN(value) || _.isArray(value) && _.isEmpty(value) || _.isObject(value) && value instanceof FileList && value.length <= 0 || _.isObject(value) && !(value instanceof FileList) && !_.some(_.values(value), function (v) {
    return v;
  })) {
    return message;
  }
  return null;
}
export function isEmail(value) {
  var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Should be a valid email address';
  /* eslint-disable */
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  /* eslint-enable */
  if (value && !re.test(value)) {
    return message;
  }
  return null;
}
export function isUrl(value) {
  var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Should be a valid url';
  var re = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/;
  if (value && !re.test(value)) {
    return message;
  }
  return null;
}
export function containsUppercase(value) {
  var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Should contain at least one uppercase letter';
  var regex = /[A-Z]/g;
  if (value && !regex.test(value)) {
    return message;
  }
  return null;
}
export function containsLowercase(value) {
  var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Should contain at least one lowercase letter';
  var regex = /[a-z]/g;
  if (value && !regex.test(value)) {
    return message;
  }
  return null;
}
export function containsDigit(value) {
  var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Should contain at least one digit';
  var regex = /\d/g;
  if (value && !regex.test(value)) {
    return message;
  }
  return null;
}
export function containsSpecialChars(value) {
  var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Should contain at least one special character';
  var regex = /[^a-zA-Z\d\s:]+/g;
  if (value && !regex.test(value)) {
    return message;
  }
  return null;
}
export function isEqual(value, message, args) {
  if (value !== args) {
    return message || 'This should be '.concat(args);
  }
  return null;
}
export function isDifferent(value, message, args) {
  if (value && value === args) {
    return message || 'This should not be '.concat(args);
  }
  return null;
}
export function isLongerThan(value, message, args) {
  if (value && value.length < args) {
    return message || "Should be longer than ".concat(args, " chars.");
  }
  return null;
}
export function isShorterThan(value, message, args) {
  if (value && value.length > args) {
    return message || "Should be shorter than ".concat(args, " chars.");
  }
  return null;
}
export function isNumber(value) {
  var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Should be a number';
  var reg = /^[+-]?\d+(\.\d+)?$/;
  if (value && !reg.test(value)) {
    return message;
  }
  return null;
}
export function isInteger(value, message) {
  if (value && !_.isInteger(parseFloat(value, 10))) {
    return message || 'Must be a whole number';
  }
  return null;
}
export function includes(value, message, args) {
  var collection = args;
  if (_.isString(args)) {
    collection = _.map(args.split(','), function (item) {
      return _.trim(item);
    });
  }
  collection = _.map(collection, function (item) {
    return _.toLower(item);
  });
  if (_.indexOf(collection, _.toLower(value)) < 0) {
    return message || "Should be one of: ".concat(collection.join(', '));
  }
  return null;
}
export function excludes(value, message, args) {
  var collection = args;
  if (_.isString(args)) {
    collection = _.map(args.split(','), function (item) {
      return _.trim(item);
    });
  }
  collection = _.map(collection, function (item) {
    return _.toLower(item);
  });
  if (_.indexOf(collection, _.toLower(value)) >= 0) {
    return message || "Should not be one of: ".concat(collection.join(', '));
  }
  return null;
}
export function hasAtLeastOne(value) {
  var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'At least one must be selected';
  if (!value || value.length < 1) {
    return message;
  }
  return null;
}
export function hasAtLeastN(value, message, number) {
  if (!value || value.length < number) {
    return message || "At least ".concat(number, " must be selected");
  }
  return null;
}
export function hasAtMostN(value, message, number) {
  if (value && value.length > number) {
    return message || "At most ".concat(number, " must be selected");
  }
  return null;
}
export function isPhoneNumber(value) {
  var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Must be at least 10 digits';
  if (!value) {
    return null;
  }
  var filtered = _.replace(value, /[^\d]/g, '');
  // If value is ONLY non-digits, it should fail validation.
  return !filtered ? message : isLongerThan(filtered, message, 10);
}
export function hasNoDuplicates(index, collection, message, normalizeFn) {
  var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
  if (!collection[index] || collection.length < 2) return null;
  var normalizedCollection = collection.map(normalizeFn);
  var normalizedValue = normalizedCollection[index];
  var firstIndex = normalizedCollection.indexOf(normalizedValue);
  if (options.minLength && normalizedValue.length < options.minLength) return null;
  var count = 0;
  var _iterator = _createForOfIteratorHelper(normalizedCollection),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var item = _step.value;
      if (item === normalizedValue) count += 1;
      if (count > 1 && index > firstIndex) return message;
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  return null;
}
export function isGreaterThan(value, message, args) {
  if ((value || value === 0) && value <= args) {
    return message || "Should be greater than ".concat(args);
  }
  return null;
}
export function isLessThan(value, message, args) {
  if ((value || value === 0) && value >= args) {
    return message || "Should be less than ".concat(args);
  }
  return null;
}
export function isGreaterOrEqualThan(value, message, args) {
  if ((value || value === 0) && value < args) {
    return message || "Should be greater than or equal to ".concat(args);
  }
  return null;
}
export function isLessOrEqualThan(value, message, args) {
  if ((value || value === 0) && value > args) {
    return message || "Should be less than or equal to ".concat(args);
  }
  return null;
}
export function isPositiveNumber(value, message) {
  return isGreaterOrEqualThan(value, message, 0);
}
export function isFieldRequired(validations) {
  if (_.isFunction(validations) && _.isEqual(validations, isRequired)) {
    return true;
  }
  if (_.isObject(validations) && _.isEqual(_.get(validations, 'func', null), isRequired)) {
    return true;
  }
  if (_.isArray(validations)) {
    return _.reduce(validations, function (acc, v) {
      if (_.isEqual(_.get(v, 'func', null), isRequired)) {
        return true;
      }
      return acc;
    }, false);
  }
  return false;
}
export function isValidUUID(value) {
  var regexValidUUID = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regexValidUUID.test(value) ? false : 'Should be a valid UUID';
}