import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isNull } from 'lodash';
import {
  TableRow,
  TableRowColumn,
  Popover,
} from '@unite-us/ui';
import { connect } from 'react-redux';
import { dates } from '@unite-us/client-utils';
import { tableWidth } from 'common/helpers/Tables';
import { viewable } from 'common/utils/Permission/Permission';
import callOrLog from 'src/common/utils/callOrLog';
import firstLine from 'src/common/utils/firstLine';
import { FACESHEET } from 'common/utils/EventTracker/utils/eventConstants';
import { goToCase } from 'src/components/Cases/utils/routing';
import CaseRowIcon from 'src/components/Dashboard/components/Cases/CaseRowIcon';
import { showFacesheetCasesTableDescription } from 'common/utils/FeatureFlags/flags';
import './CaseTableRow.scss';

class CaseTableRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popoverOpen: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    const { groupId, serviceCase } = this.props;

    if (viewable(serviceCase)) {
      callOrLog(() => this.context.eventTracker(FACESHEET.serviceCaseClicked, null, {
        serviceCase,
      }));
      goToCase(serviceCase, {}, groupId);
    } else {
      this.setState({ popoverOpen: !this.state.popoverOpen });
    }
  }

  render() {
    const {
      serviceCase,
      serviceType,
      primaryWorker,
      serviceCaseGroup,
      index,
      parent,
      rowIndex,
      secondaryDataList,
      showDescription,
    } = this.props;

    // We don't have much room in the table and we don't want to overflow into
    // multiple lines, so we try to take only the first line. If the first line
    // is really long or the entire description is on one line that is alright
    // because we use CSS to truncate the description to ensure it only takes a
    // single line. This just makes it look nicer and allows users to have a
    // pseudo title for the case.
    const description = firstLine(serviceCase.description);

    return (
      <TableRow
        className="case-row"
        key={`case-row-${index}`}
        id={`case-row-${serviceCase.id}`}
        onCellClick={this.toggle}
        parent={parent}
        rowIndex={rowIndex}
        secondaryDataList={secondaryDataList}
      >
        <TableRowColumn style={{ width: tableWidth('icon') }}>
          <CaseRowIcon serviceCase={serviceCase} />
        </TableRowColumn>
        <TableRowColumn style={{ width: tableWidth('small') }}>
          {isNull(serviceCase.opened_date) ?
            dates.formatDate(serviceCase.created_at) :
            dates.formatDate(serviceCase.opened_date)}
        </TableRowColumn>
        <TableRowColumn style={{ width: tableWidth('small') }}>
          {serviceCase.outcome ? 'Closed' : 'Open'}
        </TableRowColumn>
        { showDescription && (
          <TableRowColumn style={{ width: tableWidth('medium') }}>
            <span className="truncate">{description}</span>
          </TableRowColumn>
        )}
        <TableRowColumn style={{ width: tableWidth('medium') }}>
          {serviceType && serviceType.name}
        </TableRowColumn>
        <TableRowColumn style={{ width: tableWidth('medium') }}>
          {primaryWorker && primaryWorker.full_name}
        </TableRowColumn>
        <TableRowColumn style={{ width: tableWidth('large') }}>
          {serviceCaseGroup && serviceCaseGroup.name}
        </TableRowColumn>
        <Popover
          placement="bottom"
          isOpen={this.state.popoverOpen}
          target={`case-row-${serviceCase.id}`}
          body="You do not have permission to access this case"
          toggle={this.toggle}
        />
      </TableRow>
    );
  }
}

CaseTableRow.propTypes = {
  serviceCase: PropTypes.object.isRequired,
  serviceType: PropTypes.object.isRequired,
  primaryWorker: PropTypes.object.isRequired,
  serviceCaseGroup: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  groupId: PropTypes.string.isRequired,
  /** Inherited props from the TableBody Component * */
  parent: PropTypes.string,
  /** Inherited props from the TableBody Component * */
  rowIndex: PropTypes.number,
  /** Inherited props from the TableBody Component * */
  secondaryDataList: PropTypes.array,
  showDescription: PropTypes.bool,
};

CaseTableRow.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    showDescription: showFacesheetCasesTableDescription(state),
  };
}

export default connect(mapStateToProps)(CaseTableRow);
