import { useFind } from 'src/api/APIHooks';
import { get, map, isEmpty, sortBy, uniqBy } from 'lodash';

function getFullName (invoice) {
  return [invoice.client_first_name, ' ', invoice.client_last_name].join('');
}

function sortInvoices (invoices) {
  const uniqueNames = uniqBy(invoices, 'label').slice(0, 25);
  return !isEmpty(uniqueNames) ? sortBy(uniqueNames, 'label') : [];
}
const useInvoiceClientNameOptions = (
  providerId,
  payerId,
  isNetworkLead,
  clientNameLike,
  statusFilters,
  statusOptionsConstant,
) => {
  let filterOptions = {
      client_name_like: clientNameLike,
      ...(payerId && { payer: payerId }),
      invoice_status: statusFilters.invoice_status,
      under_dispute: statusFilters.under_dispute,
      ...(!payerId & isNetworkLead && { network_lead: providerId }),
      ...(!payerId & !isNetworkLead && { provider: providerId }),
  };

  if (statusOptionsConstant) {
    filterOptions = {
      ...filterOptions,
      has_user_archive: Boolean(statusFilters.has_user_archive),
    };
  }

  const { isFetching, data } = useFind(
    'invoices',
    filterOptions,
    {
      queryConfig: { enabled: (!isEmpty(clientNameLike)) },
    },
  );

  const invoicesClientName = get(data, 'data.data', []);
  const invoicesClientNameOptions = map(invoicesClientName, (key) => ({
    label: getFullName(key),
    value: getFullName(key),
  }));

  return isFetching ? [] : sortInvoices(invoicesClientNameOptions);
};

export default useInvoiceClientNameOptions;
