import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { browserHistory } from 'common/utils/browserHistory';
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';
// (todo micah) make sure these transition groups are right
import { AppBar } from '@unite-us/ui';
import { setCurrentNetwork } from 'common/utils/Session/actions';
import featureFlag from 'common/utils/FeatureFlag/FeatureFlag';
import {
  REPORTS_VIEW_REPORTS,
} from 'common/utils/FeatureFlag/utils/constants';
import SearchBar from 'src/components/Search/SearchBar';
import fetchEmployees from 'src/api/core/Employees/fetchEmployees';
import updateCurrentEmployeeState from 'src/api/core/Employees/updateCurrentEmployeeState';
import { logoutUser } from 'actions/Login';
import {
  hasUserRole,
  isOrgAdmin,
  isReferralsUserAndAbove,
  isSupervisor,
  isCaseManagerAndAbove,
} from 'src/components/User/utils';
import _ from 'lodash';
import callOrLog from 'src/common/utils/callOrLog';
import hasBackOfficeAccess from 'src/common/utils/BackOffice/backOffice';
import {
  INTAKE,
  LEFT_NAVIGATION,
  NEW_CLIENT,
  REFERRAL,
  SCREENINGS,
  LOGIN,
  SEARCH,
  TASKS,
} from 'common/utils/EventTracker/utils/eventConstants';
import { CREATE_REFERRAL_FORM } from 'src/components/Referrals/constants';
import { destroyForm } from 'actions/Form';
import { IntercomHelper } from '@unite-us/client-utils';
import { UNITEUS_SUPPORT_URL } from 'src/config/env/env.config';
import { redirectUserToLearn } from 'src/common/utils/utils';

import {
  hasInAppNotifications,
  hasPayerAuthorizationAccess,
  hasPayerEnrollmentsAccess,
  hasInvoicesAccess,
  hasPayerInvoicesRole,
  useInvoiceDisputeWorkflow,
  isMessagingEnabledFlag,
  programBasedSearchSelector,
  isLearnEnabled,
  isTasksMenuEnabled,
  orgAdminEnhancements,
} from 'common/utils/FeatureFlags/flags';
import LeftNav from './components/LeftNav';
import RightNav from './components/RightNav';
import './stylesheets/navBar.scss';

export class NavBar extends Component {
  static showHelp() {
    const newWindow = window.open('about:blank', '_blank');
    newWindow.location.href = UNITEUS_SUPPORT_URL;
  }

  static navigateToExports() {
    return browserHistory.push('/exports');
  }

  static navigateToUserSettings() {
    return browserHistory.push('/user/settings');
  }

  constructor(props) {
    super(props);

    this.closeSearchResults = this.closeSearchResults.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.setGroupId = this.setGroupId.bind(this);
    this.navigateTo = this.navigateTo.bind(this);
    this.linkToPath = this.linkToPath.bind(this);
    this.linkToGroupSettingsUsers = this.linkToGroupSettingsUsers.bind(this);
    this.linkToGroupSettingsPrograms = this.linkToGroupSettingsPrograms.bind(this);
    this.linkToGroupSettingsProfile = this.linkToGroupSettingsProfile.bind(this);
    this.linkToGroupReports = this.linkToGroupReports.bind(this);
    this.linkToNetwork = this.linkToNetwork.bind(this);
    this.getNavbarTitle = this.getNavbarTitle.bind(this);
    this.showReportsMenu = this.showReportsMenu.bind(this);
    this.onNetworkLinkClick = this.onNetworkLinkClick.bind(this);
    this.onReportClick = this.onReportClick.bind(this);
    this.onNewClientClick = this.onNewClientClick.bind(this);
    this.onNewIntakeClicked = this.onNewIntakeClicked.bind(this);
    this.onNewScreeningClicked = this.onNewScreeningClicked.bind(this);
    this.onNewReferralClicked = this.onNewReferralClicked.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onUserClick = this.onUserClick.bind(this);
    this.onNewTaskClicked = this.onNewTaskClicked.bind(this);

    this.state = {
      isSearchBarOpen: false,
      loggingOut: false,
    };
  }

  componentWillUnmount() {
    if (this.state.loggingOut) {
      IntercomHelper.shutdown();
      // if user has several tabs open, and we are using autologout, update the autologout
      // storage so that we notify the other tab to logout.
      const autologoutStorageKey = 'autologout';
      if (window.localStorage && localStorage.getItem(autologoutStorageKey)) {
        const storage = localStorage.getItem('autologout');
        const storageData = JSON.parse(storage);
        const newStorageData = { ...storageData, isActive: false, logout: true };
        localStorage.setItem(autologoutStorageKey, JSON.stringify(newStorageData));
      }
    }
  }

  handleLogout() {
    IntercomHelper.shutdown();
    callOrLog(() => this.context.eventTracker(LOGIN.signedOut));
    this.setState({ loggingOut: true }, () => {
      this.props.logoutUser();
    });
  }

  onNetworkLinkClick(id) {
    if (id) { this.linkToNetwork(id); }
  }

  onNewClientClick() {
    browserHistory.push('/contacts/new/search');
    callOrLog(() => this.context.eventTracker(NEW_CLIENT.startFromPlusIcon));
  }

  onReportClick() {
    callOrLog(() => this.context.eventTracker(LEFT_NAVIGATION.reports));
  }

  onNewIntakeClicked() {
    browserHistory.push('/intakes/search');
    callOrLog(() => this.context.eventTracker(INTAKE.startFromPlusIcon));
  }

  onNewScreeningClicked() {
    browserHistory.push('/screenings/new/search');
    callOrLog(() => this.context.eventTracker(SCREENINGS.startFromPlusIcon));
  }

  onNewTaskClicked() {
    const currentLocation = browserHistory.getCurrentLocation();
    browserHistory.push({
      pathname: '/tasks',
      search: '?showTaskInputModal=true',
      state: currentLocation,
    });
    callOrLog(() => this.context.eventTracker(TASKS.startFromPlusIcon));
  }

  onNewReferralClicked() {
    this.props.destroyForm(CREATE_REFERRAL_FORM);
    browserHistory.push('/referrals/new/search');
    callOrLog(() => this.context.eventTracker(REFERRAL.startFromPlusIcon));
  }

  onSearchClick() {
    if (this.state.isSearchBarOpen) {
      this.closeSearchResults();
      callOrLog(() => this.context.eventTracker(SEARCH.closeSearchBar));
    } else {
      this.setState({
        isSearchBarOpen: true,
      });
      callOrLog(() => this.context.eventTracker(SEARCH.openSearchBar));
    }
  }

  async onUserClick(dispatch) {
    browserHistory.push('/user/settings');
    const { currentEmployee } = this.props;
    const { employees } = await fetchEmployees({
      userId: currentEmployee.user.id,
      providers: currentEmployee.provider.id,
    })(dispatch);
    const selectedEmployee = employees.find((employee) => employee.id === currentEmployee.id);
    const data = {
      data: selectedEmployee,
    };
    this.props.updateCurrentEmployeeState({ data });
  }

  getNavbarTitle() {
    const pageTitle = _.findLast(this.props.routes, (route) => _.has(route, 'title'));
    if (pageTitle) {
      return pageTitle.title;
    }
    return null;
  }

  setGroupId(id) {
    this.props.setGroupId(id);
  }

  closeSearchResults() {
    this.setState({
      isSearchBarOpen: false,
    });
  }

  navigateTo(ev, item) {
    if (item.props.route) {
      this.props.router.push(item.props.route);
    }
  }

  linkToPath(path, eventType) {
    callOrLog(() => this.context.eventTracker(eventType));
    this.props.router.push(path);
  }

  linkToGroupSettingsProfile() {
    this.props.router.push('/organization/settings');
  }

  linkToGroupSettingsUsers() {
    this.props.router.push('/organization/settings/users');
  }

  linkToGroupSettingsPrograms() {
    this.props.router.push(`/org/${this.context.groupId}/settings/programs`);
  }

  linkToGroupReports(id) {
    this.props.router.push(`/reports/group/${id}/population`);
  }

  linkToNetwork(id) {
    this.props.setCurrentNetwork(id);
    this.props.router.push(`/network/${id}`);
    callOrLog(() => this.context.eventTracker(LEFT_NAVIGATION.networkMenuItem));
  }

  showReportsMenu() {
    const { flagEnabled, user } = this.props;
    const { groupId } = this.context;

    // Need to optimize this to include insights
    return flagEnabled(REPORTS_VIEW_REPORTS) || isSupervisor(user, groupId);
  }

  render() {
    const {
      currentProvider,
      currentEmployee,
      group,
      isNetworkGroupsFetching,
      setGroupId,
      styles,
      user,
      showAuthorizations,
      showBackOffice,
      showExports,
      showInsights,
      showTasks,
      showInvoices,
      showEnrollments,
      showNetworkDirectory,
      showNotifications,
      showDashboard,
      showContacts,
      showDisputeWorkflow,
      canCreateContacts,
      isMessagingEnabled,
      showProgramBasedSearch,
      showPayerInvoices,
      showLearnLink,
      orgAdminEnhancementsFlag,
    } = this.props;

    const { groupId } = this.context;
    const userGroups = _.get(user, 'groups', []);
    const networks = _.get(user, 'networks', []);
    const isOrgActive = currentProvider?.group?.state === 'active';
    const isNetworkLead = _.get(currentProvider, 'group.provider_type') === 'network_lead';
    const isPayer = _.get(currentProvider, 'group.provider_type') === 'payer';

    const navBar = this.state.isSearchBarOpen ? (
      <CSSTransition
        key="search"
        classNames="nav-search"
        timeout={{ enter: 300, exit: 300 }}
      >
        <SearchBar
          key="search"
          closeSearchResults={this.closeSearchResults}
          onSearchClick={this.onSearchClick}
        />
      </CSSTransition>
    ) : (
      <CSSTransition
        key="app-bar"
        classNames="nav-search"
        timeout={{ enter: 300, exit: 300 }}
      >
        <AppBar
          iconElementLeft={
            (
              <LeftNav
                group={group}
                groupId={groupId}
                isNetworkGroupsFetching={isNetworkGroupsFetching}
                isNetworkLead={isNetworkLead}
                isPayer={isPayer}
                linkToGroup={this.linkToGroupReports}
                linkToNetwork={this.linkToNetwork}
                linkToPath={this.linkToPath}
                networks={networks}
                onLeftNavLinkClick={this.linkToPath}
                onNetworkLinkClick={this.onNetworkLinkClick}
                onReportClick={this.onReportClick}
                onSelectExports={NavBar.navigateToExports}
                showBackOffice={showBackOffice}
                showContacts={showContacts}
                showDashboard={showDashboard}
                showExports={showExports}
                showInsights={showInsights}
                showTasks={showTasks}
                showInvoices={showInvoices}
                showEnrollments={showEnrollments}
                showAuthorizations={showAuthorizations}
                showNetworkDirectory={showNetworkDirectory}
                showPayerInvoices={showPayerInvoices}
                showReports={this.showReportsMenu()}
                user={user}
                isOrgActive={isOrgActive}
                showDisputeWorkflow={showDisputeWorkflow}
                showProgramBasedSearch={showProgramBasedSearch}
                currentPath={this.props.urlPath}
              />
            )
          }
          iconElementRight={
            (
              <RightNav
                isMessagingEnabled={isMessagingEnabled}
                isCaseManagerAndAbove={isCaseManagerAndAbove(user, groupId)}
                canCreateReferrals={isReferralsUserAndAbove(user, groupId)}
                canCreateScreenings={isReferralsUserAndAbove(user, groupId)}
                canCreateContacts={canCreateContacts}
                canViewContacts={showContacts}
                canViewGroupSettings={isOrgAdmin(user, groupId)}
                canViewNotifications={showNotifications || showTasks}
                currentEmployee={currentEmployee}
                onSearchClick={this.onSearchClick}
                onNewReferralClick={this.onNewReferralClicked}
                onNewClientClick={this.onNewClientClick}
                onNewIntakeClick={this.onNewIntakeClicked}
                onNewScreeningClick={this.onNewScreeningClicked}
                onNewTaskClick={this.onNewTaskClicked}
                onUserClick={this.onUserClick}
                onLearnClick={redirectUserToLearn}
                onLogoutClick={this.handleLogout}
                userAvatarUrl={user.avatar_url}
                userName={`${user.first_name} ${user.last_name}`}
                loggingOut={this.state.loggingOut}
                onOrgSettingsUsersClick={this.linkToGroupSettingsUsers}
                onOrgSettingsProgramsClick={this.linkToGroupSettingsPrograms}
                onOrgSettingsProfileClick={this.linkToGroupSettingsProfile}
                onHelpClick={NavBar.showHelp}
                group={group}
                user={user}
                groups={userGroups.map(({ group: { name, id } }) => ({ name, id }))}
                groupId={groupId}
                setGroupId={setGroupId}
                userGroups={userGroups}
                isOrgActive={isOrgActive}
                isNetworkLead={isNetworkLead}
                showLearnLink={showLearnLink}
                showTasks={showTasks}
                orgAdminEnhancementsFlag={orgAdminEnhancementsFlag}
              />
            )
          }
          key="app-bar"
          opened={false}
          style={styles.appBar}
        />
      </CSSTransition>
    );

    return (
      <div className="navigation">
        <TransitionGroup>
          {navBar}
        </TransitionGroup>
      </div>
    );
  }
}

NavBar.propTypes = {
  canCreateContacts: PropTypes.bool.isRequired,
  currentEmployee: PropTypes.object.isRequired,
  currentProvider: PropTypes.object.isRequired,
  destroyForm: PropTypes.func.isRequired,
  flagEnabled: PropTypes.func.isRequired,
  group: PropTypes.shape({
    group: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  isNetworkGroupsFetching: PropTypes.bool.isRequired,
  logoutUser: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  routes: PropTypes.array.isRequired,
  showAuthorizations: PropTypes.bool,
  showBackOffice: PropTypes.bool,
  showExports: PropTypes.bool,
  showInsights: PropTypes.bool,
  showTasks: PropTypes.bool,
  showInvoices: PropTypes.bool,
  showEnrollments: PropTypes.bool,
  showNetworkDirectory: PropTypes.bool.isRequired,
  showDashboard: PropTypes.bool.isRequired,
  showContacts: PropTypes.bool.isRequired,
  showNotifications: PropTypes.bool.isRequired,
  showDisputeWorkflow: PropTypes.bool,
  showPayerInvoices: PropTypes.bool,
  showProgramBasedSearch: PropTypes.bool,
  setCurrentNetwork: PropTypes.func.isRequired,
  setGroupId: PropTypes.func.isRequired,
  styles: PropTypes.shape({
    appBar: PropTypes.object,
    appBarTitle: PropTypes.object,
    logo: PropTypes.object,
  }),
  urlPath: PropTypes.string,
  updateCurrentEmployeeState: PropTypes.func.isRequired,
  user: PropTypes.shape({
    avatar_url: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    groups: PropTypes.array,
    networks: PropTypes.array,
  }),
  isMessagingEnabled: PropTypes.bool.isRequired,
  showLearnLink: PropTypes.bool.isRequired,
  orgAdminEnhancementsFlag: PropTypes.bool.isRequired,
};

NavBar.defaultProps = {
  group: {},
  showAuthorizations: false,
  showExports: false,
  showInsights: false,
  showInvoices: false,
  showTasks: false,
  showEnrollments: false,
  showBackOffice: false,
  showDisputeWorkflow: false,
  showPayerInvoices: false,
  showProgramBasedSearch: false,
  styles: {
    appBar: {
      height: '40px',
      padding: '10px 10px 10px 20px',
      position: 'fixed',
      top: '0px',
      width: 'calc(100% - 35px)',
      boxSizing: 'content-box',
    },
    appBarTitle: {
      color: 'white',
    },
    isNetworkGroupsFetching: false,
    showInsights: false,
    logo: { margin: '5px 9px 12px' },
  },
  user: {},
  urlPath: undefined,
};

function mapStateToProps(state) {
  const isNetworkGroupsFetching = _.get(state, 'networks.isFetching', false);
  const session = state.session;
  const currentEmployee = _.get(state, 'globalState.currentEmployee', {});
  const currentProvider = _.get(state, 'globalState.currentProvider', {});

  return {
    isMessagingEnabled: isMessagingEnabledFlag(state),
    currentEmployee,
    currentProvider,
    groupId: session.groupId,
    group: _.find(state.user.groups, (group) => group.group.id === session.groupId) || {},
    showExports: hasUserRole({
      groupId: session.groupId,
      name: 'Oversight',
      roleType: 'primary',
      user: state.user,
    }) || hasUserRole({
      groupId: session.groupId,
      name: 'Org Admin',
      roleType: 'primary',
      user: state.user,
    }),
    showInsights: hasUserRole({
      groupId: session.groupId,
      name: 'Insights',
      roleType: 'feature',
      user: state.user,
    }),
    showTasks: hasUserRole({
      groupId: session.groupId,
      name: 'Tasks',
      roleType: 'feature',
      user: state.user,
    }) && isTasksMenuEnabled(state),
    showNetworkDirectory: true,
    showDashboard: !hasUserRole({
      groupId: session.groupId,
      name: 'Network User',
      roleType: 'primary',
      user: state.user,
    }),
    showContacts: !hasUserRole({
      groupId: session.groupId,
      name: 'Network User',
      roleType: 'primary',
      user: state.user,
    }),
    canCreateContacts: true,
    isNetworkGroupsFetching,
    session,
    showBackOffice: hasBackOfficeAccess(state),
    showInvoices: hasInvoicesAccess(state),
    showAuthorizations: hasPayerAuthorizationAccess(state),
    showEnrollments: hasPayerEnrollmentsAccess(state),
    showNotifications: hasInAppNotifications(state),
    showDisputeWorkflow: useInvoiceDisputeWorkflow(state),
    showProgramBasedSearch: programBasedSearchSelector(state),
    showPayerInvoices: hasPayerInvoicesRole(state),
    showLearnLink: isLearnEnabled(state),
    orgAdminEnhancementsFlag: orgAdminEnhancements(state),
  };
}

NavBar.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
};

export default withRouter(connect(mapStateToProps, {
  logoutUser,
  destroyForm,
  setCurrentNetwork,
  updateCurrentEmployeeState,
})(featureFlag(NavBar)));
