import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { getEnumsFromState } from 'common/utils/Enums';
import updateCurrentEmployeeState from 'src/api/core/Employees/updateCurrentEmployeeState';
import updateEmployee from 'src/actions/User/User/updateEmployee';
import { AppUserSettings } from '@unite-us/app-user-settings';
import { OverlaySpinner } from 'common/spinners';
import formatOptions from 'common/utils/formatOptions';
import _ from 'lodash';
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
} from '@unite-us/ui';
import { RadioField } from 'src/components/Backoffice/form/RadioField';
import { Toggle } from 'common/Toggle';
import { hasUserRole, isOrgAdmin } from 'src/components/User/utils';
import {
  hasInvoicesAccess,
  hasPayerAuthorizationAccess,
  hasPayerEnrollmentsAccess,
  hasPaymentsUserAccess,
  isMessagingEnabledFlag,
  hasPayerInvoicesRole,
  isTasksMenuEnabled,
  hbh690EnableAppUserSettings,
} from 'src/common/utils/FeatureFlags/flags';
import labelCustomization from 'src/common/utils/Customization/labels';
import defaultLabels from 'src/constants/labels';
import { isTaskNotification } from '../utils';

export class NotificationPreferencesForm extends Component {
  static isLoading(formValues) {
    const values = _.values(formValues);

    return _.every(values, (value) => _.isUndefined(value));
  }

  constructor(props) {
    super(props);

    this.state = {
      submitting: false,
    };

    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    this.props.initializeForm(this.props.initialValuesObj);
  }

  onReminderChange(value, field) {
    field.onChange(value);
    this.submitForm({ [field.name]: value });
  }

  toggle(prop, label) {
    return (
      <div key={`${prop.name}-toggle`} className="flex pl-4 space-x-12" data-test-element={prop.name}>
        <Toggle
          checked={prop.value}
          value={prop.value}
          onChange={() => {
            prop.onChange(!prop.value);
            this.submitForm({ [prop.name]: !prop.value });
          }}
          label={label}
          labelClass="w-5/12"
          toggleClass="w-7/12"
        >
          <div className="pl-2">
            { prop.value ? 'On' : 'Off' }
          </div>
        </Toggle>
      </div>
    );
  }

  multiToggle(props, toggleVal) {
    const formData = {};
    const reminderFields = ['remind_sent_referrals', 'remind_received_referrals', 'remind_service_cases'];
    const reminderToggleVal = toggleVal ? 'on' : 'off';
    props.forEach((prop) => {
      const value = reminderFields.indexOf(prop.field.name) > -1 ? reminderToggleVal : toggleVal;
      formData[prop.field.name] = value;
      prop.field.onChange(value);
    });
    this.submitForm(formData);
  }

  sectionHeader(props, toggleValue, title, description, id) {
    return (
      <div className="flex py-4 pl-4 items-center" data-test-element={id}>
        <div className="w-1/2 sm:w-1/2 md:w-7/12 lg:w-4/6 xl:w-3/4">
          <div className="text-lg font-extrabold font-medium-font py-2">{title}</div>
          <div className="text-sm font-extrabold font-medium-font">{description}</div>
        </div>
        {/* We don't need a section toggle if only one notification setting is the in section */}
        {props.length > 1 && (
          <div className="flex w-1/2 sm:w-1/2 md:w-5/12 lg:w-2/6 xl:w-1/4">
            <Toggle
              checked={toggleValue}
              value={toggleValue}
              onChange={() => this.multiToggle(props, !toggleValue)}
              label="Turn on all notifications"
              labelClass="pr-2"
            >
              { toggleValue && <div id={`toggle-all-${id}-on`} className="pl-2">On</div> }
            </Toggle>
          </div>
        )}
      </div>
    );
  }

  async submitForm(formData) {
    this.setState({ submitting: true });
    let updatedGroupNotifications = {};
    const { currentEmployee } = this.props;
    if (isTaskNotification(formData)) {
      const updatedinAppNotifications = {
        ...currentEmployee?.notification_preferences?.in_app,
        ...formData,
      };
      updatedGroupNotifications = {
        ...currentEmployee?.notification_preferences,
        in_app: updatedinAppNotifications,
      };
    } else {
      updatedGroupNotifications = {
        ...currentEmployee.notification_preferences,
        ...formData,
      };
    }

    return this.props.updateEmployee(
      currentEmployee.id,
      {
        notification_preferences: updatedGroupNotifications,
      },
    ).then((payload) => {
      this.props.updateCurrentEmployeeState(payload);
      this.setState({ submitting: false });
    });
  }

  render() {
    const {
      currentEmployee,
      handleSubmit,
      hbh690AppUserSettingsEnabled,
      enums,
      fields: {
        email,
        new_assistance_request,
        new_incoming_referral,
        referral_accepted,
        referral_closed,
        referral_declined,
        referral_held_for_review,
        referral_new_note,
        remind_sent_referrals,
        remind_received_referrals,
        remind_service_cases,
        accepted_informed_consent,
        care_coordinator_assigned,
        case_status_change,
        contact_case_new_note,
        contact_new_note,
        declined_informed_consent,
        primary_worker_assigned_to_case,
        enrollment_request,
        social_care_coverage_ended,
        program_accepting_referrals,
        program_not_accepting_referrals,
        export_completed,
        new_invoice, // invoice_submitted_to_cbo_admin, invoice_submitted_to_network_lead
        invoice_received,
        invoice_accepted, // accepted_by_payer
        invoice_paid,
        invoice_rejected, // rejected_by_cbo_admin, invoice_rejected_by_network_lead, invoice_rejected_by_payer
        invoice_under_dispute,
        invoice_dispute_resolved,
        fee_schedule_program,
        messaging_notification,
        service_authorization_requested,
        service_authorization_approved,
        service_authorization_denied,
        task_new,
        task_due,
        task_overdue,
        task_assigned,
      },
      isCareManager,
      isPayerAuthorizationUser,
      showEnrollment,
      showInvoice,
      showMessaging,
      showPayerInvoices,
      showPayment,
      showTasks,
      hasOrgAdmin,
    } = this.props;
    const { submitting } = this.state;
    const reminderOptions = formatOptions(_.get(enums, 'preferences.trinary', {}));

    const overallProps = [{ label: 'Allow any emails', field: email }];

    const clientProps = [
      { label: 'Client provides consent', field: accepted_informed_consent },
      { label: 'Client declines consent', field: declined_informed_consent },
      { label: 'Note added to client profile', field: contact_new_note },
      { label: `You are assigned as ${this.props.labels.CareCoordinator}`, field: care_coordinator_assigned },
    ];

    // const reminderOptions = formatOptions(_.get(enums, 'preferences.binary', {}));
    const referralProps = [
      { label: 'New Assistance Request received', field: new_assistance_request },
      { label: 'New Referral sent to your organization', field: new_incoming_referral },
      { label: 'Note added to sent Referral/Case', field: referral_new_note },
      { label: 'Sent Referral is accepted', field: referral_accepted },
      { label: 'Sent Referral is held for review', field: referral_held_for_review },
      { label: 'Sent Referral is rejected', field: referral_declined },
      { label: 'Sent Referral is closed', field: referral_closed },
      { label: 'Case has note added', field: contact_case_new_note },
      { label: 'You are assigned as Primary Worker', field: primary_worker_assigned_to_case },
      { label: 'Case closed', field: case_status_change },
    ];

    const messageProps = [
      { label: 'New message received from a Unite Us user', field: messaging_notification },
    ];

    const paymentProps = [
      { label: 'Enrollment request received or updated', field: enrollment_request },
      { label: 'Social care coverage has ended', field: social_care_coverage_ended },
      { label: 'Authorization request received', field: service_authorization_requested },
      { label: 'Authorization request is approved', field: service_authorization_approved },
      { label: 'Authorization request is denied', field: service_authorization_denied },
      { label: 'New invoice', field: new_invoice },
      { label: 'New invoice', field: invoice_received },
      { label: 'Invoice accepted by payer', field: invoice_accepted },
      { label: 'Invoice paid', field: invoice_paid },
      { label: 'Invoice rejected', field: invoice_rejected },
      { label: 'Invoice disputed', field: invoice_under_dispute },
      {
        label: showPayerInvoices ? 'Invoice dispute resolved' : 'Resolved disputed invoice',
        field: invoice_dispute_resolved,
      },
      { label: 'Invoice export file ready to download', field: export_completed },
      { label: 'Contracted program is updated', field: fee_schedule_program },
    ].reduce((acc, prop) => {
      if (prop.field.name === 'enrollment_request' && (showEnrollment || showPayment)) {
        return [...acc, prop];
      }
      if (prop.field.name === 'social_care_coverage_ended' && hasOrgAdmin && showPayment) {
        return [...acc, prop];
      }
      if (prop.field.name === 'service_authorization_requested' && isPayerAuthorizationUser) {
        return [...acc, prop];
      }
      if (prop.field.name === 'service_authorization_approved' && isCareManager) {
          return [...acc, prop];
      }
      if (prop.field.name === 'service_authorization_denied' && isCareManager) {
          return [...acc, prop];
      }
      if (prop.field.name === 'new_invoice' && showInvoice && !showPayerInvoices) {
        return [...acc, prop];
      }
      if (prop.field.name === 'invoice_received' && showPayerInvoices) {
        return [...acc, prop];
      }
      if (prop.field.name === 'invoice_accepted' && showInvoice && !showPayerInvoices) {
        return [...acc, prop];
      }
      if (prop.field.name === 'invoice_paid' && showInvoice && !showPayerInvoices) {
        return [...acc, prop];
      }
      if (prop.field.name === 'invoice_rejected' && showInvoice && !showPayerInvoices) {
        return [...acc, prop];
      }
      if (prop.field.name === 'invoice_under_dispute' && (showInvoice || showPayerInvoices)) {
        return [...acc, prop];
      }
      if (prop.field.name === 'invoice_dispute_resolved' && (showInvoice || showPayerInvoices)) {
        return [...acc, prop];
      }
      if (prop.field.name === 'export_completed' && (showInvoice || showPayerInvoices)) {
        return [...acc, prop];
      }
      if (
        prop.field.name === 'fee_schedule_program' &&
        hasOrgAdmin &&
        (showPayment || showInvoice)
      ) {
        return [...acc, prop];
      }
      return acc;
    }, []);

    const reminderProps = [
      { label: 'Remind me when a referral that I received needs my action', field: remind_received_referrals },
      { label: 'Remind me when a referral that I sent requires action', field: remind_sent_referrals },
      { label: 'Remind me when I have a case that needs to be updated', field: remind_service_cases },
    ];

    const programProps = [
      { label: 'Program Starts Accepting Referrals', field: program_accepting_referrals },
      { label: 'Program Stops Accepting Referrals', field: program_not_accepting_referrals },
    ];

    const taskProps = [
      { label: 'New task added', field: task_new },
      { label: 'Task due', field: task_due },
      { label: 'Task overdue', field: task_overdue },
      { label: 'Task assigned', field: task_assigned },
    ];

    const overallAll = !_.find(overallProps, ['field.value', false]);
    const clientAll = !_.find(clientProps, ['field.value', false]);
    const referralAll = !_.find(referralProps, ['field.value', false]);
    const paymentAll = !_.find(paymentProps, ['field.value', false]);
    const reminderAll = !_.find(reminderProps, ['field.value', 'off']);
    const programAll = !_.find(programProps, ['field.value', false]);
    const messageAll = !_.find(messageProps, ['field.value', false]);
    const taskAll = !_.find(taskProps, ['field.value', false]);

    const notificationAll = (
      overallAll && clientAll && referralAll && paymentAll && reminderAll && programAll && messageAll
    );
    const allProps = [
      ...overallProps,
      ...clientProps,
      ...referralProps,
      ...paymentProps,
      ...reminderProps,
      ...programProps,
      ...messageProps,
    ];

    if (NotificationPreferencesForm.isLoading(this.props.values)) {
      return <div> Loading settings.... </div>;
    }

    if (hbh690AppUserSettingsEnabled) {
      const labels = _.mapValues(this.props.labels, _.toLower);
      return (
        <AppUserSettings
          appState={{
            currentEmployee,
            source: 'app-client',
            submitting,
            enums: {
              ...enums,
              labels,
            },
          }}
          callbacks={{
            submitForm: this.submitForm,
          }}
          flags={{
            showMessaging,
            showPayerInvoices,
            showEnrollment,
            showPayment,
            hasOrgAdmin,
            isPayerAuthorizationUser,
            isCareManager,
            showInvoice,
            showTasks,
          }}
        />
      );
    }

    return (
      <form
        className="form-horizontal"
        ref={(el) => { this.groupNotificationForm = el; }}
        onSubmit={handleSubmit(this.submitForm)}
      >

        <OverlaySpinner text="Saving..." show={submitting} />
        <BaseCard>
          <BaseCardHeader
            title="Email Notification Settings"
          >
            <div className="flex pr-8" data-test-element="all_email_notifications">
              <Toggle
                checked={notificationAll}
                value={notificationAll}
                onChange={() => this.multiToggle(allProps, !notificationAll)}
                label="Turn on all notifications"
                labelClass="pr-2"
              />
              { notificationAll && <div id="toggle-all-on" className="self-center pl-2">On</div> }
            </div>
          </BaseCardHeader>
          <BaseCardBody>
            <div className="p-2 text-text-blue">
              <div className="px-4" data-test-element="notification_settings_content">
                {/* Clients */}
                {this.sectionHeader(
                  clientProps,
                  clientAll,
                  'Clients',
                  "Notifications about updates to your client's profile",
                  'clients',
                )}
                <div className="py-6 space-y-4">
                  {clientProps.map((propObject) => (
                    this.toggle(propObject.field, propObject.label)
                  ))}
                </div>

                <hr className="border-dark-border-blue" />

                {/* Referrals & Cases */}
                {this.sectionHeader(
                  referralProps,
                  referralAll,
                  'Referrals & Cases',
                  'Notifications about referrals/cases you received, sent, opened or were added to',
                  'referrals',
                )}
                <div className="py-6 space-y-4">
                  {referralProps.map((propObject) => (
                    this.toggle(propObject.field, propObject.label)
                  ))}
                </div>

                <hr className="border-dark-border-blue" />

                {/* Messaging */}
                {
                  showMessaging && (
                    <>
                      {this.sectionHeader(
                        messageProps,
                        messageAll,
                        'Messages',
                        'Notifications about messages you received',
                        'messages',
                      )}
                      <div className="py-6 space-y-4">
                        {messageProps.map((propObject) => (
                          this.toggle(propObject.field, propObject.label)
                        ))}
                      </div>

                      <hr className="border-dark-border-blue" />
                    </>
                  )
                }

                {/* Payments */}
                { paymentProps.length > 0 && (
                  <>
                    {this.sectionHeader(
                      paymentProps,
                      paymentAll,
                      'Payments',
                      'Notifications about updates to enrollments, authorizations and invoices',
                      'payment',
                    )}
                    <div className="py-6 space-y-4">
                      {paymentProps.map((propObject) => (
                        this.toggle(propObject.field, propObject.label)
                      ))}
                    </div>

                    <hr className="border-dark-border-blue" />
                  </>
                )}

                {/* Reminders */}
                <div className="p-4">
                  <div className="text-lg font-extrabold font-medium-font py-2">Reminders</div>
                  <div className="text-sm font-extrabold font-medium-font">
                    Get email reminders about referrals and cases that require action
                  </div>
                </div>
                <div className="py-2 px-4">
                  {reminderProps.map((propObject) => (
                    <div className="py-2" key={propObject.field.name}>
                      <div className="pb-2 text-sm font-medium-font text-text-blue">{propObject.label}</div>
                      <RadioField
                        id={propObject.field.name}
                        field={propObject.field}
                        value={propObject.field.value}
                        onChange={(value) => this.onReminderChange(value, propObject.field)}
                        options={reminderOptions}
                      />
                    </div>
                  ))}
                </div>

                <hr className="border-dark-border-blue" />

                {/* Programs */}
                {this.sectionHeader(
                  programProps,
                  programAll,
                  'Programs',
                  'Notifications about updates to programs',
                  'programs',
                )}
                <div className="py-6 space-y-4">
                  {programProps.map((propObject) => (
                    this.toggle(propObject.field, propObject.label)
                  ))}
                </div>

                {/* Tasks */}
                { showTasks && taskProps.length > 0 && (
                  <>
                    <hr className="border-dark-border-blue" />
                    {this.sectionHeader(
                      taskProps,
                      taskAll,
                      'Tasks',
                      'Notifications about tasks',
                      'tasks',
                    )}
                    <div className="py-6 space-y-4">
                      {taskProps.map((propObject) => (
                        this.toggle(propObject.field, propObject.label)
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </BaseCardBody>
        </BaseCard>
      </form>
    );
  }
}

NotificationPreferencesForm.propTypes = {
  currentEmployee: PropTypes.object.isRequired,
  enums: PropTypes.shape({
    preferences: PropTypes.shape({
      binary: PropTypes.array,
    }).isRequired,
  }).isRequired,
  fields: PropTypes.any.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hasOrgAdmin: PropTypes.bool.isRequired,
  hbh690AppUserSettingsEnabled: PropTypes.bool,
  initializeForm: PropTypes.func.isRequired,
  initialValuesObj: PropTypes.object.isRequired,
  isCareManager: PropTypes.bool.isRequired,
  isPayerAuthorizationUser: PropTypes.bool.isRequired,
  showEnrollment: PropTypes.bool.isRequired,
  showInvoice: PropTypes.bool.isRequired,
  showMessaging: PropTypes.bool.isRequired,
  showPayerInvoices: PropTypes.bool.isRequired,
  showPayment: PropTypes.bool.isRequired,
  showTasks: PropTypes.bool.isRequired,
  updateCurrentEmployeeState: PropTypes.func.isRequired,
  updateEmployee: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  labels: PropTypes.object,
};

NotificationPreferencesForm.defaultProps = {
  hbh690AppUserSettingsEnabled: false,
  labels: defaultLabels,
};

function mapStateToProps(state) {
  const currentEmployee = _.get(state, 'globalState.currentEmployee', {});
  const {
    email,
    new_assistance_request,
    new_incoming_referral,
    referral_accepted,
    referral_closed,
    referral_declined,
    referral_held_for_review,
    referral_new_note,
    organization_added,
    remind_sent_referrals,
    remind_received_referrals,
    remind_service_cases,
    new_invoice,
    invoice_received,
    invoice_accepted,
    invoice_paid,
    invoice_rejected,
    invoice_under_dispute,
    invoice_dispute_resolved,
    fee_schedule_program,
    export_completed,
    accepted_informed_consent,
    care_coordinator_assigned,
    case_status_change,
    contact_case_new_note,
    contact_new_note,
    declined_informed_consent,
    primary_worker_assigned_to_case,
    enrollment_request,
    social_care_coverage_ended,
    program_accepting_referrals,
    program_not_accepting_referrals,
    messaging_notification,
    service_authorization_requested,
    service_authorization_approved,
    service_authorization_denied,
  } = currentEmployee.notification_preferences;
  const {
    task_new,
    task_due,
    task_overdue,
    task_assigned,
  } = currentEmployee.notification_preferences.in_app;
  const initialValues = {
    email,
    new_assistance_request,
    new_incoming_referral,
    referral_accepted,
    referral_closed,
    referral_declined,
    referral_held_for_review,
    referral_new_note,
    organization_added,
    remind_sent_referrals,
    remind_received_referrals,
    remind_service_cases,
    accepted_informed_consent,
    care_coordinator_assigned,
    case_status_change,
    contact_case_new_note,
    contact_new_note,
    declined_informed_consent,
    primary_worker_assigned_to_case,
    new_invoice, // invoice_submitted_to_cbo_admin, invoice_submitted_to_network_lead
    invoice_received,
    invoice_accepted,
    invoice_paid,
    invoice_rejected, // rejected_by_cbo_admin, invoice_rejected_by_network_lead, invoice_rejected_by_payer
    invoice_under_dispute,
    invoice_dispute_resolved,
    fee_schedule_program,
    export_completed,
    enrollment_request,
    social_care_coverage_ended,
    program_accepting_referrals,
    program_not_accepting_referrals,
    messaging_notification,
    service_authorization_requested,
    service_authorization_approved,
    service_authorization_denied,
    task_new,
    task_due,
    task_overdue,
    task_assigned,
  };
  const hasFeeSchedules = currentEmployee.fee_schedules.length !== 0;
  const isNotPayer = currentEmployee.provider.provider_type !== 'payer';
  return {
    initialValuesObj: initialValues,
    currentEmployee,
    enums: getEnumsFromState(state),
    hbh690AppUserSettingsEnabled: hbh690EnableAppUserSettings(state),
    isCareManager: hasPaymentsUserAccess(state) && hasFeeSchedules && isNotPayer,
    isPayerAuthorizationUser: hasPayerAuthorizationAccess(state),
    showInvoice: hasInvoicesAccess(state),
    showEnrollment: hasPayerEnrollmentsAccess(state),
    showPayment: hasPaymentsUserAccess(state),
    showMessaging: isMessagingEnabledFlag(state),
    showPayerInvoices: hasPayerInvoicesRole(state),
    showTasks: hasUserRole({
      groupId: state.session.groupId,
      name: 'Tasks',
      roleType: 'feature',
      user: state.user,
    }) && isTasksMenuEnabled(state),
    hasOrgAdmin: isOrgAdmin(state.user, state.session.groupId),
    labels: labelCustomization(state),
  };
}

export const NotificationPreferencesReduxForm = reduxForm({
  fields: [
    'email',
    'new_assistance_request',
    'new_incoming_referral',
    'referral_accepted',
    'referral_closed',
    'referral_declined',
    'referral_held_for_review',
    'referral_new_note',
    'organization_added',
    'remind_sent_referrals',
    'remind_received_referrals',
    'remind_service_cases',
    'accepted_informed_consent',
    'care_coordinator_assigned',
    'case_status_change',
    'contact_case_new_note',
    'contact_new_note',
    'declined_informed_consent',
    'primary_worker_assigned_to_case',
    'enrollment_request',
    'social_care_coverage_ended',
    'program_accepting_referrals',
    'program_not_accepting_referrals',
    'new_invoice',
    'invoice_received',
    'invoice_accepted',
    'invoice_paid',
    'invoice_rejected',
    'invoice_under_dispute',
    'invoice_dispute_resolved',
    'fee_schedule_program',
    'export_completed',
    'messaging_notification',
    'service_authorization_requested',
    'service_authorization_approved',
    'service_authorization_denied',
    'task_new',
    'task_due',
    'task_overdue',
    'task_assigned',

  ],
}, mapStateToProps, {
  updateCurrentEmployeeState,
  updateEmployee,
})(NotificationPreferencesForm);
