/* eslint-disable */
export default {
  "languages": [{
    "display_name": "Afrikaans",
    "value": "af",
    "shares": true
  }, {
    "display_name": "Akan",
    "value": "ak",
    "shares": true
  }, {
    "display_name": "Albanian",
    "value": "sq",
    "shares": true,
    "consent": true
  }, {
    "display_name": "American Sign Language",
    "value": "american_sign_language"
  }, {
    "display_name": "Amharic",
    "value": "am",
    "shares": true,
    "consent": true
  }, {
    "display_name": "Arabic",
    "value": "ar",
    "shares": true,
    "consent": true
  }, {
    "display_name": "Armenian",
    "value": "hy",
    "shares": true,
    "consent": true
  }, {
    "display_name": "Assamese",
    "value": "as",
    "shares": true
  }, {
    "display_name": "Aymara",
    "value": "ay",
    "shares": true
  }, {
    "display_name": "Azerbaijani",
    "value": "az",
    "shares": true
  }, {
    "display_name": "Bambara",
    "value": "bm",
    "shares": true
  }, {
    "display_name": "Basque",
    "value": "eu",
    "shares": true
  }, {
    "display_name": "Belarusian",
    "value": "be",
    "shares": true
  }, {
    "display_name": "Bengali",
    "value": "bn",
    "shares": true,
    "consent": true
  }, {
    "display_name": "Bhojpuri",
    "value": "bho",
    "shares": true
  }, {
    "display_name": "Bosnian",
    "value": "bs",
    "shares": true,
    "consent": true
  }, {
    "display_name": "Burmese",
    "value": "my",
    "consent": true
  }, {
    "display_name": "Bulgarian",
    "value": "bg",
    "shares": true
  }, {
    "display_name": "Catalan",
    "value": "ca",
    "shares": true
  }, {
    "display_name": "Cebuano",
    "value": "ceb",
    "shares": true
  }, {
    "display_name": "Central Khmer",
    "value": "km"
  }, {
    "display_name": "Chichewa",
    "value": "ny",
    "shares": true
  }, {
    "display_name": "Chinese (Simplified)",
    "value": "zh",
    "shares": true,
    "consent": true
  }, {
    "display_name": "Chinese (Traditional)",
    "value": "zh-TW",
    "shares": true
  }, {
    "display_name": "Chuukese",
    "value": "chk",
    "consent": true
  }, {
    "display_name": "Corsican",
    "value": "co",
    "shares": true
  }, {
    "display_name": "Croatian",
    "value": "hr",
    "shares": true,
    "consent": true
  }, {
    "display_name": "Czech",
    "value": "cs",
    "shares": true
  }, {
    "display_name": "Danish",
    "value": "da",
    "shares": true
  }, {
    "display_name": "Divehi",
    "value": "dv",
    "shares": true
  }, {
    "display_name": "Dogri",
    "value": "doi",
    "shares": true
  }, {
    "display_name": "Dari",
    "value": "prs",
    "consent": true
  }, {
    "display_name": "Dutch",
    "value": "nl",
    "shares": true
  }, {
    "display_name": "English",
    "value": "en",
    "shares": true,
    "consent": true,
    "widgets": true
  }, {
    "display_name": "Esperanto",
    "value": "eo",
    "shares": true
  }, {
    "display_name": "Estonian",
    "value": "et",
    "shares": true
  }, {
    "display_name": "Ewe",
    "value": "ee",
    "shares": true
  }, {
    "display_name": "Farsi",
    "value": "fa",
    "consent": true
  }, {
    "display_name": "Fijian",
    "value": "fj",
    "consent": true
  }, {
    "display_name": "Filipino",
    "value": "tl",
    "shares": true
  }, {
    "display_name": "Finnish",
    "value": "fi",
    "shares": true
  }, {
    "display_name": "French",
    "value": "fr",
    "shares": true,
    "consent": true
  }, {
    "display_name": "Frisian",
    "value": "fy",
    "shares": true
  }, {
    "display_name": "Galician",
    "value": "gl",
    "shares": true
  }, {
    "display_name": "Ganda",
    "value": "lg",
    "shares": true
  }, {
    "display_name": "Georgian",
    "value": "ka",
    "shares": true
  }, {
    "display_name": "German",
    "value": "de",
    "shares": true
  }, {
    "display_name": "Goan Konkani",
    "value": "gom",
    "shares": true
  }, {
    "display_name": "Greek",
    "value": "el",
    "shares": true,
    "consent": true
  }, {
    "display_name": "Guarani",
    "value": "gn",
    "shares": true
  }, {
    "display_name": "Gujarati",
    "value": "gu",
    "consent": true,
    "new": true,
    "shares": true
  }, {
    "display_name": "Haitian Creole",
    "value": "ht",
    "shares": true,
    "consent": true,
    "widgets": true
  }, {
    "display_name": "Hausa",
    "value": "ha",
    "shares": true
  }, {
    "display_name": "Hawaiian",
    "value": "haw",
    "shares": true,
    "consent": true
  }, {
    "display_name": "Hebrew",
    "value": "he",
    "shares": true
  }, {
    "display_name": "Hindi",
    "value": "hi",
    "shares": true
  }, {
    "display_name": "Hmong",
    "value": "hmn",
    "shares": true,
    "consent": true
  }, {
    "display_name": "Hungarian",
    "value": "hu",
    "shares": true
  }, {
    "display_name": "Icelandic",
    "value": "is",
    "shares": true
  }, {
    "display_name": "Igbo",
    "value": "ig",
    "shares": true
  }, {
    "display_name": "Iloko",
    "value": "ilo",
    "shares": true
  }, {
    "display_name": "Ilocano",
    "value": "ilo",
    "consent": true
  }, {
    "display_name": "Indonesian",
    "value": "id",
    "shares": true
  }, {
    "display_name": "Irish",
    "value": "ga",
    "shares": true
  }, {
    "display_name": "Italian",
    "value": "it",
    "consent": true,
    "new": true,
    "shares": true
  }, {
    "display_name": "Japanese",
    "value": "ja",
    "shares": true,
    "consent": true
  }, {
    "display_name": "Javanese",
    "value": "jv",
    "shares": true
  }, {
    "display_name": "Kannada",
    "value": "kn",
    "shares": true
  }, {
    "display_name": "Kazakh",
    "value": "kk",
    "shares": true
  }, {
    "display_name": "Karenni",
    "value": "kar",
    "consent": true
  }, {
    "display_name": "Khmer",
    "value": "km",
    "shares": true
  }, {
    "display_name": "Khmer Cambodian",
    "value": "km",
    "consent": true
  }, {
    "display_name": "Kinyarwanda",
    "value": "rw",
    "shares": true,
    "consent": true
  }, {
    "display_name": "Kirundi",
    "value": "rn",
    "consent": true
  }, {
    "display_name": "Korean",
    "value": "ko",
    "shares": true,
    "consent": true
  }, {
    "display_name": "Kosraean",
    "value": "kos",
    "consent": true
  }, {
    "display_name": "Krio",
    "value": "kri",
    "shares": true
  }, {
    "display_name": "Kurdish (Kurmanji)",
    "value": "ku",
    "shares": true
  }, {
    "display_name": "Kurdish (Sorani)",
    "value": "ckb",
    "shares": true
  }, {
    "display_name": "Kyrgyz",
    "value": "ky",
    "shares": true
  }, {
    "display_name": "Lao",
    "value": "lo",
    "shares": true,
    "consent": true
  }, {
    "display_name": "Latin",
    "value": "la",
    "shares": true
  }, {
    "display_name": "Latvian",
    "value": "lv",
    "shares": true
  }, {
    "display_name": "Lingala",
    "value": "ln",
    "shares": true
  }, {
    "display_name": "Lithuanian",
    "value": "lt",
    "shares": true
  }, {
    "display_name": "Luxembourgish",
    "value": "lb",
    "shares": true
  }, {
    "display_name": "Maay Maay",
    "value": "ymm",
    "consent": true
  }, {
    "display_name": "Macedonian",
    "value": "mk",
    "shares": true
  }, {
    "display_name": "Maithili",
    "value": "mai",
    "shares": true
  }, {
    "display_name": "Malagasy",
    "value": "mg",
    "shares": true
  }, {
    "display_name": "Malay",
    "value": "ms",
    "shares": true
  }, {
    "display_name": "Malayalam",
    "value": "ml",
    "shares": true
  }, {
    "display_name": "Maltese",
    "value": "mt",
    "shares": true
  }, {
    "display_name": "Manipuri (Meitei Mayek)",
    "value": "mni-Mtei",
    "shares": true
  }, {
    "display_name": "Maori",
    "value": "mi",
    "shares": true
  }, {
    "display_name": "Marathi",
    "value": "mr",
    "shares": true
  }, {
    "display_name": "Marshallese",
    "value": "mh",
    "consent": true
  }, {
    "display_name": "Mizo",
    "value": "lus",
    "shares": true
  }, {
    "display_name": "Mongolian",
    "value": "mn",
    "shares": true
  }, {
    "display_name": "Myanmar (Burmese)",
    "value": "my",
    "shares": true
  }, {
    "display_name": "Nepali",
    "value": "ne",
    "consent": true,
    "new": true,
    "shares": true
  }, {
    "display_name": "Northern Sotho",
    "value": "nso",
    "shares": true
  }, {
    "display_name": "Norwegian",
    "value": "no",
    "shares": true
  }, {
    "display_name": "Odia (Oriya)",
    "value": "or",
    "shares": true
  }, {
    "display_name": "Oromo",
    "value": "om",
    "shares": true,
    "consent": true
  }, {
    "display_name": "Pashto",
    "value": "ps",
    "shares": true
  }, {
    "display_name": "Pashto (Afghanistan)",
    "value": "pbu",
    "consent": true
  }, {
    "display_name": "Pashto (Pakistan)",
    "value": "pbt",
    "consent": true
  }, {
    "display_name": "Persian",
    "value": "fa",
    "shares": true
  }, {
    "display_name": "Pohnpeian",
    "value": "pon",
    "consent": true
  }, {
    "display_name": "Polish",
    "value": "pl",
    "shares": true,
    "consent": true
  }, {
    "display_name": "Portuguese",
    "value": "pt",
    "shares": true,
    "consent": true
  }, {
    "display_name": "Punjabi",
    "value": "pa",
    "shares": true,
    "consent": true
  }, {
    "display_name": "Quechua",
    "value": "qu",
    "shares": true
  }, {
    "display_name": "Romanian",
    "value": "ro",
    "shares": true
  }, {
    "display_name": "Russian",
    "value": "ru",
    "shares": true,
    "consent": true
  }, {
    "display_name": "Samoan",
    "value": "sm",
    "shares": true,
    "consent": true
  }, {
    "display_name": "Sanskrit",
    "value": "sa",
    "shares": true
  }, {
    "display_name": "Scots Gaelic",
    "value": "gd",
    "shares": true
  }, {
    "display_name": "Serbian",
    "value": "sr",
    "shares": true
  }, {
    "display_name": "Sesotho",
    "value": "st",
    "shares": true
  }, {
    "display_name": "S'gaw Karen",
    "value": "ksw",
    "consent": true
  }, {
    "display_name": "Shona",
    "value": "sn",
    "shares": true
  }, {
    "display_name": "Sindhi",
    "value": "sd",
    "shares": true
  }, {
    "display_name": "Sinhala",
    "value": "si",
    "shares": true
  }, {
    "display_name": "Slovak",
    "value": "sk",
    "shares": true
  }, {
    "display_name": "Slovenian",
    "value": "sl",
    "shares": true
  }, {
    "display_name": "Somali",
    "value": "so",
    "shares": true,
    "consent": true
  }, {
    "display_name": "Spanish",
    "value": "es",
    "shares": true,
    "consent": true,
    "widgets": true
  }, {
    "display_name": "Sundanese",
    "value": "su",
    "shares": true
  }, {
    "display_name": "Swahili",
    "value": "sw",
    "shares": true,
    "consent": true
  }, {
    "display_name": "Swedish",
    "value": "sv",
    "shares": true
  }, {
    "display_name": "Tagalog",
    "value": "tl",
    "consent": true
  }, {
    "display_name": "Tajik",
    "value": "tg",
    "shares": true
  }, {
    "display_name": "Tamil",
    "value": "ta",
    "shares": true
  }, {
    "display_name": "Tatar",
    "value": "tt",
    "shares": true
  }, {
    "display_name": "Telugu",
    "value": "te",
    "shares": true
  }, {
    "display_name": "Thai",
    "value": "th",
    "shares": true
  }, {
    "display_name": "Tibetan",
    "value": "bo"
  }, {
    "display_name": "Tigrinya",
    "value": "ti",
    "shares": true
  }, {
    "display_name": "Tongan",
    "value": "to",
    "consent": true
  }, {
    "display_name": "Tsonga",
    "value": "ts",
    "shares": true
  }, {
    "display_name": "Turkish",
    "value": "tr",
    "shares": true,
    "consent": true
  }, {
    "display_name": "Turkmen",
    "value": "tk",
    "shares": true
  }, {
    "display_name": "Ukrainian",
    "value": "uk",
    "shares": true,
    "consent": true
  }, {
    "display_name": "Urdu",
    "value": "ur",
    "shares": true,
    "consent": true
  }, {
    "display_name": "Uyghur",
    "value": "ug",
    "shares": true
  }, {
    "display_name": "Uzbek",
    "value": "uz",
    "shares": true
  }, {
    "display_name": "Vietnamese",
    "value": "vi",
    "shares": true,
    "consent": true
  }, {
    "display_name": "Welsh",
    "value": "cy",
    "shares": true
  }, {
    "display_name": "Xhosa",
    "value": "xh",
    "shares": true
  }, {
    "display_name": "Yapese",
    "value": "yap",
    "consent": true
  }, {
    "display_name": "Yiddish",
    "value": "yi",
    "shares": true
  }, {
    "display_name": "Yoruba",
    "value": "yo",
    "shares": true
  }, {
    "display_name": "Zulu",
    "value": "zu",
    "shares": true
  }]
};