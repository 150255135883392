function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { reduce, get, some, find, has, countBy } from 'lodash';
function getQuestionValue(id, allValues) {
  var value = find(allValues, function (val) {
    return has(val, id);
  });
  return get(value, id, {});
}
function getCheckboxValidations() {
  var question = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var validationList = reduce(question.validators, function (acc, validator) {
    if (get(validator, 'validation_type', '') === 'presence') {
      return [].concat(_toConsumableArray(acc), [function (value, allValues) {
        var questionValue = getQuestionValue(question.id, allValues);
        if (some(questionValue)) {
          return undefined;
        }
        return get(validator, 'message', 'Required');
      }]);
    }
    return acc;
  }, []);
  if (question.min_selections > 0) {
    validationList = [].concat(_toConsumableArray(validationList), [function (value, allValues) {
      var questionValue = getQuestionValue(question.id, allValues);
      var selections = countBy(questionValue);
      if (get(selections, 'true', 0) < question.min_selections) {
        return "At least ".concat(question.min_selections, " must be selected");
      }
      return undefined;
    }]);
  }
  if (question.max_selections > 0) {
    validationList = [].concat(_toConsumableArray(validationList), [function (value, allValues) {
      var questionValue = getQuestionValue(question.id, allValues);
      var selections = countBy(questionValue);
      if (get(selections, 'true', 0) > question.max_selections) {
        return "At most ".concat(question.max_selections, " must be selected");
      }
      return undefined;
    }]);
  }
  return validationList;
}
export default getCheckboxValidations;