import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { caseCloseFields } from 'src/components/Cases/utils/fields';
import { resolvedOptions } from 'src/components/Cases/utils/selectOptions';
import { CLOSE_CASE_FORM } from 'src/components/Cases/constants';
import * as CaseCloseHelper from 'src/components/Cases/utils/caseCloseHelpers';
import { closeServiceCase } from 'actions/Case/Contact/Group';
import { fetchGroupCaseOutcomes } from 'actions/Outcome/ServiceType/Group';
import { fetchNetworkCaseOutcomes } from 'actions/Outcome/ServiceType/Network';
import { setDashboardRefetch } from 'actions/Dashboard';
import { goToClosedCasesIndex } from 'src/components/Cases/utils/routing';
import _ from 'lodash';
import moment from 'moment';
import { validateReduxForm } from 'common/form';
import { validations } from '@unite-us/client-utils';
import { OverlaySpinner, Spinner } from 'common/spinners';
import getClosingParams from 'src/common/utils/Closing/getClosingParams';
import getOutcomesOptions from 'src/common/utils/Outcomes/utils/getOutcomesOptions';
import getOutcomesForServiceType from 'src/common/utils/Outcomes/utils/getOutcomesForServiceType';
import today from 'src/common/utils/today';
import callOrLog from 'src/common/utils/callOrLog';
import { SERVICE_CASE } from 'common/utils/EventTracker/utils/eventConstants';
import NoteDisclaimer from 'common/Disclaimer/NoteDisclaimer';
import {
  Button,
  SelectField,
  TextField,
  DateField,
} from '@unite-us/ui';

export class CloseCase extends Component {
  constructor(props) {
    super(props);

    this.clearOutcomesValue = this.clearOutcomesValue.bind(this);
    this.fetchOutcomes = this.fetchOutcomes.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.resetForm();
    const { serviceTypeId } = this.props;
    if (!_.isEmpty(serviceTypeId)) {
      this.fetchOutcomes();
    }
  }

  onSubmit(values) {
    const {
      groupId,
      contact,
      caseToClose,
      invalidateQueries,
    } = this.props;

    const closingParams = getClosingParams(values);

    return this.props.closeServiceCase(groupId, contact.id, caseToClose, closingParams)
      .then(() => {
        invalidateQueries('notes');
        this.props.setDashboardRefetch();
        callOrLog(() => this.context.eventTracker(SERVICE_CASE.close, null, {
          serviceCase: caseToClose,
        }));
        goToClosedCasesIndex(_.get(caseToClose, 'state'), _.get(caseToClose, 'provider.id'), groupId);
      });
  }

  onCancel() {
    this.props.resetForm();
    this.props.closeDialog();
  }

  clearOutcomesValue() {
    this.props.fields.outcome_id.onChange();
  }

  fetchOutcomes() {
    const { caseToClose, serviceTypeId } = this.props;
    if (caseToClose) {
      const applicable_states = [caseToClose.state];
      if (caseToClose.network) {
        const networkId = _.get(caseToClose, 'network.id');
        this.props.fetchNetworkCaseOutcomes(networkId, serviceTypeId, { applicable_states });
      } else {
        const groupId = _.get(caseToClose, 'group.id');
        this.props.fetchGroupCaseOutcomes(groupId, serviceTypeId, { applicable_states });
      }
    }
  }

  render() {
    const {
      caseToClose,
      contact,
      contactCases,
      fields: {
        exited_at, note, outcome_id, resolved,
      },
      handleSubmit,
      outcomes,
      registerField,
      submitting,
    } = this.props;

    if (_.isEmpty(contact) || _.isEmpty(caseToClose)) {
      return <Spinner />;
    }
    const minDate = _.get(caseToClose, 'opened_date') !== null ?
      moment.unix(_.get(caseToClose, 'opened_date') - 1).format('MM/DD/YYYY') :
      null;

    const dateInput = (
      <DateField
        field={exited_at}
        id="exitDateInput"
        label="Closed Date"
        ref={registerField}
        validations={validations.isRequired}
        inline={false}
        required
        maxDate={today()}
        minDate={minDate}
        customErrorMessage="Closed date cannot be later than today"
      />
    );
    const isOON = _.get(caseToClose, 'state') === 'off_platform';
    const dateDiv = CaseCloseHelper.showExitedAt(contactCases, _.get(caseToClose, 'id')) && !isOON ? dateInput : null;

    return (
      <form
        onSubmit={handleSubmit(this.onSubmit)}
        className="content-with-actions"
      >
        <div className="content-container">
          <OverlaySpinner text="Closing Case..." show={submitting} />
          <div>
            <SelectField
              field={resolved}
              id="resolvedInput"
              forceObjectValue
              label="Is Resolved?"
              onChange={this.clearOutcomesValue}
              options={resolvedOptions}
              ref={registerField}
              required
              searchEnabled={false}
              validations={validations.isRequired}
              labelKey="label"
              valueKey="value"
            />
            <SelectField
              field={outcome_id}
              id="outcomeInput"
              forceObjectValue
              label="Outcome"
              options={getOutcomesOptions(outcomes, _.get(resolved, 'value'))}
              ref={registerField}
              required
              shouldSort={false}
              validations={validations.isRequired}
              labelKey="label"
              valueKey="value"
            />
            <TextField
              field={note}
              id="noteInput"
              label="Note"
              afterLabelContent={<NoteDisclaimer />}
              ref={registerField}
              validations={validations.isRequired}
              required
            />
            {dateDiv}
          </div>
        </div>
        <div className="actions">
          <span className="action-item">
            <Button
              id="close-case-cancel-btn"
              label="Cancel"
              onClick={this.onCancel}
            />
          </span>
          <span className="action-item">
            <Button
              id="close-case-submit-btn"
              onClick={handleSubmit(this.onSubmit)}
              label="Close Case"
              disabled={submitting}
              primary
            />
          </span>
        </div>
      </form>
    );
  }
}

CloseCase.propTypes = {
  caseToClose: PropTypes.shape({
    id: PropTypes.string.isRequired,
    network: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    group: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    state: PropTypes.string,
  }).isRequired,
  closeDialog: PropTypes.func.isRequired,
  closeServiceCase: PropTypes.func.isRequired,
  contact: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  contactCases: PropTypes.arrayOf(
    PropTypes.shape({
      program: PropTypes.object.isRequired,
    }).isRequired,
  ),
  fetchGroupCaseOutcomes: PropTypes.func.isRequired,
  fetchNetworkCaseOutcomes: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  outcomes: PropTypes.array,
  registerField: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  serviceTypeId: PropTypes.string.isRequired,
  setDashboardRefetch: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalidateQueries: PropTypes.func.isRequired,
};

CloseCase.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

CloseCase.defaultProps = {
  contactCases: [],
  outcomes: [],
};

function mapStateToProps(state, ownProps) {
  const contact = ownProps.contact;
  const contactCaseObj = _.get(state.serviceCase, contact.id);
  const contactCases = _.get(contactCaseObj, 'data', []);
  const caseToClose = ownProps.serviceCase;
  const isNetworkCase = !_.isEmpty(caseToClose.network);

  const serviceTypeOutcomes = _.get(state, [
    'outcomes',
    isNetworkCase ? 'networkOutcomes' : 'groupOutcomes',
  ], []);

  const serviceTypeId = _.get(caseToClose, 'service_type.id');
  const outcomes = getOutcomesForServiceType(serviceTypeId, serviceTypeOutcomes);

  return {
    groupId: _.get(state, 'session.groupId'),
    contact,
    contactCases,
    caseToClose,
    outcomes,
    initialValues: {
      exited_at: today(),
    },
    serviceTypeId,
  };
}

export default validateReduxForm({
  form: CLOSE_CASE_FORM,
  fields: caseCloseFields,
}, mapStateToProps, {
  closeServiceCase,
  fetchGroupCaseOutcomes,
  fetchNetworkCaseOutcomes,
  setDashboardRefetch,
})(CloseCase);
