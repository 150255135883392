import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compact, get, some, isEmpty, each } from 'lodash';
import { Button, Icon } from '@unite-us/ui';
import { ShowHide, Serializer } from '@unite-us/client-utils';
import { ShareDrawer } from '@unite-us/shares-utils';
import {
  pluckSelectedGroupIds,
} from 'src/components/Referrals/ReferralGroupsPrograms/utils';
import classNames from 'classnames';
import {
  canAddMoreOONGroups,
  oonGroupSelected,
} from 'src/components/Referrals/ReferralFormFields/OONGroupsSelector/utils';
import { SHARES_URL } from 'src/config/env/env.config';
import { coreApi } from 'src/api/config';
import callOrLog from 'common/utils/callOrLog';
import { MY_NETWORKS } from 'common/utils/EventTracker/utils/eventConstants';
import { getGroup } from 'common/utils/stateHelpers';
import { paginateNetworkGroups } from 'src/common/utils/FeatureFlags/flags';
import {
  CaseOONGroupSelect,
  CaseOONGroupHeader,
} from './components';
import './stylesheets/caseOONGroupsSelector.scss';

export class CaseOONGroupsSelector extends Component {
  constructor(props) {
    super(props);
    this.addGroupField = this.addGroupField.bind(this);
    this.removeGroupField = this.removeGroupField.bind(this);
    this.toggleShareDrawer = this.toggleShareDrawer.bind(this);
    this.onShareEvent = this.onShareEvent.bind(this);
    this.trackOONShares = this.trackOONShares.bind(this);
    this.trackGroupShares = this.trackGroupShares.bind(this);
    this.state = {
      disableOrgAddButton: false,
      showShareDrawer: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const selectedOONGroups = compact(nextProps.fields.service_case.oonCase.selected);
    if (selectedOONGroups.length === 0) {
      this.addGroupField();
    }
    this.evaluateSelectedFields();

    this.setState({
      disableOrgAddButton: some(selectedOONGroups, ['group.value', '']),
    });
  }

  onShareEvent({ shareMethod, language }) {
    this.trackOONShares({ shareMethod, language });
    if (shareMethod === 'print') this.toggleShareDrawer();
  }

  evaluateSelectedFields() {
    const selectedGroups = this.props.selectedFields.filter((group) => group.group.value);

    if (selectedGroups.length === 0) {
      this.setState({
        showShareDrawer: false,
      });
    }
  }

  toggleShareDrawer() {
    const { showShareDrawer } = this.state;
    this.setState({ showShareDrawer: !showShareDrawer });
  }

  removeGroupField(selected, index) {
    const {
      canPaginateNetworkGroups,
      fields,
      removeSelectedBrowseGroup,
    } = this.props;

    this.evaluateSelectedFields();
    if (!selected) {
      fields.service_case.oonCase.selected.removeField(index);
    }
    if (canPaginateNetworkGroups) {
      removeSelectedBrowseGroup(fields);
    }
  }

  addGroupField() {
    this.props.fields.service_case.oonCase.selected.addField();
  }

  trackOONShares(value) {
    const { selectedFields, oonGroups } = this.props;
    const selectedGroups = [];
    selectedFields.map((field) => selectedGroups.push(...oonGroups.filter((g) => g.id === field.group.value.id)));
    this.trackGroupShares(value, selectedGroups);
  }

  trackGroupShares(value, groups) {
    // groups will always be an array of one or many
    const groupCount = groups.length;
    const { network } = this.props;
    each(groups, (g) => {
      const sharedGroup = Serializer.build({ sharedGroup: g });
      const payload = {
        ...sharedGroup,
        shared_message_type: value.messageType,
        browse_network_id: network.id,
        browse_network_name: network.name,
        shared_group_count: groupCount,
        shared_language_code: value.language,
        share_type: 'providers',
      };
      callOrLog(() => this.context.eventTracker(MY_NETWORKS.shareClicked, payload));
    });
  }

  render() {
    const {
      canPaginateNetworkGroups,
      debouncedSearchNetworkGroups,
      employeeId,
      groupKey,
      hide,
      isOONCase,
      oonGroups,
      originCoordinates,
      registerField,
      selectedFields,
      onServiceTypeChangeFetchEmpty,
      toggleBrowse,
    } = this.props;

    const { disableOrgAddButton, showShareDrawer } = this.state;

    const shareDrawerList = selectedFields
      .filter((group) => !isEmpty(group.group.value))
      .map(({ group: { value: { id, name } } }) => ({ id, name }));

    const selectedGroupIds = pluckSelectedGroupIds(selectedFields, groupKey);

    const oonGroupsEmpty = canPaginateNetworkGroups ? onServiceTypeChangeFetchEmpty : oonGroups.length === 0;

    // As long as we have at least 1 oon group selected/custom group entered
    // we can allow the form to submit with additional blank group fields.
    const hasAtLeastOneValue = some(selectedFields, (field) => (get(field, 'group.value') !== ''));

    const caseOONShareClass = () => classNames({
      'case-oon-share-btns': !hide,
    });

    return (
      <div className="case-oon-groups-selector">
        <ShowHide hide={hide}>
          <div className="row">
            <div className="col-xs-6">
              <CaseOONGroupHeader
                oonGroupsEmpty={oonGroupsEmpty}
                toggleBrowse={toggleBrowse}
              />
            </div>
          </div>
          <div className="case-oon-groups-selector__programs-wrapper">
            {
              selectedFields.map((selected, index) => (
                <div className="row" key={selected.group.value.id}>
                  <div className="col-xs-6 case-oon-groups-selector__programs-padded-col">
                    <CaseOONGroupSelect
                      debouncedSearchNetworkGroups={debouncedSearchNetworkGroups}
                      allowEmpty={hasAtLeastOneValue}
                      oonGroupFields={selectedFields}
                      groupField={selected[groupKey]}
                      originCoordinates={originCoordinates}
                      index={index}
                      isOONCase={isOONCase}
                      registerField={registerField}
                      suggestedGroups={oonGroups}
                      selectedGroupIds={selectedGroupIds}
                      removeGroupField={this.removeGroupField}
                    />
                  </div>
                </div>
              ))
            }
            <div className="row mb-one">
              <div className="col-xs-12">
                <div className={caseOONShareClass()}>
                  {
                    canAddMoreOONGroups({ selectedFields, suggestedGroups: oonGroups }) && (
                      <Button
                        id="add-another-oon-group-btn"
                        className="mr-one"
                        label="+ ADD ANOTHER OUT OF NETWORK RECIPIENT"
                        onClick={this.addGroupField}
                        disabled={disableOrgAddButton}
                      />
                    )
                  }
                  {
                    // TODO: Review whether we want to remove sharebutton as well
                    oonGroupSelected(selectedFields) && !oonGroupsEmpty && (
                      <Button
                        id="share-btn"
                        className="share-button case-creation-groups-programs__share-btn"
                        onClick={this.toggleShareDrawer}
                        iconRight={<Icon icon="IconShare" color="#2C405A" />}
                        label="Share"
                      />
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          <ShareDrawer
            employeeId={employeeId}
            coreApi={coreApi}
            SHARES_URL={SHARES_URL}
            show={showShareDrawer}
            onClose={this.toggleShareDrawer}
            resourceType="provider"
            resources={shareDrawerList}
            onShare={
              ({ shareMethod, language }) => this.onShareEvent({ shareMethod, language })
            }
          />
        </ShowHide>
      </div>
    );
  }
}

CaseOONGroupsSelector.propTypes = {
  canPaginateNetworkGroups: PropTypes.bool.isRequired,
  debouncedSearchNetworkGroups: PropTypes.func.isRequired,
  removeSelectedBrowseGroup: PropTypes.func.isRequired,
  employeeId: PropTypes.string,
  fields: PropTypes.shape({
    service_case: PropTypes.shape({
      oonCase: PropTypes.shape({
        selected: PropTypes.array.isRequired,
      }).isRequired,
    }),
  }).isRequired,
  groupKey: PropTypes.string,
  hide: PropTypes.bool,
  isOONCase: PropTypes.bool,
  network: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  onServiceTypeChangeFetchEmpty: PropTypes.bool.isRequired,
  oonGroups: PropTypes.array,
  originCoordinates: PropTypes.array,
  registerField: PropTypes.func.isRequired,
  selectedFields: PropTypes.array,
  toggleBrowse: PropTypes.func.isRequired,
};

CaseOONGroupsSelector.defaultProps = {
  employeeId: '',
  groupKey: 'group',
  hide: false,
  isOONCase: false,
  oonGroups: [],
  selectedFields: [],
  originCoordinates: [],
};

CaseOONGroupsSelector.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const groupId = state.session.groupId;
  const groupName = getGroup(state, groupId).name;
  const canPaginateNetworkGroups = paginateNetworkGroups(state);
  const employeeId = state.globalState?.currentEmployee?.id;

  return {
    canPaginateNetworkGroups,
    groupName,
    employeeId,
  };
};

export default connect(mapStateToProps)(CaseOONGroupsSelector);
