import React from 'react';
import PropTypes from 'prop-types';
import { SelectField } from '@unite-us/ui';
import validations from '@unite-us/client-utils/dist/utils/validations';

const ProcedureCodeRow = (props) => {
  const {
    row,
    options,
    index,
    registerField,
  } = props;

  const selectedProcedureCode = options?.find((procedureCode) => {
    const procedureCodeId = row.procedure_code?.value?.id ? row.procedure_code?.value?.id : row.procedure_code?.value;
    return procedureCodeId === procedureCode.id;
  });

  const procedureCodeModifiers = selectedProcedureCode ? selectedProcedureCode.procedure_code_modifiers : [];

  const onProcedureCodeChange = () => {
    row.procedure_code_modifier.onChange('');
  };

  const pcOptions = options?.map((pc) => ({
      label: pc?.description,
      value: pc?.id,
      disabled: pc?.disabled,
  }));

  const pcmOptions = procedureCodeModifiers?.map((pcm) => ({
    label: pcm?.description,
    value: pcm?.id,
  }));

  const hideProcedureCodeRow = options?.length === 1 && options[0].procedure_code_modifiers.length <= 1;

  return (
    <div className={'col-span-4 grid-cols-4 gap-x-6 flex flex-row items-center my-0 px-0'} key={index}>
      <SelectField
        id={`provided-service-procedure-code-${index}`}
        field={row?.procedure_code}
        options={pcOptions}
        placeholder="Choose one..."
        label="Procedure Code"
        searchPlaceholderValue="Search by code or description"
        onChange={onProcedureCodeChange}
        autoSelectValue={options?.length === 1}
        className={hideProcedureCodeRow ? 'hidden' : 'my-1'}
        ref={registerField}
        validations={(val) => validations.isRequired(val)}
        required
      />
      <SelectField
        id={`provided-service-procedure-code-modifier-${index}`}
        field={row?.procedure_code_modifier}
        label="Procedure Code Modifier"
        searchPlaceholderValue="Search by description"
        autoSelectValue={pcmOptions?.length === 1}
        options={pcmOptions}
        placeholder="Choose one..."
        className={hideProcedureCodeRow ? 'hidden' : 'my-1'}
        required
        ref={registerField}
        validations={(val) => validations.isRequired(val)}
        disabled={procedureCodeModifiers.length <= 1}
      />
    </div>
  );
};

ProcedureCodeRow.propTypes = {
  row: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  registerField: PropTypes.func.isRequired,
};
export default ProcedureCodeRow;
