import _ from 'lodash';

export function primaryWorkerOptions(props) {
  const groupUsers = _.get(props, 'groupsUsers.data', []);
  const activeGroupUsers = _.filter(groupUsers, (userObject) => (
    _.get(userObject, 'user.active_in_current_group', false)
  ));
  const options = activeGroupUsers.map((groupUser) => {
    const { employee } = groupUser;
    return {
      value: _.get(employee, 'id'),
      label: _.get(employee, 'full_name'),
      id: _.get(employee, 'user.id'),
    };
  });

  return _.sortBy(options, (option) => (option.label));
}

export function networkOptions(networks) {
  const options = networks.map((network) => ({
    id: network.id,
    name: network.name,
  }));

  return _.sortBy(options, (network) => (network.name));
}

export function programOptions(programmingOptionsArray) {
  const filteredOptions = _.reject(programmingOptionsArray, (p) => p.attributes.name === 'Referred Out of Network');
  const options = _.map(filteredOptions, (program = {}) => (
    {
      id: program.id,
      name: program.attributes.name,
      default_cc_program: program.attributes.default,
    }
  ));

  return _.sortBy(options, (program) => (program.name));
}

export const resolvedOptions = [
  { label: 'Resolved', value: 'resolved' },
  { label: 'Unresolved', value: 'not_resolved' },
];
