import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Disclosure } from '@headlessui/react';
import cx from 'classnames';
import { useFindProviderRequired } from 'src/components/Organization/api/hooks/v1/providerHooks';
import { connect } from 'react-redux';
import { flow, get, isEmpty } from 'lodash';
import { DashboardNavigationLink, LeftNavigationBar } from 'src/common/Navigation';
import FeatureFlagContainer from 'src/common/utils/FeatureFlags/FeatureFlagContainer';
import { useFeatureFlag } from 'src/common/hooks';
import { hasInvoicesAccess, hasPayerInvoicesRole } from 'src/common/utils/FeatureFlags/flags';
import { CurrentEmployeeContext } from 'src/common/contexts/CurrentEmployeeContext';
import { browserHistory } from 'src/common/utils/browserHistory';
import { Icon } from '@unite-us/ui';
import { CurrentProviderContextProvider } from 'common/contexts/CurrentProviderContext';
import { useInvoiceStatusCounts } from './hooks';

const InvoicesContainer = ({
  children,
  currentEmployee,
  showPayerInvoices,
  providerId,
  showInvoices,
  payerId,
}) => {
  const showArchive = useFeatureFlag('pays-1837-temp-invoices-archive');
  const showDisputeWorkflow = useFeatureFlag('useInvoiceDisputeWorkflow');
  const { data } = useFindProviderRequired({ providerId });
  const isNetworkLead = get(data, 'provider_type') === 'network_lead';

  useEffect(() => {
    if (!showInvoices) {
      browserHistory.push('/');
    }
  }, [showInvoices]);

  const counts = useInvoiceStatusCounts(providerId, isNetworkLead, payerId, showPayerInvoices, !isEmpty(data));

  if (showDisputeWorkflow) {
    return showInvoices && (
      <CurrentProviderContextProvider providerId={providerId}>
        <CurrentEmployeeContext.Provider value={currentEmployee}>
          <div className="flex antialiased min-h-full -mx-container-padding">
            <LeftNavigationBar className="space-y-2">
              <>
                {
                  (!isNetworkLead && !showPayerInvoices) && (
                    <>
                      <DashboardNavigationLink
                        key="draft"
                        path="/invoices/draft"
                        label="Draft"
                        count={counts.draft}
                        className="text-13px"
                        data-testid="draft-invoice-bucket"
                      />
                      <DashboardNavigationLink
                        key="rejected"
                        path="/invoices/rejected"
                        label="Rejected"
                        count={counts.rejectedDWQ}
                        className="text-13px"
                        data-testid="rejected-invoice-bucket"
                      />
                    </>
                  )
                }
                {
                  (isNetworkLead || showPayerInvoices) && (
                    <DashboardNavigationLink
                      key="open"
                      path="/invoices/open"
                      label="Open"
                      count={counts.open}
                      className="text-13px"
                      data-testid="open-invoice-bucket"
                    />
                  )
                }
                <DashboardNavigationLink
                  key="disputed"
                  path="/invoices/disputed"
                  label="Disputed"
                  count={counts.disputed}
                  className="text-13px"
                  data-testid="disputed-invoice-bucket"
                />
                {
                  (!isNetworkLead && !showPayerInvoices) && (
                    <>
                      <DashboardNavigationLink
                        key="submitted"
                        path="/invoices/submitted"
                        label="Submitted"
                        className="text-13px"
                        data-testid="submitted-invoice-bucket"
                      />
                      <DashboardNavigationLink
                        key="accepted"
                        path="/invoices/accepted"
                        label="Accepted"
                        className="text-13px"
                        data-testid="accepted-invoice-bucket"
                      />
                    </>
                  )
                }
                {
                  (isNetworkLead || showPayerInvoices) && (
                    <DashboardNavigationLink
                      key="closed"
                      path="/invoices/closed"
                      label="Closed"
                      className="text-13px"
                      data-testid="closed-invoice-bucket"
                    />
                  )
                }
                {
                  showArchive && (
                    <DashboardNavigationLink
                      key="archived-invoices"
                      className="text-13px"
                      label="Archived"
                      path="/invoices/archive"
                      data-testid="archived-invoice-bucket"
                    />
                  )
                }
                {/* All Invoices */}
                <DashboardNavigationLink
                  key="all-invoices"
                  className="text-13px"
                  label="All Invoices"
                  path="/invoices/all"
                  data-testid="all-invoices-bucket"
                />
              </>
            </LeftNavigationBar>
            <div className="mb-20 break-normal flex-grow overflow-hidden">
              {children}
            </div>
          </div>
        </CurrentEmployeeContext.Provider>
      </CurrentProviderContextProvider>
    );
  }

  return showInvoices && (
    <CurrentProviderContextProvider providerId={providerId}>
      <CurrentEmployeeContext.Provider value={currentEmployee}>
        <div className="flex antialiased min-h-full -mx-container-padding">
          <LeftNavigationBar className="space-y-4 -mb-20">
            <>
              {/* Open Invoices */}
              <Disclosure
                as="div"
                key="Open Invoices"
                className="space-y-1"
                defaultOpen="true"
              >
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className="leading-6 font-medium-font w-full py-2 pl-2
                              flex items-center rounded-md hover:bg-dark-grey focus:outline-none"
                    >
                      <Icon
                        className={cx(
                        'mr-2 h-3 w-3 transform',
                        open ? '-rotate-90' :
                          '',
                      )}
                        icon="IconChevronRight"
                        color="white"
                      />
                      Open Invoices
                    </Disclosure.Button>
                    <Disclosure.Panel className="space-y-1">
                      {/* Open Invoices Children */}
                      <DashboardNavigationLink
                        key="needs-attention"
                        path="/invoices/open/needs-attention"
                        label="Needs Attention"
                        count={isNetworkLead ? counts.submittedToNetworkLead : counts.submittedToCBOAdmin}
                        className="text-13px pl-5"
                        data-testid="needs-attention-invoices"
                      />
                      <DashboardNavigationLink
                        key="submitted"
                        path="/invoices/open/submitted"
                        label="Submitted"
                        className="text-13px pl-5"
                        data-testid="submitted-invoices"
                      />
                    </Disclosure.Panel>
                  </>
              )}
              </Disclosure>
              {/* In Dispute Invoices */}
              <DashboardNavigationLink
                key="in-dispute"
                className="text-base"
                path="/invoices/in-dispute"
                label="In Dispute"
                data-testid="in-dispute-invoices"
              />
              {/* Closed Invoices */}
              <Disclosure as="div" key="Closed Invoices" className="space-y-1">
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className="leading-6 font-medium-font w-full py-2 pl-2
                  flex items-center rounded-md hover:bg-dark-grey focus:outline-none"
                    >
                      <Icon
                        className={cx(
                        'mr-2 h-3 w-3 transform',
                        open ? '-rotate-90' :
                          '',
                      )}
                        icon="IconChevronRight"
                        color="white"
                      />
                      Closed Invoices
                    </Disclosure.Button>
                    <Disclosure.Panel className="space-y-1">
                      {/* Closed Invoices Children */}
                      <DashboardNavigationLink
                        key="accepted"
                        path="/invoices/closed/accepted"
                        label="Accepted"
                        className="text-13px pl-5"
                        data-testid="accepted-invoices"
                      />
                      <DashboardNavigationLink
                        key="paid"
                        path="/invoices/closed/paid"
                        label="Paid"
                        className="text-13px pl-5"
                        data-testid="paid-invoices"
                      />
                      <DashboardNavigationLink
                        key="rejected"
                        path="/invoices/closed/rejected"
                        label="Rejected"
                        count={isNetworkLead ? null : counts.rejected}
                        className="text-13px pl-5"
                        data-testid="rejected-invoices"
                      />
                    </Disclosure.Panel>
                  </>
              )}
              </Disclosure>
              {
                showArchive && (
                  <DashboardNavigationLink
                    key="archived-invoices"
                    className="text-base"
                    label="Archive"
                    path="/invoices/archived"
                    data-testid="archived-invoices"
                  />
                )
              }
              {/* All Invoices */}
              <DashboardNavigationLink
                key="all-invoices"
                className="text-base"
                label="All Invoices"
                path="/invoices/all"
                data-testid="all-invoices"
              />
            </>
          </LeftNavigationBar>
          <div className="mb-20 break-normal flex-grow">
            {children}
          </div>
        </div>
      </CurrentEmployeeContext.Provider>
    </CurrentProviderContextProvider>
  );
};

InvoicesContainer.propTypes = {
  children: PropTypes.node.isRequired,
  currentEmployee: PropTypes.object,
  showPayerInvoices: PropTypes.bool,
  providerId: PropTypes.string,
  showInvoices: PropTypes.bool,
  payerId: PropTypes.string,
};

InvoicesContainer.defaultProps = {
  currentEmployee: {},
  providerId: '',
  showInvoices: false,
  showPayerInvoices: false,
  payerId: '',
};

function mapStateToProps(state) {
  const providerType = get(state, 'session.currentProvider.provider_type');
  const payerId = providerType === 'payer' ? get(state, 'session.currentProvider.payer.id') : '';

  return {
    payerId,
    currentEmployee: get(state, 'globalState.currentEmployee', ''),
    providerId: get(state, 'session.currentProvider.id', ''),
    showInvoices: hasInvoicesAccess(state),
    showPayerInvoices: hasPayerInvoicesRole(state),
  };
}

export default flow(
  FeatureFlagContainer,
  connect(mapStateToProps),
)(InvoicesContainer);
