import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import { SelectField } from '@unite-us/ui';
import { validations } from '@unite-us/client-utils';
import classNames from 'classnames';

class ReferralOONCaseFields extends Component {
  constructor(props) {
    super(props);
    this.selectField = React.createRef();
  }

  componentDidMount() {
    this.props.registerField(this.selectField.current);
  }

  componentWillUnmount() {
    this.props.unregisterField(this.selectField.current);
  }

  render() {
    const {
      activeUsersInGroup,
      fields,
      fetchGroupsUsers,
      hidden,
    } = this.props;

    const classes = classNames({
      'referral-oon-case-fields': true,
      hidden,
    });

    return (
      <div className={classes}>
        <div className="row">
          <div className="col-xs-6">
            <SelectField
              id="primary-worker"
              label="Primary Worker"
              field={fields.oonCase.primary_worker}
              labelKey="full_name"
              valueKey="id"
              forceObjectvalue
              inline={false}
              loadOptions={fetchGroupsUsers}
              options={activeUsersInGroup}
              placeholder="Choose one..."
              searchPlaceholderValue="Search - Displaying 50 Max"
              ref={this.selectField}
              required
              validations={[{
                func: (value) => {
                  if (!hidden && isEmpty(value)) {
                    return validations.isRequired();
                  }
                  return null;
                },
              }]}
            />
          </div>
        </div>
      </div>
    );
  }
}

ReferralOONCaseFields.propTypes = {
  activeUsersInGroup: PropTypes.array,
  fetchGroupsUsers: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    oonCase: PropTypes.shape({
      primary_worker: PropTypes.object.isRequired,
      program_entry: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
  hidden: PropTypes.bool,
  registerField: PropTypes.func.isRequired,
  unregisterField: PropTypes.func,
};

ReferralOONCaseFields.defaultProps = {
  activeUsersInGroup: [],
  hidden: false,
  isCheckingProgramEnrollment: false,
  unregisterField: noop,
};

export default ReferralOONCaseFields;
