function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { reduce, find, isArray, map, compact, isEmpty } from 'lodash';
function getValue(question) {
  var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  // Values for hidden questions may be undefined. The default value given to
  // the input parameter (value = '') should cover that, but we'll check isEmpty
  // in case it comes in as null or an empty object.
  if (isEmpty(value) && typeof value !== 'number') {
    return '';
  }
  switch (question.input_type) {
    case 'select':
      if (isArray(value)) {
        return map(value, 'id');
      }
      return value.id;
    case 'checkbox':
      return compact(map(value, function (val, id) {
        return val === true ? id : null;
      }));
    case 'duration':
      return [value.start, value.end];
    default:
      return value.toString();
  }
}
function formatData(formData, values) {
  var formatedData = reduce(values, function (accu, section, sectionId) {
    var formSection = find(formData.sections, {
      id: sectionId
    });
    var sectionResponses = reduce(section, function (acc, response, questionId) {
      var formQuestion = find(formSection.questions, {
        id: questionId
      });
      if (!formQuestion) {
        return acc;
      }
      var value = getValue(formQuestion, response);
      if (isEmpty(value)) {
        return acc;
      }
      return [].concat(_toConsumableArray(acc), [{
        question_id: formQuestion.id,
        response_value: value
      }]);
    }, []);
    return [].concat(_toConsumableArray(accu), _toConsumableArray(sectionResponses));
  }, []);
  return formatedData;
}
export default formatData;