import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'common/utils/browserHistory';
import { get, isEmpty, some } from 'lodash';
import { Serializer } from '@unite-us/client-utils';
import { Icon, BarLoader } from '@unite-us/ui';
import { buildDraftState, canAccept, withAppCreateReferralContext } from '@unite-us/app-create-referral';

import ToolTip from 'common/ToolTip';

// Action Creators
import { addContactToGroup } from 'actions/Contact/Group';
import { fetchServiceCaseReferrals } from 'src/actions/Case/Contact/Group';

// Constants
import { REFERRAL } from 'common/utils/EventTracker/utils/eventConstants';
import {
  ACCEPT_MODAL_DIALOG,
  CLOSE_REFERRAL_MODAL_DIALOG,
  HOLD_MODAL_DIALOG,
  RECALL_MODAL_DIALOG,
  REJECT_MODAL_DIALOG,
  INACTIVE_PROGRAM_DIALOG,
} from 'src/components/Referrals/ReferralStatus/constants';

// Utils
import callOrLog from 'src/common/utils/callOrLog';
import featureFlag from 'common/utils/FeatureFlag/FeatureFlag';
import { uup459SupersetPhase2, crtb676CloseInboundReferrals, crtb1239AdaptDraftReferralFlow, crtb1060ChangeReasonInReviewReferral } from 'common/utils/FeatureFlags/flags';
import { goToCase } from 'src/components/Cases/utils/routing';
import canReject from 'src/components/Referrals/ReferralStatus/utils/referralActions/canReject';
import canClose from 'src/components/Referrals/ReferralStatus/utils/referralActions/canClose';
import canHoldForReview from 'src/components/Referrals/ReferralStatus/utils/referralActions/canHoldForReview';
import canChangeHoldReason from 'src/components/Referrals/ReferralStatus/utils/referralActions/canChangeHoldReason';
import canManageFeeSchedule from 'src/components/Referrals/ReferralStatus/utils/referralActions/canManageFeeSchedule';
import canRecall from 'src/components/Referrals/ReferralStatus/utils/referralActions/canRecall';
import canView from 'src/common/utils/SecureLink/utils/canView';
import { RejectModalDialog } from 'src/components/Referrals/ReferralRejection/components';
import handleDraftSubmission from './handleDraftSubmission';
import { actionOptions } from '../utils';

// Components
import AcceptModalDialog from './AcceptModalDialog';
import CloseReferralModalDialog from './CloseReferralModalDialog';
import HoldModalDialog from './HoldModalDialog';
import RecallModalDialog from './RecallModalDialog';
import InactiveProgramDialog from './InactiveProgramDialog';
import InactiveReferralDialog from './InactiveReferralDialog';
import ReferralActionButton from './ReferralActionButton';

const { Rollbar } = window;

export const getPostActionRedirectPath = (status) => {
  if (status === 'REJECTED') {
    return '/dashboard/referrals/sent/rejected';
  }
  if (status === 'RECALLED') {
    return '/dashboard/referrals/sent/recalled';
  }

  return '/dashboard/referrals/sent/all';
};

const ReferralActionsDisabledReason = ({ reason }) => {
  const Button = () => (
    <Icon
      icon="IconInfoCircle"
      className="fill-current text-text-blue"
      ariaLabel="Referral Action Disabled Details"
    />
);

  return (
    <ToolTip
      buttonComponentContent={Button}
      panelTextContent={reason}
      panelClassName="w-1/5 mt-4 -mr-2 border bg-white border-solid border-gray-400
      rounded p-8 text-gray-600 leading-5 shadow-md"
      buttonClassName="px-2 focus:outline-none"
      placement="bottom-end"
    />
  );
};

ReferralActionsDisabledReason.propTypes = {
  reason: PropTypes.string.isRequired,
};

export class ReferralActions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      referralActionTypes: [],
      caseReferrals: [],
      isLoading: true,
    };

    this.goToCase = this.goToCase.bind(this);
    this.goToIntake = this.goToIntake.bind(this);
    this.openModal = this.openModal.bind(this);
    this.resetActionValue = this.resetActionValue.bind(this);
    this.goToReferralSend = this.goToReferralSend.bind(this);
    this.handleActionChange = this.handleActionChange.bind(this);
    this.onDraftSend = this.onDraftSend.bind(this);
  }

  componentDidMount() {
    const {
      referral,
    } = this.props;

    const serviceCase = get(referral, 'service_case', {});
    const contact = get(serviceCase, 'contact') || get(referral, 'contact', {});
    if (canView(contact)) {
      this.createReferralActionTypes().finally(() => {
        this.setState({ isLoading: false });
      });
    } else {
      this.setState({ isLoading: false });
    }
  }

  handleActionChange(action) {
    const { referral } = this.props;
    const { value } = action;

    const eventPayload = Serializer.build({ referral });

    if (REFERRAL[value]) {
      callOrLog(() => this.context.eventTracker(REFERRAL[value], { referral: eventPayload }));
    } else if (Rollbar) {
      Rollbar.warning(`Untracked Referral Action Value, ${value}`);
    }
  }

  onDraftSend() {
    const {
      isSendable,
      isSupersetEnabled,
      referral,
      dispatchCreateReferral,
    } = this.props;

    if (!isSendable) {
      return this[INACTIVE_PROGRAM_DIALOG].openDialog();
    }

    if (isSupersetEnabled) {
      buildDraftState({ referral, dispatchCreateReferral });
      return browserHistory.push('/referrals/2/create/builder');
    }

    return this.props.handleDraftSubmission({
      networkGroups: this.props.networkGroups,
      referral: this.props.referral,
      resetActionValue: this.resetActionValue,
    });
  }

  async createReferralActionTypes() {
    const {
      employee,
      groupId,
      isCC,
      referral,
      user,
      closeInboundReferralsEnabled,
      crtb1239AdaptDraftReferralFlowEnabled,
      crtb1060ChangeReasonInReviewReferralEnabled,
    } = this.props;

    const [referralsResponse, canManage] = await Promise.all([
      fetchServiceCaseReferrals(get(referral, 'case.id')),
      canManageFeeSchedule({
        employee,
        referral,
        user,
      }),
    ]);

    const caseReferrals = get(referralsResponse, 'data.data', []);

    const referralActionTypes = await actionOptions({
      goToCase: this.goToCase, // can we deprecate this?
      goToReferralSend: this.goToReferralSend,
      user,
      groupId,
      isCC,
      onSubmit: this.onDraftSend,
      openModal: this.openModal,
      referral,
      caseReferrals,
      closeInboundReferralsEnabled,
      crtb1239AdaptDraftReferralFlowEnabled,
      crtb1060ChangeReasonInReviewReferralEnabled,
    });

    if (isEmpty(referralActionTypes)) {
      this.props.falsifyActionBool();
    }

    this.setState({ canManage, caseReferrals, referralActionTypes });
  }

  openModal(target) {
    const {
      groupId,
      referral,
      isCC,
      user,
      closeInboundReferralsEnabled,
    } = this.props;

    const otherReferralLegs = this.state.caseReferrals.filter((r) => r.id !== referral.id &&
    r.receiving_provider.id !== groupId);
    const openReferrals = some(otherReferralLegs, (r) => ['sent', 'in_review', 'accepted'].includes(r.state));
    const canBeClosed = canClose({
      user,
      referral,
      groupId,
      isCC,
      closeInboundReferralsEnabled,
    });

    if (!canBeClosed && target === 'closeModalDialog' && openReferrals) {
      return this.openDialog();
    }

    return this[target].openDialog();
  }

  goToReferralSend() {
    const { referral } = this.props;
    browserHistory.push({
      pathname: `/referrals/${referral.id}/send`,
      state: { caseReferrals: this.state.caseReferrals },
    });
  }

  goToCase() {
    const { referral } = this.props;
    const serviceCase = get(referral, 'service_case', {});
    const contact = get(serviceCase, 'contact') || get(referral, 'contact', {});
    goToCase({ ...serviceCase, contact });
  }

  goToIntake() {
    const {
      groupId,
      pathName,
      referral,
    } = this.props;
    const contactId = get(referral, 'contact.id', null);

    this.props.addContactToGroup(contactId, groupId).then(() => {
      browserHistory.push({
        pathname: '/intakes/new',
        search: `?contactId=${contactId}&referralId=${referral.id}`,
        state: { lastPath: pathName },
      });
    });
  }

  resetActionValue() {
    const actionSelect = get(this, 'referralActionButton.actionSelect');
    if (actionSelect) {
      actionSelect.resetValue();
    }
  }

  render() {
    const {
      disabled,
      groupId,
      outcomes,
      outcomesIsFetching,
      referral,
      isCC,
      user,
      status,
      closeInboundReferralsEnabled,
      crtb1060ChangeReasonInReviewReferralEnabled,
    } = this.props;
    const { referralActionTypes, canManage, isLoading } = this.state;
    const referralId = get(referral, 'id');

    const actions = {
      data: referralActionTypes,
    };

    // eslint-disable-next-line max-len
    const cannotManageTooltip = 'Only care managers of the associated fee schedule can take action on referrals with an authorization request.';
    // Temporary message for pending auth referrals
    // eslint-disable-next-line max-len
    const pendingAuthTooltip = 'You cannot recall a referral that is pending authorization. Please wait until the payer has responded to take action.';

    const isPendingAuth = referral?.state === 'pending_authorization';
    const disableButton = disabled || !canManage || isPendingAuth;

    let tooltip = null;
    if (isPendingAuth) {
      tooltip = pendingAuthTooltip;
    } else if (!canManage) {
      tooltip = cannotManageTooltip;
    }

    if (isLoading) {
      return (<BarLoader style={{ width: '200px' }} tall />);
    }

    return (
      <div className="detail-statuses flex items-center">
        <ReferralActionButton
          disabled={disableButton}
          ref={(referralActionButton) => { this.referralActionButton = referralActionButton; }}
          actions={actions}
          onChange={this.handleActionChange}
        />
        {tooltip && <ReferralActionsDisabledReason reason={tooltip} />}
        {
          canAccept({
            isCC,
            user,
            referral,
            groupId,
          }) && (
            <AcceptModalDialog
              ref={(el) => { this[ACCEPT_MODAL_DIALOG] = el; }}
              referral={referral}
              resetActionValue={this.resetActionValue}
            />
          )
        }
        {
          canClose({
            user,
            referral,
            groupId,
            isCC,
            closeInboundReferralsEnabled,
          }) && (
          <CloseReferralModalDialog
            ref={(el) => { this[CLOSE_REFERRAL_MODAL_DIALOG] = el; }}
            referralId={referralId}
            outcomes={outcomes}
            outcomesIsFetching={outcomesIsFetching}
            resetActionValue={this.resetActionValue}
            referral={referral}
            isCC={isCC}
          />
          )
        }
        {
          canHoldForReview({ user, referral, groupId }) && (
            <HoldModalDialog
              ref={(el) => { this[HOLD_MODAL_DIALOG] = el; }}
              referral={referral}
              dataTestId="hold-referral-modal"
              resetActionValue={this.resetActionValue}
            />
          )
        }
        {
          canChangeHoldReason({ user, referral, groupId }) && crtb1060ChangeReasonInReviewReferralEnabled && (
            <HoldModalDialog
              ref={(el) => { this[HOLD_MODAL_DIALOG] = el; }}
              referral={referral}
              dataTestId="change-hold-for-review"
              resetActionValue={this.resetActionValue}
            />
          )
        }
        {
          canRecall({ user, referral, groupId }) && (
            <RecallModalDialog
              ref={(el) => { this[RECALL_MODAL_DIALOG] = el; }}
              referral={referral}
              resetActionValue={this.resetActionValue}
            />
          )
        }
        {
          canReject({
            user,
            referral,
            groupId,
            isCC,
          }) && (
            <RejectModalDialog
              ref={(el) => { this[REJECT_MODAL_DIALOG] = el; }}
              referral={referral}
              groupId={groupId}
              resetActionValue={this.resetActionValue}
              isCC={isCC}
            />
          )
        }
        <InactiveProgramDialog
          ref={(el) => { this[INACTIVE_PROGRAM_DIALOG] = el; }}
          referral={referral}
          groupId={groupId}
          resetActionValue={this.resetActionValue}
          isCC={isCC}
        />
        <InactiveReferralDialog
          actionRedirectPath={getPostActionRedirectPath(status)}
          referralId={referral.id}
          resetActionValue={this.resetActionValue}
          setDialogCallback={(el) => { this.openDialog = el; }}
        />
      </div>
    );
  }
}

ReferralActions.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

ReferralActions.propTypes = {
  addContactToGroup: PropTypes.func.isRequired,
  closeInboundReferralsEnabled: PropTypes.bool,
  crtb1239AdaptDraftReferralFlowEnabled: PropTypes.bool,
  crtb1060ChangeReasonInReviewReferralEnabled: PropTypes.bool,
  disabled: PropTypes.bool,
  dispatchCreateReferral: PropTypes.func,
  employee: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fee_schedules: PropTypes.array,
  }).isRequired,
  falsifyActionBool: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  handleDraftSubmission: PropTypes.func.isRequired,
  // intakes: PropTypes.array.isRequired,
  isCC: PropTypes.bool.isRequired,
  /** Boolean value to determine if draft referrals can be sent (inactive programs) */
  isSendable: PropTypes.bool.isRequired,
  isSupersetEnabled: PropTypes.bool,
  networkGroups: PropTypes.array.isRequired,
  outcomes: PropTypes.array.isRequired,
  outcomesIsFetching: PropTypes.bool,
  pathName: PropTypes.string,
  referral: PropTypes.shape({
    id: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    contact: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  status: PropTypes.string,
  user: PropTypes.object.isRequired,
};

ReferralActions.defaultProps = {
  closeInboundReferralsEnabled: false,
  crtb1239AdaptDraftReferralFlowEnabled: false,
  crtb1060ChangeReasonInReviewReferralEnabled: false,
  disabled: false,
  isSupersetEnabled: false,
  outcomesIsFetching: false,
  pathName: '',
  status: '',
};

function mapStateToProps(state) {
  return {
    user: state.user,
    intakes: state.intakes?.intakes || [],
    employee: get(state, 'globalState.currentEmployee'),
    isSupersetEnabled: uup459SupersetPhase2(state),
    closeInboundReferralsEnabled: crtb676CloseInboundReferrals(state),
    crtb1239AdaptDraftReferralFlowEnabled: crtb1239AdaptDraftReferralFlow(state),
    crtb1060ChangeReasonInReviewReferralEnabled: crtb1060ChangeReasonInReviewReferral(state),
  };
}

export default connect(mapStateToProps, {
  addContactToGroup,
  handleDraftSubmission,
})(withAppCreateReferralContext(featureFlag(ReferralActions)));
