// Library Imports
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { dates } from '@unite-us/client-utils';

// Component Imports
import ServiceType from '../../../Group/Programs/components/ServiceType';
import './CaseSummaryItem.scss';

function truncateName(name) {
  return _.truncate(name, { length: 40 });
}

function CaseSummaryItem(props) {
  const { serviceCase, styles: { serviceCaseItemStyle } } = props;
  const date = _.get(serviceCase, 'opened_date', {});
  const programName = _.has(serviceCase, 'program.name') ? (
    <p style={serviceCaseItemStyle} title={_.get(serviceCase, 'program.name')}>
      {truncateName(_.get(serviceCase, 'program.name'))}
    </p>
  ) : null;

  return (
    <div className="case-summary-item">
      <div className="case-summary-item__media-container">
        <div className="case-summary-item__media-left">
          <ServiceType service={_.get(serviceCase, 'service')} svgStyle={props.styles.mediaIcon} iconOnly />
        </div>
        <div className="case-summary-item__media-body">
          <h4 className="case-summary-item__media-heading">{_.get(serviceCase, 'service.name')}</h4>
          {programName}
          <p style={serviceCaseItemStyle} title={_.get(serviceCase, 'provider.name')}>
            {truncateName(_.get(serviceCase, 'provider.name'))}
          </p>
          <p className="case-summary-item__subhead">Date Opened: {dates.formatDate(date)}</p>
        </div>
      </div>
    </div>
  );
}

CaseSummaryItem.propTypes = {
  serviceCase: PropTypes.object.isRequired,
  styles: PropTypes.object.isRequired,
};
CaseSummaryItem.defaultProps = {
  styles: {
    mediaIcon: { width: '2em', height: '2em' },
    serviceCaseItemStyle: { margin: '3px 0' },
  },
};

export default CaseSummaryItem;
