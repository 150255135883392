// Library Imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { dates } from '@unite-us/client-utils';
import _ from 'lodash';

// Component Imports
import EditModal from 'common/modal/EditModal';
import ServiceCaseProgramExitForm from 'src/components/Cases/components/Detail/ServiceCaseProgramExitForm';

class ServiceCaseProgramExit extends Component {
  render() {
    const {
      serviceCase,
      contactId,
      groupId,
      editable,
    } = this.props;

    const editSection = (
      <div className="service-case-details__edit-section">
        <EditModal
          id="service-case-details__edit-modal"
          buttonId="service-case-details__edit-button"
          header="Edit Closed Date"
          formName="editServiceCaseClosedDate"
          labelText="Edit"
          size="mini"
        >
          <ServiceCaseProgramExitForm
            ref={(el) => { this.editServiceCaseProgramExit = el; }}
            serviceCase={serviceCase}
            contactId={contactId}
            groupId={groupId}
          />
        </EditModal>
      </div>
    );

    return (
      <div className="service-case-program-exit service-case-node">
        <p className="service-case-program-exit__text detail-status__description">
          {dates.formatDate(_.get(serviceCase, 'closed_date')) || 'N/A'}
        </p>

        {editable && editSection}
      </div>
    );
  }
}

ServiceCaseProgramExit.propTypes = {
  serviceCase: PropTypes.shape({
    program: PropTypes.shape({
      enrolled_at: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  contactId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
};

export default ServiceCaseProgramExit;
