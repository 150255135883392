// Library Imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// Util Imports
import { dates } from '@unite-us/client-utils';

// Component Imports
import EditModal from 'common/modal/EditModal';
import ServiceCaseProgramEntryForm from 'src/components/Cases/components/Detail/ServiceCaseProgramEntryForm';

class ServiceCaseProgramEntry extends Component {
  render() {
    const {
      serviceCase,
      contactId,
      groupId,
      editable,
    } = this.props;

    const editSection = (
      <div className="service-case-details__edit-section">
        <EditModal
          id="service-case-details__edit-modal"
          buttonId="service-case-details__edit-button"
          header="Edit Date Opened"
          formName="editServiceCaseProgramEntryForm"
          labelText="Edit"
          size="mini"
        >
          <ServiceCaseProgramEntryForm
            ref={(el) => { this.editServiceCaseProgramEntry = el; }}
            serviceCase={serviceCase}
            contactId={contactId}
            groupId={groupId}
          />
        </EditModal>
      </div>
    );

    return (
      <div className="service-case-program-entry service-case-node">
        <p className="service-case-program-entry__text detail-status__description">
          {dates.formatDate(_.get(serviceCase, 'opened_date', ''))}
        </p>

        {editable && editSection}
      </div>
    );
  }
}

ServiceCaseProgramEntry.propTypes = {
  serviceCase: PropTypes.shape({
    program: PropTypes.shape({
      enrolled_at: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  contactId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
};

export default ServiceCaseProgramEntry;
