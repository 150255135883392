import { NOTE_TYPE_VALUES } from "./constants";
function getCollectionFromNoteType(collection_class, values) {
  var GENERAL = NOTE_TYPE_VALUES.GENERAL,
    INTERACTION = NOTE_TYPE_VALUES.INTERACTION,
    PROVIDED_SERVICE = NOTE_TYPE_VALUES.PROVIDED_SERVICE;
  var noteValues = values[collection_class];
  switch (collection_class) {
    case GENERAL:
      return {
        interaction: noteValues,
        collection_class: collection_class
      };
    case INTERACTION:
      return {
        interaction: noteValues,
        collection_class: noteValues.type
      };
    case PROVIDED_SERVICE:
      return {
        collection_class: collection_class,
        provided_service: noteValues
      };
    default:
      return {};
  }
}
export default getCollectionFromNoteType;