import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { validateReduxForm } from 'common/form';
import { validations } from '@unite-us/client-utils';
import {
  Button,
  TextField,
} from '@unite-us/ui';
import _ from 'lodash';
import { OverlaySpinner } from 'common/spinners';
import callOrLog from 'common/utils/callOrLog';
import { REFERRAL } from 'common/utils/EventTracker/utils/eventConstants';
import { isHttpError } from 'common/utils/Error';
import NoteDisclaimer from 'common/Disclaimer/NoteDisclaimer';
import { rejectReferral } from 'actions/Referral/Group';
import { ReferralReasonField } from 'src/components/Referrals/ReferralFormFields';
import {
  createReasonOptions,
} from 'src/components/Referrals/ReferralFormFields/ReferralReasonField/utils';
import { validateNoteText } from 'src/components/Referrals/utils/form';
import { hasNewRejectionReason } from 'common/utils/FeatureFlags/flags';

const REJECT_REFERRAL_FORM = 'rejectReferralForm';

export class RejectModalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shortenedReason: '',
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.setShortenedReason = this.setShortenedReason.bind(this);
    this.navigateToIndex = this.navigateToIndex.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  onSubmit(values) {
    return this.props.rejectReferral(
      this.props.groupId,
      this.props.referral.id,
      { ...values, reason_shortened: this.state.shortenedReason },
      true,
    )
      .then((response) => {
        if (isHttpError(response, 409)) {
          return () => this.props.router.push('/dashboard/new/referrals');
        }

        const referral = _.get(response, 'data.data', {});

        callOrLog(() => this.context.eventTracker(REFERRAL.rejected, {
          reason_shortened: _.get(referral, 'rejection.reason_shortened'),
        }, { referral }));
        this.closeDialog();
        return this.navigateToIndex;
      });
  }

  setShortenedReason(result) {
    const { rejection } = this.props;

    const selectedReason = _.find(rejection, { value: result });

    this.setState({
      shortenedReason: _.get(selectedReason, 'display_name', ''),
    });
  }

  navigateToIndex() {
    const { referral } = this.props;
    return _.get(referral, 'state') === 'in_review' ?
      this.props.router.push('/dashboard/new/in-review') :
      this.props.router.push('/dashboard/new/referrals');
  }

  closeDialog() {
    this.props.resetForm();
    this.props.closeDialog();
  }

  render() {
    const {
      fields: {
        note,
        reason,
      },
      handleSubmit,
      registerField,
      rejection,
      submitting,
    } = this.props;

    const composeValidators = (...validators) => (
      (value) => validators.reduce((error, validator) => error || validator(value), undefined)
    );

    return (
      <form onSubmit={handleSubmit(this.onSubmit)} className="content-with-actions">
        <div className="content-container">
          <OverlaySpinner show={this.props.submitting} text="Rejecting Referral..." />
          <ReferralReasonField
            className="reject-referral-reason-select"
            field={reason}
            id="reject-reason-input"
            onChange={this.setShortenedReason}
            options={createReasonOptions(rejection)}
            registerField={registerField}
          />
          <TextField
            field={note}
            id="reject-note-input"
            label="Note"
            afterLabelContent={<NoteDisclaimer />}
            ref={registerField}
            validations={composeValidators(validations.isRequired, validateNoteText)}
            inline={false}
            required
          />
        </div>
        <div className="actions">
          <span className="action-item">
            <Button
              id="reject-referral-cancel-btn"
              onClick={this.closeDialog}
              disabled={submitting}
              label="Cancel"
            />
          </span>
          <span className="action-item">
            <Button
              id="reject-referral-reject-btn"
              onClick={handleSubmit(this.onSubmit)}
              disabled={submitting}
              label="Reject"
              primary
            />
          </span>
        </div>
      </form>
    );
  }
}

RejectModalForm.propTypes = {
  referral: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  groupId: PropTypes.string.isRequired,
  rejectReferral: PropTypes.func.isRequired,
  registerField: PropTypes.func.isRequired,
  rejection: PropTypes.array.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

RejectModalForm.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  let rejectionReasons = state.session.enums.referrals.rejection;
  if (!hasNewRejectionReason(state)) {
    rejectionReasons = rejectionReasons.filter((reason) => reason.value !== 'Client declined services');
  }

  return {
    groupId: state.session.groupId,
    programs: state.groupsPrograms.data,
    employees: state.groupsUsers.data,
    rejection: rejectionReasons,
  };
}

const fields = [
  'reason',
  'note',
];
export default validateReduxForm(
  {
    form: REJECT_REFERRAL_FORM,
    fields,
    onSubmitSuccess: (response) => response(),
  },
  mapStateToProps,
  { rejectReferral },
)(withRouter(RejectModalForm));
