import _, { get } from 'lodash';
import {
  DASHBOARD_FETCH_CASES,
  SET_DASHBOARD_FETCHING,
} from 'actions';
import Notifier from 'common/helpers/Notifier';
import { unsetDashboardFetching } from 'actions/Dashboard';
import { coreApi } from 'src/api/config';
import { oneYearAgoDateString } from 'src/common/utils/today';
import { includePathwaysServices } from 'common/utils/FeatureFlags/flags';

const defaultOptions = {
  sidx: 'created_at',
  sord: 'asc',
  filters: {},
  page: 1,
};

function fetchDashboardCases(groupId, caseType, options = defaultOptions, pays5604Flag = false) {
  return async (dispatch, getState) => {
    try {
      let cancel;
      const reduxState = getState();

      dispatch({
        type: SET_DASHBOARD_FETCHING,
        target: options.target,
        filters: options.filters,
        cancel,
      });

      // state is to filter by in/out network
      const statesTarget = () => {
        switch (options.target) {
          case 'draftReferrals':
            return 'draft';
          case 'oonCases':
            return 'off_platform';
          case 'closedOONCases':
            return 'off_platform';
          case 'allOONCases':
            return 'off_platform';
          case 'externalCases':
            return 'managed,off_platform';
          case 'closedExternalCases':
            return 'managed,off_platform';
          case 'allExternalCases':
            return 'managed,off_platform';
          case 'allNetworkCases':
            return 'managed,off_platform';
          default:
            if (pays5604Flag) {
              return 'managed,pending_authorization';
            }
            return 'managed';
        }
      };
      const states = {
        out: 'off_platform',
        in: pays5604Flag ? 'managed,pending_authorization' : 'managed',
        all: statesTarget(),
        undefined: statesTarget(),
      };
      const careCoordinatorUsers = _.get(options, 'filters.care_coordinator_users', []);
      const careCoordinators = careCoordinatorUsers.filter((c) => c !== 'none');
      const noneAssigned = careCoordinatorUsers.includes('none');

      const state = states[options.filters.network_inclusion_scope];

      let consent_state = null;
      let person_has_consent = null;
      const consentStatus = get(options, 'filters.consent_status', null);
      if (consentStatus === 'none' || !consentStatus) {
        // do nothing
      } else if (consentStatus === 'pending' || consentStatus === 'revoked') {
        consent_state = null;
        person_has_consent = false;
      } else {
        consent_state = consentStatus;
        person_has_consent = null;
      }

      let primaryWorkers = _.get(options, 'filters.primary_worker_users', []);
      const caseStatuses = _.get(options, 'filters.status_filter', []);
      const authorizationStatuses = _.get(options, 'filters.authorization_status_filter', []);
      if (primaryWorkers[0] === 'all') {
        primaryWorkers = [];
      }

      // pass closed only when we want to filter by open/close cases
      let closed = null;
      if (['closedCases', 'closedOONCases', 'closedExternalCases'].includes(options.target)) {
        closed = true;
      }
      if (['openCases', 'oonCases', 'externalCases', 'draftReferrals'].includes(options.target)) {
        closed = false;
      }
      if (caseStatuses.length === 1) {
        if (caseStatuses[0] === 'open') {
          closed = false;
        } else {
          closed = true;
        }
      }

      let watchers = null;
      if (!_.isEmpty(options.filters.sent_by)) {
        watchers = options.filters.sent_by.join(',');
      }
      let program = null;
      if (!_.isEmpty(options.filters.programs_filter)) {
        program = options.filters.programs_filter.join(',');
      }

      const networks = _.get(options, 'filters.networks', []);
      let providers = _.get(options, 'filters.groups', []);
      if (options.target !== 'allNetworkCases') {
        providers = [groupId];
      }

      const additionalFilters = () => {
        if (['externalCases', 'closedExternalCases', 'allExternalCases'].includes(options.target)) {
          return { 'referrals.sending_provider': providers.join(','), 'referrals.state': 'accepted' };
        }
        if (options.target === 'allNetworkCases') {
          return { consent_state: 'accepted', provider: providers.join(',') };
        }
        return { provider: providers.join(',') };
      };

      const includePathways = includePathwaysServices(reduxState) || false;

      // empty values will be removed from the query
      const response = await coreApi.query('case', {
        ...additionalFilters(),
        network: networks.join(','),
        service: _.get(options, 'filters.service_types', []).join(','),
        primary_worker: primaryWorkers.join(','),
        state,
        has_outcome: closed,
        'person.consent.state': consent_state,
        'case.person.has_consent': person_has_consent,
        ...(!_.isEmpty(careCoordinators) ?
          { 'person.client_relationships.care_coordinator': careCoordinators.join(',') } : null),
        ...(noneAssigned ? {
          'person.client_relationships.has_care_coordinator': false,
          'person.client_relationships.provider': groupId,
        } : null),
        'outcome.resolved': _.get(options, 'filters.resolved'),
        ...(!_.isEmpty(program) ? { program } : null),
        'service_authorization.state': authorizationStatuses.join(','),
        ...(!_.isEmpty(watchers) ? { watchers } : null),
        updated_after: oneYearAgoDateString,
        include_pathways: includePathways,
      }, {
        page: {
          number: options.page,
          size: options.size || 50,
        },
        sort: options.sord,
      });

      const cases = response.data.data;
      await Promise.all([
        coreApi.populateRelationship('service', 'service', cases, {
          customQuery: (modelName, ids) => coreApi.query(modelName, {
              ids,
              include_pathways: includePathways,
            }).then((res) => ({ data: { data: _.get(res, 'data.data', []) } })),
        }),
        coreApi.populateRelationship('program', 'program', cases, {
          customQuery: (modelName, ids) => {
            const maxChunkSize = 50;

            if (ids.length <= maxChunkSize) {
              return coreApi.query(modelName, {
                ids,
                include_pathways: includePathways,
              }, { page: { number: 1, size: ids.length } });
            }

            const chunks = _.chunk(ids, maxChunkSize);
            const promises = chunks.map((chunk) => coreApi.query(modelName, {
              ids: chunk,
              include_pathways: includePathways,
            }, { page: { number: 1, size: chunk.length } }));

            return Promise.all(promises).then((responses) => ({
              data: {
                data: _.compact(responses.flatMap((res) => _.get(res, 'data.data', []))),
              },
            }));
          },
        }),
        coreApi.populateRelationship('primary_worker', 'employee', cases),
        coreApi.populateRelationship('outcome', 'outcome', cases),
        coreApi.populateRelationship('provider', 'provider', cases),
        coreApi.populateRelationship('person', 'person', cases),
      ]);

      dispatch({
        type: DASHBOARD_FETCH_CASES,
        payload: response,
        target: options.target,
        filters: options.filters,
      });

      dispatch(unsetDashboardFetching(response, options.target));

      return response;
    } catch (error) {
      Notifier.handleErrors(error);
      return error;
    }
  };
}

export default fetchDashboardCases;
