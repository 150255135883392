import { consentIsPending } from 'src/components/InformedConsent/utils';
import _ from 'lodash';

export function openable(type = {}) {
  return _.get(type, 'permissions.can_open.authorization', false);
}

export function viewable(type = {}) {
  return _.get(type, 'permissions.can_view.authorization', false);
}

export function editable(type = {}, caseType = '', currentProviderId = '', isCoordinationGroup = false) {
  return _.get(type, 'permissions.can_edit.authorization', false) &&
  (isCoordinationGroup || !['closedExternalCases', 'externalCases', 'allExternalCases'].includes(caseType)) &&
  (
    (currentProviderId ? _.isEqual(currentProviderId, _.get(type, 'provider.id', '')) : true) ||
    isCoordinationGroup
  );
}

export function closeable(type = {}) {
  return _.get(type, 'permissions.can_close.authorization', false);
}

export function generatePermissionMessage(referral) {
  if (consentIsPending(referral.contact)) {
    return 'You will gain access to this referral after consent is received from the client.';
  }
  return 'You do not have permission to access this referral.';
}
