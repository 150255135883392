import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { useUpdateRecord, useInvalidateQueries } from 'src/api/APIHooks';
import Notifier from 'common/helpers/Notifier';
import { every } from 'lodash';
import { ROLES } from 'src/pages/invoices/constants';

const useUpdateInvoice = (invoiceId, fields, message) => {
  const invalidateQueries = useInvalidateQueries();
  const { updateRecord } = useUpdateRecord('invoice', {
    mutationConfig: {
      onError: () => {
        Notifier.dispatch('error', 'Something went wrong...');
      },
    },
  });

  return async (id = invoiceId, params = fields, successMessage = message) => {
    const invoiceUpdated = await updateRecord(id, params);
    if (isHttpSuccess(invoiceUpdated.status)) {
      Notifier.dispatch(
        invoiceUpdated.status,
        successMessage,
      );
    }

    invalidateQueries('invoice');
  };
};

const useUpdateInvoices = (ids, params, successMessage) => {
  const invalidateQueries = useInvalidateQueries();
  const { updateRecord } = useUpdateRecord('invoice', {
    mutationConfig: {
      onError: () => {
        Notifier.dispatch('error', 'Something went wrong...');
      },
     },
  });

  return async () => {
    const invoicesUpdated = ids.map((id) => updateRecord(id, params));

    await Promise.all(invoicesUpdated);
    const httpSuccess = every(invoicesUpdated.map((invoice) => isHttpSuccess(invoice.status)));

    if (httpSuccess) {
       Notifier.dispatch(
        invoicesUpdated[0].status,
        successMessage,
      );
    } else {
      Notifier.handleErrors('There was an error. Some or all invoices were not properly updated');
    }
    invalidateQueries('invoice');
  };
};

export const useSubmitInvoice = (id, status = 'submitted_to_network_lead') => {
  const params = {
    invoice_status: status,
  };

  return useUpdateInvoice(id, params, 'Your invoice was submitted to the network lead.');
};

export const useSubmitInvoiceToPayer = (id, invoiceStatus, message) => {
  const params = {
     invoice_status: invoiceStatus,
  };

  return useUpdateInvoice(id, params, message);
};

export const useRejectInvoice = () => {
  const updateInvoice = useUpdateInvoice();
  const statusByRole = {
    [ROLES.networkLead]: 'rejected_by_network_lead',
    [ROLES.payerProvider]: 'rejected_by_payer',
    [ROLES.cboAdmin]: 'rejected_by_cbo_admin',
  };

  return async (role, id, fields) => {
    const params = {
      invoice_rejection_reason: fields.invoice_rejection_reason,
      ...(fields.rejection_note && { rejection_note: fields.rejection_note }),
      invoice_status: statusByRole[role],
    };

    await updateInvoice(id, params, 'Your invoice was rejected.');
  };
};

export const useDisputeByNetworkLead = (id, fields = {}, showInvoiceDisputeWorkflow = false) => {
  const newWorkflowDisputedInvoices = showInvoiceDisputeWorkflow ?
    { under_dispute: true } : { invoice_status: 'under_dispute' };
  const params = {
    invoice_dispute_reason: fields.invoice_dispute_reason,
    ...(fields.dispute_reason_note && { dispute_reason_note: fields.dispute_reason_note }),
    ...newWorkflowDisputedInvoices,
  };

  return useUpdateInvoice(id, params, 'Your invoice is in dispute.');
};

export const useDisputeResolveByNetworkLead = (id, fields = {}, showInvoiceDisputeWorkflow = false) => {
  const resolvedInvoice = showInvoiceDisputeWorkflow ?
    { under_dispute: false } : { };
  const params = {
    invoice_dispute_resolution_reason: fields.invoice_dispute_resolution_reason,
    ...(fields.dispute_resolution_reason_note &&
      { invoice_dispute_resolution_note: fields.dispute_resolution_reason_note }),
    ...resolvedInvoice,
  };

  return useUpdateInvoice(id, params, 'Invoice Resolved.');
};

export const usePayerUpdateInvoiceStatus = (id, invoiceStatus) => {
  const params = {
    invoice_status: invoiceStatus,
  };
  return useUpdateInvoice(id, params, 'Invoice status successfully updated');
};

export const usePayerFixResponse = (id, fields = {}, message) => {
  const params = {
    ...fields,
  };
  return useUpdateInvoice(id, params, message);
};

export const useBulkUpdateInvoiceStatus = (invoiceIds, invoiceStatus) => {
  const params = {
    invoice_status: invoiceStatus,
  };

  return useUpdateInvoices(invoiceIds, params, 'Invoice statuses successfully updated');
};
