import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import { Field, useFormState } from 'react-final-form';
import { validations } from '@unite-us/client-utils';
import {
  DateField,
  InputField,
  SelectField,
} from '@unite-us/ui';
import CurrencyField from 'common/form/CurrencyField';

const PaymentsTrackServiceFundsDistributed = ({
  distributionReasons,
  paymentMethods,
  providedServiceUnit,
  validateInsuranceCoverage,
}) => {
  const formState = useFormState();

  const startDate = formState.values.provided_service.service_start_date;
  const isSingleDateRequested = formState.values.provided_service.period_of_service === 'Single Date';
  const endDate = isSingleDateRequested ? startDate : formState.values.provided_service.service_end_date;
  useEffect(() => {
    validateInsuranceCoverage(startDate, endDate);
  }, [validateInsuranceCoverage, startDate, endDate]);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-8">
      <div className="lg:col-span-1 px-0">
        <Field name="provided_service.unit_amount" validate={(value) => validations.isRequired(value)}>
          {(fieldProps) => (
            <CurrencyField
              id="provided-service-unit-amount"
              label="Amount Distributed ($)"
              required
              {...fieldProps}
            />
          )}
        </Field>
      </div>
      <div className="lg:col-span-1 mb-4 lg:mb-0 px-0">
        <div className="ui-input-field ui-form-field">
          <label htmlFor="provided-service-unit" className="ui-form-field__label">Unit</label>
          <div className="pointer-events-none opacity-75 pt-1 leading-8" id="provided-service-unit">
            {capitalize(providedServiceUnit)}
          </div>
        </div>
      </div>
      <div className="lg:col-span-1 px-0">
        <Field name="provided_service.payment_method" validate={(value) => validations.isRequired(value)}>
          {(fieldProps) => (
            <SelectField
              id="payment-method-select"
              label="Distribution Method"
              options={paymentMethods}
              valueKey="value"
              labelKey="field"
              required
              {...fieldProps}
            />
          )}
        </Field>
      </div>
      <div className="lg:col-span-1 px-0">
        <Field name="provided_service.distribution_reason" validate={(value) => validations.isRequired(value)}>
          {(fieldProps) => (
            <SelectField
              id="distribution-reason-select"
              label="Reason for Distribution"
              options={distributionReasons}
              valueKey="value"
              labelKey="field"
              required
              {...fieldProps}
            />
          )}
        </Field>
      </div>
      <div className="lg:col-span-1 px-0">
        <Field name="provided_service.distribution_id">
          {(fieldProps) => (
            <InputField
              id="provided-service-distribution-id"
              label="Payment Distribution ID"
              type="text"
              {...fieldProps}
            />
          )}
        </Field>
      </div>
      <div className="lg:col-span-1 px-0">
        <Field name="provided_service.service_start_date" validate={(value) => validations.isRequired(value)}>
          {(fieldProps) => (
            <DateField
              id="provided-service-date"
              label="Date of Distribution"
              required
              {...fieldProps}
            />
          )}
        </Field>
      </div>
      <div className="lg:col-span-1 px-0">
        <Field name="provided_service.payee">
          {(fieldProps) => (
            <InputField
              id="provided-service-payee"
              label="Payee Name"
              type="text"
              {...fieldProps}
            />
          )}
        </Field>
      </div>
    </div>
  );
};

PaymentsTrackServiceFundsDistributed.propTypes = {
  distributionReasons: PropTypes.array.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  providedServiceUnit: PropTypes.string.isRequired,
  validateInsuranceCoverage: PropTypes.func.isRequired,
};

export default PaymentsTrackServiceFundsDistributed;
