import { formFieldOptions } from '@unite-us/client-utils';
import { OTHER_OUTCOME_DESCRIPTION } from 'src/common/utils/Outcomes/constants';

function sortReasonOptions(options = []) {
  return formFieldOptions.sortOptions({
    moveToEndValue: OTHER_OUTCOME_DESCRIPTION,
    options,
  });
}

export default sortReasonOptions;
