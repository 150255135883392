import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { browserHistory } from 'common/utils/browserHistory';
import { isHttpError } from 'common/utils/Error';
import { getEnumsFromState } from 'common/utils/Enums';
import findCurrentGroup from 'common/utils/findCurrentGroup';
import { getGroup } from 'common/utils/stateHelpers';
import { fetchGroup } from 'actions/Group';
import _ from 'lodash';
import { validateReduxForm } from 'common/form';
import { validations } from '@unite-us/client-utils';
import { OverlaySpinner, Spinner } from 'common/spinners';
import { Button } from '@unite-us/ui';
import callOrLog from 'src/common/utils/callOrLog';
import { NEW_CLIENT } from 'common/utils/EventTracker/utils/eventConstants';
import { formatNewContactDataWithNoInsurance } from 'common/display/ContactStepper/utils';
import { createUpdateDeletePaymentsInsurances } from 'src/components/Insurance/utils/';
import createInsuranceEntry from 'src/components/Insurance/actions/createInsuranceEntry';
import PaymentsInformation from 'src/components/Insurance/PaymentsInformation';
import '../stylesheets/addContactForm.scss';
import { retrieveSelectedContact } from '../actions';
import Header from './Header';
import createContact from '../actions/CreateContact';
import BasicInformation from './BasicInformation';
import SupplementalInformation from './SupplementalInformation';
import ContactInformation from './ContactInformation';
import MilitaryInformation from './MilitaryInformation';

const NOT_FOUND_HEADER_COPY = 'We did not find your client in our records.';
const REGULAR_HEADER_COPY = 'Please fill out your client\'s basic contact information below.';

class AddContactInformationFromScreening extends Component {
  static restartContactForm() {
    browserHistory.push('/screenings/new/search');
  }

  constructor(props) {
    super(props);

    this.onCreateContact = this.onCreateContact.bind(this);
    this.initiatePaymentsEventTracking = this.initiatePaymentsEventTracking.bind(this);
  }

  componentDidMount() {
    if (!this.props.completeGroup) {
      this.props.fetchGroup(this.props.groupId);
    }
  }

  onCreateContact(values) {
    const {
      contact,
      fields,
      groupId,
    } = this.props;

    return this.props.createContact({
      contact: formatNewContactDataWithNoInsurance({ fields, values }),
      groupId,
      showNotification: false,
    })
      .then((payload) => {
        if (!isHttpError(payload)) {
          const createdContact = _.get(payload, 'data.data', contact);
          const { id: contactId } = createdContact;
          callOrLog(() => this.context.eventTracker(NEW_CLIENT.createFromScreening, null, {
            contact: createdContact,
          }));

          createUpdateDeletePaymentsInsurances({
            values,
            contactId,
            groupId,
            createInsuranceEntry: this.props.createInsuranceEntry,
            paymentsEventTracking: this.initiatePaymentsEventTracking,
            showNotification: false,
          });

          this.props.retrieveSelectedContact(createdContact.id)
            .then(() => browserHistory.push('/screenings/new/screening'));
        }
      });
  }

  initiatePaymentsEventTracking(insurancesArr = []) {
    const updatedTypes = insurancesArr.reduce((insurances, currentInsurance) => {
      if (_.isEmpty(currentInsurance.plan_type)) {
        return insurances;
      }
      return [...insurances, currentInsurance.plan_type.toLowerCase()];
    }, []);

    const eventTracker = this.context.eventTracker;
    if (!_.isEmpty(updatedTypes)) {
      callOrLog(() => eventTracker(
        NEW_CLIENT.changedInsurance,
        { insurance_types: updatedTypes },
      ));
    }
  }

  render() {
    const {
      completeGroup,
      currentGroup,
      enums,
      fields,
      handleSubmit,
      header,
      location,
      mainHeader,
      registerField,
      submitting,
      untouch,
      values,
    } = this.props;
    const { query: { not_found } } = location;
    const subHeader = not_found ? `${NOT_FOUND_HEADER_COPY} ${REGULAR_HEADER_COPY}` : REGULAR_HEADER_COPY;

    return (
      <form
        onSubmit={handleSubmit(this.onCreateContact)}
        className="add-contact-information"
      >
        <OverlaySpinner text="Creating Client..." show={submitting} />
        <div className="row">
          <div className="referral-header col-md-12">
            <Header
              header={header}
              mainHeader={mainHeader}
              subHeader={subHeader}
            />
          </div>
        </div>

        <BasicInformation
          registerField={registerField}
          fields={fields}
          validations={validations}
          enums={enums}
        />

        <ContactInformation
          registerField={registerField}
          fields={fields}
          enums={enums}
          validations={validations}
        />
        {
          completeGroup ? (
            <SupplementalInformation
              completeGroup={completeGroup}
              currentGroup={currentGroup}
              enums={enums}
              fields={fields}
              registerField={registerField}
              untouch={untouch}
              values={values}
            />
          ) : <Spinner />
        }

        <div className="row">
          <div className="col-md-12">
            <footer className="referral-footer">
              <Button
                id="go-back-btn"
                onClick={AddContactInformationFromScreening.restartContactForm}
                label="Go Back"
                style={{ marginRight: '10px' }}
              />
              <Button
                id="save-client-btn"
                onClick={handleSubmit(this.onCreateContact)}
                label="Save Client"
                primary
                disabled={submitting}
              />
            </footer>
          </div>
        </div>
      </form>
    );
  }
}

AddContactInformationFromScreening.propTypes = {
  contact: PropTypes.object,
  createContact: PropTypes.func.isRequired,
  createInsuranceEntry: PropTypes.func.isRequired,
  currentGroup: PropTypes.object.isRequired,
  completeGroup: PropTypes.object,
  enums: PropTypes.object,
  fetchGroup: PropTypes.func.isRequired,
  fields: PropTypes.object,
  groupId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func,
  header: PropTypes.string.isRequired,
  location: PropTypes.object,
  mainHeader: PropTypes.string,
  registerField: PropTypes.func.isRequired,
  retrieveSelectedContact: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  untouch: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

AddContactInformationFromScreening.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {
    user,
    contacts,
    searchedContacts,
    selectedContact,
    session,
    session: { groupId },
  } = state;
  const allContacts = [...contacts.contacts, ...searchedContacts];
  const contact = allContacts.find((person) => person.id === selectedContact);

  const initialValues = {
    first_name: _.get(state.form, 'contact.first_name.value', ''),
    last_name: _.get(state.form, 'contact.last_name.value', ''),
    date_of_birth: _.get(state.form, 'contact.date_of_birth.value', ''),
    gender: 'undisclosed',
    marital_status: 'undisclosed',
    race: 'undisclosed',
    ethnicity: 'undisclosed',
    addresses: [undefined],
    insurance: [{}],
  };

  return {
    contact,
    completeGroup: getGroup(state, groupId),
    currentGroup: findCurrentGroup(user, session),
    enums: getEnumsFromState(state),
    groupId,
    initialValues,
    selectedContact,
  };
}

export default validateReduxForm(
  {
    form: 'contact',
    fields: [
      ...BasicInformation.fields,
      ...ContactInformation.fields,
      ...MilitaryInformation.fields,
      ...PaymentsInformation.fields,
    ],
    destroyOnUnmount: true,
  },
  mapStateToProps,
  {
    createContact,
    createInsuranceEntry,
    fetchGroup,
    retrieveSelectedContact,
  },
)(AddContactInformationFromScreening);
