import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import pluckSelectedFieldIds from 'src/common/form/utils/pluckSelectedFieldIds';
import ProcedureCodeRow from './ProcedureCodeRow';

const ProcedureCodeSelectField = (props) => {
  const { procedureCodesWithModifiers, fields, registerField } = props;

  const rows = fields.provided_service.procedure_code_rows;
  const selectedProcedureCodes = pluckSelectedFieldIds(rows, 'procedure_code');
  const procedureCodeRow = [rows[0]];

  const availableProcedureCodes =
    procedureCodesWithModifiers?.map((procedureCode) => (
      { ...procedureCode, disabled: selectedProcedureCodes.includes(procedureCode.id) }
    ));

  const addField = () => {
    rows.addField();
  };

  useEffect(() => {
    if (rows.length === 0) {
      addField();
    }
  }, [rows]);

  return (
    <>
      <div className="procedure-code-row-container grid grid-cols-5 gap-x-6 lg:gap-y-2 mb-3">
        { !isEmpty(rows) && (procedureCodeRow?.map((row, index) => (
          <ProcedureCodeRow
            row={row}
            index={index}
            options={availableProcedureCodes}
            fields={fields}
            registerField={registerField}
          />
        )))}
      </div>
    </>
  );
};

ProcedureCodeSelectField.propTypes = {
  procedureCodesWithModifiers: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  registerField: PropTypes.func.isRequired,
};

export default ProcedureCodeSelectField;
