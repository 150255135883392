import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { validations } from '@unite-us/client-utils';
import AddDeleteButtons from 'src/common/form/AddDeleteButtons/AddDeleteButtons';
import { EmailAddressField } from 'common/form/Email';
import { noop } from 'lodash';
import { CommunicationTypesCheckBoxes } from 'common/form';
import { anyCheckedValidation } from 'common/utils/Form';
import './stylesheets/emailFields.scss';

class EmailFields extends Component {
  constructor(props) {
    super(props);

    this.handleAddEmail = this.handleAddEmail.bind(this);
    this.handleRemoveEmail = this.handleRemoveEmail.bind(this);
  }

  handleAddEmail() {
    this.props.add();
  }

  handleRemoveEmail() {
    this.props.remove();
  }

  render() {
    const {
      fields,
      id,
      index,
      length,
      multi,
      registerField,
      onAddBlur,
    } = this.props;

    return (
      <div className="email-field">
        <div className="row">
          <div className="col-xs-9">
            <EmailAddressField
              field={fields.email_address}
              id={`email-${index}`}
              inline
              registerField={registerField}
              validations={[
                { func: validations.isEmail },
                {
                  func: anyCheckedValidation(fields.acceptable_communication_types),
                  message: 'Required',
                },
              ]}
            />
            <div className="communication_preferences">
              <div className="communication_preferences__label-spacer" />
              <CommunicationTypesCheckBoxes
                email
                hideOralTypes
                id={id}
                fields={fields.acceptable_communication_types}
              />
            </div>
          </div>
          {
            multi && index === 0 && (
              <div className="col-xs-3">
                <AddDeleteButtons
                  index={index}
                  displayText={'Add Email'}
                  length={length}
                  onAddClick={this.handleAddEmail}
                  onAddBlur={onAddBlur}
                />
              </div>
            )
          }
          {
            multi && index !== 0 && (
              <div className="col-xs-3">
                <AddDeleteButtons
                  index={index}
                  displayText={'Delete Email'}
                  length={length}
                  onRemoveClick={this.handleRemoveEmail}
                />
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

EmailFields.propTypes = {
  add: PropTypes.func,
  fields: PropTypes.shape({
    acceptable_communication_types: PropTypes.object.isRequired,
    email_address: PropTypes.object.isRequired,
  }).isRequired,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  multi: PropTypes.bool.isRequired,
  registerField: PropTypes.func.isRequired,
  remove: PropTypes.func,
  onAddBlur: PropTypes.func,
};

EmailFields.defaultProps = {
  add: noop,
  multi: true,
  remove: noop,
  onAddBlur: noop,
};

export default EmailFields;
