import moment from 'moment';

const constructFeeScheduleProgramFormPayload = (feeScheduleProgram, feeScheduleId) => {
  const data = feeScheduleProgram;
  const startsAtMoment = moment(data.starts_at, 'MM/DD/YYYY', true);
  const endsAtMoment = moment(data.ends_at, 'MM/DD/YYYY', true);
  data.unit_rate = data.unit_rate && parseInt(data.unit_rate, 10);
  data.billable = typeof data.billable === 'string' ? data.billable === 'true' : !!data.billable;
  data.authorization_required = typeof data.authorization_required === 'string' ?
    data.authorization_required === 'true' : !!data.authorization_required;
  data.auto_authorizes = typeof data.auto_authorizes === 'string' ?
   data.auto_authorizes === 'true' : !!data.auto_authorizes;
  data.can_invoice_above_remaining_authorized_amount =
    typeof data.can_invoice_above_remaining_authorized_amount === 'string' ?
     data.can_invoice_above_remaining_authorized_amount === 'true' :
      !!data.can_invoice_above_remaining_authorized_amount;
  data.starts_at = startsAtMoment.isValid() ? startsAtMoment.utc().startOf('day').toISOString() : data.starts_at;
  data.ends_at = endsAtMoment.isValid() ? endsAtMoment.utc().startOf('day').toISOString() : data.ends_at;
  data.fee_schedule = { id: feeScheduleId };
  if (data.authorization_required === false) {
    data.auto_authorizes = false;
    data.can_invoice_above_remaining_authorized_amount = false;
  }
  return data;
};

export default constructFeeScheduleProgramFormPayload;
