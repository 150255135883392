import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'common/utils/browserHistory';
import { coreApi } from 'api/config';
import FacesheetSubHeader from 'src/components/Facesheet/components/FacesheetSubHeader';
import { destroyForm } from 'actions/Form';
import { fetchGroupContact } from 'actions/Contact/Group';
import { fetchServiceCases } from 'actions/Case/Contact/Group';
import { getActiveFacesheetTab } from 'src/components/Facesheet/utils';
import { selectContact } from 'actions/Contact';
import { CREATE_REFERRAL_FORM } from 'src/components/Referrals/constants';
import { Spinner } from 'common/spinners';
import { wget } from 'lodash';
import findCurrentGroup from 'common/utils/findCurrentGroup';
import { getEmployeeNetworks } from 'src/components/Employee/employeeGetters';
import { fetchClientRelationship, updateClientRelationship } from 'actions/CareCoordinator/Contact/Group';
import fetchProvidersUserCore from 'src/actions/UserProvider/fetchProvidersUser';
import DuplicatesModal from 'src/components/Facesheet/components/DuplicatesModal';
import { uup459SupersetPhase2, cerb1367ResourceListDetailEnhancements as cerb1367ResourceListDetailEnhancementsSelector, hasClientLinkOnClientFacesheet, isClientMergingEnabled as isClientMergingEnabledFF } from 'common/utils/FeatureFlags/flags';
import { isOrgAdmin } from 'components/User/utils';
import getResourceListIdFromUrl from 'src/pages/facesheet/utils/getResourceListIdFromUrl';
// Style Imports
import './Facesheet.scss';

export const Facesheet = (props) => {
  const {
    contactId,
    groupId,
    activeTab,
    children,
    enums,
    locationSrc,
    isSupersetEnabled,
    hasClientLink,
    userIsAdmin,
    isClientMergingEnabled,
    cerb1367ResourceListDetailEnhancements,
  } = props;

  const [contact, setContact] = useState(null);
  const [isFetching, setIsFetching] = useState(true);
  const [clientRelationship, setClientRelationship] = useState({});
  const [hasDuplicates, setHasDuplicates] = useState(false);

  Facesheet.goToCreateReferral = (location) => {
    const queryString = location ? `?src=${location}` : '';

    const backToResource = cerb1367ResourceListDetailEnhancements ? getResourceListIdFromUrl() : null;

    let path;

    if (cerb1367ResourceListDetailEnhancements && backToResource) {
      path = `/referrals/create/add-resources?person=${contactId}&backToResource=${backToResource}`;
    } else if (isSupersetEnabled) {
      path = `/referrals/create/add-resources?person=${contactId}`;
    } else {
      path = `/referrals/new/add-service-types${queryString}`;
    }

    browserHistory.push(path);
  };

  const handleOnCheck = async (e) => {
    const relationship = await updateClientRelationship(clientRelationship, e.target.checked);
    setClientRelationship(relationship);
  };

  const onCreateReferral = () => {
    props.selectContact(contactId);
    props.destroyForm(CREATE_REFERRAL_FORM);
    window.localStorage.removeItem('referralsProgress');
    Facesheet.goToCreateReferral(locationSrc);
  };

  const getClientRelationship = async () => {
    const relationship = await fetchClientRelationship(contactId, groupId);
    setClientRelationship(relationship);
  };

  const fetchContact = (providerId, personId) => {
    props.fetchGroupContact(providerId, personId)
      .then((response) => {
        setIsFetching(false);
        setContact(response?.data?.data);
      })
      .catch(() => setIsFetching(false));
  };

  const fetchGroupContactSettingFetching = (providerId, personId) => {
    setIsFetching(true);
    fetchContact(providerId, personId);
  };

  const checkForDuplicates = async () => {
    const response = await coreApi.query(
      `people/${contact.id}/duplicates`,
      {},
      { page: { number: 1, size: 1 } },
    );

    setHasDuplicates(response.data.meta.page.total_count > 1);
  };

  useEffect(() => {
    fetchGroupContactSettingFetching(groupId, contactId);
    props.fetchServiceCases(groupId, contactId);
    getClientRelationship();
    props.fetchProvidersUserCore({
      providers: groupId,
    });
  }, []);

  useEffect(() => {
    if (contact && isClientMergingEnabled && userIsAdmin) {
      checkForDuplicates();
    }
  }, [contact, isClientMergingEnabled, userIsAdmin]);

  useEffect(() => {
    fetchGroupContactSettingFetching(groupId, contactId);
    getClientRelationship();
  }, [contactId]);

  return (
    <div className="facesheet-index" id="facesheet-container">
      <div className="row facesheet-index__subheader-container">
        <div className="col-sm-10 offset-sm-1">
          {contact && (
            <FacesheetSubHeader
              contact={contact}
              contactId={contactId}
              activeTab={activeTab}
              onButtonClick={onCreateReferral}
              enums={enums}
              isMilitaryFocused
              isFetching={isFetching}
              handleOnCheck={handleOnCheck}
              clientRelationship={clientRelationship}
              isSupersetEnabled={isSupersetEnabled}
              hasClientLink={hasClientLink}
              showDuplicatesLink={hasDuplicates}
            />
          )}
        </div>
      </div>
      <div className="facesheet-index__container row" id="facesheet-container">
        <div className="col-sm-10 offset-sm-1">
          {
            isFetching ? (
              <div className="facesheet-index__content-spinner" aria-live="polite">
                <Spinner />
              </div>
            ) :
              React.isValidElement(children) &&
              React.Children.map(
                children,
                (child) => React.cloneElement(child, {
                  getClientRelationship,
                  setHeaderContact: setContact,
                }),
              )
          }
        </div>
        {hasDuplicates && <DuplicatesModal contactId={contact.id} />}
      </div>
    </div>
  );
};

Facesheet.propTypes = {
  activeTab: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  contactId: PropTypes.string.isRequired,
  destroyForm: PropTypes.func.isRequired,
  fetchGroupContact: PropTypes.func.isRequired,
  fetchProvidersUserCore: PropTypes.func.isRequired,
  fetchServiceCases: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  locationSrc: PropTypes.string,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  selectContact: PropTypes.func.isRequired,
  isSupersetEnabled: PropTypes.bool,
  hasClientLink: PropTypes.bool,
  enums: PropTypes.object.isRequired,
  userIsAdmin: PropTypes.bool.isRequired,
  isClientMergingEnabled: PropTypes.bool.isRequired,
  cerb1367ResourceListDetailEnhancements: PropTypes.bool.isRequired,
};

Facesheet.defaultProps = {
  locationSrc: undefined,
  isSupersetEnabled: false,
  hasClientLink: false,
};

function mapStateToProps(state, ownProps) {
  const { session } = state;
  const { location, params } = ownProps;
  const groupId = wget(session, 'groupId', '');
  const enums = wget(session, 'enums');
  const group = findCurrentGroup(state.user, state.session);
  const networks = getEmployeeNetworks({ state });
  const locationSrc = undefined;

  return {
    groupId,
    contactId: params.id,
    activeTab: getActiveFacesheetTab(location.pathname),
    enums,
    group,
    networks,
    locationSrc,
    isSupersetEnabled: uup459SupersetPhase2(state),
    hasClientLink: hasClientLinkOnClientFacesheet(state),
    isClientMergingEnabled: isClientMergingEnabledFF(state),
    userIsAdmin: isOrgAdmin(state.user, session.groupId),
    cerb1367ResourceListDetailEnhancements: cerb1367ResourceListDetailEnhancementsSelector(state),
  };
}

export default connect(mapStateToProps, {
  destroyForm,
  fetchGroupContact,
  fetchProvidersUserCore,
  fetchServiceCases,
  selectContact,
})(Facesheet);
