import moment from 'moment';

const constructPlanFormPayload = (plan, payerId) => {
  const data = { ...plan };
  data.enrollment_required = !!data.enrollment_required;
  data.starts_at = moment(data.starts_at, 'MM/DD/YYYY').toISOString();
  data.ends_at = moment(data.ends_at, 'MM/DD/YYYY').toISOString();
  data.external_id = data.external_id ?? null;
  data.region = data.region ?? null;
  data.payer = { id: payerId };
  data.coverage_period = data.coverage_period ?
    parseInt(data.coverage_period, 10) :
    null;
  if (data.enrollment_required && data.plan_type === 'social') {
    data.coverage_period = null;
  }
  if (data.networks && data.networks.length) {
    if (data.plan_type === 'social') {
      data.networks = data.networks.map((network) => ({ id: network.id }));
    } else {
      delete data.networks;
    }
  }
  return data;
};

export default constructPlanFormPayload;
