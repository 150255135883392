// Library Imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { dates } from '@unite-us/client-utils';
import { Spinner } from 'common/spinners';
import { saveAs } from 'file-saver';
import {
  Icon,
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from '@unite-us/ui';

// Util Imports
import { fetchDownloadFile } from 'src/actions/Download/Insights/Api/';

// Stylesheet Imports
import 'common/stylesheets/tableView.scss';
import './InsightsTable.scss';
import { filterMimeType } from '../utils';

function findInsightValue(insight, key) {
  return _.get(insight, key, '');
}

class InsightsTable extends Component {
  static initiateDownload(data, fileName) {
    const fileExt = _.last(_.split(fileName, '.'));
    const blob = new Blob([data], { type: filterMimeType(fileExt) });
    saveAs(blob, fileName);
  }

  constructor(props) {
    super(props);

    this.onDownloadClick = this.onDownloadClick.bind(this);
  }

  onDownloadClick(insight) {
    const { file_name } = insight;

    const params = {
      id: insight.user_id,
      file_name,
    };

    fetchDownloadFile(params)
      .then((response) => {
        const { data } = response;
        InsightsTable.initiateDownload(data, file_name);
      }).catch(({ message }) => {
        this.props.notify({
          status: 'error',
          message,
        });
      });
  }

  render() {
    const {
      insights, loading, styles, refetch,
    } = this.props;

    if (loading && !refetch) {
      return (
        <Spinner
          className="insights-table insights-table--loading"
          text="Loading Insights..."
          textStyle={{ marginTop: '20px' }}
        />
      );
    }

    return (
      <Table
        className="insights-table"
        noBorder
        secondaryDataList={[1]}
      >
        <TableHeader>
          <TableRow>
            <TableHeaderColumn>File</TableHeaderColumn>
            <TableHeaderColumn>Date Created</TableHeaderColumn>
            <TableHeaderColumn style={styles.downloadWidth}>Download</TableHeaderColumn>
          </TableRow>
        </TableHeader>

        <TableBody
          displayRowCheckbox={false}
          showRowHover
        >
          {
            insights.map((insight, index) => (
              <TableRow
                className="insights-table__row"
                key={`insight-row-${index}`}
                selectable={false}
              >
                <TableRowColumn>
                  {findInsightValue(insight, 'file_name')}
                </TableRowColumn>
                <TableRowColumn>
                  {dates.formatDate(findInsightValue(insight, 'created_at'))}
                </TableRowColumn>
                <TableRowColumn style={styles.downloadWidth}>
                  <Icon
                    onClick={() => this.onDownloadClick(insight)}
                    icon="IconDownload"
                    size={22}
                  />
                </TableRowColumn>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    );
  }
}

InsightsTable.propTypes = {
  insights: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  notify: PropTypes.func.isRequired,
  styles: PropTypes.object.isRequired,
  refetch: PropTypes.bool,
};

InsightsTable.defaultProps = {
  refetch: false,
  styles: {
    downloadWidth: {
      width: '75px',
      textAlign: 'center',
    },
  },
};

InsightsTable.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

export default InsightsTable;
