import { orderBy, toLower } from 'lodash';
var byKey = function byKey() {
  var option = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var sortKey = arguments.length > 1 ? arguments[1] : undefined;
  return toLower(option[sortKey]);
};
var sortByKey = function sortByKey(_ref) {
  var _ref$options = _ref.options,
    options = _ref$options === void 0 ? [] : _ref$options,
    _ref$sortKey = _ref.sortKey,
    sortKey = _ref$sortKey === void 0 ? 'label' : _ref$sortKey,
    _ref$sortDirection = _ref.sortDirection,
    sortDirection = _ref$sortDirection === void 0 ? 'asc' : _ref$sortDirection;
  return orderBy(options, function (option) {
    return byKey(option, sortKey);
  }, sortDirection);
};
export default sortByKey;