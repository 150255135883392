import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import { validateReduxForm } from 'common/form';
import { validations } from '@unite-us/client-utils';
import {
  Button,
  TextField,
} from '@unite-us/ui';
import { holdReferral as holdReferralAction, changeHoldReason as changeHoldReasonAction } from 'actions/Referral/Group';
import { setDashboardRefetch as setDashboardRefetchAction } from 'actions/Dashboard';
import { OverlaySpinner } from 'common/spinners';
import { goToReferralsIndex } from 'src/components/Referrals/utils/routing';
import { ReferralReasonField } from 'src/components/Referrals/ReferralFormFields';
import {
  createReasonOptions,
} from 'src/components/Referrals/ReferralFormFields/ReferralReasonField/utils';
import {
  HOLD_REFERRAL_FORM,
  REFERRAL_STATUS_IN_REVIEW,
} from 'src/components/Referrals/constants';
import callOrLog from 'src/common/utils/callOrLog';
import { REFERRAL } from 'common/utils/EventTracker/utils/eventConstants';
import NoteDisclaimer from 'common/Disclaimer/NoteDisclaimer';
import mergeProps from '../utils/mergeProps';

export class HoldModalForm extends Component {
  static navigateToIndex() {
    goToReferralsIndex(REFERRAL_STATUS_IN_REVIEW);
  }

  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  onSubmit(values) {
    const {
 referral, groupId, changeHoldReason: changeHoldReasonProp, holdReferral, setDashboardRefetch,
} = this.props;
    const { eventTracker } = this.context;
    const { heldForReview } = REFERRAL;

    const action = referral.state === 'in_review' ? changeHoldReasonProp : holdReferral;

    return action(groupId, referral.id, values, true)
      .then((response) => {
        const referralData = _.get(response, 'data.data', {});
        callOrLog(() => eventTracker(heldForReview, {
          reason_shortened: values.reason,
        }, { referral: referralData }));

        setDashboardRefetch();
        // Return this function to the validateReduxForm parameters below
        return HoldModalForm.navigateToIndex;
      });
  }

  closeDialog() {
    this.props.resetForm();
    this.props.closeDialog();
  }

  render() {
    const {
      fields: { note, reason },
      handleSubmit,
      holdOptions,
      registerField,
      submitting,
    } = this.props;

    return (
      <form className="hold-modal-form content-with-actions" onSubmit={handleSubmit(this.onSubmit)}>
        <div className="content-container">
          <OverlaySpinner show={submitting} text="Holding for Review..." />
          <ReferralReasonField
            className="hold-referral-reason-select"
            field={reason}
            id="reasonInput"
            options={createReasonOptions(holdOptions)}
            registerField={registerField}
          />
          <TextField
            field={note}
            id="noteInput"
            label="Note"
            afterLabelContent={<NoteDisclaimer />}
            ref={registerField}
            validations={validations.isRequired}
            inline={false}
            required
          />
        </div>

        <div className="actions">
          <span className="action-item">
            <Button
              id="hold-referral-cancel-btn"
              onClick={this.closeDialog}
              disabled={submitting}
              label="Cancel"
            />
          </span>
          <span className="action-item">
            <Button
              id="hold-referral-hold-btn"
              onClick={handleSubmit(this.onSubmit)}
              disabled={submitting}
              label="Hold"
              primary
            />
          </span>
        </div>
      </form>
    );
  }
}

HoldModalForm.propTypes = {
  referral: PropTypes.shape({
    id: PropTypes.string.isRequired,
    state: PropTypes.string,
  }).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  groupId: PropTypes.string.isRequired,
  holdReferral: PropTypes.func.isRequired,
  changeHoldReason: PropTypes.func.isRequired,
  registerField: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  setDashboardRefetch: PropTypes.func.isRequired,
  holdOptions: PropTypes.array.isRequired,
};

HoldModalForm.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isCoordinationGroup: state.session.isCoordinationGroup,
    groupId: state.session.groupId,
    programs: state.groupsPrograms.data,
    employees: state.groupsUsers.data,
    holdOptions: _.wget(state, 'session.enums.referrals.hold', []),
  };
}

const fields = [
  'reason',
  'note',
];
export default validateReduxForm(
  {
    form: HOLD_REFERRAL_FORM,
    fields,
    onSubmitSuccess: (response) => response(),
  },
  mapStateToProps,
  {
 holdReferral: holdReferralAction,
    changeHoldReason: changeHoldReasonAction,
    setDashboardRefetch: setDashboardRefetchAction,
  },
  mergeProps,
)(HoldModalForm);
