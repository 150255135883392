import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Form, Field } from 'react-final-form';
import {
  Button,
  DateField,
  SelectField,
} from '@unite-us/ui';
import moment from 'moment';
import { browserHistory } from 'common/utils/browserHistory';
import { InputField, RadioField } from 'components/Backoffice/form';
import { validations } from '@unite-us/client-utils';
import composeValidators from 'src/components/Organization/utils/composeValidators';
import { BackButton } from 'common/buttons';
import { Spinner } from 'common/spinners';
import NetworkSelector from './components/NetworkSelector';
import { PLAN_TYPES, ENROLLMENT_OPTIONS } from './constants';

const getMinDate = (pickedStartDate, startsAt) => {
  const pickedStartMoment = moment(pickedStartDate, 'MM/DD/YYYY');
  if (pickedStartMoment.isAfter(moment(startsAt))) {
    return pickedStartMoment.add('12', 'h').format('MM/DD/YYYY');
  }
  return moment(startsAt).add('12', 'h').format('MM/DD/YYYY');
};

const getMaxDate = (pickedEndDate, endsAt) => {
  const pickedEndMoment = moment(pickedEndDate, 'MM/DD/YYYY');
  if (pickedEndMoment.isBefore(moment(endsAt))) {
    return pickedEndMoment.subtract('12', 'h').format('MM/DD/YYYY');
  }
  return moment(endsAt).subtract('12', 'h').format('MM/DD/YYYY');
};

const PlanForm = ({
  initialValues,
  payerId,
  planId,
  showPending,
  onSubmit,
  feeScheduleId,
}) => {
  const isEditing = !isEmpty(planId);
  const startsAt = initialValues.starts_at ?? moment().toISOString();
  const endsAt = initialValues.ends_at ?? moment().add('7', 'days').toISOString();
  const [enrollmentRequired, setEnrollmentRequired] = useState(initialValues.enrollment_required === 'true');
  const [planType, setPlanType] = useState(initialValues.plan_type);
  const shouldRequireNetwork = !!(initialValues.networks && initialValues.networks.length);
  const onCancel = () => {
    const basePath = `/backoffice/payers/${payerId}`;
    const planPath = feeScheduleId ?
      `/backoffice/fee_schedules/${feeScheduleId}/plans/${planId}` :
      `${basePath}/plans/${planId}`;
    browserHistory.push({
      pathname: isEditing ? planPath : basePath,
    });
  };

  const title = isEditing ? 'Edit Plan' : 'Add New Plan';

  return (
    <div id="plan_form" className="bg-dark-fill-blue">
      <div className="flex p-4 justify-start items-center bg-white">
        <div>
          <BackButton navigateTo={onCancel} />
        </div>
        <h1 className="pl-6 w-9/12 font-bold">{title}</h1>
      </div>
      {showPending && <Spinner />}
      {!showPending && (
      <div className="p-6">
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={({ handleSubmit, values, form }) => (
            <form
              onSubmit={handleSubmit}
              className={
                'grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-12 ' +
                'pl-10 pt-6 bg-medium-fill-grey border border-solid rounded overflow-hidden border-dark-border-blue'
              }
            >
              <Field
                name="name"
                validate={composeValidators(validations.isRequired)}
              >
                {(props) => (
                  <InputField
                    className="md:col-span-6 xl:col-span-6 px-0"
                    id="plan__name"
                    label="Plan Name"
                    required
                    {...props}
                  />
                )}
              </Field>
              <div className="md:col-span-6 xl:col-span-6 px-0 flex" />
              <Field
                name="plan_type"
                validate={composeValidators(validations.isRequired)}
              >
                {(props) => (
                  <SelectField
                    className="md:col-span-6 xl:col-span-6 px-0"
                    id="plan__plan_type"
                    label="Plan Type"
                    value={values?.plan_type}
                    options={PLAN_TYPES}
                    onChange={(value) => { setPlanType(value); }}
                    required
                    {...props}
                  />
                )}
              </Field>
              <div className="md:col-span-6 xl:col-span-6 px-0 flex" />
              <Field
                name="external_id"
              >
                {(props) => (
                  <InputField
                    className="md:col-span-6 xl:col-span-6 px-0"
                    id="plan__external_id"
                    label="External ID"
                    {...props}
                  />
                )}
              </Field>
              <div className="md:col-span-6 xl:col-span-6 px-0 flex" />
              {
                values?.plan_type === 'social' &&
                (
                  <>
                    <Field
                      name="networks"
                      validate={(val) => {
                        if (shouldRequireNetwork) {
                          return validations.isRequired(val, 'Should maintain at least one network relationship');
                        }
                        return null;
                      }}
                    >
                      {(props) => (
                        <NetworkSelector
                          className="md:col-span-6 xl:col-span-6 px-0"
                          required={shouldRequireNetwork}
                          changeValue={form.change}
                          {...props}
                        />
                        )}
                    </Field>
                    <div className="md:col-span-6 xl:col-span-6 px-0 flex" />
                  </>
                )
              }
              <Field
                name="region"
              >
                {(props) => (
                  <InputField
                    className="md:col-span-6 xl:col-span-6 px-0"
                    id="plan__region"
                    label="Region"
                    {...props}
                  />
                )}
              </Field>
              <div className="md:col-span-6 xl:col-span-6 px-0 flex" />
              <div className="md:col-span-6 xl:col-span-6 px-0 flex">
                <Field
                  name="starts_at"
                  validate={(value) => {
                    if (value && values.ends_at) {
                      if (moment(value).isSameOrAfter(values.ends_at)) {
                        return 'Start date should be before end date';
                      }
                      if (moment(value).isBefore(startsAt)) {
                        return `Earliest start date is
                        ${moment(startsAt).add('12', 'h').format('MM/DD/YYYY')}`;
                      }
                    }
                    return '';
                  }}
                >
                  {(props) => (
                    <DateField
                      className="md:col-span-4 xl:col-span-4 pl-0 pr-4"
                      id="plan__starts-at-field"
                      label="Start Date"
                      minDate={moment(startsAt).subtract('12', 'h').format('MM/DD/YYYY')}
                      maxDate={getMaxDate(values.ends_at, endsAt)}
                      valueFormat="MM/DD/YYYY"
                      {...props}
                    />
                  )}
                </Field>
                <Field
                  name="ends_at"
                  validate={(value) => {
                    if (value && values.starts_at) {
                      if (moment(value).isSameOrBefore(values.starts_at)) {
                        return 'End date should be after start date';
                      }
                    }
                    return '';
                  }}
                >
                  {(props) => (
                    <DateField
                      className="md:col-span-4 xl:col-span-4 pl-4 pr-0"
                      id="plan_ends-at-field"
                      label="End Date"
                      minDate={getMinDate(values.starts_at, startsAt)}
                      valueFormat="MM/DD/YYYY"
                      {...props}
                    />
                  )}
                </Field>
              </div>
              <div className="md:col-span-6 xl:col-span-6 px-0 flex" />
              <Field
                name="enrollment_required"
              >
                {(props) => (
                  <RadioField
                    className="flex-wrap md:col-span-4 xl:col-span-4 px-0"
                    id="plan__enrollment_required"
                    label="Enrollment Required"
                    onChange={(value) => { setEnrollmentRequired(value === 'true'); }}
                    options={ENROLLMENT_OPTIONS}
                    dataTestId="plan-enrollment-radio-group"
                    {...props}
                  />
                )}
              </Field>
              {(!enrollmentRequired || planType !== 'social') && (
                <>
                  <div className="md:col-span-6 xl:col-span-6 px-0 flex" />
                  <Field
                    name="coverage_period"
                  >
                    {(props) => (
                      <InputField
                        className="md:col-span-4 xl:col-span-4 pl-0 pr-4"
                        disabled={enrollmentRequired && planType === 'social'}
                        id="plan__coverage_period"
                        label="Coverage Period"
                        type="number"
                        {...props}
                      />
                    )}
                  </Field>
                </>
              )}
              <div className="md:col-span-8 xl:col-span-8 px-0 flex" />
              <div className="flex items-start pb-9">
                <Button
                  label="Cancel"
                  onClick={onCancel}
                  data-testid="cancel-submit-new-plan-btn"
                />
                <Button
                  className="mr-5 ml-3"
                  type="submit"
                  label={isEditing ? 'Update' : 'Create'}
                  primary
                  data-testid="submit-new-plan-btn"
                />
              </div>
            </form>
          )}
        />
      </div>
      )}
    </div>
  );
};

PlanForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  showPending: PropTypes.bool,
  payerId: PropTypes.string.isRequired,
  planId: PropTypes.string,
  feeScheduleId: PropTypes.string,
};

PlanForm.defaultProps = {
  initialValues: {},
  showPending: false,
  planId: '',
  feeScheduleId: '',
};

export default PlanForm;
