import React, { useRef } from 'react';
import { PropTypes } from 'prop-types';
import { TextField, SelectField } from '@unite-us/ui';
import { SecondaryButton } from 'common/TailwindComponents';
import { validations } from '@unite-us/client-utils';
import { Form, Field } from 'react-final-form';
import DialogV2 from 'common/modal/DialogV2';
import NoteDisclaimer from 'common/Disclaimer/NoteDisclaimer';
import { useFind } from 'src/api/APIHooks';
import { Spinner } from 'common/spinners';
import { ENROLLMENT_STATUSES, REQUEST_TYPES } from '../constants';
import { useRejectEnrollmentRequest } from '../hooks';

const RejectEnrollmentRequestButton = ({ enrollmentRequest, setDrawerOpen }) => {
  const modalRef = useRef();
  const rejectRequest = useRejectEnrollmentRequest();

  const { data, isLoading } = useFind(
    'enrollment_rejection_reasons',
    { plan: enrollmentRequest.plan.id },
    { queryConfig: { placeholderData: undefined } },
  );
  const rejectionReasons = data?.data?.data ?? [];
  const isDisenroll = enrollmentRequest.request_type === REQUEST_TYPES.disenroll;
  const openDialog = () => modalRef.current.openDialog();

  const rejectionOptions = rejectionReasons.filter((reason) => (
    reason.request_type === enrollmentRequest.request_type
  ));

  const closeDialog = ({ reset }) => {
    if (modalRef.current) {
      reset();
      modalRef.current.closeDialog();
    }
  };

  const onRejectClick = async ({ rejection_reason, rejection_note }, form) => {
    await rejectRequest(enrollmentRequest, rejection_reason, rejection_note);
    setDrawerOpen(false);
    closeDialog(form);
  };

  if (isLoading) {
    return <Spinner scale={0.5} />;
  }
  return (
    <>
      <SecondaryButton
        label="Reject"
        ariaLabel="reject enrollment request"
        onClick={openDialog}
        icon="IconCrossCircle"
        disabled={enrollmentRequest.enrollment_status !== ENROLLMENT_STATUSES.open}
      />
      <Form
        onSubmit={onRejectClick}
        render={({ handleSubmit, form }) => (
          <DialogV2
            cancelHandler={() => { closeDialog(form); }}
            confirmLabel={`Reject ${isDisenroll ? 'Disenrollment' : 'Enrollment'} Request`}
            confirmationHandler={handleSubmit}
            ref={modalRef}
            scrollable={false}
            title={`Reject ${isDisenroll ? 'Disenrollment' : 'Enrollment'} Request #${enrollmentRequest.short_id}`}
            width="3xl"
          >
            <div className="text-sm font-light">
              To continue with rejecting this
              {isDisenroll ? ' disenrollment ' : ' enrollment '}
              request, provide more information on why.
            </div>

            <Field
              name="rejection_reason"
              validate={(value) => validations.isRequired(value)}
            >
              {(params) => (
                <SelectField
                  className="pt-4 max-w-sm text-brand-blue relative break-normal"
                  label="Rejection Reason"
                  id="rejection_reason"
                  options={rejectionOptions.map((reason) => ({ label: reason.display_name, value: reason.id }))}
                  placeholder="Choose Code"
                  required
                  truncateOptions={false}
                  {...params}
                />
              )}
            </Field>

            <Field name="rejection_note">
              {(params) => (
                <TextField
                  id="rejection_note"
                  afterLabelContent={<NoteDisclaimer />}
                  placeholder="Additional details..."
                  required={false}
                  label="Note"
                  style={{ error: { height: 0 } }}
                  {...params}
                />
            )}
            </Field>
          </DialogV2>
        )}
      />
    </>
  );
};

RejectEnrollmentRequestButton.propTypes = {
  enrollmentRequest: PropTypes.object.isRequired,
  setDrawerOpen: PropTypes.func,
};

RejectEnrollmentRequestButton.defaultProps = {
  setDrawerOpen: () => {},
};

export default RejectEnrollmentRequestButton;
