import { useFind } from 'src/api/APIHooks';
import { get, groupBy, map, isEmpty } from 'lodash';

const useInvoiceShortIdOptions = (
  providerId,
  payerId,
  isNetworkLead,
  shortIdLike,
  statusFilters,
  statusOptionsConstant,
) => {
  let filterOptions = {
      short_id_like: shortIdLike,
      ...(payerId && { payer: payerId }),
      invoice_status: statusFilters.invoice_status,
      under_dispute: statusFilters.under_dispute,
      ...(!payerId & isNetworkLead && { network_lead: providerId }),
      ...(!payerId & !isNetworkLead && { provider: providerId }),
  };

  if (statusOptionsConstant) {
    filterOptions = {
      ...filterOptions,
      has_user_archive: Boolean(statusFilters.has_user_archive),
    };
  }

  const { isFetching, data } = useFind(
    'invoices',
    filterOptions,
    {
      queryConfig: { enabled: (!isEmpty(shortIdLike)) },
    },
  );

  const invoices = groupBy(get(data, 'data.data', []), 'short_id');
  const invoiceShortIdOptions = map(invoices, (val, key) => ({
    label: key,
    value: map(val, 'short_id').join(','),
  }));

  return isFetching ? [] : invoiceShortIdOptions.slice(0, 30);
};

export default useInvoiceShortIdOptions;
