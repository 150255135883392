function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import _ from 'lodash';
function returnAssistanceRequestPayload(assistanceRequest) {
  if (assistanceRequest) {
    return {
      assistance_request_id: _.get(assistanceRequest, 'id'),
      service_type: _.get(assistanceRequest, 'service_type.name'),
      status: _.get(assistanceRequest, 'status')
    };
  }
  return null;
}
function returnPagePayload(_ref) {
  var nextPage = _ref.nextPage,
    prevPage = _ref.prevPage;
  if (nextPage) {
    var toPage = nextPage + 1;
    return {
      to_page: toPage,
      from_page: nextPage
    };
  }
  if (prevPage) {
    var _toPage = prevPage - 1;
    return {
      to_page: _toPage,
      from_page: prevPage
    };
  }
  return null;
}
function returnServiceCasePayload(serviceCase) {
  if (serviceCase) {
    return {
      case_id: _.get(serviceCase, 'id'),
      contact_id: _.get(serviceCase, 'contact.id'),
      service_type: _.get(serviceCase, 'service_type.name'),
      out_of_network: _.get(serviceCase, 'program.out_of_network')
    };
  }
  return null;
}
function returnReferralPayload(referral) {
  if (referral) {
    return {
      referral_id: _.get(referral, 'id'),
      contact_id: _.get(referral, 'contact.id'),
      referred_to_group_id: _.get(referral, 'referred_to_group.id'),
      referred_by_group_id: _.get(referral, 'referred_by_group.id')
    };
  }
  return null;
}
function returnContactPayload(contact) {
  if (contact) {
    return {
      contact_id: _.get(contact, 'id')
    };
  }
  return null;
}
function returnAssessmentPayload(assessment) {
  if (assessment) {
    return {
      assessment_id: _.get(assessment, 'id')
    };
  }
  return null;
}
function returnIntakePayload(intake) {
  if (intake) {
    return {
      intake_id: _.get(intake, 'id')
    };
  }
  return null;
}
function returnSourcePayload(source) {
  return source ? {
    source: source
  } : null;
}
export default function filterPayload(_ref2) {
  var source = _ref2.source,
    payload = _ref2.payload,
    defaultData = _ref2.defaultData;
  var assistanceRequest = defaultData.assistanceRequest,
    nextPage = defaultData.nextPage,
    prevPage = defaultData.prevPage,
    serviceCase = defaultData.serviceCase,
    referral = defaultData.referral,
    contact = defaultData.contact,
    assessment = defaultData.assessment,
    intake = defaultData.intake;
  var sourcePayload = returnSourcePayload(source);
  var assistanceRequestPayload = returnAssistanceRequestPayload(assistanceRequest);
  var pagePayload = returnPagePayload({
    nextPage: nextPage,
    prevPage: prevPage
  });
  var serviceCasePayload = returnServiceCasePayload(serviceCase);
  var referralPayload = returnReferralPayload(referral);
  var contactPayload = returnContactPayload(contact);
  var assessmentPayload = returnAssessmentPayload(assessment);
  var intakePayload = returnIntakePayload(intake);
  return _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, payload), sourcePayload), assistanceRequestPayload), pagePayload), serviceCasePayload), referralPayload), contactPayload), assessmentPayload), intakePayload);
}