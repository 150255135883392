import moment from 'moment';
import _ from 'lodash';
function formatLocalDateTime(date) {
  var dateFormat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'M/D/YYYY';
  var timeFormat = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'h:mm a';
  var at = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'at';
  var dateMoment;

  // use new Date(date) for moment deprecation warning
  // https://github.com/moment/moment/issues/1407

  var dateObj = new Date(date);
  var isValidDate = moment(dateObj).isValid();
  if (!isValidDate || _.isNil(date)) {
    return '';
  }
  if (_.isNumber(date)) {
    dateMoment = moment.unix(dateObj);
  }
  if (_.isString(date)) {
    dateMoment = moment(dateObj);
  }
  return dateMoment.format("".concat(dateFormat, " [").concat(at, "] ").concat(timeFormat));
}
export default formatLocalDateTime;