// TODO: Potentially separate into individual exports if modal leaves client-utils
var AEM_CONSTANTS = {
  ACTION_TYPES: {
    REFERRAL: 'referral',
    SHARE: 'share',
    FIND_MORE: 'findMoreResources',
    SAVE: 'save'
  },
  TITLE_TEXT: {},
  PRE_LIST_TEXT: {},
  POST_LIST_TEXT: {},
  CONTINUE_BUTTON_TEXT: {},
  STAY_BUTTON_TEXT: {}
};
var ACTION_TYPES = AEM_CONSTANTS.ACTION_TYPES,
  TITLE_TEXT = AEM_CONSTANTS.TITLE_TEXT,
  PRE_LIST_TEXT = AEM_CONSTANTS.PRE_LIST_TEXT,
  POST_LIST_TEXT = AEM_CONSTANTS.POST_LIST_TEXT,
  CONTINUE_BUTTON_TEXT = AEM_CONSTANTS.CONTINUE_BUTTON_TEXT,
  STAY_BUTTON_TEXT = AEM_CONSTANTS.STAY_BUTTON_TEXT;

// #region Title text
TITLE_TEXT[ACTION_TYPES.REFERRAL] = {
  SOME_INVALID: 'Some programs cannot be referred',
  ALL_INVALID: 'Programs cannot be referred'
};
TITLE_TEXT[ACTION_TYPES.SHARE] = {
  SOME_INVALID: 'Some programs cannot be shared',
  ALL_INVALID: 'Programs cannot be shared'
};
TITLE_TEXT[ACTION_TYPES.SAVE] = {
  SOME_INVALID: 'Some programs cannot be saved',
  ALL_INVALID: 'Programs cannot be saved'
};
TITLE_TEXT[ACTION_TYPES.FIND_MORE] = {
  SOME_INVALID: "Some programs can't be shared or referred",
  ALL_INVALID: "Programs can't be shared or referred"
};
// #endregion

// #region Pre-list text
PRE_LIST_TEXT[ACTION_TYPES.REFERRAL] = {
  SOME_INVALID: "The following programs cannot be referred to and won't appear on the next page.",
  ALL_INVALID: "None of the resources can be referred to and won't appear on the next page."
};
PRE_LIST_TEXT[ACTION_TYPES.SHARE] = {
  SOME_INVALID: 'The following programs cannot be shared with the client:',
  ALL_INVALID: 'None of the resources can be shared at this time.'
};
PRE_LIST_TEXT[ACTION_TYPES.SAVE] = {
  SOME_INVALID: 'The following programs cannot be saved to the client’s profile:',
  ALL_INVALID: 'None of the resources can be saved at this time.'
};
PRE_LIST_TEXT[ACTION_TYPES.FIND_MORE] = {
  SOME_INVALID: "The following programs can't be shared or referred to and won’t appear on the next page.",
  ALL_INVALID: "None of the programs can be shared or referred to and won't appear on the next page."
};
// #endregion

// #region Post-list text
POST_LIST_TEXT[ACTION_TYPES.REFERRAL] = {
  SOME_INVALID: 'Do you want to continue creating referrals for the remaining programs?',
  ALL_INVALID: 'We recommend adding new resources to refer your client to.'
};
POST_LIST_TEXT[ACTION_TYPES.SHARE] = {
  SOME_INVALID: 'Do you want to share the remaining programs?',
  ALL_INVALID: 'We recommend adding new resources to share with your client.'
};
POST_LIST_TEXT[ACTION_TYPES.SAVE] = {
  SOME_INVALID: 'Do you want to save the remaining programs?',
  ALL_INVALID: 'We recommend adding new resources to save to the client’s profile.'
};
POST_LIST_TEXT[ACTION_TYPES.FIND_MORE] = {
  SOME_INVALID: 'Do you want to continue?',
  ALL_INVALID: 'Do you want to continue?'
};
// #endregion

// #region 'Continue' button text
CONTINUE_BUTTON_TEXT[ACTION_TYPES.REFERRAL] = {
  SOME_INVALID: 'Continue',
  ALL_INVALID: 'Find more resources'
};
CONTINUE_BUTTON_TEXT[ACTION_TYPES.SHARE] = {
  SOME_INVALID: 'Continue',
  ALL_INVALID: 'Find more resources'
};
CONTINUE_BUTTON_TEXT[ACTION_TYPES.SAVE] = {
  SOME_INVALID: 'Continue',
  ALL_INVALID: 'Find more resources'
};
CONTINUE_BUTTON_TEXT[ACTION_TYPES.FIND_MORE] = {
  SOME_INVALID: 'Continue',
  ALL_INVALID: 'Continue'
};
// #endregion

// #region 'Stay' button text
STAY_BUTTON_TEXT[ACTION_TYPES.REFERRAL] = {
  SOME_INVALID: 'Stay on page',
  ALL_INVALID: 'Stay on page'
};
STAY_BUTTON_TEXT[ACTION_TYPES.SHARE] = {
  SOME_INVALID: 'Stay on page',
  ALL_INVALID: 'Stay on page'
};
STAY_BUTTON_TEXT[ACTION_TYPES.SAVE] = {
  SOME_INVALID: 'Stay on page',
  ALL_INVALID: 'Stay on page'
};
STAY_BUTTON_TEXT[ACTION_TYPES.FIND_MORE] = {
  SOME_INVALID: 'Stay on page',
  ALL_INVALID: 'Stay on page'
};
// #endregion
export default AEM_CONSTANTS;