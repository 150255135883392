import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import $scripts from 'scriptjs';
import { requiredMapScript } from 'src/components/Browse/Map/utils';
import {
  InputField,
  BaseCard,
  BaseCardHeader,
  BaseCardBody,
} from '@unite-us/ui';
import { Field, useField } from 'react-final-form';
import { LocationAddressField, validations } from '@unite-us/client-utils';
import PhoneNumbersField from 'src/components/Organization/components/PhoneNumbersField';
import Fields from 'src/components/Organization/components/Fields';
import composeValidators from 'src/components/Organization/utils/composeValidators';
import { Spinner } from 'src/common/spinners';
import { apiV1 } from 'src/api/config';
import { useUsersByEmail } from 'src/components/Organization/api/hooks/v1';
import mapProviderIdToProps from 'src/components/Organization/utils/mapProviderIdToProps';

export const UserInfoForm = ({ currentProviderId, withPhoneExtensionField }) => {
  const [scriptsLoaded, setScriptsLoaded] = useState(
    window.google && window.google.maps,
  );
  // Let's check to make sure that the google maps script is loaded
  if (!(window.google && window.google.maps)) {
    $scripts(requiredMapScript.scripts, () => {
      setScriptsLoaded(true);
    });
  }

  // attempted several solutions with hook and async validation
  // using react-query with and without refetch, did not resolve issue where
  // fresh data was available while validation method is executing
  // todo: attempt hooks solution
  const getEmployees = async ({ query, provider }) => apiV1.query(
      'employee',
      { query, provider },
      {
        page: { number: 1, size: 50 },
      },
    );

  let lastQuery;
  let queryResult;
  const validateEmployeeNotExists = (initialValue) => async (value) => {
    const newQuery = value.trim().toLowerCase();
    let exists;
    if (initialValue !== value) {
      if (newQuery !== lastQuery) {
        const employeesResult = await getEmployees({
          query: newQuery,
          provider: currentProviderId,
        });
        queryResult = employeesResult;
        lastQuery = newQuery;
      }
      exists = (queryResult.data.data || []).find(
        (employee) => employee.email.toLowerCase() === newQuery,
      );
    }
    return exists ?
      `A user with this email address already exists in your organization. 
      Please provide a different email address or update the existing user.` :
      undefined;
  };

  const [userEmailAddress, setUserEmailAddress] = useState();
  const userIdField = useField('userId');
  useUsersByEmail(
    { email: userEmailAddress },
    {
      enabled: Boolean(userEmailAddress),
      staleTime: 0,
      onSuccess: (result) => {
        userIdField.input.onChange(result[0]?.id || '');
      },
    },
  );

  // initial email value needed to turn off validation when editing existing employee except when email is changed
  const emailField = useField('email');
  const emailValidations = [
    validations.isRequired,
    validations.isEmail,
    validateEmployeeNotExists(emailField?.meta?.initial),
  ];

  // eslint-disable-next-line
  const Error = ({ name }) => {
    const {
      meta: { error },
    } = useField(name, { subscription: { error: true } });
    return error ? <span>{error}</span> : null;
  };

  return (
    <BaseCard className="mb-6">
      <BaseCardHeader title="User Info" />
      <BaseCardBody className="px-8 py-10">
        <div data-test-element="user-email">
          <Field name="email" validate={composeValidators(...emailValidations)}>
            {({ meta, ...rest }) => (
              <>
                <div className="flex">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label
                    className={meta.invalid && meta.touched ? 'text-red' : ''}
                    htmlFor="user-email"
                  >
                    Email<span className="text-red pl-2">*</span>
                  </label>
                  {meta.validating ? (
                    <Spinner className="w-3 h-3 -mt-2" scale={0.2} />
                  ) : (
                    ''
                  )}
                </div>
                <InputField
                  id="user-email"
                  label="email"
                  hideLabel
                  {...meta}
                  {...rest}
                  onChange={(event) => {
                    const email = event.target.value.trim().toLowerCase();
                    if (validations.isEmail(email) === null) {
                      setUserEmailAddress(email);
                    }
                  }}
                />
              </>
            )}
          </Field>
        </div>
        <div className="flex w-full">
          <div className="w-6/12 pr-10" data-test-element="user-first-name">
            <Field
              name={'first_name'}
              validate={(value) => validations.isRequired(value)}
            >
              {(props) => (
                <InputField
                  required
                  label="First Name"
                  id="user-first-name"
                  {...props}
                />
              )}
            </Field>
          </div>
          <div className="w-6/12" data-test-element="user-last-name">
            <Field
              name={'last_name'}
              validate={(value) => validations.isRequired(value)}
            >
              {(props) => (
                <InputField
                  required
                  label="Last Name"
                  id="user-last-name"
                  {...props}
                />
              )}
            </Field>
          </div>
        </div>
        <div>
          <PhoneNumbersField
            name="phone_numbers"
            userPhoneTypes
            initializeWithBlankPhoneNumber
            withPhoneExtensionField={withPhoneExtensionField}
          />
        </div>
        <div data-test-element="address">
          {scriptsLoaded ? (
            <Fields
              names={[
                'line_1',
                'city',
                'county',
                'state',
                'postal_code',
                'country',
                'latitude',
                'longitude',
              ]}
              data-test-element="address"
            >
              {(props) => (
                <LocationAddressField
                  id="location-address"
                  label="Address"
                  google={window.google}
                  fieldNamePath={null}
                  {...props}
                  required={false}
                />
              )}
            </Fields>
          ) : (
            <Spinner center />
          )}
        </div>
        <div data-test-element="line_2">
          <Field name="line_2">
            {(props) => {
              // eslint-disable-next-line react/prop-types
               const propsToPass = !props.meta.valid ? {
                ...props,
                meta: {
                  // eslint-disable-next-line react/prop-types
                  ...props.meta,
                  // Address field won't display the validation state unless touched is true.
                  touched: true,
                },
              } : props;
              return (
                <InputField
                  id="location-line2"
                  label="Apt/Suite/Unit/Building (optional)"
                  {...propsToPass}
                />
);
            }}
          </Field>
          <Error name="line_2" />
        </div>
        <div data-test-element="user-job-title">
          <Field name="work_title">
            {(props) => (
              <InputField id="user-job-title" label={'Job Title'} {...props} />
            )}
          </Field>
        </div>
      </BaseCardBody>
    </BaseCard>
  );
};

UserInfoForm.propTypes = {
  currentProviderId: PropTypes.string.isRequired,
  withPhoneExtensionField: PropTypes.bool,
};

UserInfoForm.defaultProps = {
  withPhoneExtensionField: false,
};

export default connect(mapProviderIdToProps)(UserInfoForm);
