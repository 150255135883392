import PropTypes from 'prop-types';
import React from 'react';
import { get } from 'lodash';
import { Router, applyRouterMiddleware } from 'react-router';
import { browserHistory } from 'common/utils/browserHistory';
import { Provider } from 'react-redux';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { Tracker } from '@unite-us/client-utils';
import { useScroll } from 'react-router-scroll';
import configAuthenticatedRoutes from 'src/pages/routes';
import { ROUTE_BASEPATH, TRACKING_URL } from 'src/config/env/env.config';

const queryClient = new QueryClient();

const ProviderUS = (props) => {
  const {
    store,
  } = props;

  const getTrackerConfig = () => {
    const state = store.getState();
    return {
      url: TRACKING_URL,
      user: get(state, 'user'),
      analytics_proxy_url: get(state, 'session.app_support.app.analytics_proxy_url', null),
      groupId: get(state, 'session.groupId'),
      impersonationType: get(state, 'session.impersonationType'),
      isCC: get(state, 'session.isCoordinationGroup'),
    };
  };

  return (
    <Provider store={store}>
      <Tracker source="app-client" getConfig={getTrackerConfig}>
        <QueryClientProvider client={queryClient}>
          <Router
            history={browserHistory}
            routes={configAuthenticatedRoutes(store)}
            render={applyRouterMiddleware(useScroll())}
            basename={ROUTE_BASEPATH === '/' ? '' : ROUTE_BASEPATH}
          />
        </QueryClientProvider>
      </Tracker>
    </Provider>
  );
};

ProviderUS.propTypes = {
  store: PropTypes.object.isRequired,
};

export default ProviderUS;
