import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get, noop, isEmpty, flow } from 'lodash';
import { TrackerContext } from '@unite-us/client-utils';
import { BaseCard, BaseCardBody } from '@unite-us/ui';
import { PaymentsTrackServiceOld, PaymentsTrackServiceWrapper } from 'src/common/form/Interactions/components';
import { PaymentsTrackService } from 'src/common/form/Interactions/components/PaymentsTrackService';
import callOrLog from 'common/utils/callOrLog';
import FeatureFlagContainer from 'src/common/utils/FeatureFlags/FeatureFlagContainer';
import { SERVICES_PROVIDED } from 'common/utils/EventTracker/utils/eventConstants';
import { UPDATE_FEE_SCHEDULE_PROVIDED_SERVICE } from 'src/components/Cases/constants';
import { useFindProviderRequired } from 'src/components/Organization/api/hooks/v1/providerHooks';
import {
  submitFeeScheduleProvidedService as submitFSPS,
} from 'actions/Case/Contact/Group';
import filterFeeScheduleProvidedServices from 'src/components/Cases/utils/filterFeeScheduleProvidedServices';
import { fetchFeeSchedules as fetchFS } from 'actions/FeeSchedules';
import { fetchPaymentsInsurance as fetchIsurances } from 'src/components/Insurance/actions';
import { fetchInsurancePlanOptions } from 'src/components/Insurance/utils';
import {
  useZcodeOptions,
  useProcedureCodeOptions,
  usePlaceOfServiceOptions,
} from 'src/common/hooks';
import useFeatureFlag from 'src/common/hooks/useFeatureFlag';
import selectOptionsFromIds from '../../utils/selectOptionsFromIds';
import FeeScheduleProvidedServiceCardData from './FeeScheduleProvidedServiceCardData';
import FeeScheduleProvidedServiceCardHeader from './FeeScheduleProvidedServiceCardHeader';

const FeeScheduleProvidedServiceCards = ({
  capInformation,
  contactId,
  editMode,
  feeScheduleProvidedServices,
  groupId,
  isBillable,
  isCostType,
  isEditing,
  metafields,
  paymentType,
  programId,
  selectedProvidedServiceId,
  serviceCase,
  showServicesProvidedInteractions,
  unit,
  submitFeeScheduleProvidedService,
  feeScheduleProgramIds,
  feeScheduleId,
  feeSchedule,
  insurances,
  fetchFeeSchedules,
  fetchPaymentsInsurance,
  onDelete,
}) => {
  const eventTracker = useContext(TrackerContext);
  const edit = (providedServiceId) => () => {
    callOrLog(() => eventTracker(SERVICES_PROVIDED.editNote));
    editMode(providedServiceId);
  };

  const hasPaymentsTrackServiceRefactoring = useFeatureFlag('pays-3318-payments-track-service-refactoring');

  const [fsPlans, setFSPlans] = useState([]);
  const [payerId, setPayerId] = useState('');

  const zcodeOptions = useZcodeOptions(feeScheduleProgramIds, payerId);
  const placeOfServiceOptions = usePlaceOfServiceOptions(feeScheduleProgramIds);
  const procedureCodeOptions = useProcedureCodeOptions(feeScheduleProgramIds, payerId);

  const { data } = useFindProviderRequired({ providerId: groupId });
  const isNetworkLead = get(data, 'provider_type') === 'network_lead';

  useEffect(() => {
    fetchFeeSchedules({ id: feeScheduleId });
  }, [feeScheduleId]);

  useEffect(() => {
    if (!isEmpty(feeSchedule)) {
      fetchPaymentsInsurance(contactId, groupId);
      fetchInsurancePlanOptions({
        planIds: get(feeSchedule, 'plans', []).map((plan) => (plan.id)),
        planType: 'social',
        showEnrollmentRequired: true,
      }).then((response) => {
        setFSPlans(response.data.data);
      });
    }
  }, [feeSchedule]);

  useEffect(() => {
    if (!isEmpty(insurances) && !isEmpty(feeSchedule)) {
      const activeInsurances = insurances.filter((insurance) => insurance.attributes.state === 'active');
      const insPlanIds = activeInsurances.map((insurance) => insurance.relationships.plan.data.id);
      const fsPlan = fsPlans.filter((fsp) => insPlanIds.includes(fsp.id)).shift();
      if (fsPlan) {
        setPayerId(get(fsPlan, 'relationships.payer.data.id', ''));
      }
    }
  }, [insurances, fsPlans]);

  const submit = (providedService) => () => submitFeeScheduleProvidedService({
    providedService,
    id: providedService.id,
    caseId: serviceCase.id,
    contactId,
    groupId,
    programId,
  });

  return (
    <div className="space-y-5">
      {
        feeScheduleProvidedServices.length !== 0 &&
        filterFeeScheduleProvidedServices(feeScheduleProvidedServices).map((providedService) => {
          const placeOfServiceCardData = placeOfServiceOptions?.find(
            (option) => option?.value === providedService.place_of_service?.id,
          );
          const buildZcodes = selectOptionsFromIds(providedService.zcodes, zcodeOptions);
          const zcodeCardData = buildZcodes?.map((code) => code?.label);
          const buildProcedureCodes = selectOptionsFromIds(providedService.procedure_codes, procedureCodeOptions);
          const procedureCodeCardData = buildProcedureCodes?.map((code) => `${code?.code} - ${code?.description}`);

          return isEditing && (selectedProvidedServiceId === providedService.id) ? (
            <PaymentsTrackServiceWrapper
              key={providedService.id}
              contactId={contactId}
              serviceCase={serviceCase}
              placeOfServiceOptions={placeOfServiceOptions}
              procedureCodeOptions={procedureCodeOptions}
              zcodeOptions={zcodeOptions}
            >
              {hasPaymentsTrackServiceRefactoring ? (
                <PaymentsTrackService
                  isBillable={isBillable}
                  cancelFunction={() => editMode('')}
                  contactId={contactId}
                  form={`${UPDATE_FEE_SCHEDULE_PROVIDED_SERVICE}-${providedService.id}`}
                  serviceCase={serviceCase}
                  selectedProvidedServiceId={selectedProvidedServiceId}
                  providedService={providedService}
                  providedServiceId={providedService.id}
                  isCostType={isCostType}
                  isEditing={isEditing}
                />
              ) : (
                <PaymentsTrackServiceOld
                  isBillable={isBillable}
                  cancelFunction={() => editMode('')}
                  contactId={contactId}
                  form={`${UPDATE_FEE_SCHEDULE_PROVIDED_SERVICE}-${providedService.id}`}
                  serviceCase={serviceCase}
                  selectedProvidedServiceId={selectedProvidedServiceId}
                  providedService={providedService}
                  providedServiceId={providedService.id}
                  isCostType={isCostType}
                  isEditing={isEditing}
                />
              )}
            </PaymentsTrackServiceWrapper>
          ) : (
            <BaseCard key={providedService.id} className="fee-schedule-provided-service-card">
              <BaseCardBody>
                <div>
                  <FeeScheduleProvidedServiceCardHeader
                    className="py-4 px-8"
                    isBillable={isBillable}
                    isNetworkLead={isNetworkLead}
                    metafields={metafields}
                    onEdit={edit(providedService.id)}
                    onSubmit={submit(providedService)}
                    programName={get(serviceCase, 'program.name')}
                    providedService={providedService}
                    showInteractions={showServicesProvidedInteractions}
                    onDelete={onDelete}
                  />
                  <hr className="border-dark-fill-blue" />
                  <FeeScheduleProvidedServiceCardData
                    capInformation={capInformation}
                    className="py-4 px-8 box-border"
                    paymentType={paymentType}
                    programName={get(serviceCase, 'program.name')}
                    providedService={providedService}
                    unit={unit}
                    metafields={metafields}
                    placeOfServiceCardData={placeOfServiceCardData}
                    procedureCodeCardData={procedureCodeCardData}
                    zcodeCardData={zcodeCardData}
                  />
                </div>
              </BaseCardBody>
            </BaseCard>
          );
          })
      }
    </div>
  );
};

FeeScheduleProvidedServiceCards.propTypes = {
  capInformation: PropTypes.string,
  contactId: PropTypes.string,
  editMode: PropTypes.func,
  feeSchedule: PropTypes.object,
  feeScheduleId: PropTypes.string,
  feeScheduleProvidedServices: PropTypes.array.isRequired,
  feeScheduleProgramIds: PropTypes.array,
  groupId: PropTypes.string,
  insurances: PropTypes.array,
  isBillable: PropTypes.bool,
  isEditing: PropTypes.bool,
  isCostType: PropTypes.bool.isRequired,
  paymentType: PropTypes.string.isRequired,
  programId: PropTypes.string,
  metafields: PropTypes.array,
  serviceCase: PropTypes.shape({
    id: PropTypes.string,
    contact: PropTypes.object,
  }).isRequired,
  selectedProvidedServiceId: PropTypes.string,
  submitFeeScheduleProvidedService: PropTypes.func.isRequired,
  showServicesProvidedInteractions: PropTypes.bool,
  unit: PropTypes.string,
  fetchFeeSchedules: PropTypes.func.isRequired,
  fetchPaymentsInsurance: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

FeeScheduleProvidedServiceCards.defaultProps = {
  capInformation: '',
  contactId: '',
  editMode: noop,
  groupId: '',
  isBillable: false,
  isEditing: false,
  metafields: [],
  programId: '',
  selectedProvidedServiceId: '',
  showServicesProvidedInteractions: true,
  unit: '',
  feeSchedule: {},
  insurances: [],
  feeScheduleId: '',
  feeScheduleProgramIds: [],
};

const mapStateToProps = (state, ownProps) => {
  const groupId = state.session.groupId;
  const programId = get(ownProps.serviceCase, 'program.id');
  const program = get(state, 'groupsPrograms.data').find(({ id }) => id === programId);
  const isBillable = get(program, 'fee_schedule_program.attributes.billable');
  const feeScheduleId = get(program, 'fee_schedule_program.relationships.fee_schedule.data.id', '');
  const feeSchedule = get(state, `feeSchedules.data.${feeScheduleId}`, {});

  const selectedFeeScheduleProgramIds = get(program, 'fee_schedule_program.id', '');
  const feeScheduleProgramIds = selectedFeeScheduleProgramIds ? [selectedFeeScheduleProgramIds] : [];
  const insurances = get(state, `insurances.data.${ownProps.contactId}`, []);

  return {
    groupId,
    isBillable,
    programId,
    feeSchedule,
    feeScheduleId,
    feeScheduleProgramIds,
    insurances,
  };
};

export default flow(
  FeatureFlagContainer,
  connect(mapStateToProps, {
  submitFeeScheduleProvidedService: submitFSPS,
  fetchFeeSchedules: fetchFS,
  fetchPaymentsInsurance: fetchIsurances,
}),
)(FeeScheduleProvidedServiceCards);
