function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t["return"] || t["return"](); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import _ from 'lodash';
export function wget(obj, path, defaultValue) {
  var defaultValueSingleton = {
    defaultValue: defaultValue
  };
  var result = _.get(obj, path, defaultValueSingleton);
  if (_.isEqual(result, defaultValueSingleton)) {
    /* eslint-disable */
    console.warn("Default Value was used @ path: ".concat(path));
    /* eslint-enable */
    result = result.defaultValue;
  }
  return result;
}
export function uuOmit(obj) {
  for (var _len = arguments.length, path = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    path[_key - 1] = arguments[_key];
  }
  return _.reduce(obj, function (acc, value, key) {
    if (_.includes(path.toString().split(','), key)) {
      return acc;
    }
    return _.merge(acc, _defineProperty({}, key, value));
  }, {});
}
export function uuPick(obj) {
  for (var _len2 = arguments.length, path = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
    path[_key2 - 1] = arguments[_key2];
  }
  return _.reduce(obj, function (acc, value, key) {
    if (_.includes(path.toString().split(','), key)) {
      return _.merge(acc, _defineProperty({}, key, value));
    }
    return acc;
  }, {});
}
export function uuOmitBy(obj, predicate) {
  return _.reduce(obj, function (acc, value, key) {
    if (predicate(value, key)) {
      return acc;
    }
    return _.merge(acc, _defineProperty({}, key, value));
  }, {});
}
export function uuPickBy(obj, predicate) {
  return _.reduce(obj, function (acc, value, key) {
    if (predicate(value, key)) {
      return _.merge(acc, _defineProperty({}, key, value));
    }
    return acc;
  }, {});
}
export function uuCompactArrayOrObject(item) {
  if (Array.isArray(item)) {
    return _.compact(item);
  }
  if (_typeof(item) === 'object') {
    return _.uuOmitBy(item, function (prop) {
      return _.isEmpty(prop) && !_.isNumber(prop) && !_.isBoolean(prop);
    });
  }
  return item;
}
export function recursiveGet(object, pathArray, defaultValue, iterator) {
  if (_.isArray(pathArray)) {
    var _iterator = _createForOfIteratorHelper(pathArray),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var path = _step.value;
        var current = _.get(object, path, undefined);
        if (_.isFunction(iterator)) {
          if (iterator(current) && current) {
            return current;
          }
        } else if (current) {
          return current;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  }
  return defaultValue;
}
export default function extendLodash() {
  _.mixin({
    wget: wget,
    uuOmit: uuOmit,
    uuPick: uuPick,
    uuOmitBy: uuOmitBy,
    uuPickBy: uuPickBy,
    uuCompactArrayOrObject: uuCompactArrayOrObject,
    recursiveGet: recursiveGet
  });
}