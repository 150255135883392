import { get } from 'lodash';
import { UNDER_DISPUTE_STATUS } from 'src/pages/invoices/constants';

const dateDisputed = (versions) => {
  const versionUnderDispute = (version) => {
    const changesIncludeSetStatusUnderDispute = get(version, 'object_changes.invoice_status[1]') ===
      UNDER_DISPUTE_STATUS;
    const changesIncludeSetInvoiceUnderDispute = get(version, 'object_changes.under_dispute[1]') === true;

    return changesIncludeSetStatusUnderDispute || changesIncludeSetInvoiceUnderDispute;
  };

  const underDisputeInvoiceVersion = versions.find(versionUnderDispute);

  if (underDisputeInvoiceVersion) {
    const date = new Date(Date.parse(underDisputeInvoiceVersion.created_at));
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
  }

  return null;
};

export default dateDisputed;
