import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _ from 'lodash';
import { InputField, SelectField } from '@unite-us/ui';
import { validations } from '@unite-us/client-utils';

import {
  AddressRemoveButton,
  AddressFieldConditionalDisplay,
} from './components';

import {
  requireAddressType,
} from './utils';

import {
  DISPLAY_CITY_STATE,
  DISPLAY_FULL,
  DISPLAY_POSTAL_CODE,
} from './constants';

import './AddressField.scss';

export class AddressFieldGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRemove: false,
      location: {
        line_1: props.field.attributes.line_1.value,
        city: props.field.attributes.city.value,
        state: props.field.attributes.state.value,
        postal_code: props.field.attributes.postal_code.value,
      },
      showInvalidWarning: false,
    };
    this.confirmRemove = this.confirmRemove.bind(this);
    this.toggleRemove = this.toggleRemove.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    if (!requireAddressType(this.props.displayFieldSet)) {
      this.props.field.address_type.onChange('home');
    }
  }

  onChange(e, attribute) {
    const value = e.target ? e.target.value : e;
    this.setState({
      location: {
        ...this.state.location,
        [attribute]: value,
      },
    }, async () => {
      try {
        const isInvalid = await this.props.isValidAddress(this.state.location);
        return this.setState({
          showInvalidWarning: isInvalid,
        });
      } catch (error) {
        return error;
      }
    });
  }

  toggleRemove() {
    this.setState({ showRemove: !this.state.showRemove });
  }

  confirmRemove() {
    this.props.remove();
    this.toggleRemove();
  }

  render() {
    const {
      displayFieldSet,
      field,
      id,
      inline,
      inputStyle,
      label,
      labelStyle,
      multi,
      registerField,
      remove,
      usStates,
    } = this.props;

    const { showInvalidWarning } = this.state;

    const groupLabelClass = () => classNames('group-label', {
      hidden: label === undefined,
      inline,
    });

    return (
      <div className="address-field">
        <div className="address-field__title row">
          <div className="col-xs-12">
            <strong className={groupLabelClass()} style={labelStyle}>
              {label}
            </strong>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <AddressFieldConditionalDisplay selectedFieldSet={displayFieldSet} validDisplayTypes={DISPLAY_FULL}>
              <InputField
                field={field.attributes.line_1}
                id={`${id}-line1`}
                inline={inline}
                inputStyle={inputStyle}
                onChange={(e) => this.onChange(e, 'line_1')}
                label="Address Line 1"
                labelStyle={labelStyle}
                ref={registerField}
                validations={validations.isRequired}
              />
            </AddressFieldConditionalDisplay>

            <AddressFieldConditionalDisplay selectedFieldSet={displayFieldSet} validDisplayTypes={DISPLAY_FULL}>
              <InputField
                field={field.attributes.line_2}
                id={`${id}-line2`}
                inline={inline}
                inputStyle={inputStyle}
                label="Address Line 2"
                labelStyle={labelStyle}
              />
            </AddressFieldConditionalDisplay>

            <AddressFieldConditionalDisplay selectedFieldSet={displayFieldSet} validDisplayTypes={DISPLAY_CITY_STATE}>
              <InputField
                field={field.attributes.city}
                id={`${id}-city`}
                inline={inline}
                inputStyle={inputStyle}
                label="City"
                labelStyle={labelStyle}
                onChange={(e) => this.onChange(e, 'city')}
                placeholder="City"
                ref={registerField}
                validations={validations.isRequired}
              />
            </AddressFieldConditionalDisplay>
          </div>
        </div>

        <div className="row">
          <AddressFieldConditionalDisplay selectedFieldSet={displayFieldSet} validDisplayTypes={DISPLAY_CITY_STATE}>
            <div className="col-xs-12 col-sm-6">
              <SelectField
                className="address-field-state"
                field={field.attributes.state}
                hideLabel={inline}
                id={`${id}-state`}
                inline={inline}
                inputStyle={inputStyle}
                label="State"
                labelKey="display_name"
                labelStyle={labelStyle}
                onChange={(e) => this.onChange(e, 'state')}
                options={usStates}
                placeholder="Select state"
                ref={registerField}
                validations={validations.isRequired}
                valueKey="value"
              />
            </div>
          </AddressFieldConditionalDisplay>

          <AddressFieldConditionalDisplay selectedFieldSet={displayFieldSet} validDisplayTypes={DISPLAY_POSTAL_CODE}>
            <div className="col-xs-12 col-sm-6">
              <InputField
                field={field.attributes.postal_code}
                hideLabel={inline}
                id={`${id}-postal-code`}
                inline={inline}
                inputStyle={inputStyle}
                label="Zip Code"
                labelStyle={labelStyle}
                maxLength="5"
                onChange={(e) => this.onChange(e, 'postal_code')}
                placeholder="Zip Code"
                ref={registerField}
                validations={validations.isRequired}
              />
            </div>
          </AddressFieldConditionalDisplay>
        </div>

        <div className="row">
          <div className="col-xs-9 address-field__error">
            {showInvalidWarning && <p>*Invalid Address. Please make sure you enter a valid address.</p>}
          </div>
          <div className="col-xs-3">
            <AddressRemoveButton
              confirmRemove={this.confirmRemove}
              field={field}
              multi={multi}
              remove={remove}
              showRemove={this.state.showRemove}
              toggleRemove={this.toggleRemove}
            />
          </div>
        </div>
      </div>
    );
  }
}

AddressFieldGroup.propTypes = {
  displayFieldSet: PropTypes.string,
  field: PropTypes.object.isRequired,
  id: PropTypes.string,
  inline: PropTypes.bool.isRequired,
  inputStyle: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  labelStyle: PropTypes.object.isRequired,
  multi: PropTypes.bool.isRequired,
  registerField: PropTypes.func.isRequired,
  remove: PropTypes.func,
  usStates: PropTypes.array.isRequired,
  isValidAddress: PropTypes.func.isRequired,
};

AddressFieldGroup.defaultProps = {
  displayFieldSet: 'full',
  inline: true,
  inputStyle: {},
  labelStyle: {},
  multi: true,
};

function mapStateToProps(state, ownProps) {
  if (!state.session) {
    return {
      addressTypes: _.get(ownProps, 'addressTypes', ownProps.enums.contact_data.address_types),
      usStates: ownProps.enums.geography.us_states,
    };
  }
  return {
    addressTypes: _.get(ownProps, 'addressTypes', state.session.enums.contact_data.address_types),
    usStates: state.session.enums.geography.us_states,
  };
}

export default connect(mapStateToProps)(AddressFieldGroup);
