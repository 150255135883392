import { DISPLAY_NPS, PRODUCT_NAME } from '../constants';

function getInMomentConfig(inMomentFlag) {
     return {
        noSurveyedCookie: inMomentFlag === DISPLAY_NPS.TEST,
        surveyImmediately: inMomentFlag === DISPLAY_NPS.TEST,
        productName: PRODUCT_NAME,
    };
}

function formatLocationForInMoment(addresses) {
    if (!addresses) return undefined;
    const city = addresses[0]?.city;
    const state = addresses[0]?.state;
    if (city && state) { return `${city}, ${state}`; }
    return state;
}

function getInMomentParams(inMomentFlag, addresses) {
    const netPromoterConfig = getInMomentConfig(inMomentFlag);
    const netPromoterLocation = formatLocationForInMoment(addresses);
    return { netPromoterConfig, netPromoterLocation };
}

export default getInMomentParams;
