import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { find, get, isEmpty, capitalize } from 'lodash';
import { fetchPayers as fetchingPayers } from 'actions/Payers';
import {
  STATUS_INITIAL,
  STATUS_PENDING,
} from 'common/Status/constants';
import {
  Badge,
  BaseCard,
  BaseCardBody,
  Icon,
  Button,
} from '@unite-us/ui';
import { Spinner } from 'common/spinners';
import { browserHistory } from 'common/utils/browserHistory';

const Card = ({ label, value }) => (
  <BaseCard className="bg-gray-100">
    <BaseCardBody className="bg-gray-100">
      <div className="p-4">
        <div className="text-base">{label}</div>
        <div className="text-xl">{value}</div>
      </div>
    </BaseCardBody>
  </BaseCard>
);

Card.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};

Card.defaultProps = {
  value: '',
};

export const Payer = ({
  fetchPayers,
  payer,
  payerId,
  status,
}) => {
  const editPayer = (id) => () => {
    browserHistory.push({ pathname: `/backoffice/payers/${id}/edit` });
  };
  const backToPayers = () => {
    browserHistory.push({ pathname: '/backoffice/payers' });
  };
  const addPlan = (id) => () => {
    browserHistory.push({ pathname: `/backoffice/payers/${id}/plans/new` });
  };
  const viewPlan = (id, plan_id) => () => {
    browserHistory.push({ pathname: `/backoffice/payers/${id}/plans/${plan_id}` });
  };

  useEffect(() => {
    fetchPayers({ id: payerId, include: 'plans' });
  }, [fetchPayers, payerId]);
  const {
    name, external_id, plans, state,
  } = payer;
  const isLoading = (status === STATUS_INITIAL || status === STATUS_PENDING) && isEmpty(payer);
  return (
    <>
      {isLoading ? <Spinner /> :
        (
          <div className="p-6">
            <div className="flex py-4">
              <div className="pr-3 text-base ">
                <Icon
                  color="#525F73"
                  icon="HousingShelter"
                />
              </div>
              <div className="px-3 text-base">/</div>
              <div
                className="px-3 text-base hover:text-medium-gray text-action-blue"
                id="back-to-payers-button"
                role="button"
                onClick={backToPayers}
                onKeyDown={backToPayers}
                tabIndex={0}
              >Payers
              </div>
              <div className="px-3 text-base">/</div>
              <div className="px-3 text-base">{name}</div>
            </div>
            <div className="flex">
              <div className="flex w-9/12">
                <div className="pr-2">
                  { name }
                </div>
                <div>
                  <Badge className="px-4 text-sm" color="#4ECE3D" text={capitalize(state)} />
                </div>
              </div>
              <div className="w-3/12 flex justify-end">
                <Button
                  id="edit-payer-button"
                  data-testid="edit-payer-details-btn"
                  iconLeft={<Icon color="#4467AB" icon="V2Edit" />}
                  className="py-1 px-4 text-sm text-action-blue rounded border-action-blue border-opacity-25"
                  label="Edit Details"
                  onClick={editPayer(payerId)}
                />
              </div>
            </div>
            <hr className="p-2 divide-y divide-brand-blue" />
            <div className="py-4 w-1/2">
              <Card label="External ID" value={external_id} />
            </div>
            <div className="flex">
              <div className="w-9/12">
                Plans
              </div>
              <div className="w-3/12 flex justify-end">
                <Button
                  id="add-plan-button"
                  data-testid="add-plan-to-payer-btn"
                  iconLeft={<Icon color="#4467AB" icon="V2Add" />}
                  className="py-1 px-4 text-sm text-action-blue rounded border-action-blue border-opacity-25"
                  label="Add Plan"
                  lowerCase
                  onClick={addPlan(payerId)}
                />
              </div>
            </div>
            <hr className="p-2 divide-y divide-brand-blue" />
            {!isEmpty(plans) && (
            <>
              <div className="flex flex-col">
                <div className="overflow-x-auto">
                  <div className="py-2 align-middle inline-block min-w-full">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-100">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Type
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              External ID
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {plans.map((p, payerIdx) => (
                            <tr
                              onClick={viewPlan(payerId, p.id)} id="edit-plan-button" key={p.name}
                              className={
                              payerIdx % 2 === 0 ? 'bg-white hover:bg-gray-300' : 'bg-gray-100 hover:bg-gray-300'
                            }
                            >
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {p.name}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{p.plan_type}</td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{p.external_id}</td>
                            </tr>
                          ))}
                          {/* TODO: add pagination */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

            </>
            )}
          </div>
        )}
    </>
  );
};

Payer.propTypes = {
  payer: PropTypes.object,
  payerId: PropTypes.string.isRequired,
  fetchPayers: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

Payer.defaultProps = {
  payer: {},
};

const mapStateToProps = (state, ownProps) => {
  const payerId = ownProps.payerId ? ownProps.payerId : get(ownProps, 'params.id', '');
  const payers = get(state, 'payers.payers', []);
  const payer = find(payers, ['id', payerId]);
  const status = get(state, 'payers.status');
  return {
    payer,
    payerId,
    status,
  };
};

export default connect(mapStateToProps, { fetchPayers: fetchingPayers })(Payer);
