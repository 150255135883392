import React, { useContext } from 'react';
import { INVOICE } from 'common/utils/EventTracker/utils/eventConstants';
import { PropTypes } from 'prop-types';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { validations, TrackerContext } from '@unite-us/client-utils';
import callOrLog from 'src/common/utils/callOrLog';
import { TextField, SelectField } from '@unite-us/ui';
import NoteDisclaimer from 'common/Disclaimer/NoteDisclaimer';
import DialogV2 from 'common/modal/DialogV2';
import { useRejectInvoice } from '../hooks/useUpdateInvoice';
import { useInvoiceRejectionReasons } from '../hooks';
import { ROLES } from '../constants';

const getRole = (isNetworkLead, isPayerProvider) => {
  if (isNetworkLead) {
    return ROLES.networkLead;
  }
  if (isPayerProvider) {
    return ROLES.payerProvider;
  }

  return ROLES.cboAdmin;
};

const InvoiceRejectionForm = ({
  invoice, isNetworkLead, isPayerProvider, close, networkId, setRef, onSuccess,
}) => {
  const role = getRole(isNetworkLead, isPayerProvider);

  const invoiceRejectionReasons = useInvoiceRejectionReasons(invoice.fee_schedule_id);

  const rejectionReasons = invoiceRejectionReasons.map((reason) => ({
    label: reason.display_name, value: reason.id,
  }));

  const eventTracker = useContext(TrackerContext);

  const rejectInvoice = useRejectInvoice();
  const submitRejection = async ({ rejection_reason, rejection_note }) => {
    const rejectionEventTypes = {
      [ROLES.networkLead]: INVOICE.nlClickedReject,
      [ROLES.payerProvider]: INVOICE.payerClickedReject,
      [ROLES.cboAdmin]: INVOICE.cboClickedReject,
    };

    await rejectInvoice(role, invoice.id, { invoice_rejection_reason: rejection_reason, rejection_note });
    onSuccess();
    callOrLog(() => eventTracker(
      rejectionEventTypes[role],
      { current_network: networkId },
    ));
  };

  return (
    <Form
      onSubmit={submitRejection}
      render={({ handleSubmit }) => (
        <DialogV2
          cancelHandler={close}
          confirmLabel="Reject Invoice"
          confirmationHandler={handleSubmit}
          dataTestId="reject-invoice-dialog"
          confirmButtonTestId="reject-invoice-dialog-confirm-button"
          ref={setRef}
          scrollable={false}
          title={`Reject Invoice #${invoice.short_id}`}
          width="3xl"
        >
          <div className="text-sm font-light">
            To continue with rejecting this invoice,
            provide more information on why.
          </div>
          <Field
            name="rejection_reason"
            validate={(value) => validations.isRequired(value)}
          >
            {(params) => (
              <SelectField
                className="pt-4 max-w-sm text-brand-blue relative break-normal"
                label="Rejection Reason"
                id="rejection_reason"
                options={rejectionReasons}
                placeholder="Choose Code"
                required
                truncateOptions={false}
                {...params}
              />
            )}
          </Field>
          <Field name="rejection_note">
            {(params) => (
              <TextField
                placeholder="Additional details..."
                afterLabelContent={!isPayerProvider && <NoteDisclaimer />}
                required={false}
                label="Note"
                id="rejection_note"
                style={{ error: { height: 0 } }}
                {...params}
                maxLength={1000}
              />
            )}
          </Field>
        </DialogV2>
      )}
    />
  );
};

InvoiceRejectionForm.propTypes = {
  close: PropTypes.func.isRequired,
  invoice: PropTypes.object.isRequired,
  isNetworkLead: PropTypes.bool.isRequired,
  networkId: PropTypes.string.isRequired,
  setRef: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
  isPayerProvider: PropTypes.bool,
 };

InvoiceRejectionForm.defaultProps = {
  onSuccess: () => { },
  isPayerProvider: false,
};

InvoiceRejectionForm.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const networkId = state.networks.networkId;
  return {
    networkId,
  };
}

export default connect(mapStateToProps)(InvoiceRejectionForm);
