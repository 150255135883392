import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import AddressField from 'common/form/AddressField/AddressField';
import { validations } from '@unite-us/client-utils';
import {
  BestTimeToContactField,
  CommunicationTypesCheckBoxes,
  PreferredMethodOfContactField,
} from 'common/form';
import { PhoneNumberField, PhoneTypeField } from 'common/form/Phone';
import { EmailAddressField } from 'common/form/Email';
import {
  phoneCanReceiveOralCommunication,
  phoneCanReceiveWrittenCommunication,
  phoneNumberNotEmptyValidation,
} from 'common/form/Phone/utils';
import { anyCheckedValidation } from 'common/utils/Form';
import '../stylesheets/contactInformation.scss';

function ContactInformation(props) {
  const {
    registerField,
    fields,
    enums,
  } = props;
  const contactEnums = _.wget(enums, 'contact_data', {});

  return (
    <div className="contact-information">
      <h3 className="contact-information__title">
        Contact Information
      </h3>

      <hr />

      <div className="row">
        <div className="col-xs-6 contact-information__email">
          <EmailAddressField
            field={fields.email.email_address}
            id="Email"
            registerField={registerField}
            validations={[
              { func: validations.isEmail },
              {
                func: anyCheckedValidation(fields.email.acceptable_communication_types),
                message: 'Required',
              },
            ]}
          />
        </div>
        <div className="col-xs-12 contact-information__phone">
          <CommunicationTypesCheckBoxes
            hideOralTypes
            email
            id="email-communication-types"
            fields={fields.email.acceptable_communication_types}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <PhoneNumberField
            field={fields.phone.phone_number}
            hideLabel={false}
            id="phone-number-field"
            registerField={registerField}
            validations={[
              { func: validations.isPhoneNumber },
              {
                func: anyCheckedValidation(fields.phone.acceptable_communication_types),
                message: 'Required',
              },
            ]}
          />
        </div>

        <div className="col-xs-12 col-sm-3 contact-information__phone-type">
          <PhoneTypeField
            field={fields.phone.phone_type}
            id="phone-type-field"
            hideLabel={false}
            registerField={registerField}
            validations={{
              func: phoneNumberNotEmptyValidation(fields.phone),
              message: 'Required',
            }}
          />
        </div>
        <div className="col-xs-12">
          <CommunicationTypesCheckBoxes
            hideOralTypes={!phoneCanReceiveOralCommunication(fields.phone.phone_type.value)}
            hideWrittenTypes={!phoneCanReceiveWrittenCommunication(fields.phone.phone_type.value)}
            id="phone-communication-types"
            fields={fields.phone.acceptable_communication_types}
            phone
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-6">
          <PreferredMethodOfContactField
            field={fields.preferred_communication_method}
            id="preferred-method-of-contact-field"
            options={_.get(contactEnums, 'communication_preferences.contact_method', [])}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-6">
          <BestTimeToContactField
            field={fields.preferred_communication_time_of_day}
            id="best-time-to-contact-field"
            options={_.get(contactEnums, 'communication_preferences.time_of_day', [])}
          />
        </div>
      </div>

      {_.get(fields, 'addresses', []).map((address, index) => (
        <div
          id={`address-${index}`}
          key={index}
        >
          <AddressField
            id={`address-${index}`}
            field={address}
            registerField={props.registerField}
            inline={false}
            multi={false}
            hideMailingField
            index={index}
          />
        </div>
      ))}
    </div>
  );
}

ContactInformation.fields = [
  'addresses[].line_1',
  'addresses[].line_2',
  'addresses[].city',
  'addresses[].state',
  'addresses[].postal_code',
  'addresses[].address_type',
  'addresses[].country',
  'preferred_communication_time_of_day',
  'email.email_address',
  'email.acceptable_communication_types.message',
  'email.acceptable_communication_types.notification',
  'phone.acceptable_communication_types.message',
  'phone.acceptable_communication_types.notification',
  'phone.acceptable_communication_types.phone_call',
  'phone.phone_number',
  'phone.phone_type',
  'preferred_communication_method',
  'preferred_communication_time_of_day',
];

ContactInformation.propTypes = {
  fields: PropTypes.object.isRequired,
  registerField: PropTypes.func.isRequired,
  enums: PropTypes.object.isRequired,
};

export default ContactInformation;
