import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  tracker,
} from '@unite-us/client-utils';
import IndexDetailView from 'src/components/Dashboard/components/IndexDetailView';
import DraftReferralsIndex from 'src/components/Dashboard/components/Referrals/DraftReferralsIndex';
import { fetchDashboardCases } from 'actions/Case/Contact/Group';
import _ from 'lodash';
import DetailView from 'src/components/Dashboard/components/DetailView';
import DraftDetailsView from 'src/components/Cases/components/Detail/DraftDetailsView';
import fetchProvidersUserCore from 'src/actions/UserProvider/fetchProvidersUser';
import { noneAssignedOption } from 'src/components/Dashboard/utils/filterDefaults';
import { getDataOfPage, isDataValid } from 'src/components/Dashboard/utils/dataHelpers';
import callOrLog from 'src/common/utils/callOrLog';
import { goToCase } from 'src/components/Cases/utils/routing';
import { careCoordinatorsFilter, consentStatusFilter, serviceTypesFilter } from 'src/components/Dashboard/utils/filter';
import { DASHBOARD_EVENTS, DASHBOARD_VIEWS } from 'common/utils/EventTracker/utils/eventConstants';
import { getGroupUsersOptions } from 'src/components/Groups/Users/utils';
import labelCustomization from 'src/common/utils/Customization/labels';
import defaultLabels from 'src/constants/labels';
import { crtb1290SortReferralsByOldest } from 'src/common/utils/FeatureFlags/flags';

export class DraftReferrals extends Component {
  constructor(props) {
    super(props);
    this.onCellClick = this.onCellClick.bind(this);
    this.onFiltersChange = this.onFiltersChange.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onPrevClick = this.onPrevClick.bind(this);
    this.shouldFetch = this.shouldFetch.bind(this);
    this.fetch = this.fetch.bind(this);
    this.fetchGroupsUsers = this.fetchGroupsUsers.bind(this);
    this.state = {
      activeUsersInGroup: [],
      page: 1,
      sidx: 'updated_at',
      sord: 'desc',
      filters: {
        service_types: _.get(props, 'filters.service_types', []),
        care_coordinator_users: _.get(props, 'filters.care_coordinator_users', []),
        consent_status: _.get(props, 'filters.consent_status', []),
      },
    };
  }

  componentDidMount() {
    this.fetch();
    this.props.fetchProvidersUserCore({
      providers: this.props.groupId,
    }).then((response) => {
      if (response) {
        const { employees: activeUsersInGroup } = response;
        this.setState({ activeUsersInGroup });
      }
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (_.get(this.props, 'draftReferrals.currentPage', 1) !== _.get(nextProps, 'draftReferrals.currentPage', 1)) {
      this.setState({ page: nextProps.draftReferrals.currentPage });
    }

    if (!this.props.refetch && nextProps.refetch) {
      this.fetch();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.fetch();
    }
  }

  onCellClick(row) {
    const pagedData = getDataOfPage(this.props.draftReferrals, this.state.page, this.props.sortAscending);
    const serviceCase = pagedData.data[row];

    callOrLog(() => this.context.eventTracker(DASHBOARD_EVENTS.draftReferralsRow, {
      view: DASHBOARD_VIEWS.draftReferrals,
    }, { serviceCase }));

    goToCase({ ...serviceCase, status: 'draft' });
  }

  onFiltersChange(key, activeFilters, callbacks) {
    let newFilters = activeFilters;
    if (_.isEqual(key, 'care_coordinator_users')) {
      newFilters = careCoordinatorsFilter(activeFilters, callbacks);
    }
    this.setState({
      page: 1,
      filters: _.assign({}, this.state.filters, { [key]: newFilters }),
    }, () => {
      this.fetch();
      callOrLog(() => {
        const filters = tracker.buildDashboardFilterPayload(this.state, this.props);
        this.context.eventTracker(DASHBOARD_EVENTS.draftReferralsFilter, filters);
      });
    });
  }

  onNextClick() {
    const page = this.state.page + 1;

    this.shouldFetch(page);
  }

  onPrevClick() {
    const page = this.state.page - 1;

    this.shouldFetch(page);
  }

  shouldFetch(page) {
    if (isDataValid(this.props.draftReferrals, page)) {
      this.setState({ page });
    } else {
      this.fetch(page);
    }
  }

  fetch(page = 1) {
    this.props.fetchDashboardCases(
      this.props.groupId,
      '',
      {
        target: 'draftReferrals',
        sidx: this.state.sidx,
        sord: this.state.sord,
        filters: this.state.filters,
        page,
      },
    );
  }

  fetchGroupsUsers(search = '') {
    const { groupId } = this.props;
    return this.props.fetchProvidersUserCore({
      providers: groupId,
      options: { text: search },
    }).then((response) => {
      if (response) {
        const { employees: activeUsersInGroup } = response;
        if (search === '') {
          return _.concat(
            [noneAssignedOption(this.state)],
            getGroupUsersOptions(activeUsersInGroup, this.state),
          );
        }

        return getGroupUsersOptions(activeUsersInGroup, this.state);
      }

      return [];
    });
  }

  render() {
    const {
      draftReferrals,
      isFetching,
      isCC,
      params,
      serviceTypes,
      labels,
    } = this.props;
    const { activeUsersInGroup } = this.state;

    let filters = [
      serviceTypesFilter(serviceTypes, this.state.filters.service_types),
      consentStatusFilter(this.state.filters.consent_status),
    ];
    filters = _.concat(filters, [
      {
        key: 'care_coordinator_users',
        name: labels.CareCoordinator,
        pluralName: `${labels.CareCoordinator}s`,
        options: _.concat(
          [noneAssignedOption(this.state)],
          getGroupUsersOptions(activeUsersInGroup, this.state),
        ),
      },
    ]);

    const innerContentView = (
      <DraftDetailsView
        params={params}
        type="allCases"
        showContactColumn
      />
    );

    const newReferralDetailView = (
      <DetailView
        innerContentView={innerContentView}
        hideMessageCenterButton
      />
    );
    const pagedData = getDataOfPage(draftReferrals, this.state.page, this.props.sortAscending);

    return (
      <IndexDetailView
        params={this.props.params}
        DetailView={newReferralDetailView}
        IndexView={DraftReferralsIndex}
        data={pagedData.data}
        paging={pagedData.paging}
        isFetching={isFetching}
        onNextClick={this.onNextClick}
        onPrevClick={this.onPrevClick}
        onCellClick={this.onCellClick}
        initialSort={{ [this.state.sidx]: this.state.sord }}
        isCC={isCC}
        onFiltersChange={this.onFiltersChange}
        filters={_.sortBy(filters, 'name')}
        fetchGroupsUsers={this.fetchGroupsUsers}
      />
    );
  }
}

DraftReferrals.propTypes = {
  location: PropTypes.object,
  params: PropTypes.object.isRequired,
  fetchDashboardCases: PropTypes.func.isRequired,
  draftReferrals: PropTypes.shape({
    data: PropTypes.array.isRequired,
    currentPage: PropTypes.number.isRequired,
  }).isRequired,
  groupId: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isCC: PropTypes.bool.isRequired,
  serviceTypes: PropTypes.array.isRequired,
  fetchProvidersUserCore: PropTypes.func.isRequired,
  refetch: PropTypes.bool.isRequired,
  labels: PropTypes.object,
  sortAscending: PropTypes.bool,
};

DraftReferrals.defaultProps = {
  labels: defaultLabels,
  sortAscending: false,
};

function mapStateToProps(state) {
  return {
    userId: _.get(state, 'user.id'),
    groupId: _.get(state, 'session.groupId'),
    draftReferrals: _.get(state, 'dashboard.draftReferrals', {}),
    filters: _.get(state, 'dashboard.draftReferrals.filters', {}),
    isFetching: _.get(state, 'dashboard.draftReferrals.isFetching', false),
    isCC: _.wget(state, 'session.isCoordinationGroup', false),
    serviceTypes: _.wget(state, 'serviceTypes.allNetworks', []),
    refetch: _.get(state, 'dashboard.refetch'),
    token: _.get(state, 'session.token', ''),
    labels: labelCustomization(state),
    sortAscending: crtb1290SortReferralsByOldest(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ fetchDashboardCases, fetchProvidersUserCore }, dispatch),
    dispatch,
  };
}

DraftReferrals.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DraftReferrals);
