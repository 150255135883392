import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { useCurrentPayerId } from 'common/contexts/CurrentProviderContext';
import { useFind } from 'src/api/APIHooks';
import { Card } from 'src/common/Card';
import { Spinner } from 'src/common/spinners';
import { Drawer } from 'src/common/Drawer';
import {
  EnrollmentDrawerDetails,
  EnrollmentsTable,
  EnrollmentPagination,
} from '../components';
import getSort from '../utils/getSort';
import FilterSelectors from '../components/FilterSelectors';
import {
  useEnrollmentDrawer,
  useEnrollmentPagination,
  useEnrollmentFilters,
  useEnrollmentSorting,
} from '../hooks';
import NoEnrollmentsFoundCard from '../components/NoEnrollmentsMessage/NoEnrollmentsFoundCard';

const getISOStringForNowMinusDays = (daysToSubtract = 0) => {
  const date = new Date();
  date.setMinutes(0, 0, 0);
  date.setDate(date.getDate() - daysToSubtract);
  return date.toISOString();
};

export const EnrollmentWorkqueue = ({
  enrollmentStatus,
  showEnrollmentAging,
}) => {
  const paginationProps = useEnrollmentPagination();
  const currentPayerId = useCurrentPayerId();
  const {
    filterValues,
    filterOptions,
    isFiltered,
    resetFilters,
    setRequestTypeFilter,
    setPlanFilter,
    setDaysSinceLastUpdatedFilter,
  } = useEnrollmentFilters();

  const sortProps = useEnrollmentSorting({ resetPagination: paginationProps.resetPagination });
  const { isLoading, data } = useFind(
    'enrollment_requests',
    {
      enrollment_status: enrollmentStatus,
      'plan.payer': currentPayerId,
      request_type: filterValues.requestType,
      plan: filterValues.plan,
      updated_after: filterValues.daysSinceLastUpdated ?
        getISOStringForNowMinusDays(filterValues.daysSinceLastUpdated) :
        undefined,
    },
    {
      page: {
        number: paginationProps.pageNumber,
        size: paginationProps.pageSize,
      },
      queryConfig: {
        enabled: !!currentPayerId,
        placeholderData: undefined,
      },
      sort: getSort(sortProps),
    },
  );

  const enrollmentRequests = get(data, 'data.data', []);
  const totalItemCount = get(data, 'data.paging.total_count', 0);
  const totalPageCount = get(data, 'data.paging.total_pages', 0);

  const drawerProps = useEnrollmentDrawer({
    enrollmentRequests,
    pageNumber: paginationProps.pageNumber,
    pageSize: paginationProps.pageSize,
    setPageNumber: paginationProps.setPageNumber,
    totalPageCount,
  });

  return (
    <div className="divide-y divide-solid divide-dark-border-blue">
      <div className="p-6 pt-8 bg-white">
        <h1 className="text-text-blue font-medium-font text-2xl">Enrollment Requests</h1>
      </div>
      <div className="bg-light-border-grey px-6 py-4">
        <FilterSelectors
          filterValues={filterValues}
          filterOptions={filterOptions}
          resetFilters={resetFilters}
          onUpdateFilter={() => paginationProps.resetPagination()}
          setRequestTypeFilter={setRequestTypeFilter}
          setPlanFilter={setPlanFilter}
          setDaysSinceLastUpdatedFilter={setDaysSinceLastUpdatedFilter}
        />
      </div>
      <div className="px-6 py-4">
        {/* eslint-disable no-nested-ternary */}
        {isLoading ? (
          <div role="alert"><Spinner /></div>
        ) : (
          !isEmpty(enrollmentRequests) ? (
            <>
              <Card className="mb-6">
                <EnrollmentsTable
                  enrollmentRequests={enrollmentRequests}
                  openDrawer={drawerProps.openDrawer}
                  showEnrollmentAging={showEnrollmentAging}
                  {...sortProps}
                />
              </Card>
              <EnrollmentPagination
                {...paginationProps}
                totalItemCount={totalItemCount}
                totalPageCount={totalPageCount}
              />
            </>
          ) : (
            <NoEnrollmentsFoundCard resetFilters={resetFilters} isFiltered={isFiltered} status={enrollmentStatus} />
          )
        )}
        {/* eslint-enable no-nested-ternary */}
        <Drawer open={drawerProps.open} setOpen={drawerProps.setOpen}>
          {
            isLoading || !drawerProps.clickedRequest ? (
              <div className="pt-8" role="alert"><Spinner /></div>
            ) : (
              <EnrollmentDrawerDetails
                getNextRequest={drawerProps.getNextRequest}
                getPrevRequest={drawerProps.getPrevRequest}
                enrollmentRequest={drawerProps.clickedRequest}
                setDrawerOpen={drawerProps.setOpen}
              />
            )
          }
        </Drawer>
      </div>
    </div>
  );
};

EnrollmentWorkqueue.propTypes = {
  enrollmentStatus: PropTypes.string.isRequired,
  showEnrollmentAging: PropTypes.bool,
};

EnrollmentWorkqueue.defaultProps = {
  showEnrollmentAging: false,
};

export default EnrollmentWorkqueue;
