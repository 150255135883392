import React from 'react';
import {
  NotificationPreferencesReduxForm,
} from 'src/components/NotificationPreferences/components/NotificationPreferencesForm';

export const NotificationPreferences = () => {
  const props = {
    form: 'NotificationPreferencesForm',
  };
  return (
    <NotificationPreferencesReduxForm {...props} />
  );
};

export default NotificationPreferences;
