import React from 'react';
import PropTypes from 'prop-types';
import { capitalize, get, isEmpty } from 'lodash';
import pluralize from 'pluralize';
import { feeSchedulePrograms as fspUtils } from '@unite-us/client-utils';
import { useFind, useFindRecord, usePopulate, usePopulateMemo } from 'api/APIHooks';
import { Card } from 'common/Card';
import { LineItem } from 'common/display/LineItem';
import { dollarAmount } from 'common/display/Money/DollarAmount';
import { Spinner } from 'common/spinners';
import formatDate from 'common/utils/Dates/formatDate';
import formatShortDate from 'common/utils/Dates/formatShortDate';
import getTimeDifference from 'common/utils/Dates/getTimeDifference';
import { AUTHORIZATION_STATUSES } from 'src/common/Status/authorizationConstants';
import moment from 'moment';
import { getCapInformation } from 'components/ServiceAuthorization/utils';
import ServiceAuthorizationStatusIndicator from '../components/ServiceAuthorizationStatusIndicator';
import ServiceAuthorizationDetailsActionBar from '../components/ServiceAuthorizationDetailsActionBar';
import ServiceAuthorizationAcceptedCard from '../components/ServiceAuthorizationAcceptedCard';
import ServiceAuthorizationRejectionCard from '../components/ServiceAuthorizationRejectionCard';
import AssessmentFormsCard from '../components/AssessmentFormsCard';
import useDateRequested from '../hooks/useDateRequested';
import ClientServiceUtilizationCard from '../components/ClientServiceUtilizationCard';
import ReferralDetailsCard from '../components/ReferralDetailsCard';
import ClientDetailsCard from '../components/ClientDetailsCard';

const VerticalDivider = () => <div className="border-l border-dark-fill-blue border-solid" />;

const ServiceAuthorizationDetails = ({ params }) => {
  const defaultConfig = { queryConfig: { placeholderData: undefined } };
  const { isLoading, data } = useFindRecord('service_authorization', params.id, defaultConfig);
  const authorization = get(data, 'data.data', {});
  const fspQuery = usePopulate('fee_schedule_program', 'fee_schedule_program', authorization, defaultConfig);
  const personQuery = usePopulate('person', 'person', authorization, defaultConfig);
  const caseQuery = usePopulate('case', 'case', authorization, defaultConfig);
  const requesterQuery = usePopulate('requester', 'employee', authorization, defaultConfig);
  const providerRequesterQuery = usePopulate('provider', 'provider', authorization.requester, defaultConfig);
  const caseId = get(authorization, 'case.id', null);

  const referralsQuery = useFind(
    'referral',
    { case: caseId },
    { queryConfig: { enabled: Boolean(caseId), placeholderData: undefined } },
  );
  const { data: referralsData } = referralsQuery;
  const referrals = get(referralsData, 'data.data', {});

  const refQueryConfig = { queryConfig: { enabled: !isEmpty(referrals), placeholderData: undefined } };
  const recNetworkQuery = usePopulate('receiving_network', 'network', referrals, refQueryConfig);
  const recOrgQuery = usePopulate('receiving_provider', 'provider', referrals, refQueryConfig);
  const recProgramQuery = usePopulate('receiving_program', 'program', referrals, refQueryConfig);

  const isMemoLoading = usePopulateMemo(data, [
    fspQuery, personQuery, caseQuery, requesterQuery, providerRequesterQuery,
  ], true);
  const isReferralsMemoLoading = usePopulateMemo(referralsData, [
    referralsQuery, recNetworkQuery, recOrgQuery, recProgramQuery,
  ], true);

  const dateRequested = useDateRequested(params.id);
  const dateRequestedStartsAt = formatShortDate(authorization.requested_starts_at);
  const dateRequestedEndsAt = formatShortDate(authorization.requested_ends_at);
  const feeScheduleProgram = get(authorization, 'fee_schedule_program', {});
  const isFspLoaded = !!feeScheduleProgram?.name;
  const person = get(authorization, 'person', {});

  const authorizedSpendFilters = {
    fee_schedule_program: feeScheduleProgram.id,
    person: person.id,
    requested_starts_at: moment(authorization.requested_starts_at).toISOString(),
    requested_ends_at: moment(authorization.requested_ends_at).toISOString(),
  };

  const authorizedSpendsQuery = useFind(
    'authorized_spend',
    authorizedSpendFilters,
    { queryConfig: { enabled: Boolean(feeScheduleProgram.id), placeholderData: undefined } },
    );
  const { data: authorizedSpendsData } = authorizedSpendsQuery;
  const capInfo = getCapInformation(feeScheduleProgram, get(authorizedSpendsData, 'data.meta'));
  const authorizedSpends = get(authorizedSpendsData, 'data.data', []);
  const totalRequestedSpend = get(authorizedSpendsData, 'data.meta.total_requested_spend', 0);

  const isSingleDateRequested = authorization.requested_starts_at === authorization.requested_ends_at;
  const isCostBased = ['cost_based_reimbursement', 'funds_distributed'].includes(feeScheduleProgram.payment_type);
  const isDataLoading = isLoading || isMemoLoading || isReferralsMemoLoading;

  const formattedAmountAvailable = capInfo.hasCap && capInfo.formattedAvailableAmount;

  if (isDataLoading) {
    return <Spinner className="mt-4" />;
  }

  return (
    <div>
      <ServiceAuthorizationDetailsActionBar
        authorization={authorization}
      />
      <div className="p-6 space-y-6">
        {authorization.state === AUTHORIZATION_STATUSES.accepted && (
          <ServiceAuthorizationAcceptedCard authorization={authorization} />
        )}
        {authorization.state === AUTHORIZATION_STATUSES.rejected && (
          <ServiceAuthorizationRejectionCard authorization={authorization} />
        )}
        <Card className="bg-white" data-testid="auth-details-card">
          <div className="px-6 py-4 space-y-2">
            <h1 className="text-text-blue font-heavy-font text-2xl leading-8">
              Authorization Request #{authorization.short_id}
            </h1>
            <div className="flex space-x-6 items-center">
              <p className="text-text-blue text-sm leading-5">Last updated: {formatDate(authorization.updated_at)}</p>
              <ServiceAuthorizationStatusIndicator
                status={authorization.state}
                autoApproved={authorization.auto_approved}
              />
            </div>
          </div>
          <div className="flex flex-col py-4 bg-gray-100">
            <div className="flex px-6 pb-4 space-x-12">
              <div className="w-1/2">
                <dl className="m-0 grid grid-cols-2 gap-x-8 gap-y-2">
                  <LineItem field="Amount Requested" dataTestElement="amount-requested">
                    {authorization.requested_unit_amount ?
                      // eslint-disable-next-line max-len
                      `${authorization.requested_unit_amount} ${pluralize(feeScheduleProgram.unit, authorization.requested_unit_amount)}` :
                      dollarAmount(authorization.requested_cents, true)}
                  </LineItem>
                  {isSingleDateRequested ? (
                    <LineItem field="Service Delivery Date Requested" dataTestElement="service-delivery-date">
                      {dateRequestedEndsAt}
                    </LineItem>
                  ) : (
                    <LineItem field="Service Delivery Dates Requested" dataTestElement="service-delivery-dates">
                      {dateRequestedStartsAt} - {dateRequestedEndsAt}
                    </LineItem>
                  )}
                  <LineItem
                    field="Submitted By"
                    dataTestElement="submitted-by"
                    isLoaded={!isEmpty(authorization.requester) && !isEmpty(authorization.requester.provider)}
                  >
                    {authorization.requester?.full_name}
                    <br />
                    ({authorization.requester?.provider?.name})
                  </LineItem>
                  <LineItem
                    field="Date of Request"
                    isLoaded={!!dateRequested || authorization?.auto_approved}
                    dataTestElement="request-date"
                  >
                    {authorization?.auto_approved ?
                    formatShortDate(authorization.updated_at) :
                    formatShortDate(dateRequested)}
                  </LineItem>
                  {authorization.state === 'requested' && (
                    <LineItem field="Time in Queue" dataTestElement="time-in-queue">
                      {getTimeDifference(authorization.updated_at)}
                    </LineItem>
                  )}
                </dl>
              </div>
              <VerticalDivider />
              <div className="w-1/2">
                <dl className="m-0 grid grid-cols-2 gap-x-8 gap-y-2">
                  {/* eslint-disable-next-line max-len */}
                  <LineItem field="Contracted Program" isLoaded={isFspLoaded} dataTestElement="request-contracted-program">
                    {feeScheduleProgram.name}
                  </LineItem>
                  <LineItem field="Payment Approach" isLoaded={isFspLoaded} dataTestElement="payment-approach">
                    {fspUtils.PAYMENT_TYPES[feeScheduleProgram.payment_type]}
                  </LineItem>
                  {!isCostBased && (
                    <>
                      <LineItem field="Unit" isloaded={isFspLoaded} dataTestElement="request-unit">
                        {capitalize(feeScheduleProgram.unit)}
                      </LineItem>
                      <LineItem field="Rate" isLoaded={isFspLoaded} dataTestElement="request-rate">
                        {feeScheduleProgram.unit_rate ?
                          `${dollarAmount(feeScheduleProgram.unit_rate, true)} per ${feeScheduleProgram.unit}` :
                          'N/A'}
                      </LineItem>
                    </>
                  )}
                  <LineItem field="Program Cap" isLoaded={isFspLoaded} dataTestElement="request-cap">
                    {/* eslint-disable-next-line react/no-danger */}
                    <div dangerouslySetInnerHTML={{ __html: feeScheduleProgram.cap_information || 'N/A' }} />
                  </LineItem>
                </dl>
              </div>
            </div>
          </div>
        </Card>
        <ClientServiceUtilizationCard
          feeScheduleProgram={feeScheduleProgram}
          serviceAuthorization={authorization}
          authorizedSpends={authorizedSpends}
          totalRequestedSpend={totalRequestedSpend}
          capInfo={capInfo}
          amountAvailable={formattedAmountAvailable}
        />
        { !isEmpty(referrals) && (
          <ReferralDetailsCard
            referrals={referrals}
            serviceAuthorization={authorization}
          />
        )}
        <ClientDetailsCard
          person={person}
          serviceAuthorization={authorization}
        />
        <AssessmentFormsCard serviceAuthorization={authorization} />
      </div>
    </div>
  );
};

ServiceAuthorizationDetails.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default ServiceAuthorizationDetails;
