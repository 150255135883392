import { get, isEmpty, trim } from 'lodash';
import isDraft from "./isDraft";
import { DRAFT_REFERRAL_DISPLAY, REFERRAL_DISPLAY } from "./constants";
function referralType(referral) {
  return isDraft(referral) ? DRAFT_REFERRAL_DISPLAY : REFERRAL_DISPLAY;
}
function getReferralName(referral) {
  var name = get(referral, 'service_type.name', '');
  var serviceTypeName = isEmpty(name) ? '' : name;
  return trim("".concat(serviceTypeName, " ").concat(referralType(referral)));
}
export default getReferralName;