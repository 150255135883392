import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { validations } from '@unite-us/client-utils';
import { validateReduxForm } from 'common/form';
import { fetchGroupContact } from 'actions/Contact/Group';
import {
  DateField,
  InputField,
  Button,
} from '@unite-us/ui';
import today from 'common/utils/today';
import Header from './Header';
import searchContact from '../actions/SearchContact';
import retrieveSearchedContacts from '../actions/RetrieveSearchedContacts';
import retrieveSelectedContact from '../actions/RetrieveSelectedContact';
import { CONTACT_SEARCH_FORM } from '../constants';

export const fields = ['first_name', 'last_name', 'date_of_birth'];

class Search extends Component {
  static handleEnter(e) {
    if (e.key === 'Enter') {
      const searchRecordsButton = document.getElementById('search-records-btn');
      if (searchRecordsButton) {
        searchRecordsButton.focus();
      }
    }
  }

  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keydown', Search.handleEnter);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', Search.handleEnter);
  }

  onSubmit(formData) {
    return this.props.searchContact(this.props.groupId, formData)
      .then((response) => this.props.onComplete(response.data.data, formData, () => {
        if (this.props.resetAfterSubmit) {
          this.props.resetForm();
        }
      }));
  }

  render() {
    const {
      fields: { first_name, last_name, date_of_birth },
      handleSubmit,
      header,
      mainHeader,
      pristine,
      registerField,
      subHeader,
      submitting,
      className,
    } = this.props;
    return (
      <div {...(className ? { className } : {})}>
        <Header
          header={header}
          mainHeader={mainHeader}
          subHeader={subHeader}
        />
        <form className="row" onSubmit={handleSubmit(this.onSubmit)}>
          <div className="col-md-6">
            <InputField
              ref={registerField}
              field={first_name}
              label="First Name"
              id="first-name"
              inline={false}
              validations={validations.isRequired}
              required
            />
          </div>
          <div className="col-md-6">
            <InputField
              ref={registerField}
              field={last_name}
              label="Last Name"
              id="last-name"
              inline={false}
              validations={validations.isRequired}
              required
            />
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-4">
                <DateField
                  ref={registerField}
                  field={date_of_birth}
                  id="date-of-birth"
                  inline={false}
                  label="Date of Birth"
                  validations={validations.isRequired}
                  maxDate={today()}
                  required
                />
              </div>
            </div>
          </div>
          <footer className="col-md-12 referral-footer">
            <Button
              id="search-records-btn"
              className="search-records-btn"
              onClick={handleSubmit(this.onSubmit)}
              label="Search Our Records"
              primary
              disabled={pristine || submitting}
              style={{ float: 'right' }}
              type="submit"
            />
          </footer>
        </form>
      </div>
    );
  }
}

Search.propTypes = {
  className: PropTypes.string,
  fetchGroupContact: PropTypes.func.isRequired,
  fetchGroupContacts: PropTypes.func,
  fields: PropTypes.object,
  groupId: PropTypes.string,
  handleSubmit: PropTypes.func,
  header: PropTypes.string.isRequired,
  mainHeader: PropTypes.string,
  onComplete: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  registerField: PropTypes.func.isRequired,
  resetAfterSubmit: PropTypes.bool,
  resetForm: PropTypes.func.isRequired,
  retrieveSearchedContacts: PropTypes.func.isRequired,
  retrieveSelectedContact: PropTypes.func.isRequired,
  searchContact: PropTypes.func.isRequired,
  searchedContacts: PropTypes.array.isRequired,
  subHeader: PropTypes.string.isRequired,
  submitting: PropTypes.bool,
};

Search.defaultProps = {
  resetAfterSubmit: false,
};

function mapStateToProps(state) {
  const { session: { groupId }, searchedContacts } = state;
  return {
    groupId,
    searchedContacts,
  };
}

export default validateReduxForm(
  {
    form: CONTACT_SEARCH_FORM,
    fields,
    destroyOnUnmount: false,
  },
  mapStateToProps,
  {
    fetchGroupContact,
    retrieveSearchedContacts,
    retrieveSelectedContact,
    searchContact,
  },
)(Search);
