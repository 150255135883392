import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { SelectField } from '@unite-us/ui';
import { validations } from '@unite-us/client-utils';
import { ScrollFieldIntoView } from 'common/form';

const BestTimeToContactField = ({
  field,
  id,
  inline,
  label,
  options,
  registerField,
  required,
}) => (
  <ScrollFieldIntoView>
    <SelectField
      className="best-time-to-contact-field"
      field={field}
      forceObjectValue
      id={id}
      inline={inline}
      label={label}
      labelKey="display_name"
      options={options}
      ref={registerField}
      required={required}
      shouldSort={false}
      validations={required && validations.isRequired}
      valueKey="value"
    />
  </ScrollFieldIntoView>
);

BestTimeToContactField.propTypes = {
  field: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  registerField: PropTypes.func,
  required: PropTypes.bool,
};

BestTimeToContactField.defaultProps = {
  inline: false,
  label: 'Best Time To Contact',
  registerField: noop,
  required: false,
};

export default BestTimeToContactField;
