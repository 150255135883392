var DISTANCE_OPTIONS = [{
  label: '1 Mile',
  value: '1'
}, {
  label: '5 Miles',
  value: '5'
}, {
  label: '10 Miles',
  value: '10'
}, {
  label: '25 Miles',
  value: '25'
}, {
  label: '50 Miles',
  value: '50'
}, {
  label: '100 Miles',
  value: '100'
}, {
  label: 'Any Distance',
  value: 'any'
}];
var ADDRESS_TYPES = {
  client: 'client',
  ours: 'ours',
  other: 'other'
};
export { DISTANCE_OPTIONS, ADDRESS_TYPES };