import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  compact,
  difference,
  get,
  isEmpty,
  isError,
  maxBy,
  noop,
  omitBy,
} from 'lodash';
import { theme } from 'src/../tailwind.config';
import { validateReduxForm } from 'common/form';
import AddressField from 'common/form/AddressField/AddressField';
import CurrencyField from 'common/form/CurrencyField';
import { SHOW_STREET_CITY_STATE_POSTAL_CODE } from 'common/form/AddressField/constants';
import callOrLog from 'common/utils/callOrLog';
import datesInRange from 'common/utils/Dates/datesInRange';
import nearestDay from 'common/utils/Dates/nearestDay';
import {
  forbidContractedServiceCreationForUnconsentedClient,
} from 'src/common/utils/FeatureFlags/flags';
import { SERVICES_PROVIDED } from 'common/utils/EventTracker/utils/eventConstants';
import {
  DateField, DateTimeField, InputField, SelectField, TextField,
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  decodeHTML,
} from '@unite-us/ui';
import { NoteDisclosure, validations } from '@unite-us/client-utils';
import {
  createFeeScheduleProvidedService,
  fetchProvidedServices,
  updateFeeScheduleProvidedService,
} from 'actions/Case/Contact/Group';
import Notifier from 'common/helpers/Notifier';
import { createFileUpload, deleteFileUpload, fetchFileUploads } from 'src/actions/FileUploads';
import {
  feeScheduleProvidedServiceInitialValues,
  validateUnitAmount,
} from 'src/common/form/Interactions/utils';
import moment from 'moment';
import { Spinner } from 'common/spinners';
import FormInteractionsButtons from './FormInteractionsButtons';
import InteractionNoteField from './InteractionNoteField';
import PaymentsTrackServiceDetailsForm from './PaymentsTrackServiceDetailsForm';
import PaymentsTrackServiceFundsDistributed from './PaymentsTrackServiceFundsDistributed';
import PaymentsSupportingDocuments from './PaymentsSupportingDocuments';
import ProcedureCodeSelectField from './ProcedureCodeSelectField';
import ContractedServiceAuthorizationDetails from './ContractedServiceAuthorizationDetails';
import './PaymentsTrackService.scss';

const METAFIELD_COMPONENTS = {
  address: AddressField,
  date: DateField,
  datetime: DateTimeField,
  dropdown: SelectField,
  multipledropdown: SelectField,
  money: CurrencyField,
  number: InputField,
  text: InputField,
  textarea: TextField,
  uuid: InputField,
};

const PAYMENTS_TRACK_SERVICE_FORM = 'paymentsTrackService';
const DATE_SELECTOR_OPTIONS = [
  { label: 'Single Date', value: 'Single Date' },
  { label: 'Date Range', value: 'Date Range' },
];

const FUNDS_DISTRIBUTED = 'funds_distributed';

const CLIENT_HAS_NOT_CONSENTED_ERROR = 'Client must be consented to submit for review.';
const INSURANCE_ID_ERROR =
  'You must enter insurance information on the Client\'s Profile before you can submit for review.';

const validateInsuranceId = (value) => (
  validations.isRequired(value, INSURANCE_ID_ERROR)
);

const validateField = (required, value = {}, type) => {
  if (type === 'uuid' && !isEmpty(value.value)) {
    return validations.isValidUUID;
  }
  if (required) {
    return validations.isRequired;
  }
  return noop;
};

const HelpText = ({ text }) => (
  /* eslint-disable react/no-danger */
  <div
    className="payments-track-service__help-text"
    dangerouslySetInnerHTML={{ __html: text }}
  />
  /* eslint-enable react/no-danger */
);
HelpText.propTypes = {
  text: PropTypes.string.isRequired,
};

const SectionSeparator = ({ className }) => (
  <div className={cx(className, 'border-t border-solid border-dark-fill-blue -mx-2 lg:-mx-8 xl:-mx-16')} />
);
SectionSeparator.propTypes = {
  className: PropTypes.string,
};
SectionSeparator.defaultProps = {
  className: '',
};

function requestDateOutOfRange(requestDate, startDate, endDate) {
  return (!!requestDate) &&
    (
      (startDate && moment.unix(requestDate) < moment(startDate)) ||
      (endDate && moment.unix(requestDate) > moment(endDate))
    );
}
export class PaymentsTrackServiceOld extends Component {
  constructor(props) {
    super(props);

    this.state = { enforceRequiredFields: true };

    this.fetchFiles = this.fetchFiles.bind(this);
    this.formatProvidedServiceRequestParams = this.formatProvidedServiceRequestParams.bind(this);
    this.onDraftOrSubmit = this.onDraftOrSubmit.bind(this);
    this.onSaveDraftBillable = this.onSaveDraftBillable.bind(this);
    this.updateFiles = this.updateFiles.bind(this);
    this.renderMetafields = this.renderMetafields.bind(this);
    this.transformMetadataFormValues = this.transformMetadataFormValues.bind(this);
    this.draftOrSubmitProvidedService = this.draftOrSubmitProvidedService.bind(this);
    this.unitAmountValidations = this.unitAmountValidations.bind(this);
    this.insuranceExpired = false;
  }

  componentDidMount() {
    this.fetchFiles();
  }

  componentWillUnmount() {
    this.props.resetForm();
  }

  onSaveDraftBillable(event) {
    /*
      Don't enforce validations when the user submits the contracted service as a draft
      for billable fee schedule programs.
    */
    this.setState({ enforceRequiredFields: false }, () => {
      this.props.handleSubmit((formValues) => this.onDraftOrSubmit(formValues, 'active'))(event);
    });
  }

  onDraftOrSubmit(formValues, providedServiceState = 'active') {
    const params = this.formatProvidedServiceRequestParams(formValues.provided_service, providedServiceState);
    const metadataFormValues = formValues.provided_service.metadata;

    callOrLog(() => this.context.eventTracker(SERVICES_PROVIDED.clickedSaveNote));

    // Edit a provided service
    if (this.props.isEditing) {
      return this.draftOrSubmitProvidedService({
        metadataFormValues,
        params,
        providedServiceId: this.props.providedServiceId,
      });
    }

    // Create a new provided service
    return this.props.createFeeScheduleProvidedService(params)
      .then((payload) => {
        if (payload) {
          return this.draftOrSubmitProvidedService({
            metadataFormValues,
            params,
            providedServiceId: get(payload, 'data.data.id', ''),
          });
        }
        return true;
      });
  }

  async draftOrSubmitProvidedService({
    metadataFormValues,
    params,
    providedServiceId,
  }) {
    const {
      successFileResponses,
      failedFileNames,
      failedDeletedFileNames,
    } = await this.updateFiles(metadataFormValues, providedServiceId);

    const providedService = {
      ...params.providedService,
      metadata: params.providedService.metadata ?
        [...params.providedService.metadata, ...successFileResponses] :
        successFileResponses,
    };

    if (failedFileNames.length || failedDeletedFileNames.length) {
      const successfulFileIdsExist = successFileResponses.find(({ value }) => value && value.length);

      if (successfulFileIdsExist) {
        await this.props.updateFeeScheduleProvidedService({
          ...params,
          providedService: { ...providedService, state: 'active' },
          id: providedServiceId,
          showNotification: false,
        });
      }

      if (!isEmpty(failedFileNames) && !isEmpty(failedDeletedFileNames)) {
        Notifier.dispatch(
          'error',
          `There was a problem uploading and deleting some of your file(s). Your contracted service has been saved.
          Try uploading and deleting your file(s) again: ${failedFileNames}, ${failedDeletedFileNames}`,
        );
      } else if (!isEmpty(failedDeletedFileNames)) {
        Notifier.dispatch(
          'error',
          `There was a problem deleting some of your file(s). Your contracted service has been saved.
          Try deleting your file(s) again: ${failedDeletedFileNames}`,
        );
      } else if (!isEmpty(failedFileNames)) {
        Notifier.dispatch(
          'error',
          `There was a problem uploading some of your file(s). Your contracted service has been saved.
          Try uploading your file(s) again: ${failedFileNames}`,
        );
      }

      this.props.resetForm();
      this.props.cancelFunction();
      return null;
    }

    await this.props.updateFeeScheduleProvidedService({ ...params, providedService, id: providedServiceId });
    this.props.resetForm();
    this.props.cancelFunction();

    return null;
  }

  async updateFiles(metadataFormValues, providedServiceId) {
    const fileMetadata = metadataFormValues.filter(({ field }) => (
      this.props.metafields.find(({ field: metafield, type }) => (metafield === field) && type === 'file')
    ));

    const fileResponses = await Promise.all(
      fileMetadata.map(async ({ field, value = [] }) => {
        const filesToCreate = value.filter((file) => file.tempId);
        const filesToMaintain = value.filter((file) => !file.tempId).map((file) => file.id);
        const previousMetadata = (this.props.providedService.metadata || []).find((md) => md.field === field);
        const previousMetadataValue = previousMetadata && previousMetadata.value;
        const filesToDelete = difference(previousMetadataValue, filesToMaintain);
        const createResponses = filesToCreate.map(async ({ file }) => {
          const response = await this.props.createFileUpload({
            file,
            recordType: 'provided_services',
            recordId: providedServiceId,
          });

          return isError(response) ? { file, response } : response;
        });
        const deleteResponses = filesToDelete.map(async (fileId) => {
          const response = await this.props.deleteFileUpload({
            fileId,
            recordType: 'provided_services',
            recordId: providedServiceId,
          });

          return isError(response) ? { file: this.props.fileUploads[fileId], response } : null;
        });

        const allResponses = await Promise.all([
          ...createResponses,
          ...deleteResponses,
          ...filesToMaintain,
        ]);

        return { field, value: compact(allResponses) };
      }),
    );

    let failedFileNames = '';
    let failedDeletedFileNames = '';

    const successFileResponses = fileResponses.map(({ field, value }) => {
      const failedDeletedFileIds = [];
      const fileIds = value.filter((fileValue) => {
        if (fileValue.file) {
          if (fileValue.file.name) {
            failedFileNames += failedFileNames ? `, ${fileValue.file.name}` : fileValue.file.name;
          }
          if (fileValue.file.filename) {
            failedDeletedFileNames += failedDeletedFileNames ? `, ${fileValue.file.filename}` : fileValue.file.filename;
            failedDeletedFileIds.push(fileValue.file.id);
          }
        }
        return !fileValue.file;
      });

      return { field, value: [...fileIds, ...failedDeletedFileIds] };
    });

    return { successFileResponses, failedFileNames, failedDeletedFileNames };
  }

  formatProvidedServiceRequestParams(providedServiceFormValues, providedServiceState) {
    const {
      contactId,
      groupId,
      programId,
      serviceCaseId,
      planId,
    } = this.props;

    const {
      distribution_reason,
      metadata,
      payment_method,
      period_of_service,
      service_end_date,
      unit_amount,
      actual_unit_amount,
      procedure_code_rows,
    } = providedServiceFormValues;

    let procedure_codes = [];
    let procedure_code_modifiers = [];
    if (procedure_code_rows) {
      procedure_codes = procedure_code_rows.map((row) => row?.procedure_code);
      procedure_code_modifiers = procedure_code_rows.map((row) => row?.procedure_code_modifier);
    }

    // ** NOTE ** If form value comes from a SelectField, make sure to decodeHTML

    const transformedMetaData = !isEmpty(metadata) ? metadata.map(this.transformMetadataFormValues) : [];
    const transformedEndDate = period_of_service === 'Single Date' ? null : service_end_date;
    const decodedDistributionReason = distribution_reason ? decodeHTML(distribution_reason) : null;
    const decodedPaymentMethod = payment_method ? decodeHTML(payment_method) : null;

    return {
      providedService: {
        ...providedServiceFormValues,
        distribution_reason: decodedDistributionReason,
        metadata: compact(transformedMetaData),
        payment_method: decodedPaymentMethod,
        service_end_date: transformedEndDate,
        state: providedServiceState,
        unit_amount,
        actual_unit_amount,
        procedure_codes: compact(procedure_codes),
        procedure_code_modifiers: compact(procedure_code_modifiers),
      },
      caseId: serviceCaseId,
      contactId,
      groupId,
      programId,
      planId,
    };
  }

  transformMetadataFormValues({
    address, field, value,
  }) {
    const { metafields } = this.props;
    const metafield = metafields.find((mf) => mf.field === field);
    const compactAddress = omitBy(address, (addressValue) => isEmpty(addressValue));

    switch (metafield.type) {
      case 'address':
        return {
          field,
          value: !isEmpty(compactAddress) ? compactAddress : null,
        };
      case 'datetime':
      case 'date':
        return {
          field,
          value: value ? new Date(value * 1000).toISOString() : value,
        };
      case 'file':
        return null;
      case 'dropdown':
        return {
          field,
          value: value ? decodeHTML(value) : value,
        };
      default:
        return { field, value };
    }
  }

  unitAmountValidations(value) {
    const { enforceRequiredFields } = this.state;
    return validateUnitAmount(value, enforceRequiredFields);
  }

  fetchFiles() {
    const fetchInitialFileValues = this.props.fields.provided_service.metadata.some(
      (d) => !d.value?.initialValue || isEmpty(d.value?.initialValue),
    );

    if (fetchInitialFileValues) {
      const providedServiceId = this.props.fields.provided_service.id.value;
      this.props.fetchFileUploads(
        { recordId: providedServiceId, recordType: 'provided_service' },
      );
    }
  }

  findInsuranceByDateRange(insurances, start_date, end_date, ignoreSocialCareExpiredDates) {
    this.insuranceExpired = false;
    let candidateInsurances = insurances;
    // if there is a service authorization, force-use its insurance
    const {
      serviceAuthorization,
      setInferredPlanId,
    } = this.props;
    if (serviceAuthorization) {
      candidateInsurances = [insurances.find((ins) => ins.id === serviceAuthorization.insurance.id)];
    }
    // tier one: check insurance start and end dates:
    const validInsurancesForDateRange = candidateInsurances.filter((insurance) => {
      if (!insurance?.attributes?.enrolled_at) return false;
      const enrolled_at = nearestDay(moment(insurance.attributes.enrolled_at));
      const expired_at = insurance.attributes.expired_at && nearestDay(moment(insurance.attributes.expired_at));
      return datesInRange(enrolled_at, expired_at, start_date, end_date);
    });
    let latestStartingInsurance = maxBy(validInsurancesForDateRange, (ins) => ins?.attributes?.enrolled_at);

    // tier two: check only insurance start dates:
    if (ignoreSocialCareExpiredDates && !latestStartingInsurance) {
      const validInsurancesIncludingExpired = candidateInsurances.filter((insurance) => {
        if (!insurance?.attributes?.enrolled_at) return false;
        const enrolled_at = nearestDay(moment(insurance.attributes.enrolled_at));
        const expired_at = null;
        return datesInRange(enrolled_at, expired_at, start_date, end_date);
      });
      latestStartingInsurance = maxBy(validInsurancesIncludingExpired, (ins) => ins?.attributes?.enrolled_at);
      this.insuranceExpired = !!latestStartingInsurance;
    }

    setInferredPlanId(latestStartingInsurance?.relationships?.plan?.data?.id);
    return latestStartingInsurance;
  }

  validateInsuranceDates() {
    const {
      fields,
      socialInsurances: insurances,
      ignoreSocialCareExpiredDates,
      setInferredPlanId,
    } = this.props;
    const isSingleDateRequested = fields?.provided_service?.period_of_service?.value === 'Single Date';
    let start_date = fields?.provided_service?.service_start_date?.value;
    let end_date = isSingleDateRequested ? null : fields?.provided_service?.service_end_date?.value;

    if (start_date) {
      start_date = moment.unix(start_date);
      if (!end_date) {
        return !!this.findInsuranceByDateRange(insurances, start_date, start_date, ignoreSocialCareExpiredDates);
      }
      end_date = moment.unix(end_date);
      return !!this.findInsuranceByDateRange(insurances, start_date, end_date, ignoreSocialCareExpiredDates);
    }
    setInferredPlanId(null);
    return false;
  }

  renderMetafields() {
    const { fields, metafields, registerField } = this.props;
    return (
      metafields.map(({
        field: fieldKey,
        help_text,
        label,
        options,
        type,
        validations: metafieldValidations,
      }) => {
        const dropdownOptions = (options || []).map((option) => ({
          field: option,
          value: option,
        }));
        const field = fields.provided_service.metadata.find((md) => md.field.value === fieldKey);
        /*
          The metafield is required
           * if the validations required key is true AND
           * if enforceRequiredFields === true
              - the fee schedule program is non-billable or the user submits the contracted service
        */
        const required = get(metafieldValidations, 'required', false) && this.state.enforceRequiredFields;
        // eslint-disable-next-line react/no-danger
        const hint = isEmpty(help_text) ? '' : <HelpText text={help_text} />;
        const MetafieldComponent = METAFIELD_COMPONENTS[type];
        switch (type) {
          case 'file':
            return null;
          case 'address':
            return (
              <>
                <label htmlFor={`${fieldKey}-address-field`} className="ui-form-field__label">{label}</label>
                <MetafieldComponent
                  addressPath="provided_service.metadata[].address"
                  displayFieldSet={SHOW_STREET_CITY_STATE_POSTAL_CODE}
                  field={field.address}
                  forceRequired={required}
                  hideMailingField
                  id={`${fieldKey}-address-field`}
                  inline={false}
                  key={fieldKey}
                  labelStyle={{
                    color: theme.extend.colors['text-blue'],
                    fontFamily: 'Proxima Nova Medium',
                    fontSize: '13px',
                    textTransform: 'none',
                  }}
                  requireAddressLine1={required}
                  registerField={registerField}
                />
              </>
            );
          default:
            return (
              <MetafieldComponent
                field={field?.value}
                hint={hint}
                inline={false}
                key={fieldKey}
                label={label}
                labelKey="field"
                multiple={type === 'multipledropdown'}
                options={dropdownOptions}
                required={required}
                ref={registerField}
                type={type}
                validations={validateField(required, field?.value, type)}
                valueKey="value"
              />
            );
        }
      })
    );
  }

  render() {
    const {
      disallowContractedServiceForUnconsentedClient,
      distributionReasons,
      feeScheduleProgramName,
      fields,
      isCostType,
      handleSubmit,
      canInvoiceAboveRemainingAuthorizedAmount,
      ignoreSocialCareExpiredDates,
      insuranceRequired,
      isBillable,
      isEditing,
      isPersonConsented,
      metafields,
      paymentMethods,
      paymentType,
      placeOfServiceOptions,
      procedureCodesWithModifiers,
      registerField,
      submitting,
      zcodeOptions,
      serviceAuthorizationInvoiceInformation,
      serviceAuthorization,
    } = this.props;

    const canForbidContractedServiceCreationWhenNoConsent = disallowContractedServiceForUnconsentedClient &&
      !isPersonConsented;
    const { enforceRequiredFields } = this.state;
    const requiredMetaFieldFiles = metafields.filter(({ type }) => type === 'file');
    const hasAdditionalInfo = !isEmpty(metafields) ||
      !isEmpty(placeOfServiceOptions) ||
      zcodeOptions.length > 1 ||
      procedureCodesWithModifiers.length > 1;

    const insuranceDateNonValid = !this.validateInsuranceDates();

    const requestedValueNonValid = (serviceAuthorizationInvoiceInformation?.remainingAuthorizedAmount < 0 ||
      fields?.provided_service?.unit_amount?.value > serviceAuthorizationInvoiceInformation?.remainingAuthorizedAmount);

    const isSingleDateRequested = fields?.provided_service?.period_of_service?.value === 'Single Date';
    const requestedStartDate = fields?.provided_service?.service_start_date?.value;
    const requestedEndDate = isSingleDateRequested ? null : fields?.provided_service?.service_end_date?.value;
    const serviceAuthorizationStartDate = serviceAuthorizationInvoiceInformation?.approvedStartsAt;
    const serviceAuthorizationEndDate = serviceAuthorizationInvoiceInformation?.approvedEndsAt;
    const requestedDatesNonValid = (
      requestDateOutOfRange(requestedStartDate, serviceAuthorizationStartDate, serviceAuthorizationEndDate) ||
      requestDateOutOfRange(requestedEndDate, serviceAuthorizationStartDate, serviceAuthorizationEndDate)
    );

    return (
      <BaseCard className={isEditing ? 'payments-track-service-card--edit' : 'payments-track-service-card--new'}>
        <BaseCardHeader title={feeScheduleProgramName} />
        <BaseCardBody className="pt-8 pb-9">
          <form className="payments-track-service px-4 lg:px-16 xl:px-32" data-role="provided-service-form">
            {serviceAuthorization && (
              <>
                <h3 className="text-lg text-text-blue mb-4 leading-7">Authorization Details</h3>
                <ContractedServiceAuthorizationDetails {...serviceAuthorizationInvoiceInformation} />
                <SectionSeparator className="my-10" />
              </>
            )}
            <h3 className="text-lg text-text-blue mb-4 leading-7">Service Details</h3>
            {paymentType === FUNDS_DISTRIBUTED ? (
              <PaymentsTrackServiceFundsDistributed
                fields={fields}
                registerField={registerField}
                distributionReasons={distributionReasons}
                paymentMethods={paymentMethods}
              />
            ) : (
              <PaymentsTrackServiceDetailsForm
                enforceRequiredFields={enforceRequiredFields}
                feeScheduleProgramName={feeScheduleProgramName}
                fields={fields}
                canInvoiceAboveRemainingAuthorizedAmount={canInvoiceAboveRemainingAuthorizedAmount}
                ignoreSocialCareExpiredDates={ignoreSocialCareExpiredDates}
                insuranceDateNonValid={insuranceDateNonValid}
                insuranceExpired={this.insuranceExpired}
                requestedDatesNonValid={requestedDatesNonValid}
                isBillable={isBillable}
                isCostType={isCostType}
                paymentType={paymentType}
                registerField={registerField}
                unitAmountValidations={this.unitAmountValidations}
                options={DATE_SELECTOR_OPTIONS}
                requestedValueNonValid={requestedValueNonValid}
              />
            )}
            <div id="additional-information" className={cx((!hasAdditionalInfo) && 'hidden')}>
              <SectionSeparator className="mt-1 mb-10" />
              <h3 className="text-lg text-text-blue normal-case tracking-normal leading-7 mb-4">
                Additional Information
              </h3>
              {zcodeOptions.length > 0 && (
                <SelectField
                  id="provided-service-zcodes"
                  field={fields.provided_service.zcodes}
                  label="Diagnosis Code (Z-Code)"
                  multiple
                  options={zcodeOptions}
                  placeholder="Z-codes"
                  autoSelectValue
                  className={zcodeOptions.length <= 1 ? 'hidden' : 'mt-5 mb-10'}
                  ref={registerField}
                  validations={(val) => validations.isRequired(val)}
                  required
                />
              )}
              {placeOfServiceOptions.length > 0 && (
                <SelectField
                  id="provided-service-place_of_service"
                  field={fields.provided_service.place_of_service}
                  label="Place of Service"
                  options={placeOfServiceOptions}
                  placeholder="Place of Service"
                  className="mt-5 mb-10"
                  required
                  ref={registerField}
                  validations={(val) => validations.isRequired(val)}
                />
              )}
              {!isEmpty(procedureCodesWithModifiers) && (
                <ProcedureCodeSelectField
                  procedureCodesWithModifiers={procedureCodesWithModifiers}
                  fields={fields}
                  registerField={registerField}
                />
              )}

              {!isEmpty(metafields) && this.renderMetafields()}
            </div>
            <SectionSeparator className="mt-5 mb-10" />
            <div className="payments-track-service__notes">
              <InteractionNoteField
                afterLabelContent={<NoteDisclosure />}
                field={fields.provided_service.note}
                label={(
                  <h3 className="text-lg text-text-blue normal-case tracking-normal leading-7 text-left">
                    Note
                  </h3>
                )}
                registerField={registerField}
                required={false}
              />
            </div>
            <div className={cx(submitting && 'relative z-0')}>
              {!isEmpty(requiredMetaFieldFiles) && (
                <>
                  <SectionSeparator className="mt-4 mb-10" />
                  <h3 className="text-lg text-text-blue mb-4 leading-7">
                    Supporting Documents
                  </h3>
                  <p className="mb-10 text-13px">
                    Upload your documents from your device. Acceptable formats are .CSV, .DOCX, .PDF, .JPG, or .PNG
                  </p>
                  <div className="space-y-8">
                    {
                      requiredMetaFieldFiles.map(({ field: fieldName, label, validations: metafieldValidations }) => {
                        const field = fields.provided_service.metadata.find((metadata) => (
                          metadata.field.value === fieldName
                        ));
                        const requiredMetaField = get(metafieldValidations, 'required', false);

                        return field ? (
                          <PaymentsSupportingDocuments
                            key={fieldName}
                            label={label}
                            fieldName={fieldName}
                            field={field.value}
                            required={requiredMetaField && enforceRequiredFields}
                            ref={registerField}
                            validations={requiredMetaField && enforceRequiredFields ? validations.isRequired : noop}
                          />
                        ) : null;
                      })
                    }
                  </div>
                </>
              )}
              {submitting && !isEmpty(requiredMetaFieldFiles) && (
                <div className="absolute inset-0 flex justify-center items-center z-10">
                  <Spinner />
                </div>
              )}
            </div>
            <InputField
              id="insuranceExternalMemberId"
              label="External Member ID"
              field={fields.insuranceExternalMemberId}
              className="hidden"
              ref={registerField}
              required={enforceRequiredFields && insuranceRequired}
              validations={enforceRequiredFields && insuranceRequired ? validateInsuranceId : noop}
            />
            {
              enforceRequiredFields && insuranceRequired &&
              (
                <div
                  id="insurance-error"
                  className="text-red mb-4"
                >
                  {validateInsuranceId(fields.insuranceExternalMemberId.value)}
                </div>
              )
            }
            {canForbidContractedServiceCreationWhenNoConsent && (
              <div
                id="client-not-consented-error"
                className="text-red mb-4"
              >
                {CLIENT_HAS_NOT_CONSENTED_ERROR}
              </div>
            )}
            <SectionSeparator className="p-0 mb-6 -mx-4 lg:-mx-16 xl:-mx-32" />
            {isBillable ? (
              <FormInteractionsButtons
                secondaryActionHandler={this.onSaveDraftBillable}
                primaryActionHandler={handleSubmit((formValues) => this.onDraftOrSubmit(formValues, 'submitted'))}
                cancelActionHandler={this.props.cancelFunction}
                submitting={submitting}
                interaction="fee-schedule-provided-service"
                primaryLabel={'SUBMIT FOR REVIEW'}
                secondaryLabel={'SAVE AS DRAFT'}
                conditionToDisableButton={
                  canForbidContractedServiceCreationWhenNoConsent ||
                  insuranceDateNonValid || (
                    !canInvoiceAboveRemainingAuthorizedAmount && (
                      requestedValueNonValid ||
                      requestedDatesNonValid
                    )
                  )
                }
                conditionToDisableSecondaryButton={
                  insuranceDateNonValid || (
                    !canInvoiceAboveRemainingAuthorizedAmount && (
                      requestedValueNonValid ||
                      requestedDatesNonValid
                    )
                  )
                }
              />
            ) : (
              <FormInteractionsButtons
                primaryActionHandler={handleSubmit((formValues) => this.onDraftOrSubmit(formValues, 'active'))}
                cancelActionHandler={this.props.cancelFunction}
                submitting={submitting}
                interaction="fee-schedule-provided-service"
                primaryLabel={'SAVE'}
                conditionToDisableButton={insuranceDateNonValid || requestedValueNonValid || requestedDatesNonValid}
              />
            )}
          </form>
        </BaseCardBody>
      </BaseCard>
    );
  }
}

PaymentsTrackServiceOld.propTypes = {
  cancelFunction: PropTypes.func,
  contactId: PropTypes.string,
  createFeeScheduleProvidedService: PropTypes.func,
  createFileUpload: PropTypes.func.isRequired,
  deleteFileUpload: PropTypes.func.isRequired,
  disallowContractedServiceForUnconsentedClient: PropTypes.bool,
  distributionReasons: PropTypes.array,
  feeScheduleProgramName: PropTypes.string,
  fields: PropTypes.object.isRequired,
  fileUploads: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  canInvoiceAboveRemainingAuthorizedAmount: PropTypes.bool,
  ignoreSocialCareExpiredDates: PropTypes.bool,
  insuranceRequired: PropTypes.bool.isRequired,
  isBillable: PropTypes.bool.isRequired,
  isCostType: PropTypes.bool,
  isEditing: PropTypes.bool,
  isPersonConsented: PropTypes.bool,
  metafields: PropTypes.array.isRequired,
  paymentMethods: PropTypes.array,
  paymentType: PropTypes.string,
  programId: PropTypes.string.isRequired,
  providedService: PropTypes.object,
  providedServiceId: PropTypes.string,
  registerField: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  serviceCaseId: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  updateFeeScheduleProvidedService: PropTypes.func.isRequired,
  placeOfServiceOptions: PropTypes.array,
  zcodeOptions: PropTypes.array,
  procedureCodesWithModifiers: PropTypes.array,
  serviceAuthorization: PropTypes.object,
  socialInsurances: PropTypes.array.isRequired,
  serviceAuthorizationInvoiceInformation: PropTypes.object,
  fetchFileUploads: PropTypes.func.isRequired,
  planId: PropTypes.string,
  setInferredPlanId: PropTypes.func.isRequired,
};

PaymentsTrackServiceOld.defaultProps = {
  cancelFunction: noop,
  contactId: '',
  createFeeScheduleProvidedService: noop,
  disallowContractedServiceForUnconsentedClient: false,
  distributionReasons: [],
  feeScheduleProgramName: '',
  canInvoiceAboveRemainingAuthorizedAmount: false,
  ignoreSocialCareExpiredDates: false,
  isCostType: false,
  isEditing: false,
  isPersonConsented: false,
  paymentMethods: [],
  paymentType: '',
  placeOfServiceOptions: [],
  procedureCodesWithModifiers: [],
  providedService: {},
  providedServiceId: '',
  serviceAuthorization: null,
  zcodeOptions: [],
  serviceAuthorizationInvoiceInformation: null,
  planId: null,
};

PaymentsTrackServiceOld.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { serviceCase, insuranceExternalMemberId } = ownProps;
  const isPersonConsented = get(serviceCase, 'person.consent.state') === 'accepted';
  const serviceCaseId = get(serviceCase, 'id');
  const groupId = state.session.groupId;
  const programId = get(serviceCase, 'program.id');
  const program = get(state, 'groupsPrograms.data').find(({ id }) => id === programId);
  const paymentType = get(program, 'fee_schedule_program.attributes.payment_type');
  const unitInformation = get(program, 'fee_schedule_program.attributes.unit', '');
  const capInformation = get(program, 'fee_schedule_program.attributes.cap_information');
  const feeScheduleProgramName = get(program, 'fee_schedule_program.attributes.name');
  const capOrUnitInitialValue = paymentType === 'cost_based_reimbursement' ? capInformation : unitInformation;
  const canInvoiceAboveRemainingAuthorizedAmount =
    get(program, 'fee_schedule_program.attributes.can_invoice_above_remaining_authorized_amount', '');
  const feeScheduleId = get(program, 'fee_schedule_program.relationships.fee_schedule.data.id', '');
  const feeSchedule = get(state, `feeSchedules.data.${feeScheduleId}`, {});
  const ignoreSocialCareExpiredDates = get(feeSchedule, 'ignore_social_care_expired_dates', false);
  const metafields = get(program, 'fee_schedule_program.attributes.metafields', []);
  const fileUploads = get(state, 'fileUploads.fileUploads', {});
  const initialValues = feeScheduleProvidedServiceInitialValues({
    providedService: ownProps.providedService,
    capOrUnitInitialValue,
    metafields,
    fileUploads,
    insuranceExternalMemberId,
    procedureCodeRows: ownProps.procedureCodesWithModifiers,
  });

  const distReasons = get(program, 'fee_schedule_program.attributes.distribution_reasons', []);
  const distributionReasons = (distReasons).map((option) => ({
    field: option,
    value: option,
  }));
  const payMethods = get(program, 'fee_schedule_program.attributes.payment_methods', []);
  const paymentMethods = (payMethods).map((option) => ({
    field: option,
    value: option,
  }));

  return {
    disallowContractedServiceForUnconsentedClient: forbidContractedServiceCreationForUnconsentedClient(state),
    distributionReasons,
    feeScheduleProgramName,
    fileUploads,
    groupId,
    canInvoiceAboveRemainingAuthorizedAmount,
    ignoreSocialCareExpiredDates,
    initialValues,
    isPersonConsented,
    metafields,
    paymentMethods,
    paymentType,
    programId,
    serviceCaseId,
  };
}

const fields = [
  'provided_service.form_id',
  'provided_service.id',
  'provided_service.unit_amount',
  'provided_service.actual_unit_amount',
  'provided_service.unit',
  'provided_service.payee',
  'provided_service.distribution_reason',
  'provided_service.payment_method',
  'provided_service.distribution_id',
  'provided_service.period_of_service',
  'provided_service.service_start_date',
  'provided_service.service_end_date',
  'provided_service.note',
  'provided_service.metadata[].value',
  'provided_service.metadata[].field',
  'provided_service.metadata[].address.city',
  'provided_service.metadata[].address.line_1',
  'provided_service.metadata[].address.line_2',
  'provided_service.metadata[].address.postal_code',
  'provided_service.metadata[].address.state',
  'provided_service.zcodes',
  'insuranceExternalMemberId',
  'provided_service.place_of_service',
  'provided_service.procedure_code_rows[]',
  'provided_service.procedure_code_rows[].procedure_code',
  'provided_service.procedure_code_rows[].procedure_code_modifier',
];

export default validateReduxForm({
  form: PAYMENTS_TRACK_SERVICE_FORM,
  fields,
}, mapStateToProps, {
  createFileUpload,
  createFeeScheduleProvidedService,
  deleteFileUpload,
  fetchFileUploads,
  fetchProvidedServices,
  updateFeeScheduleProvidedService,
})(PaymentsTrackServiceOld);
