function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import _ from 'lodash';
var findOptionByValue = function findOptionByValue(_ref) {
  var option = _ref.option,
    value = _ref.value,
    valueKey = _ref.valueKey;
  var optionValue = _.get(option, valueKey);
  return _.isString(optionValue) ?
  // Case insensitive string comparison
  _.toLower(value) === _.toLower(optionValue) :
  // Object comparison
  _.isEqual(value, optionValue);
};
var moveOptionToEnd = function moveOptionToEnd(_ref2) {
  var options = _ref2.options,
    _ref2$value = _ref2.value,
    value = _ref2$value === void 0 ? '' : _ref2$value,
    _ref2$valueKey = _ref2.valueKey,
    valueKey = _ref2$valueKey === void 0 ? 'value' : _ref2$valueKey;
  return _.compact([].concat(_toConsumableArray(_.reject(options, function (option) {
    return findOptionByValue({
      option: option,
      value: value,
      valueKey: valueKey
    });
  })), [_.find(options, function (option) {
    return findOptionByValue({
      option: option,
      value: value,
      valueKey: valueKey
    });
  })]));
};
export default moveOptionToEnd;