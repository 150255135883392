import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Icon, InputField } from '@unite-us/ui';
import { validations } from '@unite-us/client-utils';
import { RemoveEmailConfirmation } from 'common/form/Profile/ProfileEmailFields/components';

import './stylesheets/programProfileFields.scss';

class ProgramProfileEmailFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRemove: false,
    };

    this.onCancelRemove = this.onCancelRemove.bind(this);
    this.onConfirmRemove = this.onConfirmRemove.bind(this);
    this.showRemoveConfirmation = this.showRemoveConfirmation.bind(this);
  }

  onCancelRemove() {
    this.setState({ showRemove: false });
  }

  onConfirmRemove() {
    this.props.remove(this.props.index);
    this.setState({ showRemove: false });
  }

  showRemoveConfirmation() {
    this.setState({ showRemove: true });
  }

  render() {
    const {
      canRemoveField,
      fields,
      id,
      registerField,
      styles,
    } = this.props;

    const { showRemove } = this.state;

    return (
      <div className="program-profile-email-fields">
        <div className="program-profile-email-fields__program-email-field-group">
          <div className="program-profile-email-fields__program-email-address">
            <InputField
              field={fields}
              id={id}
              label="Email"
              ref={registerField}
              validations={[
                { func: validations.isRequired },
                { func: validations.isEmail },
              ]}
            />
          </div>
          <div
            className="program-profile-email-fields__remove-email"
            style={showRemove ? styles.fadeRemove : styles.showRemove}
          >
            {
              canRemoveField && (
                <Icon
                  icon="IconTrash"
                  onClick={this.showRemoveConfirmation}
                  size={21}
                />
              )
            }
          </div>
        </div>

        <div className="mb-two">
          <RemoveEmailConfirmation
            show={showRemove}
            onCancel={this.onCancelRemove}
            onConfirm={this.onConfirmRemove}
          />
        </div>
      </div>
    );
  }
}

ProgramProfileEmailFields.propTypes = {
  canRemoveField: PropTypes.bool,
  fields: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  registerField: PropTypes.func,
  remove: PropTypes.func,
  styles: PropTypes.object,
};

ProgramProfileEmailFields.defaultProps = {
  canRemoveField: true,
  remove: _.noop,
  registerField: _.noop,
  styles: {
    fadeRemove: { opacity: '.3' },
    showRemove: { visibility: 'visible' },
    hideRemove: { visibility: 'hidden', display: 'none' },
    checkbox: { marginTop: '26px' },
  },
};

export default ProgramProfileEmailFields;
