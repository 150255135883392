import { isEmpty } from 'lodash';

function buildProcedureCodeInitialFormValues({
    procedureCodeRows = [],
    procedureCodes = [],
    procedureCodeModifiers = [],
}) {
  return procedureCodes?.map((procedureCode) => {
    const { procedure_code_modifiers, id: procedure_code } = procedureCodeRows?.find(
     (data) => data?.id === procedureCode,
    ) || [];

    const procedure_code_modifier = procedure_code_modifiers?.reduce((pcmIds, currentPCM) => {
      const modifierId = procedureCodeModifiers.find(
        (procedureCodeModifier) => procedureCodeModifier === currentPCM.id,
      );

      return modifierId ? [...pcmIds, modifierId] : pcmIds;
    }, []);

    return {
      procedure_code,
      procedure_code_modifier: !isEmpty(procedure_code_modifier) ? procedure_code_modifier[0] : [],
    };
  }) || [];
}

export default buildProcedureCodeInitialFormValues;
