import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { browserHistory } from 'common/utils/browserHistory';
import removeSelectedContact from 'common/display/ContactStepper/actions/RemoveSelectedContact';
import { getEnumsFromState } from 'common/utils/Enums';
import _ from 'lodash';
import findCurrentGroup from 'common/utils/findCurrentGroup';
import { getGroup } from 'common/utils/stateHelpers';
import { fetchGroup } from 'actions/Group';
import { validateReduxForm } from 'common/form';
import { validations } from '@unite-us/client-utils';
import { Spinner } from 'common/spinners';
import { Button } from '@unite-us/ui';
import callOrLog from 'src/common/utils/callOrLog';
import { NEW_CLIENT } from 'common/utils/EventTracker/utils/eventConstants';
import { formatNewContactDataWithNoInsurance } from 'common/display/ContactStepper/utils';
import { destroyForm } from 'actions/Form';
import { REFERRALS_CREATE_REFERRALS } from 'common/utils/FeatureFlag/utils/constants';
import { createUpdateDeletePaymentsInsurances } from 'src/components/Insurance/utils/';
import createInsuranceEntry from 'src/components/Insurance/actions/createInsuranceEntry';
import PaymentsInformation from 'src/components/Insurance/PaymentsInformation';
import BasicInformation from './BasicInformation';
import MilitaryInformation from './MilitaryInformation';
import SupplementalInformation from './SupplementalInformation';
import ContactInformation from './ContactInformation';
import Header from './Header';
import createContact from '../actions/CreateContact';
import retrieveSearchedContacts from '../actions/RetrieveSelectedContact';
import { NOT_FOUND_HEADER_COPY, REGULAR_HEADER_COPY } from '../constants';
import '../stylesheets/addContactForm.scss';

class AddContactInformationFromReferral extends Component {
  static restartReferralProcess() {
    browserHistory.push('/referrals/new/search');
  }

  constructor(props) {
    super(props);

    this.onCreateContact = this.onCreateContact.bind(this);
    this.initiatePaymentsEventTracking = this.initiatePaymentsEventTracking.bind(this);
  }

  componentDidMount() {
    if (!this.props.completeGroup) {
      this.props.fetchGroup(this.props.groupId);
    }
  }

  onCreateContact(values) {
    const {
      groupId,
      fields,
    } = this.props;

    return this.props.createContact({
      contact: formatNewContactDataWithNoInsurance({ fields, values }),
      groupId,
      showNotification: false,
    }).then((contact) => {
      const { id: contactId } = _.get(contact, 'data.data', {});

      createUpdateDeletePaymentsInsurances({
        values,
        contactId,
        groupId,
        createInsuranceEntry: this.props.createInsuranceEntry,
        paymentsEventTracking: this.initiatePaymentsEventTracking,
        showNotification: false,
      });

      this.props.destroyForm(REFERRALS_CREATE_REFERRALS);

      this.setSelectedContact(contact);
    });
  }

  setSelectedContact(returnedContact) {
    const createdContact = _.get(returnedContact, 'data.data');

    this.props.retrieveSearchedContacts(createdContact.id).then(() => {
      callOrLog(() => this.context.eventTracker(NEW_CLIENT.createFromNav, null, {
        contact: createdContact,
      }));
      browserHistory.push('/referrals/new/add-service-types');
    });
  }

  initiatePaymentsEventTracking(insurancesArr = []) {
    const updatedTypes = insurancesArr.reduce((insurances, currentInsurance) => {
      if (_.isEmpty(currentInsurance.plan_type)) {
        return insurances;
      }
      return [...insurances, currentInsurance.plan_type.toLowerCase()];
    }, []);

    const eventTracker = this.context.eventTracker;
    if (!_.isEmpty(updatedTypes)) {
      callOrLog(() => eventTracker(
        NEW_CLIENT.changedInsurance,
        { insurance_types: updatedTypes },
      ));
    }
  }

  render() {
    const {
      completeGroup,
      currentGroup,
      enums,
      fields,
      handleSubmit,
      header,
      location,
      mainHeader,
      registerField,
      submitting,
      untouch,
      values,
    } = this.props;
    const { query: { not_found } } = location;
    const subHeader = not_found ? `${NOT_FOUND_HEADER_COPY} ${REGULAR_HEADER_COPY}` : REGULAR_HEADER_COPY;

    return (
      <form
        onSubmit={handleSubmit(this.onCreateContact)}
        className="add-contact-from-referral"
      >
        <div className="row">
          <div className="referral-header col-md-12">
            <Header
              header={header}
              mainHeader={mainHeader}
              subHeader={subHeader}
            />
          </div>
        </div>

        <BasicInformation
          registerField={registerField}
          fields={fields}
          validations={validations}
          enums={enums}
        />

        <ContactInformation
          registerField={registerField}
          fields={fields}
          enums={enums}
          validations={validations}
        />

        {
          completeGroup ? (
            <SupplementalInformation
              completeGroup={completeGroup}
              currentGroup={currentGroup}
              enums={enums}
              fields={fields}
              registerField={registerField}
              untouch={untouch}
              values={values}
            />
          ) : <Spinner />
        }

        <div className="row">
          <div className="col-md-12">
            <footer className="referral-footer">
              <Button
                id="go-back-btn"
                onClick={AddContactInformationFromReferral.restartReferralProcess}
                label="Go Back"
                style={{ marginRight: '10px' }}
              />
              <Button
                id="save-client-btn"
                className="save-client-btn"
                onClick={handleSubmit(this.onCreateContact)}
                label="Save Client"
                primary
                disabled={submitting}
              />
            </footer>
          </div>
        </div>
      </form>
    );
  }
}

AddContactInformationFromReferral.propTypes = {
  completeGroup: PropTypes.object,
  createContact: PropTypes.func.isRequired,
  createInsuranceEntry: PropTypes.func.isRequired,
  currentGroup: PropTypes.object.isRequired,
  destroyForm: PropTypes.func.isRequired,
  enums: PropTypes.object,
  fetchGroup: PropTypes.func.isRequired,
  fields: PropTypes.object,
  groupId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func,
  header: PropTypes.string.isRequired,
  location: PropTypes.object,
  mainHeader: PropTypes.string,
  registerField: PropTypes.func.isRequired,
  retrieveSearchedContacts: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  untouch: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

AddContactInformationFromReferral.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {
    user,
    contacts,
    searchedContacts,
    selectedContact,
    session,
    session: { groupId },
  } = state;
  const allContacts = [...contacts.contacts, ...searchedContacts];

  const contact = allContacts.find((person) => person.id === selectedContact);
  const initialValues = {
    first_name: _.get(state.form, 'contact.first_name.value', ''),
    last_name: _.get(state.form, 'contact.last_name.value', ''),
    date_of_birth: _.get(state.form, 'contact.date_of_birth.value', ''),
    gender: 'undisclosed',
    marital_status: 'undisclosed',
    race: 'undisclosed',
    ethnicity: 'undisclosed',
    addresses: [undefined],
    insurance: [{}],
  };

  return {
    enums: getEnumsFromState(state),
    groupId,
    contact,
    selectedContact,
    initialValues,
    currentGroup: findCurrentGroup(user, session),
    completeGroup: getGroup(state, groupId),
  };
}

export default validateReduxForm(
  {
    form: 'contact',
    fields: [
      ...BasicInformation.fields,
      ...ContactInformation.fields,
      ...MilitaryInformation.fields,
      ...PaymentsInformation.fields,
    ],
    destroyOnUnmount: true,
  },
  mapStateToProps,
  {
    createContact,
    createInsuranceEntry,
    destroyForm,
    fetchGroup,
    removeSelectedContact,
    retrieveSearchedContacts,
  },
)(AddContactInformationFromReferral);
