import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { browserHistory } from 'common/utils/browserHistory';
import Header from 'common/display/ContactStepper/components/Header';
import { patchAssessment as patchCaseAssessment } from 'actions/Assessment/Case/Group';
import { patchAssessmentFromReferral } from 'actions/Assessment/Contact/Referral/Group';
import buildResponses from '@unite-us/surveyjs/dist/components/Renderer/buildResponses';
import querystring from 'query-string';
import _ from 'lodash';
import { validateReduxForm } from 'common/form';
import {
  Button,
  FormLegend,
} from '@unite-us/ui';
import { OverlaySpinner } from 'common/spinners';
import { destroyAllDropzones } from 'common/form/FileUpload/actions/FileUpload';
import { returnedAssessment, setAssessmentsResponses, cleanReturnedAssessments } from 'actions/Assessment';
import FormForm from 'common/form/FormForm';
import { updateGroupContact } from 'actions/Contact/Group';
import { submitReferral } from 'actions/Referral/Contact/Group';
import addNotification from 'common/utils/Notifications/actions/AddNotification';
import {
  newReferralFormFieldsAsServices,
  someInNetworkReferrals,
} from 'src/components/Referrals/utils/form';
import {
  CREATE_REFERRAL_FORM,
} from 'src/components/Referrals/constants';
import { crtb107ValidateDraftAssessments } from 'common/utils/FeatureFlags/flags';
import callOrLog from 'src/common/utils/callOrLog';
import { REFERRAL } from 'common/utils/EventTracker/utils/eventConstants';
import { goToOpenCasesIndex } from 'src/components/Dashboard/utils/navigation';
import {
  filterAssessmentsData,
} from 'common/utils/FormRenderer';

export class AddSupportingDetailsStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedFormCard: 0,
      showErrors: false,
    };

    this.formRefs = {};

    this.handleExpandChange = this.handleExpandChange.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onSaveCaseAssessments = this.onSaveCaseAssessments.bind(this);
    this.onSaveDraft = this.onSaveDraft.bind(this);
    this.getAssessmentsResponses = this.getAssessmentsResponses.bind(this);
    this.goToReviewStep = this.goToReviewStep.bind(this);
    this.patchDraftReferralAssessments = this.patchDraftReferralAssessments.bind(this);
    this.patchCaseAssessments = this.patchCaseAssessments.bind(this);
    this.submitDraftReferralsAssessments = this.submitDraftReferralsAssessments.bind(this);
    this.submitCaseAssessments = this.submitCaseAssessments.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { fields, params } = this.props;
    const assistanceRequestId = _.get(params, 'assistance_request_id', '');
    const prefix = assistanceRequestId ?
      `/assistance-requests/${assistanceRequestId}` :
      '';

    const networkIds = new Set(fields.services.map((service) => service.referred_by_network.value));
    const serviceTypeIds = new Set(fields.services.map((service) => service.service_type.value.id));
    if (!networkIds.size || !serviceTypeIds.size) {
      browserHistory.push(`${prefix}/referrals/new/add-service-types`);
    }
    // Skip assessments if only out of network referrals are being sent.
    if (!someInNetworkReferrals(fields.services)) {
      this.goToReviewStep();
    }

    this.props.cleanReturnedAssessments();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(this.state) !== JSON.stringify(nextState)) {
      return true;
    }
    return false;
  }

  handleExpandChange(index) {
    return () => {
      this.setState({ expandedFormCard: index });
    };
  }

  onNextClick() {
    const { groupForms, contactId } = this.props;

    return this.getAssessmentsResponses((responses) => {
      this.props.setAssessmentsResponses(responses, contactId);

      for (const assessment of responses) {
        const groupForm = _.find(groupForms, { id: assessment.id }) || {};
        this.props.returnedAssessment(groupForm.name);
      }

      this.goToReviewStep();
    });
  }

  onSaveCaseAssessments() {
    return this.submitCaseAssessments((assessmentsResult) => (
      Promise.all(assessmentsResult)
        .then(() => {
          this.props.addNotification({
            payload: {
              status: 'success',
              statusText: 'Supporting Assessments Successfully Saved',
            },
          });
          goToOpenCasesIndex();
        })
    ));
  }

  onSaveDraft() {
    const {
      groupId,
      groupForms,
      contactId,
      params: {
        assistance_request_id,
      },
      values: {
        services,
      },
      screeningId,
      validateDraftAssessments,
    } = this.props;

    return this.getAssessmentsResponses((responses) => {
      if (validateDraftAssessments) {
        this.props.setAssessmentsResponses(responses, contactId);

        for (const assessment of responses) {
          const groupForm = _.find(groupForms, { id: assessment.id }) || {};
          this.props.returnedAssessment(groupForm.name);
        }
      }

      const newdraftPromise = this.props.submitReferral(
        groupId,
        contactId,
        services,
        { assistance_request_id, screening_id: screeningId, draft: true },
      );

      return Promise.resolve(newdraftPromise).then((response) => {
        const referrals = _.map(response, 'data.data', []);

        this.submitDraftReferralsAssessments(referrals);
        callOrLog(() => this.context.eventTracker(REFERRAL.saveDraft, {
          referral_ids: _.map(referrals, 'id'),
        }));

        this.props.resetForm();
        this.props.destroyForm(CREATE_REFERRAL_FORM);
        this.props.destroyAllDropzones();
        browserHistory.push('/dashboard/referrals/sent/draft');
      });
    });
  }

  getAssessmentsResponses(onComplete) {
    const formResponses = {};
    let hasErrors = false;
    Object.keys(this.formRefs).forEach((formId) => {
      const formRef = this.formRefs[formId];
      formRef.formId = formId;

      if (formRef.ref.hasErrors()) {
        hasErrors = true;
      }
      const response = buildResponses(formRef.ref);
      formResponses[formId] = response;
    });

    this.setState({ showErrors: true });

    if (!hasErrors) {
      return onComplete(filterAssessmentsData(this.formRefs, formResponses));
    }

    return 0;
  }

  patchCaseAssessments(assessmentData) {
    const {
      groupId,
      caseId,
      currentEmployee,
    } = this.props;

    return Promise.all(_.map(assessmentData, (assessment) => (
      this.props.patchCaseAssessment({
        groupId,
        caseId,
        id: assessment.id,
        values: assessment.data,
        submitter: currentEmployee,
      })
    )));
  }

  patchDraftReferralAssessments(assessmentData, referral) {
    const { groupId, groupForms, currentEmployee } = this.props;

    return Promise.all(_.map(assessmentData, (assessment) => {
      const submittedForm = _.find(groupForms, { id: assessment.id });
      this.props.patchAssessmentFromReferral({
        groupId,
        caseId: referral.case.id,
        id: assessment.id,
        values: assessment.data,
        submitter: currentEmployee,
      }).then((response) => {
        this.props.returnedAssessment(submittedForm.name);

        return response;
      });
    }));
  }

  submitDraftReferralsAssessments(referrals, onComplete) {
    return this.getAssessmentsResponses((responses) => (
      Promise.all(referrals.map((referral) => (
        this.patchDraftReferralAssessments(responses, referral)
      )))
    )).then(onComplete);
  }

  submitCaseAssessments(onComplete) {
    return this.getAssessmentsResponses(this.patchCaseAssessments).then(onComplete);
  }

  goToReviewStep() {
    const {
      params: {
        assistance_request_id,
        screening_id,
        need_id,
      },
      location,
    } = this.props;
    const querySrc = _.get(location, 'query.src');

    // determine if there is any Assistance Request id needed to build the route
    const arPrefix = assistance_request_id ?
      `/assistance-requests/${assistance_request_id}` :
      '';
    // determine if there is a location src in the route and pass along
    const queryString = querySrc ? `?src=${querySrc}` : '';

    // determine if there is any Screeing id or Need id needed to build the route
    const screeningPrefix = screening_id ?
      `/screenings/${screening_id}/need/${need_id}` :
      '';
    browserHistory.push(`${arPrefix}${screeningPrefix}/referrals/new/final-review${queryString}`);
  }

  render() {
    const {
      caseId,
      groupForms,
      handleSubmit,
      isReferralSaving,
      submitting,
      fields,
      isFetchingForms,
      groupId,
      contactId,
      currentEmployee,
      responses,
      responsesContact,
    } = this.props;

    const { showErrors } = this.state;

    const hasOONCases = fields.services
      .some((s) => _.get(s, 'isOONCase.checked', false));
    const isResponseForContact = (responsesContact && responsesContact === contactId);

    const toolTip = hasOONCases && {
      'data-tooltip-left': true,
      'data-tooltip-large': 'Out of Network Cases cannot be saved as drafts',
    };

    return (
      <div>
        <OverlaySpinner show={submitting || isReferralSaving} text="Saving..." />

        <div className="referral-header">
          {
            caseId ? (
              <Header
                header="Add Supporting Information"
                subHeader="Collecting this information can assist you in connecting your client with services Out of Network." /* eslint-disable-line max-len */
              />
            ) : (
              <Header
                header="Create Referral"
                mainHeader="Add Supporting Information"
                subHeader="Providing this information can help us quickly connect your client with services."
              />
            )
          }
        </div>
        <FormLegend />
        {
          groupForms.map((form, index) => (
            <FormForm
              id={`group-form-${index}`}
              className={`custom-form-${index}`}
              setRef={(c) => { this.formRefs[form.id] = c; }}
              key={`${form.id}formform`}
              formId={form.id}
              groupId={groupId}
              handleExpandChange={this.handleExpandChange(index)}
              expanded={index === this.state.expandedFormCard}
              onFormLoaded={this.onFormLoaded}
              currentEmployee={currentEmployee}
              hasErrors={showErrors ? this.formRefs[form.id]?.ref?.hasErrors() : false}
              contextType="case"
              contextPerson={contactId}
              response={isResponseForContact && responses?.find((x) => x.id === form.id)}
              usage_type="referral_assessment"
            />
          ))
        }

        <form>
          <footer className="referral-footer">
            {
              caseId ? (
                <div style={{ float: 'right' }}>
                  <Button
                    id="save-case-assessments-btn"
                    label="Save"
                    onClick={handleSubmit(this.onSaveCaseAssessments)}
                    primary
                  />
                </div>
              ) : (
                <div style={{ float: 'right' }}>
                  <span {...toolTip}>
                    <Button
                      id="save-draft-btn"
                      className="save-draft-btn"
                      onClick={handleSubmit(this.onSaveDraft)}
                      label="Save Draft"
                      disabled={hasOONCases || submitting || isFetchingForms}
                      secondary
                    />
                  </span>
                  <Button
                    id="next-btn"
                    className="ml-one"
                    onClick={handleSubmit(this.onNextClick)}
                    disabled={submitting || isFetchingForms}
                    label="Next"
                    primary
                  />
                </div>
              )
            }
          </footer>
        </form>
      </div>
    );
  }
}

AddSupportingDetailsStep.propTypes = {
  addNotification: PropTypes.func.isRequired,
  caseId: PropTypes.string.isRequired,
  cleanReturnedAssessments: PropTypes.func.isRequired,
  contact: PropTypes.object.isRequired,
  contactId: PropTypes.string.isRequired,
  currentEmployee: PropTypes.object.isRequired,
  destroyAllDropzones: PropTypes.func.isRequired,
  destroyForm: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  groupForms: PropTypes.array.isRequired,
  groupId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isReferralSaving: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  patchCaseAssessment: PropTypes.func.isRequired,
  patchAssessmentFromReferral: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  responses: PropTypes.array.isRequired,
  returnedAssessment: PropTypes.func.isRequired,
  setAssessmentsResponses: PropTypes.func.isRequired,
  submitReferral: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  screeningId: PropTypes.string,
  isFetchingForms: PropTypes.bool.isRequired,
  responsesContact: PropTypes.string,
  validateDraftAssessments: PropTypes.func.isRequired,
};

AddSupportingDetailsStep.defaultProps = {
  screeningId: undefined,
  responsesContact: undefined,
};

AddSupportingDetailsStep.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const {
    groupForms,
    selectedContact: contactId,
  } = state;

  const groupId = _.get(state, 'session.groupId');
  const groupFormsArray = _.get(groupForms, groupId, []);
  const contacts = _.get(state, 'contacts.contacts', []);
  const contact = _.find([...state.searchedContacts, ...contacts], { id: contactId }) || {};
  const isReferralSaving = _.get(state, 'contactReferrals.isSaving', false);
  const caseId = (querystring.parse(ownProps.location.search) || {}).caseId;
  const screeningId = _.get(ownProps, 'params.screening_id');
  const isFetchingForms = _.get(state, 'groupForms.isFetching', false);
  const currentEmployee = _.get(state, 'globalState.currentEmployee');
  const formContact = _.get(groupForms, 'contactId');
  const responsesContact = _.get(groupForms, 'responsesContactId');
  const responses = (formContact === contactId) ? _.get(groupForms, 'responses', []) : [];
  const validateDraftAssessments = crtb107ValidateDraftAssessments(state);

  return {
    caseId,
    contact,
    contactId,
    groupId,
    groupForms: groupFormsArray,
    isReferralSaving,
    screeningId,
    isFetchingForms,
    currentEmployee,
    responsesContact,
    responses,
    validateDraftAssessments,
  };
}

const fields = newReferralFormFieldsAsServices();

export default validateReduxForm(
  {
    form: CREATE_REFERRAL_FORM,
    fields,
    destroyOnUnmount: false,
  },
  mapStateToProps,
  {
    addNotification,
    destroyAllDropzones,
    patchCaseAssessment,
    patchAssessmentFromReferral,
    returnedAssessment,
    setAssessmentsResponses,
    cleanReturnedAssessments,
    submitReferral,
    updateGroupContact,
  },
)(AddSupportingDetailsStep);
