import { validations } from '@unite-us/client-utils';
import { compact, uniq } from 'lodash';

const validateUnitAmount = (value, enforceRequiredFields = true) => {
  const validation = uniq(compact([
    validations.isInteger(value),
    validations.isGreaterThan(value, null, 0),
    validations.isLessThan(value, null, 2147483648),
    enforceRequiredFields ? validations.isRequired(value) : null,
    Number.isNaN(value) ? 'Required' : null,
  ]));
  return validation.length > 0 ? validation : null;
};

export default validateUnitAmount;
