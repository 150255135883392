import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import cx from 'classnames';
import { Form, Field } from 'react-final-form';
import _ from 'lodash';

// unite-us
import { Button, Icon } from '@unite-us/ui';

// api
import { apiDefault } from 'api/config';
import { useFindRecord, useFind, usePopulate } from 'api/APIHooks';
import { isHttpSuccess } from 'api/utils/httpStatus';

// enums
import DEFAULT_ENUMS from 'reducers/constants/defaultEnums';

// common
import FeatureFlagContainer from 'common/utils/FeatureFlags/FeatureFlagContainer';

import { Spinner } from 'common/spinners';
import { BackButton } from 'common/buttons';
import { useFeatureFlag, useAuthorizeClientMerging } from 'common/hooks';
import DollarAmount from 'common/display/Money/DollarAmount';
import Notifier from 'common/helpers/Notifier';
import { browserHistory } from 'common/utils/browserHistory';

// components
import { isOrgAdmin } from 'components/User/utils';
import ConfirmationModal from 'components/People/MergeDetails/components/ConfirmationModal';

const serializeAddress = (address) => {
  const whitelist = [
    'address_type',
    'city',
    'country',
    'county',
    'is_primary',
    'latitude',
    'line_1',
    'line_2',
    'longitude',
    'postal_code',
    'state',
  ];

  const serializedAddress = Object.keys(address)
    .filter((key) => whitelist.includes(key))
    .reduce((acc, key) => {
      acc[key] = address[key];
      return acc;
    }, {});

  return {
    data: {
      id: address.id,
      type: 'address',
      attributes: serializedAddress,
    },
  };
};

const serializeContactPreference = (contact_preference) => {
  const whitelist = ['contact_notes', 'preferred_contact_methods', 'timeslots', 'voice_mail_ok'];

  const serializedContactPreference = Object.keys(contact_preference)
    .filter((key) => whitelist.includes(key))
    .reduce((acc, key) => {
      acc[key] = contact_preference[key];
      return acc;
    }, {});

  return {
    data: {
      attributes: {
        ...serializedContactPreference,
        timeslots:
          (serializedContactPreference.timeslots && Object.keys(serializedContactPreference.timeslots).length > 0) ?
          serializedContactPreference.timeslots :
          undefined,
      },
    },
  };
};

const serializeMergeData = (obj) => {
  const whitelist = [
    'citizenship',
    'communication_preferences',
    'date_of_birth',
    'email_addresses',
    'ethnicity',
    'first_name',
    'gender',
    'gross_monthly_income',
    'household',
    'last_name',
    'marital_status',
    'middle_name',
    'military',
    'nicknames',
    'phone_numbers',
    'preferred_name',
    'race',
    'suffix',
    'title',
  ];

  const serializedData = Object.keys(obj)
    .filter((key) => whitelist.includes(key))
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});

  return {
    data: {
      attributes: {
        ...serializedData,
        communication_preferences: serializedData.communication_preferences || undefined,
        date_of_birth: obj.date_of_birth ? moment.unix(obj.date_of_birth).utc().format('YYYY-MM-DD') : undefined,
        sexuality: obj.sexual_orientation ? obj.sexual_orientation.sexuality : undefined,
        sexuality_other:
          obj.sexual_orientation && obj.sexual_orientation.sexuality?.includes('other') ?
          obj.sexual_orientation.sexuality_other :
          undefined,
      },
      relationships: {
        addresses: obj.addresses ? obj.addresses.map((address) => (serializeAddress(address))) : [],
        contact_preference:
          (obj.contact_preference && Object.keys(obj.contact_preference).length > 0) ?
          serializeContactPreference(obj.contact_preference) :
          undefined,
      },
    },
  };
};

const required = (value) => (value ? undefined : 'Required');
const validateForm = (values) => {
  const errors = {};
  Object.keys(values).forEach((key) => {
    const error = required(values[key]);
    if (error) {
      errors[key] = error;
    }
  });

  return errors;
};
const enumDisplayName = (value, enumsPath) => {
  const path = enumsPath === 'people.gender' ? 'people.genders' : enumsPath;
  const pathEnums = _.wget(DEFAULT_ENUMS, path, []);
  const foundValue = pathEnums.find((element) => element.value === value);
  return foundValue?.display_name || '';
};
const formatDate = (date) => moment(date).format('MM/DD/YY');

const FieldType = {
  gross_monthly_income: 'money',
  marital_status: 'enum',
  gender: 'enum',
  race: 'enum',
  ethnicity: 'enum',
  citizenship: 'enum',
  date_of_birth: 'date',
  household: 'household',
  sexual_orientation: 'sexuality',
};

const SexualityDisplay = ({ value }) => {
  const sexuality =
    value.sexuality.map((sexualityOption) => enumDisplayName(sexualityOption, 'people.sexual_orientations')).join(', ');
  const sexualityOther = value.sexuality_other;

  return (
    <div>
      <div>{sexuality}&nbsp;</div>
      {sexualityOther && (<div>Other: {sexualityOther}</div>)}
    </div>
  );
};

const HouseholdValue = ({ value }) => (
  <div>
    {value.total ? <p>Household Size: {value.total}&nbsp;</p> : null}
    {
      value.adults || value.children ? (
        <p className="mt-1">
          {value.adults ? <span>Adults: {value.adults}&nbsp;</span> : null}
          {value.children ? <span>Children: {value.children}</span> : null}
        </p>
      ) :
      null
    }
  </div>
);

const FieldValue = ({ field, value }) => {
  switch (FieldType[field]) {
    case 'enum':
      return enumDisplayName(value, `people.${field}`);
    case 'money':
      return <DollarAmount value={value} />;
    case 'date':
      return formatDate(value);
    case 'household':
      return <HouseholdValue value={value} />;
    case 'sexuality':
      return <SexualityDisplay value={value} />;
    default:
      return value;
  }
};

const FieldRow = ({
  field, selectedValue, clientIds, values,
}) => {
  const fieldDisplayName = _.startCase(field);

  return (
    <div
      className="grid grid-cols-custom gap-2 w-full ui-radio-field ui-radio-field--inline ui-form-field mb-0"
      role="listitem"
    >
      <div className="text-brand-blue font-bold font-heavy-font p-2 flex items-center">
        {fieldDisplayName}
      </div>
      {values.map((value, index) => (
        <div
          key={`${clientIds[index]}-${field}`}
          className={cx(
            'p-2 flex items-center',
            { 'bg-light-fill-grey': selectedValue === index.toString() },
          )}
        >
          <div className="ui-radio-field__item">
            <label htmlFor={`client-${index}-${field}`}>
              <div className="flex items-center gap-1">
                <Field
                  id={`client-${index}-${field}`}
                  name={field}
                  component="input"
                  type="radio"
                  value={index.toString()}
                  validate={required}
                  aria-labelledby={`client-${index}-${field}`}
                />
                <span className="normal-case" />
                <FieldValue field={field} value={value} />
              </div>
            </label>
          </div>
        </div>
      ))}
    </div>
  );
};

export const MergeDetails = ({ location, userIsAdmin }) => {
  const isClientMergingEnabled = useFeatureFlag('cl-67-client-merging');
  const [mergedClientId, setMergedClientId] = useState(null);
  const DuplicatesModalRef = useRef();
  const [submitForm, setSubmitForm] = useState(null);
  const { query: { clients } } = location;

  useAuthorizeClientMerging(isClientMergingEnabled, userIsAdmin);

  const { data: duplicatesClientsResponse } = useFind(`people/${mergedClientId}/duplicates`, {}, {
    api: 'coreApi',
    queryConfig: {
      enabled: mergedClientId !== null,
      placeholderData: undefined,
    },
  });

  const { data: clientsComparison, isFetching: isFetchingClientComparison } = useFind(
    `people/comparison?ids[]=${clients[0]}&ids[]=${clients[1]}&`,
    {},
    {
      api: 'coreApi',
      queryConfig: {
        enabled: isClientMergingEnabled && userIsAdmin,
        placeholderData: undefined,
      },
    },
  );
  const { data: firstClientResponse, isFetching: isFetchingFirstClient } = useFindRecord(
    'person',
    clients[0],
    {
      include: 'addresses',
      queryConfig: { placeholderData: undefined },
      enabled: isClientMergingEnabled && userIsAdmin,
    },
  );
  const firstClientData = firstClientResponse?.data?.data;
  const { data: secondClientResponse, isFetching: isFetchingSecondClient } = useFindRecord(
    'person',
    clients[1],
    {
      include: 'addresses',
      queryConfig: { placeholderData: undefined },
      enabled: isClientMergingEnabled && userIsAdmin,
    },
  );
  const secondClientData = secondClientResponse?.data?.data;
  usePopulate(
    'contact_preference',
    'contact_preference',
    firstClientData,
    {
      queryConfig: { placeholderData: undefined },
    },
  );
  usePopulate(
    'contact_preference',
    'contact_preference',
    secondClientData,
    {
      queryConfig: { placeholderData: undefined },
    },
  );

  const isLoading = isFetchingFirstClient || isFetchingSecondClient || isFetchingClientComparison;
  const hasLoadingError = !isLoading && (!firstClientData || !secondClientData || !clientsComparison);
  const mergedClientHasDuplicates = duplicatesClientsResponse?.data?.length >= 2;
  const differentFields = Object.fromEntries(
    Object.entries(clientsComparison?.data?.data || {}).filter(([, value]) => value[2] === true),
  );
  const hasDifferences = Object.keys(differentFields).length > 0;

  const openConfirmationModal = (formSubmit) => {
    setSubmitForm(() => formSubmit);
    DuplicatesModalRef.current.openModal();
  };

  const confirmMergeClients = async (formValues) => {
    try {
      const values = Object.keys(formValues)
        .reduce((acc, key) => {
          acc[key] = differentFields[key][parseInt(formValues[key], 10)];
          return acc;
        }, {});

      const mergeData = serializeMergeData({
        ...values,
        first_name: firstClientData.first_name,
        last_name: firstClientData.last_name,
      });

      const response = await apiDefault.post(
        `/people/merge?ids[]=${firstClientData.id}&ids[]=${secondClientData.id}`,
        mergeData,
      );

      if (!response || !isHttpSuccess(response.status)) {
        Notifier.dispatch('error', 'We could not complete the merge. Please try again.');
      } else {
        const clientId = response.data.data.id;
        setMergedClientId(clientId);
      }
    } catch {
      Notifier.dispatch('error', 'We could not complete the merge. Please try again.');
    } finally {
      DuplicatesModalRef.current.closeModal();
    }
  };

  return (
    <div className="flex justify-center py-12">
      {mergedClientId ? (
        <div
          className="bg-white border border-solid
            rounded border-filter-border-color w-771px
            h-409px flex flex-col items-center justify-center
            text-brand-blue space-y-6"
        >
          <h1 className="text-4xl">Merge Successful!</h1>
          <div className="text-sm">
            The client profiles are now merged.
            You can view the merge history in the note section of the client&rsquo;s face sheet
          </div>
          <div className="flex items-center justify-center space-x-4">
            <Button
              label="Go to Client's Face Sheet"
              className="w-56"
              onClick={() => browserHistory.push({ pathname: `/facesheet/${mergedClientId}` })}
            />
            {mergedClientHasDuplicates && (
              <Button
                primary
                label="Merge Additional Duplicates"
                className="w-56"
                onClick={() => browserHistory.push({ pathname: `/people/duplicates/${mergedClientId}` })}
              />
            )}
          </div>
        </div>
      ) : (
        <Form
          onSubmit={confirmMergeClients}
          validate={validateForm}
          render={({
            handleSubmit, submitting, pristine, values, errors,
          }) => (
            <form
              onSubmit={(event) => {
                event.preventDefault();
                openConfirmationModal(() => handleSubmit(event));
              }}
            >
              <div className="flex flex-col items-center w-full max-w-5xl space-y-4">
                <BackButton className="self-start" />
                <div className="w-full">
                  <div className="px-8 py-4 bg-white border border-solid rounded-t-md border-filter-border-color">
                    <h2 className="text-xl font-semibold text-brand-blue">
                      Select Client Profile Information to Keep
                    </h2>
                    <p className="my-2 text-sm text-brand-blue">
                      When you merge client records, the new record is updated with the information you select below.
                      The rest of the profile information from both records, including the referral history, is saved.
                    </p>
                    <div className="flex items-center gap-2">
                      <Icon
                        className="fill-current text-brand-blue"
                        icon={'V2Warning'}
                        size={14}
                      />
                      <span>
                        This action can&apos;t be undone.
                      </span>
                    </div>
                  </div>
                  <div
                    className="
                            flex flex-col items-center py-8 px-40
                            space-y-4 bg-white border-b border-l
                            border-r border-solid rounded-b-md
                            border-filter-border-color"
                    role="list"
                  >
                    {isLoading && <Spinner />}
                    {hasLoadingError && <div>Error loading client data</div>}
                    {!isLoading && !hasLoadingError && (
                    <div
                      className={cx('w-full flex flex-col items-center space-y-2', { 'space-y-10': !hasDifferences })}
                    >
                      <div
                        className={cx(
                          'w-full gap-2',
                          {
                            'grid grid-cols-custom': hasDifferences,
                            'flex justify-center': !hasDifferences,
                          },
                        )}
                      >
                        <div />
                        {[firstClientData, secondClientData].map((client) => (
                          <div
                            key={client.id}
                            className="
                              flex flex-col py-3 w-56
                              px-4 border border-solid rounded-md
                            border-filter-border-color
                            "
                          >
                            <h3 className="mb-1 text-base font-semibold text-action-blue">{client.full_name}</h3>
                            <div className="flex flex-col gap-1">
                              <span className="italic">Last Updated: {formatDate(client.updated_at)}</span>
                              <span className="italic">Date Created: {formatDate(client.created_at)}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                      {Object.keys(differentFields).map((field) => (
                        <FieldRow
                          key={`field-row-${field}`}
                          field={field}
                          clientIds={[firstClientData.id, secondClientData.id]}
                          values={[
                            differentFields[field][0],
                            differentFields[field][1],
                          ]}
                          selectedValue={values[field]}
                        />
                      ))}
                      {!hasDifferences && (
                        <div className="italic">
                          All of the client details match in these records. Select Merge Records to continue.
                        </div>
                      )}
                    </div>
                  )}
                  </div>
                </div>
                <div className="w-full flex justify-end">
                  <Button
                    type="submit"
                    primary
                    label="Merge Records"
                    className="w-40"
                    disabled={hasDifferences && (submitting || pristine || Object.keys(errors).length > 0)}
                  />
                </div>
              </div>
            </form>
          )}
        />
      )}
      <ConfirmationModal modalRef={DuplicatesModalRef} onMergeRecordsClick={submitForm} />
    </div>
  );
};

HouseholdValue.propTypes = {
  value: PropTypes.object.isRequired,
};

FieldValue.propTypes = {
  field: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
};

FieldRow.defaultProps = {
  selectedValue: null,
};

SexualityDisplay.propTypes = {
  value: PropTypes.object.isRequired,
};

FieldRow.propTypes = {
  field: PropTypes.string.isRequired,
  clientIds: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
  selectedValue: PropTypes.any,
};

MergeDetails.propTypes = {
  location: PropTypes.object.isRequired,
  userIsAdmin: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { session } = state;

  return {
    userIsAdmin: isOrgAdmin(state.user, session.groupId),
  };
};

export default connect(mapStateToProps)(FeatureFlagContainer(MergeDetails));
