import Notifier from 'common/helpers/Notifier';
import { CLOSE_SERVICE_CASE } from 'actions';
import { coreApi } from 'src/api/config';
import moment from 'moment';
import createNote from './createNote';

export default function closeServiceCase(groupId, contactId, caseObj, closing) {
  return async (dispatch) => {
    try {
      await dispatch(createNote({
        interaction: {
          note: closing.note,
          occurred_on: closing.exited_at,
          category: 'general',
        },
        subjectType: 'case',
        subject: caseObj.id,
        opts: { sendSuccessNotification: false },
        context: 'closed',
      }));

      const caseResponse = await coreApi.updateRecord('case', caseObj.id, {
        outcome: closing.outcome_id,
        ...(caseObj.opened_date ?
          { closed_date: moment.unix(closing.exited_at).utc().format('YYYY-MM-DD') } : {}),
      });

      dispatch({
        type: CLOSE_SERVICE_CASE,
        payload: caseResponse,
        contactId,
      });
      Notifier.dispatch(caseResponse.status, 'Case Successfully closed');

      return caseResponse;
    } catch (error) {
      Notifier.handleErrors(error);
      return error;
    }
  };
}
