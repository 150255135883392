import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { noop } from 'lodash';
import { validations } from '@unite-us/client-utils';
import { TextField } from '@unite-us/ui';
import './InteractionNoteField.scss';

const InteractionNoteField = ({
  afterLabelContent,
  hintText,
  label,
  required,
  name,
}) => (
  <div className="interaction-note-field form-group">
    <Field
      name={name}
      validate={required ? validations.isRequired : noop}
    >
      {(fieldProps) => (
        <TextField
          afterLabelContent={afterLabelContent}
          hint={hintText}
          id="interactionNote"
          label={label}
          placeholder="Enter your note here..."
          required={required}
          rows={5}
          validations={required ? validations.isRequired : noop}
          {...fieldProps}
        />
      )}
    </Field>
  </div>
);

InteractionNoteField.propTypes = {
  afterLabelContent: PropTypes.node,
  hintText: PropTypes.string,
  label: PropTypes.node,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

InteractionNoteField.defaultProps = {
  afterLabelContent: undefined,
  hintText: undefined,
  label: 'Note',
  required: true,
};

export default InteractionNoteField;
