import Notifier from 'common/helpers/Notifier';
import {
  UPDATE_PAYER_STARTED,
  UPDATE_PAYER_SUCCESS,
  UPDATE_PAYER_ERROR,
} from 'actions';
import { apiV1 } from 'src/api/config';
import { isHttpError } from 'common/utils/Error';
import { isHttpSuccess } from 'src/api/utils/httpStatus';

const buildPayer = (params) => ({
  external_id: params.external_id,
  name: params.name,
  state: params.state,
});

export default function updatePayer(params) {
  return async (dispatch) => {
    dispatch({ type: UPDATE_PAYER_STARTED });

    const payer = buildPayer(params);
    const payload = await apiV1.updateRecord('payer', params.id, payer);

    if (!(payload && isHttpSuccess(payload.status))) {
      dispatch({ type: UPDATE_PAYER_ERROR });
      if (isHttpError(payload)) {
        Notifier.dispatch(
          'error',
          'Error updating payer.',
        );
      } else {
        Notifier.handleErrors(payload);
      }
      return payload;
    }

    Notifier.dispatch(payload.status, 'Payer updated');

    dispatch({
      type: UPDATE_PAYER_SUCCESS,
      payload: payload.data.data,
    });

    return payload;
  };
}
