import { coreApi } from 'src/api/config';
import { CORE_API, CORE_BASE_URL } from 'src/config/env/env.config';
import {
  FETCH_GROUPS_REFERRAL_RECEIVED,
  FETCH_GROUPS_REFERRAL_SENT,
  FETCH_REFERRAL_DOCUMENTS,
  REFERRALS_UNSET_REFETCH,
  FETCH_REFERRAL_CONTACT,
  SET_CONTACTS_IS_FETCHING,
  UNSET_CONTACTS_IS_FETCHING,
} from 'actions';
import Notifier from 'common/helpers/Notifier';

const fetchGroupsReferral = (groupId, referralId) => (
  async (dispatch) => {
    try {
      const response = await coreApi.findRecord('referral', referralId);
      const referral = response.data.data;
      let isSensitive = false;
      let documents;

      dispatch({ type: SET_CONTACTS_IS_FETCHING }); // from fetchReferralContact action

      // Referral is sensitive if fetching the referral's case returns a 403
      try {
        await coreApi.populateRelationship('case', 'case', referral);

        await coreApi.populateRelationship('case.outcome', 'outcome', referral);
        const filesResponse = await coreApi.query('file_upload', { record: referral.case.id, 'record.type': 'case' });
        documents = filesResponse.data.data;
        documents.forEach((d) => {
          /* eslint-disable no-param-reassign */
          d.url = `${CORE_BASE_URL}${d.path}`;
          d.thumbnail_url = `${CORE_API}${d.path}`;
          d.title = d.filename;
          d.pdf_url = `${CORE_BASE_URL}${d.path}`;
          /* eslint-enable no-param-reassign */
        });

        await Promise.all([
          coreApi.populateRelationship('case.person', 'person', referral),
          coreApi.populateRelationship('case.service_type', 'service', referral),
          coreApi.populateRelationship('receiving_provider', 'provider', referral),
          coreApi.populateRelationship('sending_provider', 'provider', referral),
          coreApi.populateRelationship('sending_network', 'network', referral),
          coreApi.populateRelationship('receiving_network', 'network', referral),
          coreApi.populateRelationship('case.service_authorization', 'service_authorization', referral),
          coreApi.populateRelationship('sending_employee', 'employee', referral),
        ]);

        await Promise.all([
          coreApi.populateRelationship(
            'case.service_authorization.fee_schedule_program',
            'fee_schedule_program',
            referral,
          ),
          coreApi.populateRelationship(
            'case.service_authorization.service_authorization_denial_reason',
            'service_authorization_denial_reason',
            referral,
          ),
        ]);

        isSensitive = false;
      } catch (err) {
        if (err.response.status === 403) {
          await coreApi.populateRelationship('case_summary', 'case_summary', referral);
          await Promise.all([
            coreApi.populateRelationship('case_summary.person', 'person', referral),
            coreApi.populateRelationship('case_summary.service', 'service', referral),
            coreApi.populateRelationship('case_summary.service_authorization', 'service_authorization', referral),
            coreApi.populateRelationship('receiving_provider', 'provider', referral),
            coreApi.populateRelationship('sending_provider', 'provider', referral),
            coreApi.populateRelationship('sending_network', 'network', referral),
            coreApi.populateRelationship('sending_employee', 'employee', referral),
          ]);

          referral.case = referral.case_summary;
          referral.case.service_type = referral.case_summary.service;
          isSensitive = true;
        }
      }

      // Failsafe - most/all of component logic use variable at `referral.case.service_type`
      // TODO: deprecate double usage `referral.case.service_type` vs `referral.case.service`
      referral.case.service = referral.case.service_type;

      if (referral.state === 'sent') {
        referral.status = 'pending';
      }
      if (referral.state === 'in_review') {
        referral.status = 'in_review';
      }
      if (referral.state === 'off_platform') {
        referral.status = 'in_review';
      }
      if (referral.state === 'recalled') {
        referral.status = 'recalled';
      }
      if (referral.state === 'draft') {
        referral.status = 'draft';
      }

      const contact = referral.case.person;
      const [addresses] = await Promise.all([
        coreApi.query('addresses', { person: contact.id }),
        coreApi.populateRelationship('receiving_program', 'program', referral),
      ]);
      contact.addresses = addresses.data.data;
      referral.contact = contact;
      referral.description = referral.case.description;
      const payload = {
        ...response,
        data: {
          ...response.data,
          data: {
            ...response.data.data,
            ...(isSensitive ? null : { referred_to_program: referral.receiving_program }),
            referred_by_network: referral.sending_network,
            referred_to_network: referral.receiving_network,
            referred_by_group: referral.sending_provider,
            referred_to_group: referral.receiving_provider,
            service_type: referral.case.service_type,
            service: referral.case.service_type,
            contact: referral.case.person,
            type: 'referrals_referral',
          },
        },
      };

      dispatch({
        type: payload.data.data.referred_by_group.id === groupId ?
          FETCH_GROUPS_REFERRAL_SENT :
          FETCH_GROUPS_REFERRAL_RECEIVED,
        payload,
      });

      if (!isSensitive) {
        dispatch({
          type: FETCH_REFERRAL_DOCUMENTS,
          documents,
        });
      }

      const contactResponse = JSON.parse(JSON.stringify(payload));
      contactResponse.data.data = contact;

      // from fetchReferralContact action:
      dispatch({ type: FETCH_REFERRAL_CONTACT, payload: contactResponse });
      dispatch({ type: UNSET_CONTACTS_IS_FETCHING });
      dispatch({ type: REFERRALS_UNSET_REFETCH });

      return isSensitive ? 'sensitive' : payload;
    } catch (error) {
      Notifier.handleErrors(error);
      return error;
    }
  }
);

export default fetchGroupsReferral;
