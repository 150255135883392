import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Form, Field } from 'react-final-form';
import { TextField, SelectField } from '@unite-us/ui';
import { validations } from '@unite-us/client-utils';
import { useCurrentEmployeeId } from 'common/contexts/CurrentEmployeeContext';
import { SecondaryButton } from 'common/TailwindComponents';
import NoteDisclaimer from 'common/Disclaimer/NoteDisclaimer';
import DialogV2 from 'common/modal/DialogV2';

import { useRejectServiceAuthorization } from '../hooks/useUpdateServiceAuthorization';
import useServiceAuthorizationDenialReasons from '../hooks/useServiceAuthorizationDenialReasons';

const RejectServiceAuthorizationButton = ({ authorization }) => {
  const modalRef = useRef();
  const [opened, setOpened] = useState(false);

  const currentEmployeeId = useCurrentEmployeeId();
  const feeScheduleId = get(authorization, 'fee_schedule_program.fee_schedule.id');
  const reasons = useServiceAuthorizationDenialReasons(opened && feeScheduleId);

  const openDialog = () => {
    setOpened(true);
    modalRef.current.openDialog();
  };

  const closeDialog = ({ reset }) => {
    // Make sure the dialog is still mounted before updating state
    if (modalRef.current) {
      reset();
      setOpened(false);
      modalRef.current.closeDialog();
    }
  };

  const rejectAuthorization = useRejectServiceAuthorization();
  const sendRejection = async ({ rejection_reason, rejection_note }, form) => {
    const payload = {
      authorization,
      reviewer: currentEmployeeId,
      denial_reason: rejection_reason,
      reviewer_note: rejection_note,
    };
    await rejectAuthorization(payload);
    closeDialog(form);
  };

  return (
    <>
      <SecondaryButton
        label="Reject"
        ariaLabel="reject service authorization"
        className="items-center"
        dataTestId="reject-authorization-button"
        icon="IconCrossCircle"
        onClick={openDialog}
      />

      <Form
        onSubmit={sendRejection}
        render={({ handleSubmit, form }) => (
          <DialogV2
            cancelHandler={() => { closeDialog(form); }}
            confirmLabel="Reject Authorization Request"
            confirmationHandler={handleSubmit}
            dataTestId="reject-auth-request-dialog"
            confirmButtonTestId="reject-auth-request-dialog-confirm-button"
            ref={modalRef}
            scrollable={false}
            title={`Reject Authorization Request #${authorization.short_id}`}
            width="3xl"
          >
            <div className="text-sm font-light">
              To continue with rejecting this authorization request,
              provide more information on why.
            </div>
            <Field
              name="rejection_reason"
              validate={(value) => validations.isRequired(value)}
            >
              {(params) => (
                <SelectField
                  className="pt-4 max-w-sm text-brand-blue relative break-normal"
                  label="Rejection Reason"
                  id="rejection_reason"
                  options={reasons.map((reason) => ({ label: reason.display_name, value: reason.id }))}
                  placeholder="Choose Code"
                  required
                  truncateOptions={false}
                  {...params}
                />
                )}
            </Field>
            <Field name="rejection_note">
              {(params) => (

                <TextField
                  placeholder="Additional details..."
                  afterLabelContent={<NoteDisclaimer />}
                  required={false}
                  label="Note"
                  id="rejection_note"
                  style={{ error: { height: 0 } }}
                  {...params}
                  maxLength={1000}
                />
                )}
            </Field>
          </DialogV2>
        )}
      />
    </>
  );
};

RejectServiceAuthorizationButton.propTypes = {
  authorization: PropTypes.shape({
    id: PropTypes.string.isRequired,
    short_id: PropTypes.string.isRequired,
    fee_schedule_program: PropTypes.shape({
      fee_schedule: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }).isRequired,
};

export default RejectServiceAuthorizationButton;
