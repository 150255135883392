import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  Menu,
  MenuItem,
} from '@unite-us/ui';
import EmployeeNotificationMenu from 'src/components/Navigation/components/EmployeeNotificationMenu';
import MessageCenterNotification from 'src/components/Navigation/components/MessageCenterNotification';
import { routeOnKeyDown } from 'src/components/Navigation/utils';
import callOrLog from 'common/utils/callOrLog';
import { LOGIN } from 'common/utils/EventTracker/utils/eventConstants';
import { LogoutAuth } from 'common/form';
import submitLogoutForm from 'src/common/utils/Auth/submitLogoutForm';
import '../stylesheets/rightNav.scss';
import { isNetworkUser } from '../../User/utils';

const OrgNode = ({
  checked,
  name,
}) => (
  <div className="check-row">
    <div className="check-row__icon">
      {checked && <Icon icon="IconCheck" className="check-row__check" />}
    </div>
    <div className="check-row__text">{name}</div>
  </div>
);

OrgNode.defaultProps = {
  checked: false,
};

OrgNode.propTypes = {
  checked: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

class RightNav extends Component {
  constructor(props) {
    super(props);

    this.setGroupId = this.setGroupId.bind(this);
    this.trackSetGroup = this.trackSetGroup.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { loggingOut } = this.props;

    if (!prevProps.loggingOut && loggingOut) {
      const logoutAuth = this.authFormWrapper.getWrappedInstance();

      logoutAuth.handleLogout();
    }
  }

  setGroupId({ id, name }) {
    this.trackSetGroup({ id, name });
    this.props.setGroupId(id);
  }

  trackSetGroup({ id, name }) {
    // Only create a log if we have a group ID.
    if (id) {
      callOrLog(() => this.context.eventTracker(LOGIN.session, {
        session_group_id: id,
        session_group_name: name,
      }));
    }
  }

  render() {
    const {
      canCreateContacts,
      canCreateReferrals,
      canCreateScreenings,
      canViewContacts,
      canViewGroupSettings,
      canViewNotifications,
      currentEmployee,
      groupId,
      groups,
      isNetworkLead,
      isOrgActive,
      onHelpClick,
      onLearnClick,
      onLogoutClick,
      onNewClientClick,
      onNewReferralClick,
      onNewScreeningClick,
      onNewTaskClick,
      onOrgSettingsProfileClick,
      onOrgSettingsUsersClick,
      onSearchClick,
      onUserClick,
      user,
      userName,
      isCaseManagerAndAbove,
      isMessagingEnabled,
      showLearnLink,
      orgAdminEnhancementsFlag,
      showTasks,
    } = this.props;
    // Disable acces to message-center if employee only has 1 primary role that it is network_user
    const primaryRoles = currentEmployee?.roles?.filter((obj) => obj.role_type === 'primary');

    return (
      <div className="right-nav">
        {
          isMessagingEnabled && !(primaryRoles?.length === 1 && isNetworkUser(user, groupId)) ? (
            <MessageCenterNotification currentEmployee={currentEmployee} />
          ) : null
        }

        {
          isOrgActive && (
            <>
              {
                canViewNotifications ? (
                  <EmployeeNotificationMenu currentEmployee={currentEmployee} showTasks={showTasks} />
                ) : null
              }

              {
                canViewContacts && !isNetworkLead && (

                  <div><Icon
                    ariaLabel="Search"
                    id="right-nav-search-btn"
                    className="right-nav__app-bar-search-btn mr-half"
                    color="white"
                    icon="IconSearch"
                    onClick={onSearchClick}
                    size={20}
                  />
                  </div>
                )
              }
              {
                (canCreateReferrals || canCreateScreenings || canCreateContacts || showTasks) &&
                !isNetworkLead && isCaseManagerAndAbove && (
                  <Menu
                    id="right-nav-create-menu-btn"
                    className="right-nav__new-item-btn ml-half mr-half"
                    anchorElement={
                      (
                        <Icon
                          ariaLabel="Add"
                          icon="IconPlusCircle"
                          color="white"
                          size={20}
                          canReceiveFocus={false}
                        />
                      )
                    }
                    position="bottom-end"
                  >
                    {canCreateReferrals && (
                      <MenuItem
                        id="right-nav-create-referral-btn"
                        className="right-nav__new-referral-menu-btn"
                        primaryNode="New Referral"
                        onClick={onNewReferralClick}
                        onKeyDown={routeOnKeyDown(onNewReferralClick)}
                      />
                    )}
                    {canCreateContacts && (
                      <MenuItem
                        id="right-nav-create-client-btn"
                        className="right-nav__new-client-menu-btn"
                        primaryNode="New Client"
                        onClick={onNewClientClick}
                        onKeyDown={routeOnKeyDown(onNewClientClick)}
                      />
                    )}
                    {canCreateScreenings && (
                      <MenuItem
                        id="right-nav-create-screening-btn"
                        className="right-nav__new-screening-menu-btn"
                        primaryNode="New Screening"
                        onClick={onNewScreeningClick}
                        onKeyDown={routeOnKeyDown(onNewScreeningClick)}
                      />
                    )}
                    {showTasks && (
                      <MenuItem
                        id="right-nav-create-task-btn"
                        className="right-nav__new-task-menu-btn"
                        primaryNode="New Task"
                        onClick={onNewTaskClick}
                        onKeyDown={routeOnKeyDown(onNewTaskClick)}
                      />
                    )}
                  </Menu>
                )
              }
              <div className="right-nav__separator ml-half mr-half" />
            </>
          )
        }

        <Menu
          id="right-nav-user-menu-btn"
          className="right-nav__user-navigation-btn ml-half mr-half"
          anchorElement={(
            <div
              className="right-nav__user-button"
            >
              <span className="right-nav__user-name">{userName}</span>
              <Icon
                icon="IconUserCircle"
                size={20}
                color="white"
              />
            </div>
          )}
          position="bottom-end"
        >
          <MenuItem
            id="right-nav-user-settings-btn"
            className="user-settings-btn"
            primaryNode={orgAdminEnhancementsFlag ? 'My Profile' : 'User Settings'}
            onClick={onUserClick}
            onKeyDown={routeOnKeyDown(onUserClick)}
          />
          <MenuItem
            id="right-nav-user-logout-btn"
            className="logout-btn"
            primaryNode="Log Out"
            onClick={submitLogoutForm}
            onKeyDown={routeOnKeyDown(onLogoutClick)}
          />
        </Menu>

        {
          <LogoutAuth />
        }

        <div className="right-nav__separator ml-half mr-half" />
        {/* user is org admin and associated with several orgs -> show gear (current org settings page) icon */}
        {/* user is associated with several orgs -> show building (org navigation) icon */}

        {(orgAdminEnhancementsFlag) && (
          <>
            {(canViewGroupSettings) && (
              <>
                <Menu
                  id="right-nav-org-settings-btn"
                  className="right-nav ml-half mr-half"
                  data-test-element="org_settings_button"
                  aria-label={'Organization Settings'}
                  anchorElement={(
                    <Icon
                      color="white"
                      icon="IconSettings"
                      size={18}
                      onClick={onOrgSettingsProfileClick}
                      onKeyDown={onOrgSettingsProfileClick}
                    />
                  )}
                  position="notification"
                />
              </>
            )}

            {(groups.length > 1) && (
              <Menu
                id="right-nav-org-menu-btn"
                className="right-nav__org-navigation-btn ml-half mr-half"
                anchorElement={(
                  <Icon
                    ariaLabel="Organization"
                    icon="IconBuildingO1"
                    color="white"
                    size={20}
                    canReceiveFocus={false}
                  />
                )}
                position="bottom-end"
              >
                {(
                  groups.map(({ name, id }, index) => (
                    <MenuItem
                      id={`right-nav-org-btn-${index}`}
                      key={id}
                      className="org-btn"
                      primaryNode={<OrgNode checked={groupId === id} name={name} />}
                      onClick={() => this.setGroupId({ id, name })}
                      onKeyDown={routeOnKeyDown(() => this.setGroupId({ id, name }))}
                    />
                  ))
                )}
              </Menu>
            )}
          </>
        )}

        {(!orgAdminEnhancementsFlag) && (
          <>
            {(canViewGroupSettings || groups.length > 1) && (
              <Menu
                id="right-nav-org-menu-btn"
                className="right-nav__org-navigation-btn ml-half mr-half"
                anchorElement={(
                  <Icon
                    ariaLabel="Organization"
                    icon="IconBuildingO"
                    color="white"
                    size={20}
                    canReceiveFocus={false}
                  />
                )}
                position="bottom-end"
              >

                {/* user is not org admin; user is associated with several orgs */}

                <div className="org-list">
                  {
                    (!canViewGroupSettings) && groups.map(({ name, id }, index) => (
                      <MenuItem
                        id={`right-nav-org-btn-${index}`}
                        key={id}
                        className="org-btn"
                        primaryNode={<OrgNode checked={groupId === id} name={name} />}
                        onClick={() => this.setGroupId({ id, name })}
                        onKeyDown={routeOnKeyDown(() => this.setGroupId({ id, name }))}
                      />
                    ))
                  }
                </div>

                {/* user is org admin; user is associated with one org */}

                {
                  (canViewGroupSettings && groups.length === 1) && ([
                    <MenuItem
                      id="right-nav-org-users-btn"
                      key={1}
                      className="right-nav__org-settings-users-btn"
                      primaryNode="Users"
                      onClick={onOrgSettingsUsersClick}
                      onKeyDown={routeOnKeyDown(onOrgSettingsUsersClick)}
                    />,
                    <MenuItem
                      id="right-nav-org-profile-btn"
                      key={3}
                      className="right-nav__org-settings-profile-btn"
                      primaryNode="Organization Profile"
                      onClick={onOrgSettingsProfileClick}
                      onKeyDown={routeOnKeyDown(onOrgSettingsProfileClick)}
                    />,
                  ])
                }

                {/* user is org admin; user is associated with several orgs */}

                {
                  (canViewGroupSettings && groups.length > 1) && ([
                    <MenuItem
                      id="right-nav-my-organizations-btn"
                      key={1}
                      primaryNode="My Organizations"
                      menuItems={groups.map(({ name, id }, index) => (
                        <MenuItem
                          id={`right-nav-org-btn-${index}`}
                          key={id}
                          className="org-btn"
                          primaryNode={<OrgNode checked={groupId === id} name={name} />}
                          onClick={() => this.setGroupId({ id, name })}
                          onKeyDown={routeOnKeyDown(() => this.setGroupId({ id, name }))}
                        />
                      ))}
                      subMenuTarget="right-left"
                    />,
                    <MenuItem
                      id="right-nav-organization-settings-btn"
                      key={2}
                      primaryNode="Organization Settings"
                      menuItems={[
                        <MenuItem
                          id="right-nav-org-users-btn"
                          key={1}
                          className="right-nav__org-settings-users-btn"
                          primaryNode="Users"
                          onClick={onOrgSettingsUsersClick}
                          onKeyDown={routeOnKeyDown(onOrgSettingsUsersClick)}
                        />,
                        <MenuItem
                          id="right-nav-org-profile-btn"
                          key={3}
                          className="right-nav__org-settings-profile-btn"
                          primaryNode="Organization Profile"
                          onClick={onOrgSettingsProfileClick}
                          onKeyDown={routeOnKeyDown(onOrgSettingsProfileClick)}
                        />,
                      ]}
                      subMenuTarget="right-left"
                    />,
                  ])
                }
              </Menu>
            )}
          </>
        )}

        {
          showLearnLink && (
            <Icon
              ariaLabel="Learn"
              id="right-nav-learn-btn"
              className="learn-btn ml-half mr-half"
              color="white"
              icon="IconGraduationCap"
              onClick={onLearnClick}
              size={20}
            />
          )
        }

        <Icon
          ariaLabel="Help"
          id="right-nav-help-btn"
          className="help-btn ml-half mr-half"
          color="white"
          icon="IconQuestionCircleO"
          onClick={onHelpClick}
          size={20}
        />

      </div>
    );
  }
}

RightNav.defaultProps = {
  isCaseManagerAndAbove: false,
  canCreateReferrals: false,
  canCreateScreenings: false,
  canViewContacts: false,
  canViewGroupSettings: false,
  canCreateContacts: false,
  groups: [],
  groupId: '',
};

RightNav.propTypes = {
  isCaseManagerAndAbove: PropTypes.bool,
  canCreateReferrals: PropTypes.bool,
  canCreateScreenings: PropTypes.bool,
  canCreateContacts: PropTypes.bool,
  canViewContacts: PropTypes.bool,
  canViewGroupSettings: PropTypes.bool,
  groupId: PropTypes.string,
  groups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  isNetworkLead: PropTypes.bool,
  loggingOut: PropTypes.bool.isRequired,
  onHelpClick: PropTypes.func.isRequired,
  onNewClientClick: PropTypes.func.isRequired,
  onNewReferralClick: PropTypes.func.isRequired,
  onNewScreeningClick: PropTypes.func.isRequired,
  onNewTaskClick: PropTypes.func.isRequired,
  onOrgSettingsUsersClick: PropTypes.func.isRequired,
  onOrgSettingsProfileClick: PropTypes.func.isRequired,
  onSearchClick: PropTypes.func.isRequired,
  onUserClick: PropTypes.func.isRequired,
  onLearnClick: PropTypes.func.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
  setGroupId: PropTypes.func.isRequired,
  canViewNotifications: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  userName: PropTypes.string.isRequired,
  isOrgActive: PropTypes.bool.isRequired,
  currentEmployee: PropTypes.object.isRequired,
  isMessagingEnabled: PropTypes.bool.isRequired,
  showLearnLink: PropTypes.bool.isRequired,
  orgAdminEnhancementsFlag: PropTypes.bool.isRequired,
  showTasks: PropTypes.bool.isRequired,
};

RightNav.defaultProps = {
  isNetworkLead: false,
};

RightNav.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

export default RightNav;
