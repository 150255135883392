import React from 'react';
import PropTypes from 'prop-types';
import {
  PaginationInformation,
  PageSelection,
  PageSizeSelector,
} from 'src/common/Pagination';

export const Pagination = ({
  pageNumber,
  pageSize,
  setPageNumber,
  setPageSize,
  totalItemCount,
  totalPageCount,
  scrollPaginationElement,
  showPageSizeSelector,
  showLabels,
}) => (
  <nav
    aria-label="Table navigation"
    data-test-element="table-navigation"
    className="flex justify-between items-end text-14px space-x-20"
  >
    <PaginationInformation
      className="font-regular-font text-text-blue"
      pageNumber={pageNumber}
      pageSize={pageSize}
      totalItemCount={totalItemCount}
    />
    {totalItemCount ? (
      <>
        <PageSelection
          className="flex-grow max-w-3xl"
          pageNumber={pageNumber}
          showLabels={showLabels}
          setPageNumber={setPageNumber}
          totalPageCount={totalPageCount}
          scrollPaginationElement={scrollPaginationElement}
        />
        {showPageSizeSelector ? (
          <PageSizeSelector
            setPageNumber={setPageNumber}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        ) : null}
      </>
    ) : null}
  </nav>
);

Pagination.propTypes = {
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  totalItemCount: PropTypes.number.isRequired,
  totalPageCount: PropTypes.number.isRequired,
  scrollPaginationElement: PropTypes.func.isRequired,
  showPageSizeSelector: PropTypes.bool,
  showLabels: PropTypes.bool,
};

Pagination.defaultProps = {
  showPageSizeSelector: true,
  showLabels: true,
};

export default Pagination;
