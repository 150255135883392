import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Header from 'common/display/ContactStepper/components/Header';
import { patchAssessment as patchCaseAssessment } from 'actions/Assessment/Contact/Group';
import { browserHistory } from 'common/utils/browserHistory';
import { uup459SupersetPhase2 } from 'common/utils/FeatureFlags/flags';
import { Button } from '@unite-us/ui';
import _ from 'lodash';
import { validateReduxForm } from 'common/form';
import { destroyAllDropzones } from 'common/form/FileUpload/actions/FileUpload';
import enforcedConsentRoute from 'common/utils/Navigation/enforcedConsentRoute';
import { DOMAIN_CONSTANTS } from 'src/common/constants';
import { coreApi } from 'src/api/config';
import {
  CREATE_CLIENT_FORM,
} from 'src/components/Contacts/constants';
import FormForm from 'common/form/FormForm';

import { returnedAssessment, setAssessmentsResponses } from 'actions/Assessment';

import { patchAssessment } from 'actions/Assessment/Case/Group';
import { removeSelectedContact } from 'actions/Contact';

import addNotification from 'common/utils/Notifications/actions/AddNotification';
import {
  newReferralFormFieldsAsServices,
} from 'src/components/Referrals/utils/form';

export class AddSupportingContactDetailsStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedFormCard: 0,
      formsToLoad: _.map(props.profileForms, 'form.id'),
      showErrors: false,
    };

    this.formRefs = {};
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(this.state) !== JSON.stringify(nextState)) {
      return true;
    }
    return false;
  }

  handleExpandChange = (index) => {
    this.setState({ expandedFormCard: index });
  }

  onFormLoaded = (id) => {
    this.setState({ formsToLoad: _.without(this.state.formsToLoad, id) });
  }

  onNextClick = async () => {
    const {
      contact,
      contactId,
      activeStep,
      clientRoute,
      isSupersetEnabled,
      context,
    } = this.props;

    if (!await this.onSaveCaseAssessments()) return;

    if (!clientRoute && activeStep.onStepComplete) {
      if (!context.includes(DOMAIN_CONSTANTS.SCREENING) && isSupersetEnabled) {
        browserHistory.push({
          pathname: '/referrals/create/add-resources',
          search: `?person=${contactId}`,
          state: { source: 'client' },
        });
      } else {
        activeStep.onStepComplete({
          contact,
          contactId,
          eventTracker: this.context.eventTracker,
          removeSelectedContact: this.props.removeSelectedContact,
        });
      }
    } else {
      this.goToConsentStep();
    }
  }

  onSaveCaseAssessments = async () => {
    try {
      const assessmentsResult = await this.submitCaseAssessments();
      if (!assessmentsResult) {
        this.props.addNotification({
          payload: {
            status: 'warning',
            statusText: 'Please complete the required fields in order to continue.',
          },
        });
        return false;
      }

      await Promise.all(assessmentsResult);

      this.props.addNotification({
        payload: {
          status: 'success',
          statusText: 'Supporting Assessments Successfully Saved',
        },
      });
      return true;
    } catch (e) {
      this.props.addNotification({
        payload: {
          status: 'warning',
          statusText: 'There was a problem saving the assessment with your contact.',
        },
      });
      return false;
    }
  }

  submitCaseAssessments = () => {
    this.setState({ showErrors: true });

    const hasErrors = Object.keys(this.formRefs).some((k) => this.formRefs[k]?.ref?.hasErrors());
    if (hasErrors) return null;

    const data = Object.keys(this.formRefs).map((k) => {
      const requestBody = this.formRefs[k].buildRequestBody();
      return coreApi.createRecord('form_submission', requestBody);
    });

    return data;
  }

  goToConsentStep = () => {
    const { contact } = this.props;
    enforcedConsentRoute({
      contact,
      itemType: DOMAIN_CONSTANTS.CONTACT,
      to: `/facesheet/${contact.id}`,
    });
    this.props.removeSelectedContact();
  }

  render() {
    const {
      profileForms,
      groupId,
      contactId,
      currentEmployee,
      submitting,
      handleSubmit,
      // invalidForms,
      header,
    } = this.props;

    const { showErrors } = this.state;

    return (
      <div>
        <div className="referral-header">
          <Header
            header={header}
            subHeader="Add Supporting Information"
          />
        </div>

        {
          profileForms.map((formUsage, index) => (
            <FormForm
              id={`group-form-${index}`}
              className={`custom-form-${index}`}
              setRef={(c) => { this.formRefs[formUsage.form.id] = c; }}
              key={`${formUsage.id}formform`}
              formId={formUsage.form.id}
              groupId={groupId}
              handleExpandChange={() => this.handleExpandChange(index)}
              expanded={index === this.state.expandedFormCard}
              onFormLoaded={this.onFormLoaded}
              currentEmployee={currentEmployee}
              hasErrors={showErrors ? this.formRefs[formUsage.form.id]?.ref?.hasErrors() : false}
              contextType="person"
              context={contactId}
              usage_type="profile"
            />
          ))
        }

        <form>
          <footer className="referral-footer">
            <div style={{ float: 'right' }}>
              <Button
                id="next-btn"
                className="ml-one"
                onClick={handleSubmit(this.onNextClick)}
                label="Next"
                primary
                disabled={submitting}
              />
            </div>
          </footer>
        </form>
      </div>
    );
  }
}

AddSupportingContactDetailsStep.propTypes = {
  addNotification: PropTypes.func.isRequired,
  contact: PropTypes.object.isRequired,
  profileForms: PropTypes.array,
  contactId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  removeSelectedContact: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  activeStep: PropTypes.object.isRequired,
  clientRoute: PropTypes.bool.isRequired,
  currentEmployee: PropTypes.object.isRequired,
  isSupersetEnabled: PropTypes.bool,
  context: PropTypes.string,
};

AddSupportingContactDetailsStep.defaultProps = {
  profileForms: [],
  isSupersetEnabled: false,
  context: '',
};

function mapStateToProps(state, ownProps) {
  const {
    selectedContact: contactId,
  } = state;

  const groupId = _.get(state, 'session.groupId');
  const contacts = _.get(state, 'contacts.contacts', []);
  const contact = _.find([...state.searchedContacts, ...contacts], { id: contactId }) || {};
  const currentEmployee = _.get(state, 'globalState.currentEmployee', {});

  return {
    contact,
    contactId,
    currentEmployee,
    groupId,
    profileForms: ownProps.profileForms,
    // invalidForms,
    removeSelectedContact: PropTypes.func.isRequired,
    isSupersetEnabled: uup459SupersetPhase2(state),
  };
}

const fields = newReferralFormFieldsAsServices();

export default validateReduxForm(
  {
    form: CREATE_CLIENT_FORM,
    fields,
    destroyOnUnmount: false,
  },
  mapStateToProps,
  {
    destroyAllDropzones,
    patchAssessment,
    setAssessmentsResponses,
    returnedAssessment,
    patchCaseAssessment,
    addNotification,
    removeSelectedContact,
  },
)(AddSupportingContactDetailsStep);
