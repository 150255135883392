import { get, map, find, join, compact } from 'lodash';
import dates from "../../../utils/dates";
var getValueOptions = function getValueOptions(question, value) {
  var result = map(value, function (val) {
    var option = find(question.input_options, {
      id: val
    });
    return get(option, 'option_label', null);
  });
  return join(compact(result), ', ');
};
var getValueSingleOption = function getValueSingleOption(question, value) {
  var option = find(question.input_options, {
    id: value
  });
  return get(option, 'option_label', '');
};
var formatDuration = function formatDuration(value) {
  var start = dates.formatDate(parseInt(value.start, 10));
  var end = dates.formatDate(parseInt(value.end, 10));
  return "".concat(start, " to ").concat(end);
};
function getQuestionDisplay(question) {
  var value = get(question, 'response.response_value', null);
  if (!value) {
    return '';
  }
  switch (question.input_type) {
    case 'select':
    case 'checkbox':
      return getValueOptions(question, value);
    case 'radio':
      return getValueSingleOption(question, value);
    case 'date':
      return dates.formatDate(parseInt(value, 10));
    case 'duration':
      return formatDuration(value);
    default:
      return value.toString();
  }
}
export default getQuestionDisplay;