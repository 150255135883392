import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { Component } from 'react';
import { CaseOONGroupSelect } from 'src/components/Cases/CaseOONGroupsSelector/components/CaseOONGroupSelect';
import { ShowHide, validations } from '@unite-us/client-utils';
import { Button, InputField, Icon } from '@unite-us/ui';
import { FieldErrorMessage } from 'common/form';
import {
  MULTI_OFF_PLATFORM_SENSITIVE_SERVICE_REFERRALS_MESSAGE,
} from 'src/components/Referrals/ReferralServicesForm/constants';
import { isEmpty } from 'lodash';

export class ServiceCaseReferredToField extends Component {
  constructor(props) {
    super(props);

    this.addGroupField = this.addGroupField.bind(this);
    this.addCustomField = this.addCustomField.bind(this);
    this.clearCustomField = this.clearCustomField.bind(this);
    this.removeGroupField = this.removeGroupField.bind(this);
  }

  addGroupField() {
    this.props.selectedGroups.addField();
  }

  removeGroupField(selected, index) {
    if (!selected) {
      this.props.selectedGroups.removeField(index);
    }
  }

  addCustomField() {
    this.props.customGroups.addField();
  }

  clearCustomField(index) {
    this.props.customGroups.removeField(index);
  }

  render() {
    const {
      canPaginateNetworkGroups,
      useProgramBasedSearch,
      casesOONReferralUnlicensedOrgs,
      debouncedSearchNetworkGroups,
      fetchingOONGroups,
      referredTo,
      oonGroups,
      originCoordinates,
      registerField,
      selectedGroups,
      customGroups,
      originLatLng,
      showError,
    } = this.props;

    const noGroupSelected = selectedGroups.some((g) => !g.group.value);
    const validateCustomProgram = (value = '') => {
      if (!isEmpty(value) && !value.match(/[A-Za-z0-9]/)) {
        return 'Custom programs need at least one alphanumeric character.';
      }
      return '';
    };

    const composeValidators = (...validators) => (
      (value) => validators.reduce((error, validator) => error || validator(value), undefined)
    );

    const hide = selectedGroups.some((g) => !g.group.value) || fetchingOONGroups;
    const hideCustom = customGroups.some((g) => !g.group.value);

    const caseOONShareClass = () => classNames({
      'case-oon-share-btns': !hide,
    });

    const selectedGroupIds = selectedGroups.map((group) => (
      group.group.value.id
    ));

    const recipients = showError ? selectedGroups.filter((g) => g.group.value) : selectedGroups;
    const error = MULTI_OFF_PLATFORM_SENSITIVE_SERVICE_REFERRALS_MESSAGE;

    if (casesOONReferralUnlicensedOrgs) {
      return (
        <div>
          {recipients.map((selected, index) => (
            <div className="row" key={selected.group.value.id}>
              <div className="col-xs-12 case-oon-groups-selector__programs-padded-col">
                <CaseOONGroupSelect
                  allowEmpty={false}
                  debouncedSearchNetworkGroups={debouncedSearchNetworkGroups}
                  canPaginateNetworkGroups={canPaginateNetworkGroups}
                  useProgramBasedSearch={useProgramBasedSearch}
                  groupField={selected.group}
                  index={index}
                  isOONCase
                  oonGroupFields={recipients}
                  registerField={registerField}
                  removeGroupField={this.removeGroupField}
                  selectedGroupIds={selectedGroupIds}
                  serviceCaseReferredToView
                  suggestedGroups={oonGroups}
                  originLatLng={originLatLng}
                  originCoordinates={originCoordinates}
                  shouldSearchOnChange={false}
                />
              </div>
            </div>
          ))}
          <ShowHide hide={hide}>
            <div className="row mb-one">
              <div className="col-xs-12">
                <div className={caseOONShareClass()}>
                  <Button
                    className="mr-one"
                    id="add-another-oon-group-btn"
                    label="+ ADD ANOTHER OUT OF NETWORK RECIPIENT"
                    onClick={this.addGroupField}
                  />
                </div>
              </div>
            </div>
          </ShowHide>

          { useProgramBasedSearch ? (
            <div className="mt-4">
              {
                customGroups.map((field, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className="row" key={`service-case-oon-custom-program-input-${index}`}>
                    <div className="col-xs-12">
                      <InputField
                        autoComplete="off"
                        className="referral-oon-custom-program-input"
                        id={`input-field-oon-program-group-${index}`}
                        field={field.group}
                        label="oon-custom-program-input"
                        hideLabel
                        inline={false}
                        ref={registerField}
                        placeholder="Type a custom program"
                        min={1}
                        validations={noGroupSelected ?
                          composeValidators(validateCustomProgram) :
                          validateCustomProgram}
                      />
                      <Icon
                        icon="IconCross"
                        className="referral-oon-custom-program-cross"
                        size={8}
                        onClick={() => this.clearCustomField(index)}
                      />
                    </div>
                  </div>
                ))
              }

              {
                !hideCustom && (
                  <Button
                    id="add-another-custom-oon-program-btn"
                    className="mb-one mr-one"
                    label="+ ADD ANOTHER CUSTOM PROGRAM"
                    onClick={this.addCustomField}
                  />
                )
              }
            </div>
          ) : null}

          {
            showError && (
              <FieldErrorMessage
                key={error}
                message={error}
              />
            )
          }
        </div>
      );
    }

    return (
      <InputField
        className="service-case-referred-to-form__input-field"
        field={referredTo}
        id="service-case-referred-to-form__name"
        label="Referred To"
        ref={registerField}
        required
        validations={validations.isRequired}
      />
    );
  }
}

ServiceCaseReferredToField.propTypes = {
  canPaginateNetworkGroups: PropTypes.bool.isRequired,
  customGroups: PropTypes.array,
  useProgramBasedSearch: PropTypes.bool.isRequired,
  debouncedSearchNetworkGroups: PropTypes.func.isRequired,
  referredTo: PropTypes.object.isRequired,
  casesOONReferralUnlicensedOrgs: PropTypes.bool.isRequired,
  fetchingOONGroups: PropTypes.bool.isRequired,
  originCoordinates: PropTypes.array.isRequired,
  oonGroups: PropTypes.array.isRequired,
  registerField: PropTypes.func.isRequired,
  selectedGroups: PropTypes.array.isRequired,
  originLatLng: PropTypes.array,
  showError: PropTypes.bool.isRequired,
};

ServiceCaseReferredToField.defaultProps = {
  originLatLng: [],
  customGroups: [],
};

export default ServiceCaseReferredToField;
