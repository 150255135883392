import { useFind } from 'src/api/APIHooks';
import { isEmpty, get } from 'lodash';

const useProcedureCodeOptions = (feeScheduleProgramIds, payerId) => {
  const fspQuery = !isEmpty(feeScheduleProgramIds) ?
    { fee_schedule_program: feeScheduleProgramIds.join() } : null;
  const payerQuery = payerId ? { payer: payerId } : null;

  const { data: response } = useFind(
    'procedure_code',
    {
      ...fspQuery, ...payerQuery,
    },
    {
      queryConfig: {
        enabled: !isEmpty(feeScheduleProgramIds) && !!payerId,
        placeholderData: undefined,
        staleTime: Infinity,
      },
    },
  );

  const procedureCodes = get(response, 'data.data', []);
  return procedureCodes;
};

export default useProcedureCodeOptions;
