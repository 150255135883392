import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import findPrimaryOrFirst from '@unite-us/client-utils/dist/utils/arrays/findPrimaryOrFirst';
import dates from '@unite-us/client-utils/dist/utils/dates';
import { CheckBoxField } from '@unite-us/ui';
import moment from 'moment';
import { phones } from '@unite-us/client-utils';
import { formatAddress } from 'src/components/ClientSummary/utils';

export default function PersonToMerge(props) {
  const { person, isSelected, handleSelect } = props;

  const dob = person.date_of_birth;
  const tel = findPrimaryOrFirst(person.phone_numbers || [])?.phone_number;
  const email = findPrimaryOrFirst(person.email_addresses || [])?.email_address;

  const age = moment().diff(moment.unix(dob), 'years');

  const demographics = {
    DOB: `${dates.formatDate(dob)} (Age ${age})`,
    TEL: phones.formatPhoneNumber(tel),
    EMAIL: email,
    ADDRESS: person.addresses?.length ? formatAddress(findPrimaryOrFirst(person.addresses)) : '',
  };

  return (
    <li className="flex items-center space-x-6">
      <div className="flex items-center w-5 h-5 mt-10">
        <CheckBoxField
          id={`${person.id}-checkbox`}
          checked={isSelected} onChange={() => handleSelect(person)}
          label=""
        />
      </div>
      <div className="
        flex flex-col min-w-0 w-full
        px-4 py-3 space-y-2 border border-solid
        rounded-md border-filter-border-color"
      >
        <div className="flex justify-between">
          <h3 className="text-base font-semibold text-action-blue">
            {person.full_name}
          </h3>
          <div className="flex gap-2">
            <p className="italic">Last Activity: {dates.formatDate(person.last_activity)}</p>
            <p className="italic">Created: {dates.formatDate(person.created_at)}</p>
          </div>
        </div>
        <div className="flex gap-2">
          <p>Open Cases: {person.open_case_count}</p>
          <p>Referrals: {person.referral_count}</p>
          <p>Notes: {person.notes_count}</p>
        </div>
        <ul className="list-unstyled flex">
          {Object.entries(demographics).map(([key, value], index) => {
            const liClasses = classNames(
              'inline-block text-sm whitespace-no-wrap text-brand-blue',
              {
                'border-l border-solid border-filter-border-color pl-2 ml-2': index !== 0,
                truncate: index === 3,
              },
            );

            return (
              <li
                key={key}
                className={liClasses}
              >
                <span className="inline-block mr-2 font-bold">{key}</span>
                <span>{value}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </li>
  );
}

PersonToMerge.propTypes = {
  person: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  handleSelect: PropTypes.func.isRequired,
};
