import React from 'react';
import PropTypes from 'prop-types';
import Card from 'common/Card/Card';
import CareTeamMember from './CareTeamMember/CareTeamMember';
import FamilyMember from './FamilyMember/FamilyMember';

const Relationships = ({
  person, isFamilyLinkEnabled, employeeId, getClientRelationship,
}) => (
  <>
    <h2 className="mb-4">Relationships</h2>
    <Card
      borderColor="border-dark-border-blue"
      className="p-4 mb-8 bg-dark-fill-blue"
    >
      <CareTeamMember
        person={person}
        getClientRelationship={getClientRelationship}
        className="mb-6"
      />
      {isFamilyLinkEnabled && <FamilyMember person={person} employeeId={employeeId} />}
    </Card>
  </>
);

Relationships.propTypes = {
  isFamilyLinkEnabled: PropTypes.bool,
  person: PropTypes.object.isRequired,
  employeeId: PropTypes.string,
  getClientRelationship: PropTypes.func.isRequired,
};

Relationships.defaultProps = {
  isFamilyLinkEnabled: false,
  employeeId: '',
};

export default Relationships;
