import React from 'react';
import PropTypes from 'prop-types';

const PaginationInformation = ({
  className,
  pageNumber,
  pageSize,
  totalItemCount,
}) => {
  const lowerBound = Math.min((pageNumber - 1) * pageSize + 1, totalItemCount);
  const upperBound = Math.min(pageNumber * pageSize, totalItemCount);
  return (
    <div
      className={className}
    >
      Showing {lowerBound} - {upperBound} of {totalItemCount}
    </div>
  );
};

PaginationInformation.propTypes = {
  className: PropTypes.string,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalItemCount: PropTypes.number.isRequired,
};

PaginationInformation.defaultProps = {
  className: '',
};

export default PaginationInformation;
