import React, { useCallback, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Form, Field } from 'react-final-form';
import { Button, InputField, TextField, Icon, SelectField } from '@unite-us/ui';
import { validations } from '@unite-us/client-utils';
import { browserHistory } from 'common/utils/browserHistory';
import { BackButton } from 'common/buttons';
import { Spinner } from 'common/spinners';
import 'src/pages/backoffice/prds/fonts.scss';
import { theme } from 'src/../tailwind.config';
import DialogV2 from 'src/common/modal/DialogV2';
import FileUpload from 'common/form/FileUploadV2/FileUpload';
import { PRD_API_URL } from 'src/config/env/env.config';
import { Toggle } from 'common/Toggle';
import { fetchAllFeeSchedules } from 'src/common/utils/FeeSchedules/fetchFeeSchedules';
import FontSelectField from './FontSelectField';
import ColorField from './ColorField';
import LatitudeLongitudeField from './LatitudeLongitudeField';
import GeographicalRestrictions from './GeographicalRestrictions';
import './PrdDetailForm.css';

export const PrdDetailForm = ({
  prdId,
  customerName,
  directoryConfigurationId,
  onSubmit,
  showPending,
  initialValues,
}) => {
  const { counties: initialCounties, cities: initialCities, include_payments } = initialValues;

  const [showPayments, setShowPayments] = useState(include_payments || false);
  const [feesOptions, setFeesOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const loadInitialData = async () => {
      const fees = await fetchAllFeeSchedules();
      setFeesOptions(fees);
      setLoading(false);
    };

    loadInitialData();
  }, []);

  const isEditing = !isEmpty(directoryConfigurationId);
  const title = isEditing ? 'Edit PRD Details' : 'Create new PRD';

  const FONT_FAMILY_OPTIONS = [
    { name: 'Arial' },
    { name: 'Montserrat' },
    { name: 'Lexend' },
    { name: 'Nunito' },
    { name: 'Open Sans' },
    { name: 'Proxima Nova' },
    { name: 'Public Sans' },
    { name: 'Roboto' },
    { name: 'Verdana' },
  ];

  const onCancel = useCallback(() => {
    if (isEditing) {
      browserHistory.push({ pathname: `/backoffice/prds/${prdId}` });
    } else {
      browserHistory.push({ pathname: '/backoffice/prds' });
    }
  }, [directoryConfigurationId, isEditing]);

  const ChangeLogoDialogRef = useRef(null);
  const formRef = useRef(null);

  const openDialog = () => {
    ChangeLogoDialogRef.current.openDialog();
  };

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState({
    filesTooBig: [],
    filesWrongType: [],
    filesTooBigAndWrongType: [],
  });
  const [logoPreview, setLogoPreview] = useState();
  const closeDialog = () => {
    ChangeLogoDialogRef.current.closeDialog();
  };

  const cleanFiles = useCallback(() => {
    setUploadedFiles([]);
    setRejectedFiles({
      filesTooBig: [],
      filesWrongType: [],
      filesTooBigAndWrongType: [],
    });
  });

  const changeLogo = (files) => {
    if (files && files[0]) {
      const fileReader = new FileReader();

      fileReader.onloadend = () => {
        const logoContainer = files[0];
        setLogoPreview(logoContainer.preview);
        formRef.current.change('logo', logoContainer.file);
      };

      fileReader.readAsDataURL(files[0].file);
      cleanFiles();
    }
    closeDialog();
  };

  const cleanFilesAndCloseDialog = () => {
    cleanFiles();
    closeDialog();
  };

  const getLogoUrl = (formValues) => {
    let logoUrl = '';
    if (logoPreview) {
      logoUrl = logoPreview;
    } else if (formValues?.logo?.name) {
      logoUrl = `${PRD_API_URL}${formValues?.logo?.name}`;
    }
    return logoUrl;
  };

  const toggleOnChange = () => {
    setShowPayments(!showPayments);
    if (!showPayments) {
      formRef.current.change('fee_schedules', []);
    }
  };

  const validateSelection = (selectedItems) => {
    if (showPayments && (!selectedItems || selectedItems.length === 0)) {
      return 'Requried';
    }
    return undefined;
  };

  return (
    <>
      <div id="prd_form" className="bg-dark-fill-blue">
        <div className="flex p-4 justify-start items-center bg-white">
          <div>
            <BackButton
              navigateTo={onCancel}
              dataTestId="back-to-prd-overview-button"
            />
          </div>
          <h3 className="pl-6 w-9/12 font-bold">{title} - {customerName}</h3>
        </div>
        {showPending && <Spinner />}
        {!showPending && (
          <div className="p-6">
            <Form
              keepDirtyOnReinitialize
              initialValues={initialValues}
              onSubmit={(values) => {
                const updatedValues = {
                  ...values,
                  include_payments: showPayments,
                };
                onSubmit(updatedValues);
              }}
              render={({ handleSubmit, form, values }) => {
                if (!formRef.current) {
                  formRef.current = form;
                }

                const {
                  primary_font_family, secondary_font_family, body_font_family, logo,
                } = values;
                return (
                  <form
                    onSubmit={handleSubmit}
                    data-testid="prd-form"
                    className="grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-12 pl-10 pt-6
                  bg-light-border-grey border border-solid rounded overflow-hidden border-dark-border-blue"
                  >
                    <div
                      id="directory-detail__fonts"
                      className="md:col-span-12 xl:col-span-12 px-0"
                    >
                      <label htmlFor="directory-detail__fonts">
                        FONT FAMILY
                      </label>
                    </div>
                    <Field name="primary_font_family">
                      {(props) => (
                        <FontSelectField
                          id="primary_font_family"
                          title="Primary Font Family"
                          options={FONT_FAMILY_OPTIONS}
                          value={primary_font_family}
                          dataTestId="directory-detail-primary-font-field"
                          {...props}
                        />
                      )}
                    </Field>
                    <Field name="secondary_font_family">
                      {(props) => (
                        <FontSelectField
                          id="secondary_font_family"
                          title="Secondary Font Family"
                          options={FONT_FAMILY_OPTIONS}
                          value={secondary_font_family}
                          dataTestId="directory-detail-secondary-font-field"
                          {...props}
                        />
                      )}
                    </Field>
                    <Field name="body_font_family">
                      {(props) => (
                        <FontSelectField
                          id="body_font_family"
                          title="Body Font Family"
                          options={FONT_FAMILY_OPTIONS}
                          value={body_font_family}
                          dataTestId="directory-detail-body-font-field"
                          {...props}
                        />
                      )}
                    </Field>
                    <div className="md:col-span-6 px-0" />
                    <div
                      id="directory-detail__colors"
                      className="md:col-span-12 xl:col-span-12 px-0"
                    >
                      <label htmlFor="directory-detail__colors">COLOR</label>
                    </div>
                    <Field name="primary_color">
                      {(props) => (
                        <ColorField
                          toolTipText="All buttons, except for Service
                          Category browse buttons. (Including filter checkboxes)"
                          className="md:col-span-6 xl:col-span-6 px-0"
                          id="directory-detail__primary_color"
                          label="Primary"
                          placeholder="Pick a color"
                          {...props}
                        />
                      )}
                    </Field>
                    <Field name="accent_color">
                      {(props) => (
                        <ColorField
                          toolTipText="All icons, including the Service
                           Category browse icons (buttons on the search page)"
                          className="md:col-span-6 xl:col-span-6 pr-10 accent_color-input_field"
                          id="directory-detail__accent_color"
                          label="Secondary"
                          placeholder="Pick a color"
                          {...props}
                        />
                      )}
                    </Field>
                    <Field name="background_color">
                      {(props) => (
                        <ColorField
                          className="md:col-span-6 xl:col-span-6 px-0 background_color-input_field"
                          id="directory-detail__background_color_color"
                          label="Landing Page Background"
                          placeholder="Pick a color"
                          {...props}
                        />
                      )}
                    </Field>
                    <Field name="link_color">
                      {(props) => (
                        <ColorField
                          className="md:col-span-6 xl:col-span-6 pr-10 link_color-input_field"
                          id="directory-detail__link_color"
                          label="Link"
                          placeholder="Pick a color"
                          {...props}
                        />
                      )}
                    </Field>
                    <Field name="map_marker_color">
                      {(props) => (
                        <ColorField
                          className="md:col-span-6 xl:col-span-6 px-0 icon_color-input_field"
                          id="directory-detail__map_marker_color"
                          label="Map Marker"
                          placeholder="Pick a color"
                          {...props}
                        />
                      )}
                    </Field>
                    <div className="md:col-span-6 xl:col-span-6 px-0" />
                    <Field
                      name="default_location"
                      validate={(value) => validations.isRequired(value)}
                    >
                      {(props) => (
                        <InputField
                          className="md:col-span-6 xl:col-span-6 px-0"
                          id="directory-detail__default-location"
                          label="Default Location Name"
                          placeholder="Type the default location"
                          required
                          dataTestId="directory-detail__default-location-field"
                          {...props}
                        />
                      )}
                    </Field>
                    <div className="md:col-span-7 xl:col-span-7 px-0">
                      <Field
                        name="latitude_and_longitude"
                        validate={(value) => (validations.isRequired(value))}
                      >
                        {(props) => (
                          <LatitudeLongitudeField {...props} changeValue={form.change} />
                        )}
                      </Field>
                    </div>
                    <div className="md:col-span-6 xl:col-span-6 px-0" />
                    <div className="md:col-span-12 xl:col-span-12 pl-0 pr-10">
                      <h4 className="ui-form-field__label pb-2">LIMIT SEARCHES GEOGRAPHICALLY</h4>
                      <GeographicalRestrictions initialCities={initialCities} initialCounties={initialCounties} />
                    </div>
                    <div className="md:col-span-12 xl:col-span-12 pl-0 pr-10">
                      <h4 className="ui-form-field__label pb-2">INCLUDE PAYMENTS RESOURCES IN SEARCHES</h4>
                      <div className="flex flex-row items-center mb-3">
                        <Toggle
                          checked={showPayments}
                          id="contact-requirements___selected-times-toggle"
                          onChange={() => toggleOnChange()}
                          toggleClass="px-0 w-10 h-6"
                          label="Show payments resources in search results"
                          labelClass="text-sm text-slate-600 mr-10"
                          value={showPayments}
                        />
                        { showPayments ? 'Yes' : 'No' }
                      </div>
                      { showPayments && (
                        <>
                          <label
                            htmlFor="fee_schedules"
                            className="normal-case text-sm tracking-normal text-text-blue font-regular-font"
                          >
                            Show payments resources associated with only these fee schedules:
                          </label>
                          {loading ?
                            (
                              <>
                                <br />
                                <span>Loading...</span>
                              </>
                            ) :
                            (
                              <Field name="fee_schedules" validate={validateSelection}>
                                {({ input, meta }) => (
                                  <>
                                    <SelectField
                                      placeholder="Select fee schedules..."
                                      id="fee_schedules"
                                      multiple
                                      loadingText="Searching..."
                                      options={feesOptions}
                                      valueKey="value"
                                      labelKey="display_name"
                                      searchThreshold="0.0"
                                      className={meta.error && meta.touched ?
                                        'ui-select-field--has-error has-error ui-form-field--has-error has-error' :
                                        ''}
                                      dataTestId="fee_schedules_select"
                                      error={meta.error && meta.touched ? meta.error : ''}
                                      {...input}
                                    />
                                  </>
                                  )}
                              </Field>
                          )}
                        </>
                      )}
                    </div>
                    <div className="md:col-span-6 xl:col-span-6 px-0" />
                    <Field name="copyright_info">
                      {(props) => (
                        <TextField
                          className="md:col-span-12 xl:col-span-12 pl-0 pr-10"
                          id="directory-detail__copyright_info"
                          label="Footer Text"
                          placeholder={
                            'Type the copyright information. This information will show at the bottom of the page.\n' +
                            'Example) © City of New York. 2023. All Rights Reserved'
                          }
                          dataTestId="directory-detail__default-location-field"
                          {...props}
                        />
                      )}
                    </Field>
                    <Field name="logo">
                      {() => (
                        <>
                          <div className="ui-input-field ui-form-field md:col-span-3 xl:col-span-3 px-0">
                            <label
                              htmlFor="directory-detail__change-logo-dialog"
                              className="ui-form-field__label"
                            >
                              Logo
                            </label>
                            <div className="flex justify-between items-center py-2">
                              <input
                                id="directory-detail__logo"
                                type="file"
                                className="hidden"
                              />
                              {logo &&
                                (
                                  <img
                                    data-testid="directory-detail__logo"
                                    className="logo"
                                    alt="PRD Logo"
                                    src={getLogoUrl(values)}
                                  />
                                )}

                            </div>
                          </div>
                          <div className="ml-auto md:col-span-3 xl:col-span-3 py-2">
                            <Button
                              id="directory-detail__change-logo-dialog"
                              data-test-element="change-logo-prd-detail-btn"
                              label="Change Logo"
                              iconLeft={(
                                <Icon
                                  color={theme.extend.colors['action-blue']}
                                  icon="V2Add"
                                />
                              )}
                              onClick={openDialog}
                            />
                          </div>
                        </>
                      )}
                    </Field>
                    <div className="md:col-span-6 xl:col-span-6 px-0" />
                    <Field
                      name="external_api_key"
                      validate={(value) => validations.isRequired(value)}
                    >
                      {(props) => (
                        <InputField
                          className="md:col-span-6 xl:col-span-6 px-0"
                          id="directory-external_api"
                          label="External API Key"
                          placeholder="Type the external API"
                          required
                          dataTestId="directory-external_api-field"
                          maxLength={700}
                          {...props}
                        />
                      )}
                    </Field>
                    <div className="md:col-span-6 xl:col-span-6 px-0" />
                    <div className="flex items-start mt-8 pb-9">
                      <Button
                        data-test-element="cancel-create-prd-btn"
                        label="Cancel"
                        onClick={onCancel}
                      />
                      <Button
                        className="ml-6"
                        data-test-element={
                          isEditing ? 'update-prd-btn' : 'create-prd-btn'
                        }
                        label={isEditing ? 'Update' : 'Create'}
                        primary
                        type="submit"
                        disabled={showPayments && loading}
                      />
                    </div>
                  </form>
                );
              }}
            />
          </div>
        )}
      </div>
      <DialogV2
        cancelHandler={cleanFilesAndCloseDialog}
        confirmationHandler={() => changeLogo(uploadedFiles)}
        confirmLabel="Change"
        ref={ChangeLogoDialogRef}
        title="Select new Logo"
        confirmationBtnDisabled={uploadedFiles.length !== 1}
        dialogDescriptionClass={'flex-auto overflow-y-auto bg-medium-fill-blue'}
      >
        <FileUpload
          rejectedFiles={rejectedFiles}
          setRejectedFiles={setRejectedFiles}
          setUploadedFiles={setUploadedFiles}
          uploadedFiles={uploadedFiles}
          acceptedFileTypes={
            'image/bmp, image/gif, image/jpeg, image/png, image/svg+xml, image/webp'
          }
        />
      </DialogV2>
    </>
  );
};

PrdDetailForm.propTypes = {
  prdId: PropTypes.string,
  customerName: PropTypes.string,
  directoryConfigurationId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  showPending: PropTypes.bool,
};

PrdDetailForm.defaultProps = {
  prdId: '',
  customerName: '',
  directoryConfigurationId: '',
  initialValues: {},
  showPending: false,
};

export default PrdDetailForm;
