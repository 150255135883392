// Library Imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ButtonSelect } from '@unite-us/client-utils';
import { connect } from 'react-redux';
import { coreApi } from 'src/api/config';
import { CONSENT_DOWNLOAD_URL, CONSENT_APP_URL, CORE_BASE_URL } from 'src/config/env/env.config';
import { showTranslationsNewLanguages } from 'common/utils/FeatureFlags/flags';
import { updateGlobalState } from 'actions/Global/globalActions';

// TODO: Improve these couple of imports
import {
  getConsentStatusValue,
  getPdfUrl,
  getConsentStatusDisplay,
} from '@unite-us/app-client-profile/dist/components/InformedConsent/utils';
import { ConsentDialog } from '@unite-us/app-client-profile/dist/components/InformedConsent/components/ConsentDialog';
import { CONSENT_OPTIONS } from 'src/components/InformedConsent/constants';
import { fetchGroupContact } from 'actions/Contact/Group';
import { fetchCommunicationConsent } from 'actions/Consent/Contact/Group';
import { get, isEmpty } from 'lodash';
import Notifier from 'src/common/helpers/Notifier';
import findStatusConfigs from './utils/findStatusConfigs';
import './stylesheets/status-select.scss';

export class StatusSelect extends Component {
  static openPdfUrl(contact) {
    const pdfUrl = getPdfUrl(contact, CORE_BASE_URL);

    window.open(pdfUrl, '_blank');
  }

  constructor(props, context) {
    super(props);
    this.context = context;

    this.state = {
      initialConsentType: null,
      isConsentDialogOpen: false,
      fetchingCommunication: false,
      contact: this.props.contact,
    };

    this.generateOptions = this.generateOptions.bind(this);
    this.checkConsentPending = this.checkConsentPending.bind(this);
  }

  componentDidMount() {
    this.checkConsentPending(this.props.contact);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.contact.id !== this.props.contact.id) {
      this.checkConsentPending(this.props.contact);
    }
  }

  onCloseConsentDialog = () => {
    this.setState({ isConsentDialogOpen: false });
  }

  onOpenConsentDialog = (value) => {
    this.setState({ initialConsentType: value, isConsentDialogOpen: true });
  }

  onConsentOrCommunicationSent = ({ type }) => {
    const { groupId } = this.context;
    const { contact } = this.props;

    if (type === 'communication') {
      Notifier.dispatch('success', 'Consent Request Sent');
    }

    this.props.fetchGroupContact(groupId, contact.id).then((res) => {
      const newContact = get(res, 'data.data');

      if (type === 'consent' && get(newContact, 'consent.state') === 'accepted') {
        Notifier.dispatch('success', 'Your consent has been recorded.');
      }

      this.checkConsentPending(newContact);
      this.onCloseConsentDialog();
    });
  }

  onLanguageChange = (language) => {
    this.props.updateGlobalState({ language });
  }

  generateOptions(contact, status) {
    let options;

    if (status === 'accepted') {
      options = [
        {
          label: 'View',
          value: 'view',
          action: () => StatusSelect.openPdfUrl(contact),
        },
      ];
    } else {
      options = [
        {
          label: 'Send SMS',
          value: CONSENT_OPTIONS.PHONE_NUMBER,
          action: this.onOpenConsentDialog,
        },
        {
          label: 'Send Email',
          value: CONSENT_OPTIONS.EMAIL_ADDRESS,
          action: this.onOpenConsentDialog,
        },
        {
          label: 'Request On-Screen',
          value: CONSENT_OPTIONS.ON_SCREEN,
          action: this.onOpenConsentDialog,
        },
        {
          label: 'Upload Signed Paper',
          value: CONSENT_OPTIONS.DOCUMENT,
          action: this.onOpenConsentDialog,
        },
        {
          label: 'Upload Audio File',
          value: CONSENT_OPTIONS.AUDIO,
          action: this.onOpenConsentDialog,
        },
        {
          label: 'Provide Attestation',
          value: CONSENT_OPTIONS.ON_SCREEN_ATTESTATION,
          action: this.onOpenConsentDialog,
        },
      ];
    }
    return options;
  }

  /**
   * The consent is pending if the user doesn't have a consent and
   * there is a communication created with category 'consent_request'
   */
  checkConsentPending(contact) {
    const { groupId } = this.context;
    const status = getConsentStatusValue(contact);
    this.setState({ status, contact });
    if (isEmpty(contact.consent)) {
      this.setState({ fetchingCommunication: true });
      this.props.fetchCommunicationConsent(contact.id, groupId).then((result) => {
        if (!isEmpty(get(result, 'data.data', []))) {
          this.setState({ status: 'pending' });
        }
        this.setState({ fetchingCommunication: false });
      });
    }
  }

  render() {
    const {
 isFetching, employee, language, shouldShowTranslationsNewLanguages,
} = this.props;
    const {
      initialConsentType, status, fetchingCommunication, contact, isConsentDialogOpen,
    } = this.state;

    if (isFetching || fetchingCommunication) { return null; }

    const placeholder = getConsentStatusDisplay(status, this.context.enums.consent.contact_status);
    const statusConfig = findStatusConfigs(status);
    const options = this.generateOptions(contact, status);

    return (
      <div className="status-select">
        <div className="status-select__full-name display">{contact.full_name}</div>

        <ButtonSelect
          id="status-select-options"
          className="status-select__button-select"
          options={options}
          valueKey="value"
          labelKey="label"
          styles={{ containerStyle: { marginTop: '6px' } }}
          placeholder={placeholder}
          icon={statusConfig.icon}
          buttonBackgroundColor={statusConfig.color}
        />

        {isConsentDialogOpen && (
          <ConsentDialog
            contact={contact}
            employee={employee}
            initialConsentType={initialConsentType}
            language={language}
            callbacks={{
              onLanguageChange: this.onLanguageChange,
              onError: (error) => {
                if (typeof error === 'string') {
                  Notifier.dispatch('error', error);
                } else {
                  Notifier.handleErrors(error);
                }
              },
              onCommunicationSent: () => this.onConsentOrCommunicationSent({ type: 'communication' }),
              onConsentSent: () => this.onConsentOrCommunicationSent({ type: 'consent' }),
              onNavigateAway: this.onCloseConsentDialog,
              onTrackEvent: this.context.eventTracker,
            }}
            constants={{
              VERBAL_CONSENT_SCRIPT_URL: '/consent/verbal-consent-script',
              CONSENT_DOWNLOAD_URL,
              CONSENT_APP_URL,
            }}
            adapters={{
              coreApi,
            }}
            flags={{
              shouldShowTranslationsNewLanguages,
            }}
          />
        )}
      </div>
    );
  }
}

StatusSelect.propTypes = {
  contact: PropTypes.object.isRequired,
  employee: PropTypes.object.isRequired,
  /* optional prop, in the instance a contact underlying data has changed but exists in store */
  isFetching: PropTypes.bool,
  language: PropTypes.string.isRequired,
  shouldShowTranslationsNewLanguages: PropTypes.bool.isRequired,
  fetchGroupContact: PropTypes.func.isRequired,
  fetchCommunicationConsent: PropTypes.func.isRequired,
  updateGlobalState: PropTypes.func.isRequired,
};

StatusSelect.defaultProps = {
  isFetching: false,
};

StatusSelect.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
  enums: PropTypes.shape({
    consent: PropTypes.shape({
      contact_status: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
  groupId: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const language = state.globalState.language;
  const employee = state.globalState.currentEmployee;

  return {
    language,
    shouldShowTranslationsNewLanguages: showTranslationsNewLanguages(state),
    employee,
  };
}

export default connect(mapStateToProps, {
  updateGlobalState,
  fetchGroupContact,
  fetchCommunicationConsent,
})(StatusSelect);
