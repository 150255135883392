import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  tracker,
} from '@unite-us/client-utils';
import { bindActionCreators } from 'redux';
import IndexDetailView from 'src/components/Dashboard/components/IndexDetailView';
import AllExternalCasesIndex from 'src/components/Dashboard/components/Cases/AllExternalCasesIndex';
import { fetchDashboardCases } from 'actions/Case/Contact/Group';
import _ from 'lodash';
import DetailView from 'src/components/Dashboard/components/DetailView';
import CaseDetailsView from 'src/components/Cases/components/Detail/CaseDetailsView';
import fetchProvidersUserCore from 'src/actions/UserProvider/fetchProvidersUser';
import { isCaseManagementProvider } from 'common/utils/User/userGroup';
import { isCoordinationCenter } from 'common/utils/Group';
import { getDataOfPage, isDataValid } from 'src/components/Dashboard/utils/dataHelpers';
import callOrLog from 'src/common/utils/callOrLog';
import {
  getCCUserNetwork,
} from 'src/components/User/utils';
import { searchNetworkGroups } from 'actions/Group/Network';
import { goToCase } from 'src/components/Cases/utils/routing';
import { serviceTypesFilter } from 'src/components/Dashboard/utils/filter';
import { DASHBOARD_VIEWS, DASHBOARD_EVENTS } from 'common/utils/EventTracker/utils/eventConstants';
import { isSuperNetwork } from 'src/components/Network/utils';
import subNetworksFilter from 'src/components/Dashboard/utils/subNetworksFilter';
import { getStatusesOptions, getSentByOptions } from 'src/components/Groups/Users/utils';
import {
  useV4toGetOrganizations,
  crtb1290SortReferralsByOldest,
} from 'src/common/utils/FeatureFlags/flags';

export class AllExternalCases extends PureComponent {
  constructor(props) {
    super(props);
    this.fetch = this.fetch.bind(this);
    this.fetchAllNetworkGroups = this.fetchAllNetworkGroups.bind(this);
    this.onCellClick = this.onCellClick.bind(this);
    this.onFiltersChange = this.onFiltersChange.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onPrevClick = this.onPrevClick.bind(this);
    this.shouldFetch = this.shouldFetch.bind(this);
    this.fetchSentByUsers = this.fetchSentByUsers.bind(this);
    this.state = {
      activeUsersInGroup: [],
      page: 1,
      sidx: 'updated_at',
      sord: 'desc',
      filters: {
        service_types: _.get(props, 'filters.service_types', []),
        sent_by: _.get(
          props,
          'filters.sent_by',
          [props.currentEmployee.id],
        ),
      },
      employees: [],
    };
  }

  componentDidMount() {
    this.fetch();
    this.props.fetchProvidersUserCore({
      providers: this.props.groupId,
    })
      .then((response) => {
        if (response) {
          const employees = response.employees;
          this.setState({ employees });
        }
      });

    const { useV4toSearchNetworkGroups } = this.props;
    if (useV4toSearchNetworkGroups && this.props.networkId) {
      this.fetchAllNetworkGroups();
    }

    if (!useV4toSearchNetworkGroups && this.props.networkId && _.isEmpty(this.props.networkGroups)) {
      this.fetchAllNetworkGroups();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (_.get(this.props, 'allCases.currentPage', 1) !== _.get(nextProps, 'allCases.currentPage', 1)) {
      this.setState({ page: nextProps.allCases.currentPage });
    }

    if (!this.props.refetch && nextProps.refetch) {
      this.fetch();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.fetch();
    }
  }

  onCellClick(row) {
    const pagedData = getDataOfPage(this.props.allCases, this.state.page, this.props.sortAscending);
    const serviceCase = pagedData.data[row];

    callOrLog(() => this.context.eventTracker(DASHBOARD_EVENTS.allExternalCasesRow, {
      view: DASHBOARD_VIEWS.allExternalCases,
    }, { serviceCase }));

    goToCase({ ...serviceCase, pathname: this.props.pathname, status: 'all' });
  }

  onFiltersChange(key, activeFilters) {
    this.setState({
      page: 1,
      filters: { ...this.state.filters, [key]: activeFilters },
    }, () => {
      callOrLog(() => {
        const filters = tracker.buildDashboardFilterPayload(this.state, this.props);
        this.context.eventTracker(DASHBOARD_EVENTS.allExternalCasesFilter, filters);
      });
      this.fetch();
    });
  }

  onNextClick() {
    const page = this.state.page + 1;

    this.shouldFetch(page);
  }

  onPrevClick() {
    const page = this.state.page - 1;

    this.shouldFetch(page);
  }

  fetchAllNetworkGroups(page = 1, search = undefined) {
    const { useV4toSearchNetworkGroups } = this.props;
    return this.props.searchNetworkGroups(
      this.props.networkId,
      {},
      { page, search },
      useV4toSearchNetworkGroups,
    ).then((response) => {
      if (useV4toSearchNetworkGroups) {
        const dropdownResponse = response.map((provider) => ({
          label: provider.name,
          value: provider.id,
          initial: false,
        }));
        return dropdownResponse;
      }
      if (!useV4toSearchNetworkGroups) {
        const nextPage = _.get(response, 'data.paging.next_page');
        if (nextPage) {
          this.fetchAllNetworkGroups(nextPage);
        }
      }
      return true;
    });
  }

  shouldFetch(page) {
    if (isDataValid(this.props.allCases, page)) {
      this.setState({ page });
    } else {
      this.fetch(page);
    }
  }

  fetch(page = 1) {
    const formattedFilters = _.reduce(
      this.state.filters,
      (acc, f, key) => {
        if (f === 'all') {
          return acc;
        }
        return _.merge(acc, { [key]: f });
      },
      {},
    );
    formattedFilters.sent_by = _.uniq(
      _.map(formattedFilters.sent_by, (f) => f.split('-employee')[0]),
    );
    if (this.props.isCC) {
      formattedFilters.networks = [this.props.networkId];
    }
    this.props.fetchDashboardCases(
      this.props.groupId,
      '',
      {
        isExternal: true,
        target: 'allExternalCases',
        sidx: this.state.sidx,
        sord: this.state.sord,
        filters: formattedFilters,
        page,
      },
    );
  }

  fetchSentByUsers(search = '') {
    const { groupId, currentEmployee } = this.props;
    return this.props.fetchProvidersUserCore({
      providers: groupId,
      options: {
        licensed: false,
        text: search,
      },
    })
      .then((response) => {
        if (response) {
          return getSentByOptions(
            response.employees,
            this.state,
            currentEmployee,
          );
        }

        return [];
      });
  }

  render() {
    const {
      allCases,
      currentEmployee,
      currentGroup,
      groupId,
      isCaseMgmtProvider,
      isCC,
      isFetching,
      params,
      serviceTypes,
    } = this.props;
    const { employees } = this.state;
    let filters = [
      serviceTypesFilter(serviceTypes, this.state.filters.service_types),
      {
        key: 'sent_by',
        name: 'Sent By',
        pluralName: 'Sent By',
        options: getSentByOptions(employees, this.state, currentEmployee),
        asyncSearch: this.fetchSentByUsers,
      },
      {
        key: 'status_filter',
        name: 'Status',
        pluralName: 'Statuses',
        options: getStatusesOptions(this.state),
      },
      subNetworksFilter(currentGroup, this.state.filters.networks),
    ];

    filters = _.compact(filters);

    const innerContentView = (
      <CaseDetailsView
        params={params}
        type="allExternalCases"
        showContactColumn
        subjectType="case"

      />
    );

    const allCasesDetailView = (
      <DetailView
        innerContentView={innerContentView}
      />
    );
    const pagedData = getDataOfPage(allCases, this.state.page, this.props.sortAscending);

    return (
      <IndexDetailView
        params={this.props.params}
        DetailView={allCasesDetailView}
        IndexView={AllExternalCasesIndex}
        data={pagedData.data}
        paging={pagedData.paging}
        isFetching={isFetching}
        onNextClick={this.onNextClick}
        onPrevClick={this.onPrevClick}
        onCellClick={this.onCellClick}
        initialSort={{ [this.state.sidx]: this.state.sord }}
        isCC={isCC}
        isSuperNetwork={isSuperNetwork(currentGroup.networks, groupId)}
        onFiltersChange={this.onFiltersChange}
        filters={_.sortBy(filters, 'name')}
        isCaseMgmtProvider={isCaseMgmtProvider}
        fetchGroupsUsers={this.fetchSentByUsers}
      />
    );
  }
}

AllExternalCases.propTypes = {
  location: PropTypes.object,
  allCases: PropTypes.shape({
    data: PropTypes.array.isRequired,
    currentPage: PropTypes.number.isRequired,
  }).isRequired,
  currentGroup: PropTypes.object.isRequired,
  currentEmployee: PropTypes.object.isRequired,
  fetchDashboardCases: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  isCaseMgmtProvider: PropTypes.bool,
  isCC: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  networkGroups: PropTypes.object.isRequired,
  networkId: PropTypes.string,
  params: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  refetch: PropTypes.bool.isRequired,
  searchNetworkGroups: PropTypes.func.isRequired,
  serviceTypes: PropTypes.array.isRequired,
  useV4toSearchNetworkGroups: PropTypes.func.isRequired,
  fetchProvidersUserCore: PropTypes.func.isRequired,
  sortAscending: PropTypes.bool,
};

AllExternalCases.defaultProps = {
  sortAscending: false,
};

function mapStateToProps(state, ownProps) {
  const user = _.wget(state, 'user', {});
  const groupId = _.get(state, 'session.groupId');
  const userGroups = _.get(state, 'groups.data', []);
  const network = getCCUserNetwork(user, groupId);
  const currentGroup = _.find(userGroups, { id: groupId }) || {};

  return {
    allCases: _.get(state, 'dashboard.allExternalCases', {}),
    currentEmployee: _.wget(state, 'globalState.currentEmployee', {}),
    currentGroup,
    filters: _.get(state, 'dashboard.allExternalCases.filters', {}),
    groupId,
    isCaseMgmtProvider: isCaseManagementProvider(state),
    // CORE-WORK - Need to resolve user.groups migration work
    isCC: isCoordinationCenter(_.get(currentGroup, 'networks', []), groupId),
    isFetching: _.get(state, 'dashboard.allExternalCases.isFetching', false),
    networkGroups: _.get(state, 'networks.groups', {}),
    networkId: _.get(network, 'id', null),
    refetch: _.get(state, 'dashboard.refetch'),
    serviceTypes: _.wget(state, 'serviceTypes.allNetworksAndPrograms', []),
    token: _.get(state, 'session.token', ''),
    user,
    userId: _.wget(user, 'id', ''),
    useV4toSearchNetworkGroups: useV4toGetOrganizations(state),
    pathname: _.wget(ownProps, 'location.pathname', ''),
    sortAscending: crtb1290SortReferralsByOldest(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({
      fetchDashboardCases,
      searchNetworkGroups,
      fetchProvidersUserCore,
    }, dispatch),
    dispatch,
  };
}

AllExternalCases.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AllExternalCases);
