import moment from 'moment';
import { feeSchedulePrograms as fspUtils } from '@unite-us/client-utils';
import formatShortDate from 'src/common/utils/Dates/formatShortDate';
import formatSpend from 'common/utils/Money/formatSpend';
/**
 * Retrieves cap information based on the fee schedule program and service delivery dates.
 *
 * @param {object} feeScheduleProgram - Fee schedule program object.
 * @param {object} authorizedSpend - Fee schedule program object.
 * @returns {object} - Cap information object.
 * @property {boolean} hasCap - Indicates whether the fee schedule program has a cap.
 * @property {number|null} totalCap - The total cap value based on the cap period.
 * @property {string} formattedCap - The formatted cap value.
 * @property {Date} startDate - The start date of the cap period.
 * @property {Date} endDate - The end date of the cap period.
 * @property {string} formattedCapPeriod - The formatted cap period string.
 */
export function getCapInformation(
  feeScheduleProgram,
  authorizedSpend,
) {
  const {
    has_cap,
    payment_type,
    unit,
    cap_reset_cadence,
  } = feeScheduleProgram;

  const startDate = moment(authorizedSpend?.cap_starts_at).utc().startOf('day');
  const endDate = moment(authorizedSpend?.cap_ends_at).utc().endOf('day');

  const isCostBased = fspUtils.isCostPaymentType(payment_type);
  const formattedCapPeriod = cap_reset_cadence === 'lifetime' ?
    'Lifetime' : `${formatShortDate(startDate)} - ${formatShortDate(endDate)}`;

  if (has_cap) {
    const formattedCap = formatSpend(authorizedSpend?.total_cap, unit, isCostBased);
    const formattedAvailableAmount = formatSpend(authorizedSpend?.available_cap, unit, isCostBased);

    return {
      hasCap: has_cap,
      totalCap: authorizedSpend?.total_cap,
      formattedCap,
      startDate,
      endDate,
      formattedCapPeriod,
      formattedAvailableAmount,
    };
  }

  const formattedCap = 'No Cap';

  return {
    hasCap: has_cap,
    formattedCap,
    startDate,
    endDate,
    formattedCapPeriod,
  };
}
