// design system reference - https://www.figma.com/file/AoPBCxLeZRZzLnihN7MTXl/New-UU-Design-System

// No purge
// const purge = process.env.NODE_ENV === 'production' ? [
//   './src/**/*.html',
//   './src/**/*.js',
//   './src/**/*.jsx',
// ] : [];

module.exports = {
  // purge,
  darkMode: false, // or 'media' or 'class'
  theme: {
    extend: {
      boxShadow: {
        'radio-ring': '0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px #4467AB',
        'input-ring': '0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px #4467AB',
        'input-error-ring': '0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px #EF4444',
        button: 'inset 10000px 10000px rgba(0,0,0,0.3)',
      },
      borderColor: {
        button: 'rgba(0, 0, 0, 0.05)',
      },
      colors: {
        // blues
        'text-blue': '#182A44',
        'brand-blue': '#2C405A',
        'deep-blue': '#2D5495',
        'action-blue': '#4467AB',
        'dark-border-blue': '#C7D8E8',
        'medium-border-blue': '#b7d2e5cc',
        'light-border-blue': '#D8E5EE',
        'dark-fill-blue': '#E5EEF5',
        'medium-fill-blue': '#ECF5FD',
        'light-fill-blue': '#F1F6FA',
        // greys
        'dark-grey': '#525F73',
        'medium-grey': '#808D9E',
        'dark-border-grey': '#D1D5DB',
        'dark-fill-grey': '#E5E7EB',
        'medium-fill-grey': '#F6F6F6',
        'light-fill-grey': '#F9F9F9',
        'light-border-grey': '#FAFBFC',
        white: '#FFFFFF',
        // colors
        'dark-red': '#991B1B',
        red: '#D0021B',
        'light-red': '#FF9494',
        orange: '#DA6625',
        yellow: '#F4D316',
        'light-yellow': '#FFFAEB',
        'dark-green': '#078B00',
        green: '#4ECE3D',
        'light-green': '#E7F3E5',
        purple: '#712F79',

        // deprecated colors
        // blues
        'dark-blue': '#182A44', // replaced by 'text-blue'
        // 'action-blue': '#2874AF', replaced by 'action-blue'
        'action-light-blue': '#52B4FF',
        'action-darker-blue': '#105890',
        'light-pale-blue': '#ECF5FD', // replaced by 'medium-fill-blue'
        'pale-blue': '#F1F6FA', // replaced by 'light-fill-blue'
        'med-pale-blue': '#E3EBF3',
        'dark-pale-blue': '#C7D8E8', // replaced by 'dark-border-blue'
        'gray-blue': '#808D9E', // replaced by 'medium-grey'
        'current-med-blue': '#5E91F7',
        'blue-border': '#C7D8E8', // replaced by 'dark-border-blue'
        'united-blue': '#4571BA',
        'focus-blue': '#005FCC',
        'placeholder-blue': '#67859E',
        'current-blue': '#4467AB', // replaced by 'action-blue'
        // greys
        grey: '#525F73', // replaced by 'dark-grey'
        'white-grey': '#FAFBFC', // replaced by 'light-border-grey'
        'light-grey': '#F6F6F6', // replaced by 'medium-fill-grey'
        'lighter-grey': '#F9F9F9', // replaced by 'light-fill-grey'
        'gray-light': '#FAFBFC', // replaced by 'light-border-grey'
        'grey-500': '#525F73', // replaced by 'dark-grey'
        'border-grey': '#D1D5DB', // replaced by 'dark-border-grey'
        'pale-grey': '#D8E5EE', // replaced by 'light-border-blue'
        'light-pale-grey': '#E5EEF5', // replaced by 'dark-fill-blue'
        'silver-color': '#CACACA',
        // greens
        // green: '#117E15' replaced by new 'green'
        'grass-green': '#4ECE3D', // replaced by new 'green'
        // 'light-green': '#1CCA21', replaced by new 'light-green'
        'pale-green': '#E7F3E5', // replaced by 'light-green'
        // reds
        'validation-red': '#D0021B', // replaced by 'red'
        // yellows
        // yellow: '#F6C83B', replaced by new 'yellow
        'alert-yellow': '#FFFAEB', // replaced by 'light-yellow'
        'canary-yellow': '#F4D316', // replaced by 'yellow'
        'medium-yellow': '#F7C83B',
        // others
        'light-purple': '#EAA1F2',
        'table-border-color': '#b7d2e5cc', // replaced by 'medium-border-blue'
        'filter-border-color': '#B7D2E5',
        'dropdown-black': '#333333',
      },
      fontFamily: {
        'regular-font': '"Proxima Nova", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
        'medium-font': '"Proxima Nova Medium", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
        'heavy-font': '"Proxima Nova Heavy", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
        'arial-font': 'Arial',
      },
      fontSize: {
        '13px': '13px',
        '15px': '15px',
      },
      gridTemplateColumns: {
        'line-items': 'minmax(min-content, max-content) minmax(min-content, 1fr)',
        custom: 'auto 260px 260px',
      },
      height: {
        1.5: '0.375rem',
        9: '2.25rem',
        14: '3.5rem',
        60: '60px',
        '38px': '38px',
        '52px': '52px',
        '175px': '175px',
        '409px': '409px',
        '700px': '700px',
      },
      maxHeight: {
        80: '20rem',
        '40px': '40px',
        '60px': '60px',
        '300px': '300px',
        '450px': '450px',
      },
      minHeight: {
        2.25: '2.25rem',
        '2.25rem': '2.25rem',
        2.375: '2.375rem',
      },
      inset: {
        46: '46px',
        2.5: '0.625rem',
        3: '0.75rem',
        4: '1rem',
        60: '60px',
        '1/2': '50%',
      },
      margin: {
        0.2: '0.063rem',
        0.5: '0.125rem',
        0.75: '0.1875rem',
        1.5: '0.375rem',
        9: '2.25rem',
        '-container-padding': '-15px',
        '0.75em': '0.75em',
        15: '3.75rem',
        '-3px': '-3px',
        '-15px': '-15px',
        '-1px': '-1px',
        '-28': '-7rem',
      },
      outline: {
        solid: ['1px solid #4467ab'],
      },
      opacity: {
        40: '0.4',
        80: '0.8',
      },
      padding: {
        0.5: '0.125rem',
        1.25: '0.3125rem',
        1.5: '0.375rem',
        2.5: '0.625rem',
        4.5: '1.125rem',
        9: '2.25rem',
        2.125: '2.125',
      },
      width: {
        0.5: '0.125rem',
        1.5: '0.375rem',
        80: '20rem',
        100: '25rem',
        '9/10': '90%',
        '771px': '771px',
      },
      maxWidth: {
        '150px': '150px',
        '220px': '220px',
        '350px': '350px',
        '400px': '400px',
      },
      minWidth: {
        '50px': '50px',
        '150px': '150px',
        '220px': '220px',
        '250px': '250px',
        '400px': '400px',
      },
      zIndex: {
        1: '1',
        button: '102',
        stickySubHeader: '103',
        stickyHeader: '104',
        dropdown: '105',
        drawer: '106',
        appbar: '107',
        popover: '108',
        modal: '109',
        notification: '110',
      },
      translate: {
        '200px': '200px',
      },
      fill: {
        'brand-blue': '#2C405A',
      },
    },
  },
  variants: {
    borderColor: ['responsive', 'hover', 'focus', 'focus-within', 'checked', 'group-hover'],
    boxShadow: ['responsive', 'hover', 'focus', 'focus-within'],
    opacity: ['disabled', 'hover'],
    borderWidth: ['hover'],
    backgroundColor: ['hover', 'checked', 'group-hover'],
    padding: ['hover'],
  },
  corePlugins: {
    container: false,
  },
  plugins: [],
  future: {
    removeDeprecatedGapUtilities: true,
    purgeLayersByDefault: true,
  },
};
