import React from 'react';
import _ from 'lodash';
import { editable } from 'common/utils/Permission/Permission';
import ServiceCasePrimaryWorker from 'src/components/Cases/components/Detail/ServiceCasePrimaryWorker';
import ServiceCaseProgramEntry from 'src/components/Cases/components/Detail/ServiceCaseProgramEntry';
import ServiceCaseProgramExit from 'src/components/Cases/components/Detail/ServiceCaseProgramExit';
import {
  displayText,
  generateColumn,
  displayNode,
  hideOrDisplayText,
} from 'common/display/SingleItemDetails/utils/common';
import { isCaseOpen } from 'src/components/Cases/utils';

function firstColumnInfo(serviceCase, groupId, contact, permissions = {}) {
  const { usePaymentsUserRole, currentProviderId } = permissions;
  const isOON = _.get(serviceCase, 'state') === 'off_platform';
  const serviceCaseDateClosed = (
    <ServiceCaseProgramExit
      serviceCase={serviceCase}
      groupId={groupId}
      contactId={contact.id}
      editable={editable(serviceCase, '', currentProviderId)}
    />
  );

  const programExitDate = isCaseOpen(serviceCase) ?
    null :
    displayNode({
      label: 'Date Closed',
      node: serviceCaseDateClosed,
      clickable: editable(serviceCase),
    });

  const authorizationId = _.get(serviceCase, 'service.name', '') === 'Service Authorization' &&
    usePaymentsUserRole ?
    displayText(serviceCase, { label: 'Authorization ID', key: 'id' }) :
    null;

  const serviceCaseDateOpened = (
    <ServiceCaseProgramEntry
      serviceCase={serviceCase}
      groupId={groupId}
      contactId={contact.id}
      editable={Boolean(serviceCase.outcome) && editable(serviceCase, '', currentProviderId)}
    />
  );

  const programName = !isOON ? displayText(serviceCase, { label: 'Program', key: 'program.name' }) : null;

  return [
    displayText(serviceCase, { label: 'Service Type', key: 'service.name' }),
    programName,
    !isOON ? displayNode({ label: 'Date Opened', node: serviceCaseDateOpened, clickable: editable(serviceCase) }) :
      null,
    !isOON ? programExitDate : null,
    authorizationId,
  ];
}

function secondColumnInfo(serviceCase, groupId, contact) {
  const serviceCasePrimaryWorker = (
    <ServiceCasePrimaryWorker
      serviceCase={serviceCase}
      groupId={groupId}
      contactId={contact.id}
      editable={editable(serviceCase)}
    />
  );
  return [
    hideOrDisplayText(serviceCase, { label: 'Network', key: 'network.name' }),
    displayText(serviceCase, { label: 'Organization', key: 'provider.name' }),
    displayNode({ label: 'Primary Worker', node: serviceCasePrimaryWorker, clickable: editable(serviceCase) }),
  ];
}

export default function generateDetailLabelData(serviceCase, groupId, contact, permissions = {}) {
  return [
    generateColumn(firstColumnInfo(serviceCase, groupId, contact, permissions)),
    generateColumn(secondColumnInfo(serviceCase, groupId, contact)),
  ];
}
