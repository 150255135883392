import React, { useContext } from 'react';
import { createSelector } from 'reselect';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';
import Notifier from 'common/helpers/Notifier';
import { useSelectedPrograms, SEARCH_CLIENTS, SEARCH_CONTEXTS, SEARCH_ROLES } from '@unite-us/app-search';
import ClientHeader from '@unite-us/app-client-profile/dist/components/ClientHeader';
import { getAuthToken, coreApi } from 'src/api/config';
import {
  crtb1127AuthPaymentProgramsInReferrals as crtb1127AuthPaymentProgramsInReferralsSelector,
  crtb1239AdaptDraftReferralFlow as crtb1239AdaptDraftReferralFlowSelector,
  hasPaymentsUserAccess,
  includePathwaysServices,
  hint542SupersetUnlistedPrograms as hint542SupersetUnlistedProgramsSelector,
  hint716SearchNetworkHubSupportPremiumSelector,
  hint1199IsAppSharesWithSearchApi as hint1199IsAppSharesWithSearchApiSelector,
  hint1246HideLgbtqPlusFilter as hint1246HideLgbtqPlusFilterSelector,
  uup459SupersetPhase2 as uup459SupersetPhase2Selector,
  hint1066PaymentsLiteModal as hint1066PaymentsLiteModalSelector,
  hint1426SharesShowNewPopulations as hint1426SharesShowNewPopulationsSelector,
  cerb1367ResourceListDetailEnhancements as cerb1367ResourceListDetailEnhancementsSelector,
} from 'common/utils/FeatureFlags/flags';
import { SEARCH_API, CORE_API, GOOGLE_MAPS_API_KEY, SHARES_URL } from 'src/config/env/env.config';
import { TrackerContext, ComponentLibraryLoader } from '@unite-us/client-utils';
import { init, useAppCreateReferralContext } from '@unite-us/app-create-referral';
import { browserHistory } from 'src/common/utils/browserHistory';
import { updateGlobalState } from 'actions/Global/globalActions';

const SupersetSearchApp = ComponentLibraryLoader({
  loadFn: () => import('@unite-us/app-search'),
  component: 'SupersetSearchApp',
  loadingApp: 'app-client',
  source: 'app-search',
  datadogRum,
});

const ReferralStepper = ComponentLibraryLoader({
  loadFn: () => import('@unite-us/app-create-referral'),
  component: 'ReferralStepper',
  loadingApp: 'app-client',
  source: 'app-create-referral',
  datadogRum,
});

function getInitialFilters({
  employeeId,
  employeeNetworks,
  personId,
  providerId,
  serviceIds,
}) {
  return {
    active: true,
    ...(serviceIds?.length && { services: serviceIds }),
    'provider.networks': employeeNetworks.toString(),
    referable: {
      employee: employeeId,
      networks: employeeNetworks.toString(),
      person: personId,
      provider: providerId,
    },
  };
}

const SupersetSearch = ({
  cerb1367ResourceListDetailEnhancements,
  crtb1127AuthPaymentProgramsInReferrals,
  crtb1239AdaptDraftReferralFlow,
  hint542SupersetUnlistedPrograms,
  hint716SearchNetworkHubSupportPremium,
  hint1199IsAppSharesWithSearchApi,
  hint1246HideLgbtqPlusFilter,
  hint1066PaymentsLiteModal,
  hint1426SharesShowNewPopulations,
  uup459SupersetPhase2,
  assistanceRequestId,
  serviceTypeOptions,
  employeeId,
  employeeNetworks,
  enums,
  formSubmissionId,
  providerId,
  includePathways,
  initialAddresses,
  userCoordinates,
  usePaymentsUserRole,
  location,
  networkName,
  personId,
  resourceListId,
  serviceIds,
  shouldPreserveCart,
  isShoppingCartOpen,
  setIsShoppingCartOpen,
}) => {
  const initialFilters = getInitialFilters({
    employeeId,
    employeeNetworks,
    personId,
    providerId,
    serviceIds,
  });
  const trackEvent = useContext(TrackerContext);
  const { selectedPrograms } = useSelectedPrograms();
  const {
    dispatch,
    state: {
      draftState,
    },
  } = useAppCreateReferralContext();
  const addSelectClient = browserHistory.getCurrentLocation().state?.source === 'client' || false;

  const onCreateReferralsClickHandler = () => {
    const programToServiceTypesDict = {};
    const unlistedPrograms = selectedPrograms.filter((p) => !p.id);
    const listedPrograms = selectedPrograms.filter((p) => p.id);

    listedPrograms.forEach((program) => {
      programToServiceTypesDict[program.id] = programToServiceTypesDict[program.id] ?
        [...programToServiceTypesDict[program.id], program.service] :
        [program.service];
    });

    const programsToUse = Object.keys(programToServiceTypesDict)
      .map((id) => ({
        id,
        services: programToServiceTypesDict[id],
        name: selectedPrograms.find((p) => p.id === id).name,
        // unlisted-programs are not aggregated, id is always null, and the names are distinct
        // this validation happens in search-app during creation
      })).concat(unlistedPrograms.map((p) => ({
        id: null,
        services: [p.service],
        name: p.name,
      })));

    dispatch(
      init({
        selectedPrograms: programsToUse,
        person: personId,
        resourceListId,
        formSubmissionId,
        serviceIds,
        workflow: 'search',
        addSelectClient,
        assistanceRequestId,
        draftState,
      }),
    );

    browserHistory.push({
      pathname: '/referrals/2/create/builder',
    });
  };

  const navigate = (path) => {
    const url = `/referrals/2${path}`;
    browserHistory.push({
      pathname: url,
    });
  };

  const onNavigateBackHandler = browserHistory.push;

  const onNavigateToResourceListHandler = (id) => {
    const newUrl = `/facesheet/${personId}/resource-lists/${id}`;
    browserHistory.push(newUrl);
  };

  return (
    <>
      <div className="bg-white -mx-container-padding">
        <ClientHeader
          adapters={{ coreApi }}
          appState={{
            providerId,
            personId,
            enums,
          }}
          mode="lite"
        />
      </div>
      <div className="py-4 -mx-4 bg-white">
        <div className="max-w-screen-xl mx-auto">
          <ReferralStepper
            navigate={navigate}
            location={location}
            searchParams={{
              person: personId,
              resource_list: resourceListId,
              form_submission: formSubmissionId,
              services: serviceIds,
            }}
            selectedPrograms={selectedPrograms}
            addSelectClient={addSelectClient}
          />
        </div>
      </div>
      <SupersetSearchApp
        callbacks={{
          trackEvent,
          notify: {
            error: (message) => Notifier.dispatch('error', message),
            success: (message) => Notifier.dispatch('success', message),
            warn: (message) => Notifier.dispatch('warning', message),
          },
          createReferrals: onCreateReferralsClickHandler,
          navigateBack: onNavigateBackHandler,
          navigateToResourceList: onNavigateToResourceListHandler,
        }}
        serviceTypeOptions={serviceTypeOptions}
        initialAddresses={initialAddresses}
        networkName={networkName}
        appSettings={{
          client: SEARCH_CLIENTS.APP_CLIENT,
          context: SEARCH_CONTEXTS.SUPERSET_REFERRAL,
          env: {
            getAuthToken,
            employeeId,
            providerId,
            googleApiKey: GOOGLE_MAPS_API_KEY,
            SHARES_URL,
            isReferralContext: true,
          },
          endpoints: {
            search: {
              url: SEARCH_API,
            },
            core: {
              url: CORE_API,
            },
          },
          flags: {
            serviceTypesIncludePathways: includePathways,
            crtb1127AuthPaymentProgramsInReferrals,
            crtb1239AdaptDraftReferralFlow,
            hint542SupersetUnlistedPrograms,
            hint1246HideLgbtqPlusFilter,
            hint716SearchNetworkHubSupportPremium,
            hint1199IsAppSharesWithSearchApi,
            hint1066PaymentsLiteModal,
            hint1426SharesShowNewPopulations,
            uup459SupersetPhase2,
            cerb1367ResourceListDetailEnhancements,
          },
          roles: [
            ...usePaymentsUserRole ? [SEARCH_ROLES.PAYMENTS] : [],
          ],
          shouldPreserveCart,
        }}
        formSubmissionId={formSubmissionId}
        initialFilters={initialFilters}
        personId={personId}
        userCoordinates={userCoordinates}
        resourceListId={resourceListId}
        assistanceRequestId={assistanceRequestId}
        isShoppingCartOpen={isShoppingCartOpen}
        setIsShoppingCartOpen={setIsShoppingCartOpen}
      />
    </>
  );
};

// Should match shape of address validator in Core
const addressShape = {
  address_type: PropTypes.string.isRequired,
  line_1: PropTypes.string,
  line_2: PropTypes.string,
  city: PropTypes.string.isRequired,
  county: PropTypes.string,
  state: PropTypes.string.isRequired,
  postal_code: PropTypes.string,
  country: PropTypes.string.isRequired,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  is_primary: PropTypes.bool,
};

SupersetSearch.propTypes = {
  // FLAGS
  includePathways: PropTypes.bool.isRequired,
  cerb1367ResourceListDetailEnhancements: PropTypes.bool.isRequired,
  crtb1127AuthPaymentProgramsInReferrals: PropTypes.bool.isRequired,
  crtb1239AdaptDraftReferralFlow: PropTypes.bool.isRequired,
  hint716SearchNetworkHubSupportPremium: PropTypes.bool.isRequired,
  hint542SupersetUnlistedPrograms: PropTypes.bool.isRequired,
  hint1246HideLgbtqPlusFilter: PropTypes.bool.isRequired,
  hint1199IsAppSharesWithSearchApi: PropTypes.bool.isRequired,
  hint1066PaymentsLiteModal: PropTypes.bool.isRequired,
  hint1426SharesShowNewPopulations: PropTypes.bool.isRequired,
  uup459SupersetPhase2: PropTypes.bool.isRequired,
  // FLAGS END
  employeeId: PropTypes.string.isRequired,
  employeeNetworks: PropTypes.arrayOf(PropTypes.string).isRequired,
  formSubmissionId: PropTypes.string,
  providerId: PropTypes.string.isRequired,
  initialAddresses: PropTypes.shape({
    CLIENT: PropTypes.arrayOf(PropTypes.shape(addressShape)),
    USER: PropTypes.arrayOf(PropTypes.shape(addressShape)),
    GROUP: PropTypes.arrayOf(PropTypes.shape(addressShape)),
  }).isRequired,
  serviceTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      parent_id: PropTypes.string,
      parent_code: PropTypes.string,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      taxonomy: PropTypes.string.isRequired,
      is_sensitive: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  userCoordinates: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
  usePaymentsUserRole: PropTypes.bool.isRequired,
  networkName: PropTypes.string.isRequired,
  personId: PropTypes.string,
  assistanceRequestId: PropTypes.string,
  resourceListId: PropTypes.string,
  serviceIds: PropTypes.string,
  location: PropTypes.object.isRequired,
  enums: PropTypes.object.isRequired,
  shouldPreserveCart: PropTypes.bool.isRequired,
  isShoppingCartOpen: PropTypes.bool.isRequired,
  setIsShoppingCartOpen: PropTypes.func.isRequired,
};

SupersetSearch.defaultProps = {
  assistanceRequestId: null,
  formSubmissionId: null,
  personId: null,
  resourceListId: null,
  serviceIds: null,
};

function mapStateToProps(state, ownProps) {
  const networkId = state.session.networkId || state.networkId;
  const allNetworks = (state.globalState.allNetworks || []).map((network) => ({
    id: network.id,
    name: network.name,
    coordination_centers: network.coordination_centers.data,
    network_type: network.network_type,
  }));
  const employeeNetworks = state.globalState.activeNetworks.map((network) => network.id);
  const currentNetwork = find(allNetworks, { id: networkId });

  const employeeId = state.globalState.currentEmployee.id;
  const providerId = state.globalState.currentEmployee.provider.id;
  const includePathways = includePathwaysServices(state);
  const usePaymentsUserRole = hasPaymentsUserAccess(state);
  const {
    location: {
      query: {
        assistance_request: assistanceRequestId,
        form_submission: formSubmissionId,
        person: personId,
        resource_list: resourceListId,
        services: serviceIds,
        preserve_cart: preserveCart,
      },
    },
  } = ownProps;

  const contact = find(state.contacts.contacts, { id: personId });

  const selectServiceTypes = createSelector(
    (inState) => inState.session.globals.service_types,
    (serviceTypes) => {
      const ret = [];
      serviceTypes.forEach((parentType) => {
        parentType.children.forEach((child) => ret.push({
          id: child.id,
          parent_id: parentType.id,
          parent_code: parentType.code,
          name: child.name,
          code: child.code,
          taxonomy: child.taxonomy,
          is_sensitive: child.sensitive,
        }));

        ret.push({
          id: parentType.id,
          parent_id: null,
          parent_code: null,
          name: parentType.name,
          code: parentType.code,
          taxonomy: parentType.taxonomy,
          is_sensitive: parentType.sensitive,
        });
      });
      return ret;
    },
  );

  const {
    currentEmployee: {
      addresses: userAddresses,
      provider: { addresses: groupAddresses },
    },
  } = state.globalState;
  const userCoordinates =
    Object.keys(state.session.position.geoCoordinates).length === 0 ?
      state.session.position.ipCoordinates :
      state.session.position.geoCoordinates;

  const enums = state.session.enums || {};

  const isShoppingCartOpen = state.globalState.isShoppingCartOpen;

  return {
    serviceTypeOptions: selectServiceTypes(state),
    employeeId,
    employeeNetworks,
    assistanceRequestId,
    formSubmissionId,
    includePathways,
    providerId,
    usePaymentsUserRole,
    contact,
    initialAddresses: {
      client: contact?.addresses,
      user: userAddresses,
      ours: groupAddresses,
    },
    userCoordinates,
    networkName: currentNetwork?.name || '',
    personId,
    shouldPreserveCart: preserveCart === 'true',
    resourceListId,
    serviceIds: serviceIds?.split(',') ?? null,
    enums,
    isShoppingCartOpen,
    hint716SearchNetworkHubSupportPremium: hint716SearchNetworkHubSupportPremiumSelector(state),
    crtb1127AuthPaymentProgramsInReferrals: crtb1127AuthPaymentProgramsInReferralsSelector(state),
    crtb1239AdaptDraftReferralFlow: crtb1239AdaptDraftReferralFlowSelector(state),
    hint542SupersetUnlistedPrograms: hint542SupersetUnlistedProgramsSelector(state),
    hint1246HideLgbtqPlusFilter: hint1246HideLgbtqPlusFilterSelector(state),
    hint1199IsAppSharesWithSearchApi: hint1199IsAppSharesWithSearchApiSelector(state),
    hint1066PaymentsLiteModal: hint1066PaymentsLiteModalSelector(state),
    hint1426SharesShowNewPopulations: hint1426SharesShowNewPopulationsSelector(state),
    uup459SupersetPhase2: uup459SupersetPhase2Selector(state),
    cerb1367ResourceListDetailEnhancements: cerb1367ResourceListDetailEnhancementsSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setIsShoppingCartOpen: (isOpen) => dispatch(updateGlobalState({ isShoppingCartOpen: isOpen })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SupersetSearch);
