import { useFind } from 'src/api/APIHooks';
import { isEmpty, get } from 'lodash';

const useProcedureCodeModifierOptions = (procedureCodes) => {
  const procedureCodeIds = procedureCodes.map((procedureCode) => procedureCode.id);

  const { data: response } = useFind(
    'procedure_code_modifier',
    {
      procedure_code: procedureCodes ? procedureCodeIds.join() : '',
    },
    {
      queryConfig: {
        enabled: !isEmpty(procedureCodes),
        staleTime: Infinity,
      },
    },
  );

  const procedureCodeModifiers = get(response, 'data.data', []);
  return procedureCodeModifiers;
};

export default useProcedureCodeModifierOptions;
