function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { compact, filter, get } from 'lodash';
import { anyChildInValue, autoAddChildren, getParent, isSelected, removeChildren } from "../utils";
function addOrRemoveParentChildren(item, oldValue, valueKey, allOptions, level, autoSelectParent, autoSelectChildren) {
  var initiallySelected = isSelected(item, oldValue, valueKey);
  var newValue = oldValue || [];
  if (level === 0) {
    if (initiallySelected) {
      newValue = filter(newValue, function (v) {
        return v[valueKey] !== item[valueKey];
      });
      newValue = removeChildren(newValue, get(item, 'children', []), valueKey);
    } else {
      newValue = [].concat(_toConsumableArray(newValue), [item]);
      newValue = removeChildren(newValue, get(item, 'children', []), valueKey);
      if (autoSelectChildren) {
        newValue = autoAddChildren(newValue, get(item, 'children', []));
      }
    }
  } else {
    var parent = getParent(item, allOptions, valueKey);
    newValue = filter(newValue, function (v) {
      return v[valueKey] !== parent[valueKey];
    });
    if (initiallySelected) {
      newValue = filter(newValue, function (v) {
        return v[valueKey] !== item[valueKey];
      });
      if (autoSelectParent && anyChildInValue(parent, newValue, valueKey)) {
        newValue = [].concat(_toConsumableArray(newValue), [parent]);
      }
    } else {
      newValue = [].concat(_toConsumableArray(newValue), [item]);
      if (autoSelectParent) {
        newValue = [].concat(_toConsumableArray(newValue), [parent]);
      }
    }
  }
  return compact(newValue);
}
export default addOrRemoveParentChildren;