import React, { useCallback } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Form, Field } from 'react-final-form';
import {
  BaseCard,
  BaseCardHeader,
  BaseCardBody,
  Button,
  DateField,
  InputField,
  SelectField,
} from '@unite-us/ui';
import RadioField from 'components/Backoffice/form/RadioField';
import { validations } from '@unite-us/client-utils';
import composeValidators from 'src/components/Organization/utils/composeValidators';
import { browserHistory } from 'common/utils/browserHistory';
import { Spinner } from 'common/spinners';
import { getFeeScheduleEndDateMinDate } from '../_id/utils';
import '../stylesheets/FeeScheduleForm.scss';

const namespace = 'fee-schedule-form';
const STATE_OPTIONS = [
  { label: 'Active', value: 'active' },
  { label: 'Archived', value: 'archived' },
];

const SCC_VALIDATION_OPTIONS = [
  { label: 'Rigid Validation - Service delivery within SCC Dates', value: 'false' },
  { label: 'Warning - Allow service delivery dates after SCC End Date', value: 'true' },
];

const createEndDateErrorMessage = (isEditing) => {
  if (!isEditing) {
    return 'New fee schedule end dates cannot be in the past and end date must be after start date';
  }
  return 'Fee schedule end date must be after fee schedule start date';
};

export const FeeScheduleForm = ({
 feeScheduleId, onSubmit, initialValues, showPending,
}) => {
  const isEditing = !isEmpty(feeScheduleId);
  const title = isEditing ? 'Edit Fee Schedule' : 'Add Fee Schedule';

  const onCancel = useCallback(() => {
    if (isEditing) {
      browserHistory.push({ pathname: `/backoffice/fee_schedules/${feeScheduleId}` });
    } else {
      browserHistory.push({ pathname: '/backoffice/fee_schedules' });
    }
  }, [feeScheduleId, isEditing]);

  return (
    <BaseCard>
      <BaseCardHeader title={title} />
      <BaseCardBody className={namespace}>
        { showPending ? (
          <div data-testid="fee-schedule-form_spinner-container">
            <Spinner />
          </div>
        ) : (
          <>
            <Form
              initialValues={initialValues}
              onSubmit={onSubmit}
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    name="name"
                    validate={composeValidators(validations.isRequired)}
                  >
                    {(props) => (
                      <InputField
                        className={`${namespace}__name-field col-xs-12`}
                        id={`${namespace}__name-field`}
                        dataTestId="fs-form__name-field"
                        label="Name"
                        required
                        {...props}
                      />
                    )}
                  </Field>
                  <div className={`${namespace}__date-fields`} data-testid={`${namespace}__date-fields-container`}>
                    <Field
                      name="starts_at"
                      validate={(value) => {
                        if (value && values.ends_at) {
                          if (moment(value).isSameOrAfter(values.ends_at)) {
                            return 'Start date should be before end date';
                          }
                        }
                        return validations.isRequired(value);
                      }}
                    >
                      {(props) => (
                        <DateField
                          className="col-xs-12 col-sm-6"
                          customErrorMessage={'Start date must be before end date'}
                          id={`${namespace}__starts-at-field`}
                          label="Start Date"
                          maxDate={
                            values.ends_at || initialValues.ends_at ?
                            moment(values.ends_at ?? initialValues.ends_at, 'MM/DD/YYYY')
                              .subtract(1, 'day')
                              .format('MM/DD/YYYY') : null
                          }
                          required
                          valueFormat="MM/DD/YYYY"
                          {...props}
                        />
                      )}
                    </Field>
                    <Field
                      name="ends_at"
                      validate={(value) => {
                        if (value) {
                          if (values.starts_at && moment(value).isSameOrBefore(values.starts_at)) {
                            return 'End date should be after start date';
                          }
                          if (moment(value).isBefore(moment().startOf('day'))) {
                            return 'End date should be today or after';
                          }
                        }
                        return validations.isRequired(value);
                      }}
                    >
                      {(props) => (
                        <DateField
                          className="col-xs-12 col-sm-6"
                          customErrorMessage={createEndDateErrorMessage(isEditing)}
                          id={`${namespace}__ends-at-field`}
                          label="End Date"
                          minDate={getFeeScheduleEndDateMinDate(values.starts_at ?? initialValues.starts_at, isEditing)}
                          required
                          valueFormat="MM/DD/YYYY"
                          {...props}
                        />
                      )}
                    </Field>
                  </div>
                  {isEditing && (
                    <Field
                      name="state"
                    >
                      {(props) => (
                        <SelectField
                          className="col-xs-12 col-sm-6"
                          label="State"
                          labelKey="label"
                          id={`${namespace}__state-field`}
                          options={STATE_OPTIONS}
                          required
                          valueKey="value"
                          value={values?.state}
                          {...props}
                        />
                      )}
                    </Field>
                  )}
                  <Field
                    name="ignore_social_care_expired_dates"
                  >
                    {(props) => (
                      <RadioField
                        className="col-xs-12"
                        hint="Controls if the SCC End Date should be strictly enforced when
                              creating a contracted service note. Start date is always enforced.
                              Rigid SCC End date validation vs warning when adding a contracted service note "
                        label="Social Care Coverage Validation"
                        labelKey="label"
                        id={`${namespace}__ignore-social-care-expired-dates-field`}
                        options={SCC_VALIDATION_OPTIONS}
                        {...props}
                      />
                    )}
                  </Field>
                  <div className={`${namespace}__buttons col-xs-12`} data-testid={`${namespace}__buttons-container`}>
                    <Button
                      className={`${namespace}__button col-xs-12 col-sm-3`}
                      data-testid="cancel-create-fs-btn"
                      label="Cancel"
                      onClick={onCancel}
                    />
                    <Button
                      className={`${namespace}__button col-xs-12 col-sm-3`}
                      label={isEditing ? 'Update' : 'Create'}
                      primary
                      type="submit"
                    />
                  </div>
                </form>
              )}
            />
          </>
        )}
      </BaseCardBody>
    </BaseCard>
  );
};

FeeScheduleForm.propTypes = {
  feeScheduleId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  showPending: PropTypes.bool,
};

FeeScheduleForm.defaultProps = {
  feeScheduleId: '',
  initialValues: {},
  showPending: false,
};

export default FeeScheduleForm;
