import React from 'react';
import PropTypes from 'prop-types';
import { BaseCard, BaseCardBody, BaseCardHeader } from '@unite-us/ui';
import { Spinner } from 'common/spinners';
import { useFindPlan } from 'src/components/Backoffice/api/hooks/v1/planHooks';
import { useFindNetworksById } from 'src/components/Backoffice/api/hooks/v1/networkHooks';
import moment from 'moment';
import { capitalize } from 'lodash';
import './PlanDetail.scss';

const PlanDetail = ({ planId }) => {
  const { data: plan, isFetching } = useFindPlan(planId);
  const networkIds = plan.networks ? plan.networks.map((network) => network.id).join(',') : '';
  const { data: networks, isFetching: isFetchingNetworks } = useFindNetworksById(networkIds);
  const startsAt = plan?.starts_at && moment(plan.starts_at).format('MM/DD/YYYY');
  const endsAt = plan?.ends_at && moment(plan.ends_at).format('MM/DD/YYYY');
  const details = [
    { label: 'Plan Name:', value: capitalize(plan.name) },
    { label: 'External ID:', value: capitalize(plan.external_id) },
    { label: 'Start Date:', value: startsAt },
    { label: 'End Date:', value: endsAt },
    { label: 'Plan Type:', value: capitalize(plan.plan_type) },
    { label: 'State:', value: capitalize(plan.state) },
    { label: 'Enrollment:', value: plan.enrollment_required ? 'Required' : 'Not Required' },
    { label: 'Coverage Period:', value: plan.coverage_period },
  ];

  if (plan.plan_type === 'social') {
    details.push({
      label: 'Network(s):',
      value: networks ? networks.map((network) => network.name).join(', ') : '',
    });
  }

  return (
    <div className="plan-base-card">
      <BaseCard className="plan-details p-8" noBorder>
        <BaseCardHeader title="Plan Details" noBorder className="px-4 font-bold text-2xl" />
        <BaseCardBody className="px-8 bg-white">
          {isFetching || isFetchingNetworks ? (
            <Spinner />
          ) :
          (
            <div className="grid grid-cols-6">
              { details.map((detail) => (
                <React.Fragment key={`${detail.label}-${detail.value}`}>
                  <div className="col-start-1 pl-0 pb-2">
                    {detail.label}
                  </div>
                  <div className="pb-2">
                    {detail.value}
                  </div>
                </React.Fragment>
              ))}
            </div>
          )}
        </BaseCardBody>
      </BaseCard>
    </div>
  );
};

PlanDetail.propTypes = {
  planId: PropTypes.string.isRequired,
};

export default PlanDetail;
