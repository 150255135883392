import moment from 'moment';
import _ from 'lodash';
function formatTableDate(date, format) {
  var dateMoment;

  // use new Date(date) for moment deprecation warning
  // https://github.com/moment/moment/issues/1407

  var dateObj = new Date(date);
  var isValidDate = moment(dateObj).isValid();
  if (!isValidDate || _.isNil(date)) {
    return '';
  }
  if (_.isNumber(date)) {
    dateMoment = moment.unix(dateObj);
  }
  if (_.isString(date)) {
    dateMoment = moment(dateObj);
  }
  var defaultFormat = format || 'M/D/YYYY';
  var sameYearFormat = 'MMM D';
  var utcDate = dateMoment.utc();
  if (format) {
    return utcDate.format(format);
  }
  var now = moment();
  if (moment(utcDate).isSame(now, 'year')) {
    return utcDate.format(sameYearFormat);
  }
  return utcDate.format(defaultFormat);
}
export default formatTableDate;