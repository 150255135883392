var _excluded = ["stylers"];
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
import _ from 'lodash';
export default function getStaticMapScheme(scheme) {
  var staticScheme = scheme.reduce(function (styleParams, _ref) {
    var _ref$stylers = _ref.stylers,
      stylers = _ref$stylers === void 0 ? [] : _ref$stylers,
      rest = _objectWithoutProperties(_ref, _excluded);
    var flatScheme = _.assign.apply(_, [{}, rest].concat(_toConsumableArray(stylers)));
    var styleStrings = _.reduce(flatScheme, function (styleString, val, key) {
      // eslint-disable-line arrow-body-style
      styleString.push("".concat(key.replace(/type/gi, ''), ":").concat(val.replace(/#/g, '0x')));
      return styleString;
    }, []);
    styleParams.push("style=".concat(styleStrings.join('|')));
    return styleParams;
  }, []);
  return encodeURI(staticScheme.join('&'));
}