import React from 'react';
import PropTypes from 'prop-types';
import {
  ColumnHeader,
  SortableColumnHeader,
  HeaderRow,
  Table,
  TableBody,
} from 'src/common/tables/V2';
import EnrollmentsTableRow from './EnrollmentsTableRow';

const EnrollmentsTable = ({
  enrollmentRequests,
  openDrawer,
  showEnrollmentAging,
  sortBy,
  sortDirection,
  setSort,
}) => (
  <Table className="bg-white w-full table-fixed">
    <HeaderRow>
      <ColumnHeader className="w-24">Request ID</ColumnHeader>
      <ColumnHeader className="w-24">Request Type</ColumnHeader>
      <SortableColumnHeader
        className="w-24"
        colName="person.last_name"
        label="client name"
        sortBy={sortBy}
        sortDirection={sortDirection}
        setSort={setSort}
      >
        Client Name
      </SortableColumnHeader>
      <SortableColumnHeader
        className="w-24"
        colName="enrollment_status"
        label="status"
        sortBy={sortBy}
        sortDirection={sortDirection}
        setSort={setSort}
      >
        Status
      </SortableColumnHeader>
      <SortableColumnHeader
        className="w-24"
        colName="plan.name"
        label="social care coverage plan"
        sortBy={sortBy}
        sortDirection={sortDirection}
        setSort={setSort}
      >
        Social Care Coverage Plan
      </SortableColumnHeader>
      <SortableColumnHeader
        className="w-24"
        colName="updated_at"
        label="last updated"
        sortBy={sortBy}
        sortDirection={sortDirection}
        setSort={setSort}
      >
        Last Updated
      </SortableColumnHeader>
      {showEnrollmentAging && (
        <SortableColumnHeader
          className="w-24"
          colName="created_at"
          label="time in queue"
          sortBy={sortBy}
          sortDirection={sortDirection}
          setSort={setSort}
        >
          Time In Queue
        </SortableColumnHeader>
      )}
    </HeaderRow>
    <TableBody data-testid="enrollments-table-body">
      {enrollmentRequests.map((request, idx) => (
        <EnrollmentsTableRow
          key={request.id}
          enrollmentRequest={request}
          openDrawer={() => openDrawer(idx)}
          showEnrollmentAging={showEnrollmentAging}
        />
      ))}
    </TableBody>
  </Table>
);

EnrollmentsTable.propTypes = {
  enrollmentRequests: PropTypes.array.isRequired,
  openDrawer: PropTypes.func.isRequired,
  showEnrollmentAging: PropTypes.bool,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  setSort: PropTypes.func.isRequired,
};

EnrollmentsTable.defaultProps = {
  showEnrollmentAging: false,
};

export default EnrollmentsTable;
