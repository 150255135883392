import { useFind } from 'src/api/APIHooks';
import { isEmpty, map } from 'lodash';

const useZcodeOptions = (feeScheduleProgramIds, payerId) => {
  const fspQuery = !isEmpty(feeScheduleProgramIds) ?
    { fee_schedule_program: feeScheduleProgramIds.join() } : null;
  const payerQeury = payerId ? { payer: payerId } : null;

  const { isLoading, data: response } = useFind(
    'zcode',
    {
      ...fspQuery, ...payerQeury,
    },
    {
      queryConfig: {
        enabled: !isEmpty(feeScheduleProgramIds) && !!payerId,
        placeholderData: undefined,
      },
    },
  );

  const zcodeOptions = map(response?.data?.data, (zcode) => ({
      label: `${zcode.code} - ${zcode.description}`,
      value: zcode.id,
  }));

  return isLoading ? [] : zcodeOptions;
};

export default useZcodeOptions;
