import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { capitalize, isObject } from 'lodash';
import Pagination from 'src/common/Pagination/Pagination';
import ScrollToElement from 'src/common/utils/Form/ScrollToElement';
import { Spinner } from 'common/spinners';
import {
  useFindFeeSchedulePrograms,
} from 'src/components/Backoffice/api/hooks/v1/feeScheduleProgramHooks';
import {
  Badge,
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  Button,
  Icon,
} from '@unite-us/ui';
import {
  ColumnHeader,
  SortableColumnHeader,
  DataCell,
  DataRow,
  HeaderRow,
  Table,
  TableBody,
} from 'src/common/tables/V2';
import { Filter } from '@unite-us/client-utils';
import { theme } from 'src/../tailwind.config';
import { browserHistory } from 'common/utils/browserHistory';
import moment from 'moment';
import stateBgColor from 'common/utils/stateBgColor';
import 'src/pages/fee-schedules/_id/FeeSchedulePrograms/_fee_schedule_program_id/FeeScheduleProgramBaseCard.scss';
import { useFindFeeSchedule } from 'src/components/Backoffice/api/hooks/v1/feeScheduleHooks';
import EmptyTable from 'src/common/tables/EmptyTable/EmptyTable';
import SearchBar from 'src/common/SearchBar/SearchBar';
import { DEFAULT_PAGE_SIZE } from 'src/components/Backoffice/backofficeUtils';
import './stylesheets/FeeScheduleProgramsTable.scss';
import { useFeeScheduleStatusContext } from '../components/FeeScheduleStatusContextProvider';

const namespace = 'fee-schedule-programs-table';

const FeeScheduleProgramsTable = ({ feeScheduleId }) => {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState(1);
  const { feeScheduleProgramStatus, setFeeScheduleProgramStatus } = useFeeScheduleStatusContext();
  const [name, setName] = useState('');
  const [{ sortBy, sortDirection }, setSort] = useState({ sortBy: 'ends_at', sortDirection: 'asc' });
  const { data: feeSchedule, isFetching: isFetchingFeeSchedule } = useFindFeeSchedule(feeScheduleId);
  const { data: feeScheduleProgramsResponse, isFetching } = useFindFeeSchedulePrograms(
    feeScheduleId,
    feeScheduleProgramStatus,
    name,
    {
      page: {
        size: pageSize,
        number: pageNumber,
      },
      sort: sortDirection === 'asc' ? sortBy : `-${sortBy}`,
    },
  );

  const { data: feeSchedulePrograms, paging } = feeScheduleProgramsResponse.data;

  const ScrollToElementEvent = () => {
    ScrollToElement(document.getElementById('TopOfForms'));
  };

  const statusFilterOptions = [
    { value: 'active', label: 'Active', initial: feeScheduleProgramStatus === 'active' },
    { value: 'inactive', label: 'Inactive', initial: feeScheduleProgramStatus === 'inactive' },
    { value: 'archived', label: 'Archived', initial: feeScheduleProgramStatus === 'archived' },
  ];

  const addFeeScheduleProgram = () => {
    browserHistory.push({ pathname: `/backoffice/fee_schedules/${feeScheduleId}/fee_schedule_programs/new` });
  };

  const viewFeeScheduleProgram = (feeScheduleProgram) => {
    browserHistory.push({
      pathname: `/backoffice/fee_schedules/${feeScheduleId}/fee_schedule_programs/${feeScheduleProgram.id}`,
    });
  };

  const searchFeeScheduleProgramByName = (data) => {
    setName(data.search);
  };

  return (
    <div className="fee-schedule-program-base-card">
      <BaseCard className="p-6" noBorder>
        <BaseCardHeader title="Program Details" noBorder className="px-4 font-bold">
          <Button
            id="add-program-button"
            iconLeft={<Icon color={theme.extend.colors['action-blue']} icon="IconPlusCircle" />}
            onClick={addFeeScheduleProgram}
            className="flex"
            label="ADD PROGRAM"
            disabled={moment.utc().isAfter(moment(feeSchedule.ends_at).utc())}
            data-testid="add-fs-program-btn"
          />
        </BaseCardHeader>
        <BaseCardBody withPadding>
          <div className="w-5/12 flex flex-row relative">
            <SearchBar
              className="flex-grow min-w-full"
              inputClassName="md:w-5/5"
              onSubmit={searchFeeScheduleProgramByName}
              dataTestId="fs-program-search-bar"
            />
            <Filter
              className="ml-4 mb-4"
              name="Status"
              pluralName="Status"
              hideSelectAll
              isSingleOptionSelect
              options={statusFilterOptions}
              onFiltersChange={(activeFilters) => {
                setFeeScheduleProgramStatus(activeFilters.toString());
                setPageNumber(1);
              }}
              dataTestId="fs-program-status-filter"
            />
          </div>
          {(isFetching || isFetchingFeeSchedule) ? <Spinner /> : (
            <>
              <Table
                id="feeScheduleProgramsTable"
                className="bg-white rounded-t-lg border border-solid border-light-border-blue"
              >
                <HeaderRow>
                  <ColumnHeader className="w-6/12">
                    Name
                  </ColumnHeader>
                  <ColumnHeader className="w-1/12">
                    Status
                  </ColumnHeader>
                  <ColumnHeader className="w-1/6">
                    Start Date
                  </ColumnHeader>
                  <SortableColumnHeader
                    colName="ends_at"
                    className="w-1/6"
                    label="End Date"
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    setSort={setSort}
                  >
                    End Date
                  </SortableColumnHeader>
                </HeaderRow>
                <TableBody>
                  {feeSchedulePrograms.filter(isObject).map((feeScheduleProgram, index) => (
                    <DataRow
                      className={`${namespace}__row`}
                      dataTestId={`fee-schedule-program-table-row-${index}`}
                      key={`fee-schedule-program-row-${feeScheduleProgram.id}`}
                      onClick={() => viewFeeScheduleProgram(feeScheduleProgram)}
                    >
                      <DataCell className="w-6/12">
                        {feeScheduleProgram.name}
                      </DataCell>
                      <DataCell className="w-1/12">
                        <Badge
                          className="px-4"
                          text={capitalize(feeScheduleProgram.state)}
                          color={stateBgColor(feeScheduleProgram.state)}
                        />
                      </DataCell>
                      <DataCell className="w-1/6">
                        {
                          (feeScheduleProgram.starts_at &&
                            moment(feeScheduleProgram.starts_at).utc().format('LL')) || 'N/A'
                        }
                      </DataCell>
                      <DataCell className="w-1/6">
                        {feeScheduleProgram.ends_at &&
                          moment(new Date()).startOf('day').isAfter(moment(feeScheduleProgram.ends_at)) ?
                            (
                              <div style={{ color: 'rgb(239 68 68)' }}>
                                {moment(feeScheduleProgram.ends_at).utc().format('LL')} (Expired)
                              </div>
                            ) :
                            moment(feeSchedule.ends_at).utc().format('LL') || 'N/A'}
                      </DataCell>
                    </DataRow>
                ))}
                </TableBody>
              </Table>
              {
                (paging.total_count === 0) && (
                  <EmptyTable
                    className={'p-6 border border-t-0 border-solid border-light-border-blue'}
                    message={'No Results Found.'}
                  />
                )
              }
              <div className="p-6">
                <Pagination
                  pageNumber={pageNumber}
                  pageSize={pageSize}
                  totalItemCount={paging.total_count}
                  totalPageCount={paging.total_pages}
                  setPageSize={setPageSize}
                  setPageNumber={setPageNumber}
                  scrollPaginationElement={ScrollToElementEvent}
                />
              </div>
            </>
          )}
        </BaseCardBody>
      </BaseCard>
    </div>
  );
};

FeeScheduleProgramsTable.propTypes = {
  feeScheduleId: PropTypes.string.isRequired,
};

export default FeeScheduleProgramsTable;
