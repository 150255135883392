import axios from 'axios';
import * as env from 'src/config/env/env.config';
import {
  buildSerializer,
  buildJSONAPIDeserializerInterceptor,
  JSONAPIAdapter,
} from '@unite-us/json-api-resources';

const serializer = buildSerializer();

const JSONAPIDeserializerInterceptor = buildJSONAPIDeserializerInterceptor(serializer);

axios.defaults.baseURL = env.CORE_API;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Accept = 'application/json';

// api default and apiV4Default are axios api adapters pointing to Core/V4, without serialization/deserialization
const apiDefault = axios.create({
  baseURL: env.CORE_API,
});

const apiV4Default = axios.create({
  baseURL: env.CORE_API,
});

const corePhase3Client = axios.create({
  baseURL: env.CORE_API,
});
const corePhase3ClientLegacy = axios.create({
  baseURL: env.CORE_LEGACY_API_URL,
});

const apiV1Client = axios.create({
  baseURL: env.CORE_API,
});

const apiV4Client = axios.create({
  baseURL: env.CORE_API,
});

const authV1Client = axios.create({
  baseURL: `${env.AUTH_URL}/v1`,
});

const authClient = axios.create({
  baseURL: `${env.AUTH_URL}`,
});

const intelClient = axios.create({
  baseURL: env.INTEL_URL,
});

const authApiClient = axios.create({
  baseURL: `${env.AUTH_URL}/v1`,
});
authApiClient.interceptors.response.use(JSONAPIDeserializerInterceptor);

const messagingApiClient = axios.create({
  baseURL: `${env.MESSAGING_SERVICE_URL}`,
});

const prdAdminApiClient = axios.create({
  baseURL: `${env.PRD_API_URL}/admin`,
});

// JSONAPIAdapter instances
const coreApi = new JSONAPIAdapter(corePhase3Client, serializer);
const coreApiLegacy = new JSONAPIAdapter(corePhase3ClientLegacy, serializer);
const apiV1 = new JSONAPIAdapter(apiV1Client, serializer);
const apiV4 = new JSONAPIAdapter(apiV4Client, serializer);
const authApi = new JSONAPIAdapter(authApiClient, serializer);
const prdAdminApi = new JSONAPIAdapter(prdAdminApiClient, serializer);

const searchAPIClient = axios.create({
  baseURL: env.SEARCH_API,
});

const apis = {
  coreApi,
  apiV1,
  apiV4,
  authApi,
  searchAPIClient,
  prdAdminApi,
};

const clients = {
  corePhase3Client,
  corePhase3ClientLegacy,
  apiV1Client,
  apiV4Client,
  apiDefault,
  apiV4Default,
  authApiClient,
  messagingApiClient,
  searchAPIClient,
  prdAdminApiClient,
};

const allClients = [
  axios,
  apiDefault,
  apiV4Default,
  corePhase3Client,
  corePhase3ClientLegacy,
  apiV1Client,
  apiV4Client,
  authV1Client,
  authClient,
  intelClient,
  authApiClient,
  messagingApiClient,
  searchAPIClient,
  prdAdminApiClient,
];

const addJSONAPIDeserializerInterceptor = (interceptorFn, errorFn) => {
  const eClients = [
    corePhase3Client,
    apiV1Client,
    apiV4Client,
    authV1Client,
    prdAdminApiClient,
  ];

  eClients.forEach((client) => {
    client.interceptors.response.use(interceptorFn, errorFn);
  });
};

addJSONAPIDeserializerInterceptor(JSONAPIDeserializerInterceptor);

const addUnauthorizeInterceptor = (interceptorFn, errorFn) => {
  allClients.forEach((client) => {
    client.interceptors.response.use(interceptorFn, errorFn);
  });
};

const addApplicationSourceHeader = () => {
  const key = 'X-Application-Source';
  const value = 'web';
  const eClients = [
    apiDefault,
    apiV4Default,
    corePhase3Client,
    corePhase3ClientLegacy,
    apiV1Client,
    apiV4Client,
    messagingApiClient,
    prdAdminApiClient,
  ];

  eClients.forEach((client) => {
    client.defaults.headers[key] = value; // eslint-disable-line no-param-reassign
  });
};

addApplicationSourceHeader();

const addEmployeeIDHeader = (value) => {
  const key = 'X-EMPLOYEE-ID';
  const eClients = [
    apiDefault,
    apiV4Default,
    corePhase3Client,
    corePhase3ClientLegacy,
    apiV1Client,
    apiV4Client,
    searchAPIClient,
    messagingApiClient,
    prdAdminApiClient,
  ];

  eClients.forEach((client) => {
    client.defaults.headers[key] = value; // eslint-disable-line no-param-reassign
  });
};

const addProviderIDHeader = (value) => {
  const key = 'X-PROVIDER-ID';
  const eClients = [
    searchAPIClient,
  ];

  eClients.forEach((client) => {
    client.defaults.headers[key] = value; // eslint-disable-line no-param-reassign
  });
};

const API_ADAPTERS = {
  CORE_API: 'coreApi',
  API_V1: 'apiV1',
  API_V4: 'apiV4',
  AUTH_API: 'authApi',
  SEARCH_API: 'searchAPIClient',
  PRD_ADMIN_API: 'prdAdminApi',
};

const getAdapter = (api) => apis[api];

const addHeaderToAxiosClients = (key, value) => {
  Object.keys(clients).forEach((clientName) => {
    clients[clientName].defaults.headers[key] = value;
  });
};

const addAuthorizationHeader = (token) => {
  allClients.forEach((client) => {
    client.defaults.headers.Authorization = `Bearer ${token}`; // eslint-disable-line no-param-reassign
  });
};

const setAuthTokenClients = (token) => {
  intelClient.defaults.headers.Authorization = `Bearer ${token}`;
  Object.keys(clients).forEach((clientName) => {
    clients[clientName].defaults.headers.Authorization = `Bearer ${token}`;
  });
};

// Assuming everyone has the same token
const getAuthToken = () => apiV1Client.defaults.headers.Authorization.split('Bearer ')[1];

export {
  addUnauthorizeInterceptor,
  addAuthorizationHeader,
  addEmployeeIDHeader,
  addProviderIDHeader,
  setAuthTokenClients,
  addHeaderToAxiosClients,
  getAuthToken,
  getAdapter,
  corePhase3Client,
  corePhase3ClientLegacy,
  apiV1Client,
  apiV4Client,
  authClient,
  intelClient,
  authApiClient,
  messagingApiClient,
  prdAdminApiClient,
  searchAPIClient,
  prdAdminApi,
  coreApi,
  coreApiLegacy,
  apiV1,
  apiV4,
  authApi,
  apiDefault,
  apiV4Default,
  API_ADAPTERS,
};
