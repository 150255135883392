import { useEffect, useRef } from 'react';
var INITIAL_OVERFLOW = 'data-initial-overflow';
var INITIAL_PADDING = 'data-initial-padding';
export default function useScrollbar() {
  var bodyRef = useRef(null);
  useEffect(function () {
    if (typeof document !== 'undefined') {
      bodyRef.current = document.body;
    }
  }, []);
  var saveInitialOverflow = function saveInitialOverflow() {
    if (!bodyRef.current || bodyRef.current.hasAttribute(INITIAL_OVERFLOW)) return;
    bodyRef.current.dataset.initialOverflow = bodyRef.current.style.overflow;
  };
  var disableBodyOverflow = function disableBodyOverflow() {
    saveInitialOverflow();
    if (bodyRef.current) {
      bodyRef.current.style.overflow = 'hidden';
    }
  };
  var saveInitialPadding = function saveInitialPadding() {
    if (!bodyRef.current) return;
    bodyRef.current.dataset.initialPadding = bodyRef.current.style.paddingRight;
  };
  var getBodyWidth = function getBodyWidth() {
    if (!bodyRef.current) return 0;
    var documentWidth = document.documentElement.clientWidth;
    return typeof window !== 'undefined' ? Math.abs(window.innerWidth - documentWidth) : 0;
  };
  var hideScrollbar = function hideScrollbar() {
    var width = getBodyWidth();
    if (!bodyRef.current) return;
    disableBodyOverflow();
    saveInitialPadding();
    bodyRef.current.style.paddingRight = "".concat(width, "px");
  };
  var restoreInitialOverflow = function restoreInitialOverflow() {
    if (!bodyRef.current) return;
    bodyRef.current.style.overflow = bodyRef.current.dataset.initialOverflow || '';
    bodyRef.current.removeAttribute(INITIAL_OVERFLOW);
  };
  var restoreInitialPadding = function restoreInitialPadding() {
    if (!bodyRef.current) return;
    bodyRef.current.style.paddingRight = bodyRef.current.dataset.initialPadding || '';
    bodyRef.current.removeAttribute(INITIAL_PADDING);
  };
  var resetScrollbar = function resetScrollbar() {
    restoreInitialOverflow();
    restoreInitialPadding();
  };
  return {
    hideScrollbar: hideScrollbar,
    resetScrollbar: resetScrollbar,
    getBodyWidth: getBodyWidth
  };
}