import moment from 'moment';
import _ from 'lodash';
function formatDate(date) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'M/D/YYYY';
  var dateMoment;

  // use new Date(date) for moment deprecation warning
  // https://github.com/moment/moment/issues/1407

  var dateObj = new Date(date);
  var isValidDate = moment(dateObj).isValid();
  if (!isValidDate || _.isNil(date)) {
    return '';
  }
  if (_.isNumber(date)) {
    dateMoment = moment.unix(dateObj);
  }
  if (_.isString(date)) {
    dateMoment = moment(dateObj);
  }
  return dateMoment.utc().format(format);
}
export default formatDate;