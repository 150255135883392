import {
  FETCH_GROUP_CONTACT,
  SELECT_CONTACT,
  SET_CONTACTS_IS_FETCHING,
  UNSET_CONTACTS_IS_FETCHING,
} from 'actions';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import Notifier from 'common/helpers/Notifier';
import { get, wget } from 'lodash';
import { coreApi } from 'src/api/config';

const fetchGroupContact = (groupId, id) => (
  async (dispatch) => {
    dispatch({ type: SET_CONTACTS_IS_FETCHING });
    const payload = await coreApi.findRecord('person', id, { include: 'addresses' });
    await Promise.all([
      coreApi.populateRelationship('contact_preference', 'contact_preference', payload.data.data),
      coreApi.populateRelationship('consent', 'consent', payload.data.data),
    ]);
    await coreApi.populateRelationship('consent.file_uploads', 'file_upload', payload.data.data, {
      customQuery: (modelName, ids) => {
        const requestPromises = ids.map((fileUploadId) => coreApi.findRecord(modelName, fileUploadId));
        return Promise.all(requestPromises).then((responses) => (
          { data: { data: responses.map((resp) => get(resp, 'data.data')) } }
        ));
      },
    });

    if (!payload || !isHttpSuccess(payload.status)) {
      Notifier.handleErrors(payload);
      dispatch({ type: UNSET_CONTACTS_IS_FETCHING });
      return payload;
    }

    dispatch({
      type: FETCH_GROUP_CONTACT,
      payload,
    });
    dispatch({
      type: SELECT_CONTACT,
      id: wget(payload, 'data.data.id'),
    });
    dispatch({ type: UNSET_CONTACTS_IS_FETCHING });

    return payload;
  }
);

export default fetchGroupContact;
