import { useQuery } from 'react-query';
import { messagingApiClient } from 'src/api/config';
import { Client as TwilioClient } from '@twilio/conversations';

export const useTwilioClient = (employeeId) => (
  useQuery(
    ['twilio_token', employeeId],
    async () => {
      const response = await messagingApiClient.post('/twilio_token', { identity: employeeId });
      const twilioClient = new TwilioClient(response.data.token);

      return twilioClient;
    },
    {
      staleTime: 10 * 60 * 1000,
      // every 10 minutes, we will automatically fetch a new Twilio token from Messaging Service:
      refetchInterval: 10 * 60 * 1000,
      enabled: Boolean(employeeId),
    },
  )
);
