import React from 'react';
import PropTypes from 'prop-types';
import { SearchableSelectField } from 'components/Backoffice/form/SearchableSelectField';
import { SelectField } from 'components/Backoffice/form/SelectField';

const FilterSelectors = ({
  filterValues,
  filterOptions,
  resetFilters,
  onUpdateFilter,
  setRequestTypeFilter,
  setPlanFilter,
  setDaysSinceLastUpdatedFilter,
}) => {
  const clearFilters = () => {
    resetFilters();
    onUpdateFilter();
  };

  return (
    <div className="flex items-start items-center space-x-4">
      <SelectField
        className="w-64"
        label="Request Type"
        placeholder="Choose Type"
        options={filterOptions.requestType}
        value={filterValues.requestType ?? ''}
        onChange={({ value }) => {
          setRequestTypeFilter(value);
          onUpdateFilter();
        }}
        clearable
      />
      <SearchableSelectField
        className="w-100"
        label="Social Care Coverage Plan"
        placeholder="Choose Plan"
        value={filterValues.plan ?? ''}
        onChange={({ value }) => {
          setPlanFilter(value);
          onUpdateFilter();
        }}
        options={filterOptions.plan}
        truncateOptions={false}
        clearable
      />
      <SelectField
        className="w-64"
        label="Last Updated"
        placeholder="Choose Last Updated"
        value={filterValues.daysSinceLastUpdated ?? ''}
        onChange={({ value }) => {
          setDaysSinceLastUpdatedFilter(value);
          onUpdateFilter();
        }}
        options={filterOptions.daysSinceLastUpdated}
        clearable
      />
      <button
        aria-label="Clear all filters"
        className="mt-5 text-action-blue"
        type="button"
        onClick={clearFilters}
      >
        <span className="text-action-blue hover:text-text-blue focus:outline-none hover:text-text-blue">
          Clear all
        </span>
      </button>
    </div>
  );
};

FilterSelectors.propTypes = {
  filterValues: PropTypes.shape({
    requestType: PropTypes.string,
    plan: PropTypes.string,
    daysSinceLastUpdated: PropTypes.number,
  }).isRequired,
  filterOptions: PropTypes.shape({
    requestType: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
    plan: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
    daysSinceLastUpdated: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })).isRequired,
  }).isRequired,
  resetFilters: PropTypes.func.isRequired,
  onUpdateFilter: PropTypes.func.isRequired,
  setRequestTypeFilter: PropTypes.func.isRequired,
  setPlanFilter: PropTypes.func.isRequired,
  setDaysSinceLastUpdatedFilter: PropTypes.func.isRequired,
};

export default FilterSelectors;
