import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { RadioField, TextField } from '@unite-us/ui';
import { PreferredMethodOfContactField } from 'common/form';
import { Toggle } from 'common/Toggle';
import ContactHoursEdit from 'src/common/display/ContactHours/ContactHoursEdit';

const HINT_TEXT = 'Notes appear throughout client records. Please avoid including sensitive information (including PHI) in your note.'; // eslint-disable-line max-len
const NOTE_PROMPT = 'Please ask the client if there are special instructions that should be followed when contacting them.'; // eslint-disable-line max-len

export const ContactRequirementsForm = ({
  enums,
  fields,
  registerField,
  submitFailed,
  validations,
  requireContactPreferences,
}) => {
  const buildTimesToContactLabelClass = () => classNames(
    'ui-form-field__label w-full mb-2',
    requireContactPreferences ? 'ui-form-field__label--required' : '',
  );

  const [toggleSelectTimes, setToggleSelectTimes] = useState(requireContactPreferences);
  const [timesToContactLabelClass, setTimesToContactLabelClass] = useState(buildTimesToContactLabelClass);

  const contactEnums = _.get(enums, 'contact_data.communication_preferences.methods_of_contact', []);
  const methodsSelected = _.get(fields, 'contact_preferences.preferred_contact_methods.value', []);
  const isPhoneCallSelected = _.includes(methodsSelected, 'phone');
  const isTimeslotsValid = toggleSelectTimes && !submitFailed;
  const contactHoursClasses = classNames(
    'lg:w-2/3 px-6 pt-6 pb-2 border border-solid rounded',
    isTimeslotsValid ? 'border-dark-border-blue' : 'border-red',
  );

  const clearTimeslots = () => {
    Object.values(fields.contact_preferences.timeslots).forEach((timeslot) => {
      timeslot.removeField(0);
    });
  };

  useEffect(() => {
    setToggleSelectTimes(requireContactPreferences);
    setTimesToContactLabelClass(buildTimesToContactLabelClass());
  }, [requireContactPreferences]);

  return (
    <div className="contact-requirements mt-4">
      <h2 className="contact-requirements__title">Contact Requirements</h2>

      <hr className="mb-4 mt-1" />

      <div className="w-6/12">
        <PreferredMethodOfContactField
          field={fields.contact_preferences.preferred_contact_methods}
          forceObjectValue={false}
          id="preferred-method-of-contact-field"
          multiple
          options={contactEnums}
          registerField={registerField}
          required={requireContactPreferences}
          validations={validations.isRequired}
        />
      </div>

      <RadioField
        field={fields.contact_preferences.voice_mail_ok}
        className={isPhoneCallSelected ? '' : 'hidden'}
        id="leave-voicemail"
        inline
        inlineLabel
        label="Is it okay to leave voicemail?"
        options={[
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' },
        ]}
        ref={registerField}
        required={isPhoneCallSelected}
        validations={isPhoneCallSelected ? validations.isRequired : undefined}
      />

      <div className="lg:w-3/4 sm:w-auto">
        <label
          htmlFor="contact-requirements___selected-times-toggle"
          className={timesToContactLabelClass}
        >
          Times To Contact
        </label>
        <div className="grid grid-cols-6 mb-4">
          <Toggle
            checked={toggleSelectTimes}
            id="contact-requirements___selected-times-toggle"
            label="Selected Contact Times"
            labelClass="px-0 col-span-4 tracking-normal"
            onChange={() => {
              if (!requireContactPreferences) {
                setToggleSelectTimes(!toggleSelectTimes);
                clearTimeslots();
              }
            }}
            toggleClass="px-0 pr-24 justify-self-end col-start-5 col-end-7"
            value={toggleSelectTimes}
          >
            <div className="pl-2">
              { toggleSelectTimes ? 'On' : 'Off' }
            </div>
          </Toggle>
        </div>
      </div>

      { toggleSelectTimes && (
        <>
          <div className={contactHoursClasses}>
            <ContactHoursEdit
              fields={fields}
              registerField={registerField}
            />
          </div>
        </>
      )}

      <div className="w-full mt-6">
        <TextField
          afterLabelContent={NOTE_PROMPT}
          field={fields.contact_preferences.contact_notes}
          hint={HINT_TEXT}
          id="contact-requirements__notes"
          inline={false}
          label="Contact Notes"
          ref={registerField}
        />
      </div>
    </div>
  );
};

ContactRequirementsForm.propTypes = {
  registerField: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  validations: PropTypes.object.isRequired,
  enums: PropTypes.object.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  requireContactPreferences: PropTypes.bool,
};

ContactRequirementsForm.fields = [
  'contact_preferences.preferred_contact_methods',
  'contact_preferences.contact_notes',
  'contact_preferences.voice_mail_ok',
  ...ContactHoursEdit.fields,
];

ContactRequirementsForm.defaultProps = {
  requireContactPreferences: false,
};

export default ContactRequirementsForm;
