import moveOptionToEnd from "./moveOptionToEnd";
import sortByKey from "./sortByKey";
var sortOptions = function sortOptions(_ref) {
  var moveToEndValue = _ref.moveToEndValue,
    _ref$moveToEndValueKe = _ref.moveToEndValueKey,
    moveToEndValueKey = _ref$moveToEndValueKe === void 0 ? 'value' : _ref$moveToEndValueKe,
    _ref$options = _ref.options,
    options = _ref$options === void 0 ? [] : _ref$options,
    _ref$sortKey = _ref.sortKey,
    sortKey = _ref$sortKey === void 0 ? 'label' : _ref$sortKey,
    _ref$sortDirection = _ref.sortDirection,
    sortDirection = _ref$sortDirection === void 0 ? 'asc' : _ref$sortDirection;
  return moveOptionToEnd({
    options: sortByKey({
      options: options,
      sortKey: sortKey,
      sortDirection: sortDirection
    }),
    value: moveToEndValue,
    valueKey: moveToEndValueKey
  });
};
export default sortOptions;