import React, { Component } from 'react';
import { browserHistory } from 'common/utils/browserHistory';
import PropTypes from 'prop-types';
import {
  SelectField,
  TextField,
  DateField,
  Button,
} from '@unite-us/ui';
import { change } from 'redux-form';
import { fetchGroupAssistanceRequestOutcomes } from 'actions/Outcome/ServiceType/Group';
import { fetchNetworkAssistanceRequestOutcomes } from 'actions/Outcome/ServiceType/Network';
import { validations, Serializer } from '@unite-us/client-utils';
import { validateReduxForm } from 'common/form';
import { OverlaySpinner } from 'common/spinners';
import {
  coordinationCenterNetwork,
  isCoordinationCenter,
} from 'common/utils/Group';
import getOutcomesOptions from 'src/common/utils/Outcomes/utils/getOutcomesOptions';
import getOutcomesForServiceType from 'src/common/utils/Outcomes/utils/getOutcomesForServiceType';
import { ASSISTANCE_REQUEST } from 'common/utils/EventTracker/utils/eventConstants';
import callOrLog from 'src/common/utils/callOrLog';
import _ from 'lodash';
import today from 'src/common/utils/today';
import {
  closeAssistanceRequest,
} from 'actions/AssistanceRequest/Group';

const CLOSE_ASSISTANCE_REQUEST_FORM = 'CLOSE_ASSISTANCE_REQUEST_FORM';

const RESOLUTION_OPTIONS = [
  { label: 'Resolved', value: 'resolved' },
  { label: 'Unresolved', value: 'not_resolved' },
];

const getNetworkIdForOutcomes = (networks = [], groupId = '') => {
  if (!groupId || _.isEmpty(networks)) {
    return '';
  }

  if (networks.length === 1) {
    return networks[0] && networks[0].id;
  }

  // @todo Handle multiple networks
  // For now, return an empty string in order to maintain the current behavior.
  return '';
};

const updateNetworkId = (networkId) => (dispatch) => {
  dispatch(change(CLOSE_ASSISTANCE_REQUEST_FORM, 'network_id', networkId));
  dispatch(change(CLOSE_ASSISTANCE_REQUEST_FORM, 'outcome_id', ''));
};

class CloseAssistanceRequestForm extends Component {
  componentDidMount() {
    const { initializeForm, serviceTypeId } = this.props;
    const initialCloseDate = new Date();
    initialCloseDate.setHours(0, 0, 0, 0);
    const close_date = initialCloseDate.getTime() / 1000;
    initializeForm({ close_date });
    if (!_.isEmpty(serviceTypeId)) {
      this.fetchOutcomes();
    }
  }

  onNetworkChange = (network) => {
    this.fetchOutcomes(network.id);
    this.props.updateNetworkId(network.id);
  };

  onCancelClick = () => {
    this.props.resetForm();
    this.props.closeDialog();
  };

  submitCloseAssistanceRequest = (formData) => {
    const {
      groupId,
      networkId,
      assistanceRequest,
    } = this.props;

    const outcomePayload = Serializer.build({ outcome: formData });
    // log assistance request closed event
    callOrLog(() => this.context.eventTracker(
      ASSISTANCE_REQUEST.closed,
      outcomePayload,
      { assistanceRequest: this.props.assistanceRequest },
    ));
    // close assistance request
    this.props.closeAssistanceRequest(
      groupId,
      networkId,
      assistanceRequest,
      formData,
      true,
    )
      .then(() => {
        this.props.resetForm();
        browserHistory.push('/dashboard/assistance-requests/new');
      });
    this.props.closeDialog();
  };

  fetchOutcomes = (overrideNetworkId = null) => {
    const { networkId, serviceTypeId } = this.props;
    // Fetch the newtork outcomes if we have a networkId.
    if (overrideNetworkId || networkId) {
      this.props.fetchNetworkAssistanceRequestOutcomes(overrideNetworkId || networkId, serviceTypeId);
    }
    // Fetch the group outcomes if we have a group ID.
    const groupId = _.get(this.props.assistanceRequest, 'group.id');
    if (groupId) {
      this.props.fetchGroupAssistanceRequestOutcomes(groupId, serviceTypeId);
    }
  }

  render() {
    const {
      networks,
      fields,
      outcomes,
      submitting,
      handleSubmit,
    } = this.props;

    return (
      <div className="close-ar">

        <OverlaySpinner text="Closing Assistance Request..." show={submitting} />

        <form
          onSubmit={
            handleSubmit(this.submitCloseAssistanceRequest)
          } className="close-ar__form"
        >
          <div className="close-ar__inputs">
            <SelectField
              className="close-ar-resolved-select"
              field={fields.resolved}
              id="resolved-input"
              label="Is Resolved?"
              options={RESOLUTION_OPTIONS}
              ref={this.props.registerField}
              validations={validations.isRequired}
              inline={false}
              required
            />
            { networks?.length > 1 && (
            <SelectField
              disabled={!fields?.resolved?.value || fields?.resolved?.invalid}
              className="close-ar-network-select"
              field={fields.network_id}
              labelKey="name"
              valueKey="id"
              forceObjectValue
              id="network-input"
              options={networks}
              label="Network"
              ref={this.props.registerField}
              validations={validations.isRequired}
              inline={false}
              required
              onChange={this.onNetworkChange}
            />
             )}
            <SelectField
              disabled={
                (!fields?.resolved?.value || fields?.resolved?.invalid) ||
                (networks?.length > 1 && (!fields?.network_id?.value || fields?.network_id?.invalid))
                }
              field={fields.outcome_id}
              className="close-ar-outcome-select"
              id="outcome-input"
              label="Outcome"
              options={getOutcomesOptions(outcomes, _.get(fields.resolved, 'value'))}
              ref={this.props.registerField}
              validations={validations.isRequired}
              inline={false}
              required
              shouldSort={false}
            />
            <TextField
              disabled={
                (!fields?.resolved?.value || fields?.resolved?.invalid) ||
                (networks?.length > 1 && (!fields?.network_id?.value || fields?.network_id?.invalid))
                }
              field={fields.note}
              id="note-input"
              label="Note"
              ref={this.props.registerField}
              validations={validations.isRequired}
              inline={false}
              required
            />

            <DateField
              field={fields.close_date}
              id="exitDateInput"
              label="Close Date"
              ref={this.props.registerField}
              validations={validations.isRequired}
              inline={false}
              required
              maxDate={today()}
            />
          </div>
          <div className="actions">
            <Button
              className="action-item"
              id="close-ar-cancel-btn"
              label="Cancel"
              onClick={this.onCancelClick}
            />
            <Button
              className="action-item"
              id="close-ar-close-btn"
              // onClick={this.closeDialog('CLOSE')}
              primary
              label="Close"
              style={{ marginLeft: '10px' }}
              type="submit"
            />
          </div>
        </form>
      </div>
    );
  }
}

CloseAssistanceRequestForm.propTypes = {
  assistanceRequest: PropTypes.shape({
    group: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  groupId: PropTypes.string.isRequired,
  fetchGroupAssistanceRequestOutcomes: PropTypes.func.isRequired,
  fetchNetworkAssistanceRequestOutcomes: PropTypes.func.isRequired,
  closeAssistanceRequest: PropTypes.func.isRequired,
  updateNetworkId: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  networkId: PropTypes.string.isRequired,
  outcomes: PropTypes.array,
  registerField: PropTypes.func.isRequired,
  initializeForm: PropTypes.func.isRequired,
  serviceTypeId: PropTypes.string.isRequired,
  submitting: PropTypes.bool,
  networks: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
};

CloseAssistanceRequestForm.defaultProps = {
  assistanceRequest: {},
  outcomes: [],
  submitting: false,
  networks: [],
};

CloseAssistanceRequestForm.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const groupId = _.get(ownProps, 'assistanceRequest.group.id');
  const serviceTypeId = _.get(ownProps, 'assistanceRequest.service_type.id');
  const networks = state.globalState?.allNetworks || [];

  const groupIsCC = isCoordinationCenter(networks, groupId);
  const ccNetwork = coordinationCenterNetwork(networks, groupId);

  const networkId = groupIsCC ?
    ccNetwork.id :
    getNetworkIdForOutcomes(networks, groupId);

  const networkOutcomes = _.get(state, 'outcomes.networkOutcomes', []);
  const groupOutcomes = _.get(state, 'outcomes.groupOutcomes', []);

  const outcomes = getOutcomesForServiceType(serviceTypeId, [...networkOutcomes, ...groupOutcomes]);

  return {
    networks,
    networkId,
    outcomes,
    serviceTypeId,
  };
}

const fields = [
  'outcome_id',
  'resolved',
  'note',
  'close_date',
  'network_id',
];
export default validateReduxForm({
  form: CLOSE_ASSISTANCE_REQUEST_FORM,
  fields,
}, mapStateToProps, {
  fetchGroupAssistanceRequestOutcomes,
  fetchNetworkAssistanceRequestOutcomes,
  updateNetworkId,
  closeAssistanceRequest,
})(CloseAssistanceRequestForm);
