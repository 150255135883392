import { replace } from 'lodash';
var isLongerThan = function isLongerThan() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var minLength = arguments.length > 1 ? arguments[1] : undefined;
  return minLength <= value.length;
};
var phoneNumberValidation = function phoneNumberValidation(value) {
  if (!value) {
    return undefined;
  }
  // If value is ONLY non-digits, it should fail validation.
  var digitsOnly = replace(value, /[^\d]/g, '');
  return !digitsOnly || !isLongerThan(digitsOnly, 10) ? 'Must be at least 10 digits' : undefined;
};
export default phoneNumberValidation;