function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { reduce, noop } from 'lodash';
import validations from "../../../utils/validations";
import getNumericalityValidations from "./getNumericalityValidations";
var typeToFunc = function typeToFunc(validator) {
  switch (validator.validation_type) {
    case 'presence':
      return [function (value) {
        return validations.isRequired(value, validator.message);
      }];
    case 'numericality':
      return getNumericalityValidations(validator);
    default:
      return [noop];
  }
};
var getValidations = function getValidations(validators) {
  var question = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var validationList = reduce(validators, function (acc, validator) {
    return [].concat(_toConsumableArray(acc), _toConsumableArray(typeToFunc(validator)));
  }, []);
  if (question.input_type === 'select') {
    if (question.min_selections > 0) {
      validationList = [].concat(_toConsumableArray(validationList), [function (value, allValues) {
        return validations.hasAtLeastN(value, null, question.min_selections, allValues);
      }]);
    }
    if (question.max_selections > 0) {
      validationList = [].concat(_toConsumableArray(validationList), [function (value, allValues) {
        return validations.hasAtMostN(value, null, question.max_selections, allValues);
      }]);
    }
  }
  return validationList;
};
export default getValidations;