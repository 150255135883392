import PropTypes from 'prop-types';
import React from 'react';
import { validations } from '@unite-us/client-utils';
import {
  SelectField,
} from '@unite-us/ui';
import { ORG_CASE } from 'src/components/Cases/constants';
import { networkOptions } from 'src/components/Cases/utils';

const CaseNetworkField = ({
  caseType,
  networks,
  ...props
}) => (
  <SelectField
    className={caseType === ORG_CASE ? 'hidden' : ''}
    validations={caseType !== ORG_CASE ? validations.isRequired : undefined}
    required={caseType !== ORG_CASE}
    options={networkOptions(networks)}
    {...props}
  />
);

CaseNetworkField.propTypes = {
  disabled: PropTypes.bool.isRequired,
  field: PropTypes.object.isRequired,
  networks: PropTypes.array.isRequired,
  registerField: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  caseType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

CaseNetworkField.defaultProps = {
  autoSelectValue: true,
  id: 'network',
  label: 'Network',
  labelKey: 'name',
  valueKey: 'id',
  forceObjectValue: true,
  placeholder: 'Choose one...',
};

export default CaseNetworkField;
