import { formatTimeOfDay } from "../../hours/HoursOfOperation/utils";
function convertTimeToMilitaryFormat(originalTime) {
  var time = originalTime;
  if (typeof time === 'number') {
    time = formatTimeOfDay(originalTime);
  }
  var hours = Number(time.match(/^(\d+)/)[1]);
  var minutes = Number(time.match(/:(\d+)/)[1]);
  var AP = time.match(/\s(.*)$/);
  if (!AP) AP = time.slice(-2);else AP = AP[1];
  if (AP === 'PM' && hours < 12) hours += 12;
  if (AP === 'AM' && hours === 12) hours -= 12;
  var Hours24 = hours.toString();
  var Minutes24 = minutes.toString();
  if (hours < 10) Hours24 = "0".concat(Hours24);
  if (minutes < 10) Minutes24 = "0".concat(Minutes24);
  return "".concat(Hours24, ":").concat(Minutes24);
}
export default convertTimeToMilitaryFormat;