// Library Imports
import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { InfoPopover, HoursOfOperationV2, addresses, phones as phonesUtils } from '@unite-us/client-utils';
import { Icon } from '@unite-us/ui';
import findPrimaryOrFirst from 'src/components/Contacts/utils/findPrimaryOrFirst';
import { dollarAmount } from 'common/display/Money/DollarAmount';
import featureFlag from 'src/common/utils/FeatureFlag/FeatureFlag';
import StatusSelect from 'src/components/StatusSelect';
import { MethodsOfContact, VoiceMailOk } from 'src/components/Facesheet/Profile/ContactRequirements/components';
import { generateClientData, getDobData, formatAddress, formatHouseholdCount, filterMilitaryInfo } from './utils';
import ClientItem from './components/ClientItem';
import ClientProfileLink from './components/ClientProfileLink';
import './stylesheets/client-summary.scss';

export class ClientSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientData: [],
    };

    this.setClientData = this.setClientData.bind(this);
  }

  componentDidMount() {
    const { contact } = this.props;

    this.setClientData(contact);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.contact !== nextProps.contact) {
      this.setClientData(nextProps.contact);
    }
  }

  setClientData(contact) {
    const { enums, isMilitaryFocused } = this.props;

    const phoneNumber = findPrimaryOrFirst(contact.phone_numbers).phone_number;
    const emailAddress = findPrimaryOrFirst(contact.email_addresses).email_address;
    const address = addresses.findMainAddress(contact.addresses);
    const militaryInfo = filterMilitaryInfo(contact, enums, isMilitaryFocused);

    const clientData = [
      generateClientData('dob', getDobData(contact.date_of_birth)),
      generateClientData('tel', phonesUtils.formatPhoneNumber(phoneNumber)),
      generateClientData('email', emailAddress),
      generateClientData('address', formatAddress(address)),
      generateClientData('household', formatHouseholdCount(_.get(contact, 'household.total'))),
      generateClientData('household monthly income', dollarAmount(contact.gross_monthly_income)),
    ];

    this.setState({ clientData: _.filter([...clientData, ...militaryInfo], (data) => !_.isEmpty(data.value)) });
  }

  render() {
    const {
      contact, showConsent, isFetching, enums, isSupersetEnabled, hasClientLink,
    } = this.props;
    const timeslots = _.get(contact, 'contact_preference.timeslots');
    const contactMethods = _.get(contact, 'contact_preference.preferred_contact_methods', []);
    const isVoicemailOK = _.get(contact, 'contact_preference.voice_mail_ok', null);
    const contactNotes = _.get(contact, 'contact_preference.contact_notes');
    const showVoiceMailOk = _.includes(contactMethods, 'phone') && isVoicemailOK != null;

    const warnContactNotes = !!contactNotes;
    const warnVoicemail = _.includes(contactMethods, 'phone') && isVoicemailOK === false;
    const warnTimeslots = !_.isEmpty(timeslots);
    const showContactRequirementsBanner = warnContactNotes || warnVoicemail || warnTimeslots;

    return (
      <div className="client-summary">
        <div className="flex">
          {showConsent && <StatusSelect contact={contact} isFetching={isFetching} />}
          {isSupersetEnabled && hasClientLink && (<ClientProfileLink />)}
        </div>
        {showContactRequirementsBanner ?
          (
            <div className="contact-requirements mt-4 ml-1 text-red flex">
              <div>
                This client has specific contact requirements. Check these requirements before contacting the client.
              </div>
              <Icon
                icon="IconInfoCircle"
                className="ml-1 mb-0.5"
                color="#CF2A2A"
                id="anchor-id-info-popover-contact-methods"
              />
              <InfoPopover
                id="info-popover-contact-methods"
                placement="bottom-start"
                hiddenTooltip={false}
                tooltipText={(
                  <div className="p-4 text-sm">
                    <div className="flex">
                      <div className="mr-3">Methods of Contact:</div>
                      <MethodsOfContact
                        contactMethods={contactMethods}
                        enums={enums}
                      />
                    </div>
                    {showVoiceMailOk && (
                      <div className="mt-3">
                        <VoiceMailOk
                          contactMethods={contactMethods}
                          isVoicemailOK={isVoicemailOK}
                        />
                      </div>
                    )}
                    {!_.isEmpty(timeslots) && (
                      <div className="mt-3">
                        <HoursOfOperationV2
                          closedText="Do not contact"
                          endTimeKey="end_time"
                          hours={timeslots}
                          hoursLabel="Contact Times:"
                          showAll
                          startTimeKey="start_time"
                        />
                      </div>
                    )}
                    {contactNotes && (
                      <div className="flex mt-3">
                        <div className="whitespace-pre mr-3">Contact Notes:</div>
                        <div className="whitespace-pre-wrap">{contactNotes}</div>
                      </div>
                    )}
                  </div>
                )}
              />
            </div>
          ) : null }
        <div className="client-summary__data-display">
          <ul className="list-unstyled">
            {
              _.map(this.state.clientData, ({ label, value }) => <ClientItem label={label} value={value} key={label} />)
            }
          </ul>
        </div>
      </div>
    );
  }
}

ClientSummary.propTypes = {
  contact: PropTypes.shape({
    full_name: PropTypes.string.isRequired,
    military: PropTypes.shape({
      affiliation: PropTypes.string,
      current_status: PropTypes.string,
    }),
    contact_preference: PropTypes.shape({
      preferred_contact_methods: PropTypes.array,
      timeslots: PropTypes.object,
    }),
  }).isRequired,
  enums: PropTypes.object.isRequired,
  isMilitaryFocused: PropTypes.bool.isRequired,
  showConsent: PropTypes.bool,
  isFetching: PropTypes.bool,
  isSupersetEnabled: PropTypes.bool,
  hasClientLink: PropTypes.bool,
};

ClientSummary.defaultProps = {
  showConsent: true,
  isFetching: false,
  isSupersetEnabled: false,
  hasClientLink: false,
};

ClientSummary.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

export default featureFlag(ClientSummary);
