import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  forEachRight,
  every,
  get,
  noop,
  some,
  isEmpty,
  each,
  filter,
  map,
  last,
} from 'lodash';
import { connect } from 'react-redux';
import { Button, InputField, Icon } from '@unite-us/ui';
import { Serializer, validations } from '@unite-us/client-utils';
import { ShareDrawer } from '@unite-us/shares-utils';
import {
  pluckSelectedGroupIds,
  pluckSelectedProgramIds,
} from 'src/components/Referrals/ReferralGroupsPrograms/utils';
import callOrLog from 'common/utils/callOrLog';
import { SERVICE_CASE, REFERRAL } from 'common/utils/EventTracker/utils/eventConstants';
import { SHARES_URL } from 'src/config/env/env.config';
import { coreApi } from 'src/api/config';
import { getGroup } from 'common/utils/stateHelpers';
import { ReferralProgramGroupSelect } from '../../ReferralGroupsPrograms/components';
import './stylesheets/oonGroupsSelector.scss';
import {
  ReferralOONGroupHeader,
  ReferralOONGroupSelect,
} from './components';
import { canAddMoreOONGroups, oonGroupSelected, canAddMoreCustomPrograms } from './utils';
import AddProgramsSection from '../../../Browse/AddProgramsSection/AddProgramsSection';
import { getInvalidSensitiveErrors } from '../MimicReferralGroupValidations/utils';

const allGroupValuesAreEmpty = (selected = []) => (
  selected.length > 1 && every(selected, ({ group } = {}) => group.value === '')
);

export class OONGroupsSelector extends Component {
  constructor(props) {
    super(props);

    this.onGroupSelect = this.onGroupSelect.bind(this);
    this.onProgramGroupSelect = this.onProgramGroupSelect.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onCustomSelect = this.onCustomSelect.bind(this);
    this.onDeselect = this.onDeselect.bind(this);
    this.addGroupField = this.addGroupField.bind(this);
    this.addCustomField = this.addCustomField.bind(this);
    this.clearCustomField = this.clearCustomField.bind(this);
    this.removeGroupField = this.removeGroupField.bind(this);
    this.toggleShareDrawer = this.toggleShareDrawer.bind(this);
    this.trackOONShares = this.trackOONShares.bind(this);
    this.trackGroupShares = this.trackGroupShares.bind(this);
    this.state = {
      disableOrgAddButton: false,
      showShareDrawer: false,
      mixpanelLanguage: 'en',
      hasSensitiveError: false,
    };
    this.inputField = React.createRef();
  }

  componentDidMount() {
    this.props.registerField(this.inputField.current);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const selected = get(nextProps, 'fields.oonCase.selected', []);
    const custom = get(nextProps, 'fields.oonCase.custom', []);

    if (selected.length === 0) {
      this.addGroupField();
    }

    if (this.props.isProgramBasedSearch && custom.length === 0) {
      this.addCustomField();
    }

    this.setState({
      disableOrgAddButton: some(selected, ['group.value', '']),
    });

    if (allGroupValuesAreEmpty(selected)) {
      forEachRight(selected, (selectedObj, index) => {
        if (index !== 0) {
          this.removeGroupField(index);
        }
      });
    }

    this.evaluateSelectedFields();
    this.checkSensitiveErrors();
  }

  componentWillUnmount() {
    this.props.unregisterField(this.inputField.current);
  }

  onGroupSelect(selected, index) {
    if (!selected) {
      this.onDeselect(index);
    } else if (selected && selected.name === selected.id) {
      this.onCustomSelect(selected);
    } else {
      this.onSelect(selected);
    }
    this.props.onGroupSelect(selected);
    this.evaluateSelectedFields();
    this.checkSensitiveErrors();
  }

  onProgramGroupSelect(selected, index) {
    const { selectedFields } = this.props;
    const group = get(selected, 'relationships.provider.data', '');

    if (!selected) {
      this.onDeselect(index);
    } else if (selected && selected.name === selected.id) {
      this.onCustomSelect(selected);
    } else {
      selectedFields[index].group.onChange(group);
      selectedFields[index].program.onChange(selected);
      this.onSelect(selected);
    }
    this.props.onGroupSelect(group);
    this.evaluateSelectedFields();
    this.checkSensitiveErrors();
  }

  onSelect(selected) {
    // See hint-421, 422, and this Slack thread: https://uniteus.slack.com/archives/C03FKCZNYAV/p1687980924001949
    if (this.props.isProgramBasedSearch) {
      return;
    }
    const {
      canPaginateNetworkGroups,
      contact,
      removeSelectedBrowseGroup,
    } = this.props;

    if (canPaginateNetworkGroups) {
      removeSelectedBrowseGroup('out-of-network');
    }
    callOrLog(() => this.context.eventTracker(
      SERVICE_CASE.OONGroupSelected,
      { OONGroup: selected.name },
      { contact },
    ));
  }

  onCustomSelect(selected) {
    // See hint-421, 422, and this Slack thread: https://uniteus.slack.com/archives/C03FKCZNYAV/p1687980924001949
    if (this.props.isProgramBasedSearch) {
      return;
    }
    const { contact } = this.props;
    callOrLog(() => this.context.eventTracker(
      SERVICE_CASE.OONGroupSelectedCustom,
      { OONGroup: selected.name },
      { contact },
    ));
  }

  onDeselect(index) {
    const {
      canPaginateNetworkGroups,
      contact,
      fields,
      removeSelectedBrowseGroup,
    } = this.props;

    if (canPaginateNetworkGroups) {
      removeSelectedBrowseGroup('out-of-network');
    }
    fields.oonCase.selected.removeField(index);
    this.checkSensitiveErrors(index);
    callOrLog(() => this.context.eventTracker(
      SERVICE_CASE.OONGroupDeselected,
      null,
      { contact },
    ));
  }

  onShareEvent({ shareMethod }) {
    this.trackOONShares(shareMethod);
    if (shareMethod === 'print') this.toggleShareDrawer();
  }

  getShareDrawerProgramsListDetails() {
    return this.props.selectedFields
      .filter((instance) => !isEmpty(instance.program?.value))
      .map((instance) => ({
        shared_group_id: instance.program.value.id,
        shared_group_name: instance.program.value.attributes.name,
      }));
  }

  checkSensitiveErrors() {
    const {
      caseReferrals,
      groupKey,
      referral,
      selectedFields,
      oonGroups,
      sensitiveProvider,
      setHasSensitiveErrors,
      index,
    } = this.props;

    const { hasSensitiveError } = this.state;

    const sensitiveGroups = filter(oonGroups, (group) => group.sensitive);
    const sensitiveGroupIds = map(sensitiveGroups, (g) => g.id);

    const hasError = selectedFields.some((field) => getInvalidSensitiveErrors({
      caseReferrals,
      sensitiveGroupIds,
      isCurrentUserGroupSensitive: sensitiveProvider,
      fields: selectedFields,
      groupId: field[groupKey].value.id,
      referral,
    }).length > 0);

    if (hasError !== hasSensitiveError) {
      this.setState({ hasSensitiveError: hasError }, () => {
        if (setHasSensitiveErrors) {
          setHasSensitiveErrors(hasError, index);
        }
      });
    }

    return hasError;
  }

  addGroupField() {
    this.props.fields.oonCase.selected.addField();
  }

  addCustomField() {
    this.props.fields.oonCase.custom.addField();
  }

  clearCustomField(index) {
    const { fields } = this.props;

    fields.oonCase.custom.removeField(index);
  }

  removeGroupField(index) {
    this.onGroupSelect(null, index);
  }

  evaluateSelectedFields() {
    const selectedGroups = this.props.selectedFields.filter((group) => group.group.value);

    if (selectedGroups.length === 0) {
      this.setState({
        showShareDrawer: false,
      });
    }
  }

  toggleShareDrawer() {
    this.setState({ showShareDrawer: !this.state.showShareDrawer });
  }

  trackOONShares(shareMethod) {
    const { isProgramBasedSearch, selectedFields, oonGroups } = this.props;
    if (isProgramBasedSearch) {
      this.trackProgramShares(shareMethod, this.getShareDrawerProgramsListDetails());
    } else {
      const selectedGroupIds = selectedFields.map((selection) => selection.group.value.id);
      const selectedGroups = oonGroups.filter((group) => selectedGroupIds.includes(group.id));
      this.trackGroupShares(shareMethod, selectedGroups);
    }
  }

  trackProgramShares(shareMethod, programs) {
    const programCount = programs.length;
    const { network } = this.props;
    const language_tracker = this.state.mixpanelLanguage;

    programs.forEach((program) => {
      const payload = {
        ...program,
        shared_message_type: shareMethod,
        browse_network_id: network.id,
        browse_network_name: network.name,
        shared_group_count: programCount,
        shared_language_code: language_tracker,
        share_type: 'programs',
      };

      callOrLog(() => this.context.eventTracker(REFERRAL.shareClicked, payload));
    });
  }

  trackGroupShares(shareMethod, groups) {
    // groups will always be an array of one or many
    const groupCount = groups.length;
    const { network } = this.props;
    const language_tracker = this.state.mixpanelLanguage;

    each(groups, (g) => {
      const sharedGroup = Serializer.build({ sharedGroup: g });

      const payload = {
        ...sharedGroup,
        shared_message_type: shareMethod,
        browse_network_id: network.id,
        browse_network_name: network.name,
        shared_group_count: groupCount,
        shared_language_code: language_tracker,
        share_type: 'providers',
      };

      callOrLog(() => this.context.eventTracker(REFERRAL.shareClicked, payload));
    });
  }

  render() {
    const {
      touch,
      canPaginateNetworkGroups,
      employeeId,
      groupKey,
      originCoordinates,
      inNetworkGroupsEmpty,
      oonGroups,
      nationalOONGroups,
      debouncedSearchNetworkGroups,
      referral,
      registerField,
      selectedFields,
      query,
      isProgramBasedSearch,
      programKey,
      customFields,
      unregisterField,
      isFetchingNetworkGroups,
      sensitiveProvider,
      caseReferrals,
    } = this.props;

    const sensitiveGroups = filter(oonGroups, (group) => group.sensitive);
    const sensitiveGroupIds = map(sensitiveGroups, (g) => g.id);
    const sensitiveFieldErrorsFilter = (field) => getInvalidSensitiveErrors({
      caseReferrals,
      sensitiveGroupIds,
      isCurrentUserGroupSensitive: sensitiveProvider,
      fields: selectedFields,
      groupId: field[groupKey].value.id,
      referral,
    }).length > 0;

    const sensitiveErrorsByField = selectedFields
      .filter(sensitiveFieldErrorsFilter)
      .map((field) => (
        {
          id: field[groupKey].value.id,
          errors: getInvalidSensitiveErrors({
            caseReferrals,
            sensitiveGroupIds,
            isCurrentUserGroupSensitive: sensitiveProvider,
            fields: selectedFields,
            groupId: field[groupKey].value.id,
            referral,
          }),
        }
      ))
      .reduce((acc, current) => {
        const result = { ...acc };
        result[current.id] = current.errors;
        return result;
      }, {});

    const sensitiveErrorKey = last(Object.keys(sensitiveErrorsByField));
    const sensitiveFieldErrors = sensitiveErrorsByField[sensitiveErrorKey];
    const { disableOrgAddButton, showShareDrawer } = this.state;
    const hasAtLeastOneCustomProgram = some(customFields, (field) => (get(field, 'group.value') !== ''));
    const hasAtLeastOneProgram = some(selectedFields, (field) => (get(field, 'program.value') !== ''));

    const selectedGroupIds = pluckSelectedGroupIds(selectedFields, groupKey);
    const customOnly = oonGroups.length === 0;

    const shareDrawerProgramsList = selectedFields
      .filter((group) => !isEmpty(group.program?.value))
      .map(({ program: { value: { id, attributes: { name } } } }) => ({ id, name }));

    const shareDrawerGroupsList = selectedFields
      .filter((group) => !isEmpty(group.group.value))
      .map(({ group: { value: { id, name } } }) => ({ id, name }));

    // As long as we have at least 1 oon group selected/custom group entered
    // we can allow the form to submit with additional blank group fields.
    const hasAtLeastOneValue = some(selectedFields, (field) => (get(field, 'group.value') !== ''));

    return (
      <div className="oon-groups-selector">
        <div className="row">
          {isProgramBasedSearch ? (
            <div className="col-xs-12">
              <AddProgramsSection
                toggleBrowse={this.props.toggleBrowse}
                isFetchingNetworkGroups={isFetchingNetworkGroups}
              />
            </div>
          ) : (
            <div className="col-xs-6">
              <ReferralOONGroupHeader
                inNetworkGroupsEmpty={inNetworkGroupsEmpty}
                oonGroupsEmpty={oonGroups.length === 0}
                toggleBrowse={this.props.toggleBrowse}
                referral={referral}
                isProgramBasedSearch={isProgramBasedSearch}
              />
              <p className="mb-quarter">Sorted by: Distance</p>
            </div>
            )}
        </div>

        <div className="referral-groups-programs__wrapper">
          {
            selectedFields.map((selected, index) => (
              isProgramBasedSearch ? (
                <div className="mb-8" key={selected.program.value.id}>
                  <ReferralProgramGroupSelect
                    allowEmptyGroups={hasAtLeastOneCustomProgram || hasAtLeastOneProgram}
                    registerField={registerField}
                    groupErrors={isEmpty(sensitiveFieldErrors) ? [] : sensitiveFieldErrors}
                    debouncedSearchNetworkGroups={debouncedSearchNetworkGroups}
                    hidden={customOnly}
                    index={index}
                    onProgramGroupSelect={this.onProgramGroupSelect}
                    originCoordinates={originCoordinates}
                    programField={selected[programKey]}
                    selectedProgramIds={pluckSelectedProgramIds(selectedFields)}
                    suggestedGroups={oonGroups}
                    referral={referral}
                    groupsOptionType="out-of-network"
                    unregisterField={unregisterField}
                  />
                </div>
              ) : (
                <div className="row" key={selected.group.value.id}>
                  <div className="col-xs-6 referral-groups-programs__padded-col">
                    <ReferralOONGroupSelect
                      canPaginateNetworkGroups={canPaginateNetworkGroups}
                      customOnly={customOnly}
                      debouncedSearchNetworkGroups={debouncedSearchNetworkGroups}
                      allowEmpty={hasAtLeastOneValue}
                      oonGroupFields={selectedFields}
                      originCoordinates={originCoordinates}
                      groupField={selected[groupKey]}
                      index={index}
                      onGroupSelect={(choice) => this.onGroupSelect(choice, index)}
                      registerField={registerField}
                      suggestedGroups={oonGroups}
                      nationalStateSuggestedGroups={nationalOONGroups}
                      selectedGroupIds={selectedGroupIds}
                      query={query}
                      touch={touch}
                    />
                  </div>
                </div>
              )))
            }
          <div className={`row ${isProgramBasedSearch ? '-mt-4 mb-2' : ''}`}>
            <div className="col-xs-12">
              {
                  canAddMoreOONGroups({ selectedFields, suggestedGroups: oonGroups }) && (
                    <Button
                      id="add-another-oon-group-btn"
                      className="mb-one mr-one"
                      label={`+ ${isProgramBasedSearch ? 'ADD ANOTHER PROGRAM BY QUICK ADD' :
                        'ADD ANOTHER OUT OF NETWORK RECIPIENT'}`}
                      onClick={this.addGroupField}
                      disabled={disableOrgAddButton}
                    />
                  )
                }
              {
                  oonGroupSelected(selectedFields) && !customOnly && (
                    <Button
                      className="share-button referral-groups-programs__share-btn"
                      id="share-btn"
                      onClick={this.toggleShareDrawer}
                      iconRight={<Icon icon="IconShare" color="#2C405A" />}
                      label="Share"
                    />
                  )
                }
            </div>
          </div>

          {isProgramBasedSearch ? (
            <div className="mt-4">
              {
                customFields.map((field, index) => (
                  <div
                    className={`row ${isProgramBasedSearch ? '-mt-2' : ''}`}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`referral-oon-custom-program-input-${index}`}
                  >
                    <div className="col-xs-12">
                      <InputField
                        autoComplete="off"
                        className="referral-oon-custom-program-input"
                        id={`input-field-oon-program-group-${index}`}
                        field={field[groupKey]}
                        label="oon-custom-program-input"
                        hideLabel
                        inline={false}
                        onChange={(e) => this.onGroupSelect({ name: e.target.value, id: e.target.value })}
                        ref={this.inputField}
                        placeholder="Type a custom program"
                        validations={!hasAtLeastOneProgram && !hasAtLeastOneCustomProgram ?
                          validations.isRequired : undefined}
                      />
                      <Icon
                        icon="IconCross"
                        className="referral-oon-custom-program-cross"
                        size={8}
                        onClick={() => this.clearCustomField(index)}
                      />
                    </div>
                  </div>
                ))
              }

              {
                canAddMoreCustomPrograms({ customFields }) && (
                  <Button
                    id="add-another-custom-oon-program-btn"
                    className="mb-one mr-one"
                    label="+ ADD ANOTHER CUSTOM PROGRAM"
                    onClick={this.addCustomField}
                  />
                )
              }
            </div>
          ) : null}
        </div>
        <div aria-hidden={!showShareDrawer}>
          <ShareDrawer
            employeeId={employeeId}
            coreApi={coreApi}
            SHARES_URL={SHARES_URL}
            show={showShareDrawer}
            onClose={this.toggleShareDrawer}
            resourceType={isProgramBasedSearch ? 'program' : 'provider'}
            resources={isProgramBasedSearch ? shareDrawerProgramsList : shareDrawerGroupsList}
            onShare={
              ({ shareMethod, language }) => this.onShareEvent({ shareMethod, language })
            }
          />
        </div>
      </div>
    );
  }
}

OONGroupsSelector.propTypes = {
  canPaginateNetworkGroups: PropTypes.bool.isRequired,
  contact: PropTypes.object.isRequired,
  customFields: PropTypes.array,
  employeeId: PropTypes.string,
  sensitiveProvider: PropTypes.bool,
  caseReferrals: PropTypes.array,
  index: PropTypes.number,
  fields: PropTypes.shape({
    service_type: PropTypes.object.isRequired,
    oonCase: PropTypes.shape({
      selected: PropTypes.array.isRequired,
      custom: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
  debouncedSearchNetworkGroups: PropTypes.func.isRequired,
  originCoordinates: PropTypes.array.isRequired,
  groupKey: PropTypes.string,
  inNetworkGroupsEmpty: PropTypes.bool,
  isProgramBasedSearch: PropTypes.bool,
  programKey: PropTypes.string,
  onGroupSelect: PropTypes.func,
  oonGroups: PropTypes.array,
  referral: PropTypes.object.isRequired,
  registerField: PropTypes.func.isRequired,
  selectedFields: PropTypes.array,
  removeSelectedBrowseGroup: PropTypes.func.isRequired,
  setHasSensitiveErrors: PropTypes.func,
  toggleBrowse: PropTypes.func.isRequired,
  touch: PropTypes.func,
  network: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  nationalOONGroups: PropTypes.array,
  query: PropTypes.string,
  unregisterField: PropTypes.func,
  isFetchingNetworkGroups: PropTypes.bool,
};

OONGroupsSelector.defaultProps = {
  customFields: [],
  employeeId: '',
  sensitiveProvider: false,
  caseReferrals: [],
  groupKey: 'group',
  inNetworkGroupsEmpty: false,
  isProgramBasedSearch: false,
  programKey: 'program',
  onGroupSelect: noop,
  oonGroups: [],
  selectedFields: [],
  query: '',
  nationalOONGroups: [],
  touch: noop,
  unregisterField: noop,
  isFetchingNetworkGroups: false,
};

OONGroupsSelector.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const groupId = state.session.groupId;
  const groupName = getGroup(state, groupId).name;
  const employeeId = state.globalState?.currentEmployee?.id;
  const sensitiveProvider = state.globalState?.currentProvider?.group?.sensitive;
  return { groupName, employeeId, sensitiveProvider };
};

export default connect(mapStateToProps)(OONGroupsSelector);
