import { useFind } from 'src/api/APIHooks';
import { isEmpty, map } from 'lodash';

const usePlaceOfServiceOptions = (feeScheduleProgramIds) => {
  const { isFetching, data: response } = useFind(
    'place_of_service',
    {
      fee_schedule_program: feeScheduleProgramIds.join(),
    },
    {
      queryConfig: {
        enabled: !isEmpty(feeScheduleProgramIds),
        staleTime: Infinity,
      },
    },
  );

  const placeOfServiceOptions = map(response.data.data, (pos) => ({
      label: `${pos.code} - ${pos.description}`,
      value: pos.id,
  }));

  return isFetching ? [] : placeOfServiceOptions;
};

export default usePlaceOfServiceOptions;
