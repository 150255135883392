import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { validateReduxForm } from 'common/form';
import { tracker, validations } from '@unite-us/client-utils';
import {
  Button,
  SelectField,
} from '@unite-us/ui';
import _, { debounce } from 'lodash';
import { OverlaySpinner } from 'common/spinners';
import callOrLog from 'src/common/utils/callOrLog';
import { CARE_COORDINATOR } from 'common/utils/EventTracker/utils/eventConstants';
import fetchProvidersUserCore from 'src/actions/UserProvider/fetchProvidersUser';

// Utilities
import labelCustomization from 'src/common/utils/Customization/labels';
import defaultLabels from 'src/constants/labels';

const fields = ['care_coordinator'];
const NO_COORDINATOR = 'none';

class EditCareCoordinatorForm extends Component {
  constructor(props) {
    super(props);

    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.loadOptions = this.loadOptions.bind(this);
    this.debouncedLoadOptions = debounce(this.loadOptions, 400);
  }

  onCancel() {
    this.props.resetForm();
    this.props.onCancel();
  }

  onSubmit(values) {
    const { detailObj, getClientRelationship } = this.props;
    return this.props.onFormSubmit(values).then(() => {
      getClientRelationship();
      callOrLog(() => this.context.eventTracker(CARE_COORDINATOR.assignedByDetailView, {
        care_coordinator: values.care_coordinator,
      }, tracker.getDetailObj(detailObj)));
    });
  }

  buildOptions = (users) => {
    const options = Array.from(users)
      .sort((u1, u2) => {
        const n1 = `${u1.user.last_name} ${u1.user.first_name}`.trim();
        const n2 = `${u2.user.last_name} ${u2.user.first_name}`.trim();
        return n1.localeCompare(n2);
      })
      .map((u) => ({
        value: u.employee.id,
        label: u.user.full_name,
      }));

    options.unshift({ value: 'none', label: 'None Assigned' });
    return options;
  };

  loadOptions(search) {
    return this.props.fetchProvidersUserCore({
      providers: this.props.groupId,
      options: {
        text: search,
        page: 1,
        size: 50,
        licensed: true,
      },
    }).then((providerUsers) => ({ options: this.buildOptions(providerUsers.response.data.data) }));
  }

  render() {
    const {
      fields: { care_coordinator },
      handleSubmit,
      registerField,
      submitting,
      usersWithContacts,
      onCancel,
      labels,
    } = this.props;

    const options = this.buildOptions(usersWithContacts);

    return (
      <form className="edit-care-coordinator-form content-with-actions">
        <OverlaySpinner show={submitting} text={`Assigning ${labels.CareCoordinator}...`} />
        <div className="content-container content-container--medium">
          <SelectField
            className="care-coordinator-select"
            id="care-coordinator-selector"
            field={care_coordinator}
            inline={false}
            label={labels.CareCoordinator}
            options={options}
            ref={registerField}
            validations={validations.isRequired}
            required
            loadOptions={this.debouncedLoadOptions}
            shouldSearchOnHide={false}
            shouldSort={false}
          />
        </div>
        <div className="actions">
          <span className="action-item">
            <Button
              id="edit-care-coordinator-cancel-btn"
              label="Cancel"
              onClick={onCancel}
              secondary
            />
          </span>
          <span className="action-item">
            <Button
              id="edit-care-coordinator-assign-btn"
              disabled={submitting}
              label="Assign"
              primary
              onClick={handleSubmit(this.onSubmit)}
            />
          </span>
        </div>
      </form>
    );
  }
}

EditCareCoordinatorForm.propTypes = {
  coordinator: PropTypes.shape({
    user: PropTypes.object.isRequired,
  }),
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  registerField: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  usersWithContacts: PropTypes.arrayOf(PropTypes.object),
  detailObj: PropTypes.object.isRequired,
  fetchProvidersUserCore: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  getClientRelationship: PropTypes.func.isRequired,
  labels: PropTypes.object,
};

EditCareCoordinatorForm.defaultProps = {
  coordinator: undefined,
  usersWithContacts: undefined,
  labels: defaultLabels,
};

EditCareCoordinatorForm.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { groupId } = state.session;
  const coordinator = _.get(ownProps, 'coordinator.id', NO_COORDINATOR);

  const activeUsersWithContacts = _.get(state, 'groupsUsers.data', []);

  return {
    groupId,
    usersWithContacts: activeUsersWithContacts,
    initialValues: {
      care_coordinator: coordinator,
    },
    labels: labelCustomization(state),
  };
}

export default validateReduxForm(
  {
    form: 'careCoordinatorSelection',
    fields,
  },
  mapStateToProps,
  {
    fetchProvidersUserCore,
  },
)(EditCareCoordinatorForm);
