import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { validateReduxForm } from 'common/form';
import { validations, NoteDisclosure } from '@unite-us/client-utils';
import today from 'common/utils/today';
import { InputField, DateField, SelectField } from '@unite-us/ui';
import { get, isEmpty, noop } from 'lodash';
import NoteVisibility from 'src/common/form/Interactions/components/NoteVisibility';
import './TrackService.scss';
import FormInteractionsButtons from './FormInteractionsButtons';
import InteractionNoteField from './InteractionNoteField';
import SelectServiceCases from './SelectServiceCases';

const TRACK_SERVICE_FORM = 'trackService';

class TrackService extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onSubmit(formData) {
    return this.props.submitFunction(formData).then(this.props.resetForm);
  }

  onCancel() {
    this.props.cancelFunction();
    this.props.resetForm();
  }

  render() {
    const {
      fields,
      handleSubmit,
      registerField,
      submitFailed,
      submitting,
      isMyProviderSensitive,
      showNoteVisibility,
    } = this.props;

    const unitOptions = [
      { value: 'dollar', label: 'Dollar Amount ($)' },
      { value: 'hours', label: 'Hours' },
      { value: 'units per item', label: 'Units/Item' },
    ];

    const warningMessage = <NoteDisclosure />;

    return (
      <div className="track-service">
        <form
          className="mt-3"
          onSubmit={handleSubmit(this.onSubmit)}
          data-role="provided-service-form"
        >
          <div className="row">
            <div className="col-sm-6">
              <InputField
                field={fields.provided_service.type}
                id="providedServiceType"
                label="Service Provided"
                placeholder="Service Type"
                ref={registerField}
                validations={validations.isRequired}
                required
              />
            </div>
            <div className="col-sm-3">
              <InputField
                field={fields.provided_service.amount}
                id="providedServiceAmount"
                label="Amount"
                placeholder="Enter amount..."
                type="number"
                ref={registerField}
                validations={validations.isRequired}
                required
              />
            </div>
            <div className="col-sm-3">
              <SelectField
                id="providedServiceUnit"
                field={fields.provided_service.unit}
                searchEnabled={false}
                label="Unit"
                options={unitOptions}
                ref={registerField}
                validations={validations.isRequired}
                required
              />
            </div>
            <div className="col-sm-4">
              <DateField
                id="serviceProvidedDate"
                label="Date of service"
                field={fields.provided_service.date}
                ref={registerField}
                validations={validations.isRequired}
                required
                maxDate={today()}
              />
            </div>
          </div>

          {null}

          <InteractionNoteField
            afterLabelContent={warningMessage}
            field={fields.provided_service.note}
            registerField={registerField}
          />

          {showNoteVisibility ? (
            <NoteVisibility
              registerField={registerField}
              disabled={isMyProviderSensitive}
              field={fields.interaction.is_note_private}
            />
          ) : null}

          <SelectServiceCases
            registerField={registerField}
            serviceCases={fields.caseOptions}
            submitFailed={submitFailed}
          />

          <FormInteractionsButtons
            primaryActionHandler={handleSubmit(this.onSubmit)}
            cancelActionHandler={this.onCancel}
            submitting={submitting}
            interaction="track-service"
          />
        </form>
      </div>
    );
  }
}

TrackService.propTypes = {
  cancelFunction: PropTypes.func,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  submitFunction: PropTypes.func.isRequired,
  registerField: PropTypes.func.isRequired,
  isMyProviderSensitive: PropTypes.bool.isRequired,
  showNoteVisibility: PropTypes.bool.isRequired,
};

TrackService.defaultProps = {
  cancelFunction: noop,
};

function mapStateToProps(state, ownProps = {}) {
  const { caseOptions } = ownProps;
  const isMyProviderSensitive = get(
    state,
    'globalState.currentProvider.group.sensitive',
    null,
  );
  const showNoteVisibility = ownProps.detailType === 'profile';
  const is_note_private = Boolean(showNoteVisibility && isMyProviderSensitive);

  return {
    initialValues: {
      caseOptions,
      interaction: {
        is_note_private,
      },
      provided_service: {
        date: today(),
      },
    },
    validateCases: !isEmpty(caseOptions),
    isMyProviderSensitive,
    showNoteVisibility,
  };
}

const fields = [
  'caseOptions[].id',
  'caseOptions[].selected',
  'caseOptions[].label',
  'caseOptionSelected',
  'provided_service.note',
  'provided_service.type',
  'provided_service.amount',
  'provided_service.unit',
  'provided_service.date',
  'interaction.is_note_private',
];

export default validateReduxForm(
  {
    form: TRACK_SERVICE_FORM,
    fields,
  },
  mapStateToProps,
)(TrackService);
