const getResourceListIdFromUrl = () => {
  const url = window.location.href;

  const regex = /\/facesheet\/[^/]+\/resource-lists\/(.*)/;
  const match = url.match(regex);

  if (match && match[1]) {
    return match[1];
  }
  return null;
};

export default getResourceListIdFromUrl;
