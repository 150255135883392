import { formFieldOptions } from '@unite-us/client-utils';
import { OTHER_OUTCOME_DESCRIPTION } from '../constants';

export default function getOutcomesOptions(outcomes, resolutionType) {
  if (!outcomes || !resolutionType) {
    return [];
  }
  const isResolved = resolutionType === 'resolved';
  const filteredOutcomes = outcomes
    .filter((o) => o.resolved == isResolved)
    .map((o) => ({
      label: o.description,
      value: o.code || o.id,
    }));

  return formFieldOptions.sortOptions({
    options: filteredOutcomes,
    moveToEndValue: OTHER_OUTCOME_DESCRIPTION,
    moveToEndValueKey: 'label',
  });
}
