import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { SelectField } from '@unite-us/ui';
import { validations } from '@unite-us/client-utils';

const PreferredMethodOfContactField = ({
  field,
  forceObjectValue,
  id,
  inline,
  label,
  multiple,
  options,
  registerField,
  required,
  shouldSort,
}) => (
  <SelectField
    className="preferred-method-of-contact-field"
    clearable
    field={field}
    forceObjectValue={forceObjectValue}
    id={id}
    inline={inline}
    label={label}
    labelKey="display_name"
    multiple={multiple}
    shouldSort={shouldSort}
    options={options}
    placeholder="Select..."
    ref={registerField}
    required={required}
    validations={required && validations.isRequired}
    valueKey="value"
  />
);

PreferredMethodOfContactField.propTypes = {
  field: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  forceObjectValue: PropTypes.bool,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  shouldSort: PropTypes.bool,
  options: PropTypes.array.isRequired,
  registerField: PropTypes.func,
  required: PropTypes.bool,
};

PreferredMethodOfContactField.defaultProps = {
  inline: false,
  label: 'Methods of Contact',
  multiple: false,
  shouldSort: false,
  registerField: noop,
  required: false,
  forceObjectValue: true,
};

export default PreferredMethodOfContactField;
