import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { validateReduxForm } from 'common/form';
import { validations } from '@unite-us/client-utils';
import {
  Button,
  SelectField,
  Icon,
} from '@unite-us/ui';
import { withRouter } from 'react-router';
import { hasPaymentsUserAccess, crtb219ChangeServiceTypeOnReferral } from 'src/common/utils/FeatureFlags/flags';
import { OverlaySpinner } from 'common/spinners';
import { isHttpError } from 'common/utils/Error';
import { fetchGroupsPrograms } from 'actions/Program/Group';
import { fetchGroupsUsersFromProgram } from 'actions/User/Program/Group';
import { acceptReferral } from 'actions/Referral/Group';
import { setDashboardRefetch } from 'actions/Dashboard';
import { goToCase } from 'src/components/Cases/utils/routing';
import today from 'src/common/utils/today';
import _ from 'lodash';
import callOrLog from 'src/common/utils/callOrLog';
import { REFERRAL } from 'common/utils/EventTracker/utils/eventConstants';
import { primaryWorkerOptions } from 'src/components/Cases/utils/selectOptions';
import { buildContractedProgramOptions, getServiceTypeOptionList } from 'src/components/Referrals/ReferralStatus/utils';
import ToolTip from 'common/ToolTip';
import DateDiv from './DateDiv';

const ACCEPT_REFERRAL_FORM = 'acceptReferralForm';

export const ClientEnrolledNotification = ({
  checkingEnrollment,
  programEnrollment,
  styles: { warningStyle, notificationStyle },
}) => {
  const enrollmentConditionsMet = programEnrollment &&
    programEnrollment.is_active_enrollment &&
    !programEnrollment.out_of_network;

  if (checkingEnrollment || enrollmentConditionsMet) {
    const pStyle = checkingEnrollment ? notificationStyle : warningStyle;
    const text = checkingEnrollment ?
      'Checking Program for Active Enrollment' :
      'Client is Already Enrolled in This Program';
    return (
      <div className="row">
        <div className="col-sm-12">
          <p style={pStyle}><em>{text}</em></p>
        </div>
      </div>
    );
  }
  return null;
};

ClientEnrolledNotification.propTypes = {
  checkingEnrollment: PropTypes.bool,
  programEnrollment: PropTypes.shape({
    is_active_enrollment: PropTypes.bool,
    out_of_network: PropTypes.bool,
  }),
  styles: PropTypes.shape({
    warningStyle: PropTypes.object.isRequired,
    notificationStyle: PropTypes.object.isRequired,
  }),
};

ClientEnrolledNotification.defaultProps = {
  checkingEnrollment: false,
  styles: {
    warningStyle: { margin: '0px', color: '#E53935' },
    notificationStyle: { margin: '0px', color: '#58606E' },
  },
  programEnrollment: {
    is_active_enrollment: false,
    out_of_network: false,
  },
};

const fields = [
  'program_id',
  'service_id',
  'primary_case_worker_id',
  'entry_date',
];

const ButtonComponent = () => (
  <Icon
    icon="IconInfoCircle"
    className="fill-current text-text-blue"
    ariaLabel="ProgramNameInformation"
  />
);

const ToolTipContent = () => (
  <p className="bg-white mb-2 w-50 p-4 border border-text-blue border-solid rounded">
    Programs for authorized services
    <br />
    cannot be changed.
  </p>
);

export class AcceptModalForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      programSelected: false,
      programs: [],
      serviceTypes: [],
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.fetchPrimaryWorkers = this.fetchPrimaryWorkers.bind(this);
    this.setupProgramAndWorkers = this.setupProgramAndWorkers.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.debouncedFetchPrimaryWorkers = _.debounce(this.debouncedFetchPrimaryWorkers.bind(this), 400);
    this.fetchServiceTypes = this.fetchServiceTypes.bind(this);
    this.fillServiceTypeField = this.fillServiceTypeField.bind(this);
  }

  componentDidMount() {
    const { groupId, referral } = this.props;
    const programId = _.get(referral, 'receiving_program.id');
    const personId = _.get(referral, 'case.person.id');

    const fetchGroupsProgramsOptions = {
      active: 'true',
      serviceId: _.get(referral, 'service_type.id', null),
      excludeBillable: false,
      excludeAuthorizationRequired: true,
      referablePerson: personId,
    };
    this.props.fetchGroupsPrograms(groupId, fetchGroupsProgramsOptions).then((payload) => {
      const programs = _.get(payload, 'data.data', []);
      this.setState({
        programs,
      });
      if (programId && _.findIndex(programs, { id: programId }) < 0) {
        this.props.fetchGroupsPrograms(groupId, {
          active: 'true',
          ids: programId,
        }).then((response) => {
          const program = _.get(response, 'data.data', []);
          this.setState({
            programs: [
              ...program,
              ...this.state.programs,
            ],
          });
        });
      }
    }).then(() => {
      if (programId) {
        this.setupProgramAndWorkers(programId);
      }
     });
  }

  onSubmit(values) {
    const newValues = {
      ...values,
      program_id: this.programId(),
     };

    return this.props.acceptReferral(
      this.props.groupId,
      this.props.referral.id,
      this.props.referral.case.id,
      newValues,
    )
      .then((response) => {
        this.props.setDashboardRefetch();
        this.props.resetForm();
        if (isHttpError(response, 409)) {
          return () => this.props.router.push('/dashboard/new/referrals');
        }

        const referral = _.get(response, 'data.data', {});
        const serviceCase = _.get(response, 'data.data.case', {});
        const navigateToCase = () => goToCase({ ...serviceCase, contact: serviceCase.person });

        callOrLog(() => this.context.eventTracker(REFERRAL.accepted, {
          case_id: serviceCase.id,
        }, { referral }));

        return navigateToCase;
      });
  }

  setupProgramAndWorkers(programId) {
    this.fetchPrimaryWorkers(programId);
    this.fetchServiceTypes(programId);
  }

  debouncedFetchPrimaryWorkers(search) {
    const { groupId } = this.props;
    const programId = this.programId();
    this.props.fetchGroupsUsersFromProgram(
      groupId,
      programId,
      { text: search, per: 50, page: 1 },
    );
  }

  fetchPrimaryWorkers(programId) {
    const primaryWorkerField = this.props.fields.primary_case_worker_id;
    const { groupId, userId } = this.props;

    if (programId !== '' && programId !== undefined) {
      this.setState({ programSelected: false });
      this.props.fetchGroupsUsersFromProgram(groupId, programId)
        .then(() => {
          this.setState({ programSelected: true });
          const pWorkerOptions = primaryWorkerOptions(this.props);
          const foundPrimaryWorker = pWorkerOptions.find((option) => option.id === userId);
          primaryWorkerField.onChange(foundPrimaryWorker ? foundPrimaryWorker.value : '');
          this.props.untouch(primaryWorkerField.name);
        });
    } else {
      this.setState({ programSelected: false });
    }
  }

  async fetchServiceTypes(program_id) {
    const { programs } = this.state;
    const { referral } = this.props;

    if (programs.length !== 0 && program_id !== null) {
      const programId = program_id.value || program_id;
      const program = programs.find((p) => p.id === programId);
      if (program !== undefined) {
        const servicesIds = program.relationships.services.data.map((service) => service.id);
        const options = await getServiceTypeOptionList(servicesIds, referral);
        this.fillServiceTypeField(options);
        this.setState({ serviceTypes: options });
      }
    }
  }

  fillServiceTypeField(options) {
    const { referral } = this.props;
    const serviceTypeField = this.props.fields.service_id;
    const serviceTypeFieldId = serviceTypeField.value === '' ? referral.service_type.id : serviceTypeField.value.id;
    const foundServiceType = options.find((op) => op.children.find((child) => child.id === serviceTypeFieldId));
    serviceTypeField.onChange(foundServiceType ? serviceTypeFieldId : '');
    this.props.untouch(serviceTypeField.name);
  }

  closeDialog() {
    this.props.resetForm();
    this.props.closeDialog();
  }

  programId() {
    return this.props.referral?.case?.service_authorization ?
    this.props.referral?.receiving_program?.id :
    this.props.fields.program_id?.value;
  }

  render() {
    const {
      fields: {
        program_id,
        service_id,
        primary_case_worker_id,
        entry_date,
      },
      checkingEnrollment,
      registerField,
      handleSubmit,
      referralProgramEnrollment,
      submitting,
      usePaymentsUserRole,
      referral,
      changeServiceTypeOnReferral,
    } = this.props;

    const { programSelected, serviceTypes } = this.state;
    const pWorkerOptions = primaryWorkerOptions(this.props);
    const authorizationReferral = referral?.case?.service_authorization;

    const networkPrograms = this.state.programs
      .filter((program) => program.attributes.name !== 'Referred Out of Network');
    let programOptions = [];
    if (usePaymentsUserRole) {
      programOptions = networkPrograms.map((program) => buildContractedProgramOptions(program, 'label'));
    } else {
      programOptions = networkPrograms.map((p) => ({ label: p.attributes.name, value: p.id }));
    }

    return (
      <form onSubmit={handleSubmit(this.onSubmit)} className="content-with-actions">
        <div className="content-container">
          <OverlaySpinner show={submitting} text="Accepting Referral..." />
          {authorizationReferral ?
            (
              <div className="display-field text-sm mb-6">
                <h5 className="text-secondary display-field__label mb-4">PROGRAM</h5>
                <div className="flex">
                  <span className="mr-1">
                    {programOptions.find((p) => p.value === this.programId())?.label}
                  </span>
                  <ToolTip
                    buttonComponentContent={ButtonComponent}
                    enableHover
                    panelComponentContent={ToolTipContent}
                    panelClassName="z-modal"
                    placement="bottom"
                    offsetOptions={{
                      offset: [0, 14],
                    }}
                    flipOptions={{
                      fallbackPlacements: [],
                    }}
                  />
                </div>
              </div>
            ) :
            (
              <SelectField
                className="accept-referral-program-select"
                ref={registerField}
                field={program_id}
                label="Program"
                id="programSelect"
                options={programOptions}
                onChange={this.setupProgramAndWorkers}
                inline={false}
                validations={validations.isRequired}
                required
              />
          )}
          {changeServiceTypeOnReferral && !(authorizationReferral && usePaymentsUserRole) && (
            <SelectField
              className="accept-referral-service-type-select"
              ref={registerField}
              field={service_id}
              label="Service Type"
              id="service_id"
              options={serviceTypes}
              loadOptions={() => {
                this.fillServiceTypeField(serviceTypes);
              }}
              valueKey="id"
              labelKey="name"
              forceObjectValue
              searchPlaceholderValue="Search"
              disabled={!programSelected}
              inline={false}
              validations={validations.isRequired}
              required
            />
          )}

          <SelectField
            className="accept-referral-primary-worker-select"
            ref={registerField}
            field={primary_case_worker_id}
            label="Primary Worker"
            id="workerSelect"
            options={pWorkerOptions}
            loadOptions={(search) => {
              this.debouncedFetchPrimaryWorkers(search);
            }}
            searchPlaceholderValue="Search - Displaying 50 Max"
            disabled={!programSelected}
            inline={false}
            validations={validations.isRequired}
            required
          />

          <div className="row">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-3">
                  {
                    <DateDiv
                      entryDate={entry_date}
                      programEnrollment={referralProgramEnrollment}
                      registerField={registerField}
                      checkingEnrollment={checkingEnrollment}
                    />
                  }
                </div>
              </div>
              <div className="accept-program-enrollment">
                <ClientEnrolledNotification
                  checkingEnrollment={checkingEnrollment}
                  programEnrollment={referralProgramEnrollment}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="actions">
          <span className="action-item">
            <Button
              id="accept-referral-cancel-btn"
              onClick={this.closeDialog}
              disabled={submitting}
              label="Cancel"
            />
          </span>
          <span className="action-item">
            <Button
              id="accept-referral-submit-btn"
              onClick={handleSubmit(this.onSubmit)}
              disabled={submitting}
              label="Save"
              primary
            />
          </span>
        </div>
      </form>
    );
  }
}

AcceptModalForm.propTypes = {
  referral: PropTypes.shape({
    id: PropTypes.string.isRequired,
    case: PropTypes.shape({
      id: PropTypes.string.isRequired,
      service_authorization: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }).isRequired,
    contact: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    network: PropTypes.shape({
      id: PropTypes.string,
    }),
    receiving_program: PropTypes.shape({
      id: PropTypes.string,
    }),
    service_type: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  fields: PropTypes.object.isRequired,
  resetForm: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  fetchGroupsPrograms: PropTypes.func.isRequired,
  acceptReferral: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  fetchGroupsUsersFromProgram: PropTypes.func.isRequired,
  registerField: PropTypes.func.isRequired,
  referralProgramEnrollment: PropTypes.object,
  checkingEnrollment: PropTypes.bool,
  submitting: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  setDashboardRefetch: PropTypes.func.isRequired,
  usePaymentsUserRole: PropTypes.bool.isRequired,
  changeServiceTypeOnReferral: PropTypes.bool.isRequired,
};

AcceptModalForm.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

AcceptModalForm.defaultProps = {
  router: {},
  referralProgramEnrollment: {},
  checkingEnrollment: false,
};

export function mapStateToProps(state, ownProps) {
  const { referral } = ownProps;
  const usePaymentsUserRole = hasPaymentsUserAccess(state);

  return {
    changeServiceTypeOnReferral: crtb219ChangeServiceTypeOnReferral(state),
    checkingEnrollment: _.get(state, 'referralProgramEnrollment.isFetching', false),
    groupId: _.get(state, 'session.groupId', ''),
    userId: _.get(state, 'user.id', ''),
    groupsUsers: state.groupsUsers,
    initialValues: {
      entry_date: today(),
      program_id: _.get(referral, 'referred_to_program.id'),
    },
    referralProgramEnrollment: _.get(state, 'referralProgramEnrollment.data', {}),
    usePaymentsUserRole,
  };
}

export default validateReduxForm(
  {
    form: ACCEPT_REFERRAL_FORM,
    fields,
    onSubmitSuccess: (response) => response(),
  },
  mapStateToProps,
  {
    fetchGroupsPrograms,
    fetchGroupsUsersFromProgram,
    acceptReferral,
    setDashboardRefetch,
  },
)(withRouter(AcceptModalForm));
