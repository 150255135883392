import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { fetchFeeSchedules as fetchFS } from 'actions/FeeSchedules';
import { useFindRecord, usePopulate } from 'api/APIHooks';
import { fetchPaymentsInsurance as fetchIsurances } from 'src/components/Insurance/actions';
import { fetchInsurancePlanOptions } from 'src/components/Insurance/utils';
import { getInsuranceExternalId } from 'src/components/Cases/utils';
import useInvoiceSpend from 'src/components/ServiceAuthorization/useInvoiceSpend';
import {
  usePlaceOfServiceOptions,
  useProcedureCodeModifierOptions,
  useProcedureCodeOptions,
  useZcodeOptions,
} from 'src/common/hooks';

export const PaymentsTrackServiceWrapper = (props) => {
  const {
    children,
    contactId,
    feeSchedule,
    feeScheduleId,
    feeScheduleProgramId,
    fetchFeeSchedules,
    fetchPaymentsInsurance,
    groupId,
    insurances,
    serviceAuthorizationId,
    serviceCase,
  } = props;

  const [fsPlans, setFSPlans] = useState([]);
  const [insurancePlans, setInsurancePlans] = useState([]);
  const [socialInsurances, setSocialInsurances] = useState([]);

  const { data: serviceAuthorizationData } = useFindRecord(
    'service_authorization',
    serviceAuthorizationId,
    { queryConfig: { placeholderData: undefined, enabled: !!serviceAuthorizationId } },
  );
  const serviceAuthorization = serviceAuthorizationData?.data?.data;
  usePopulate('insurance', 'insurance', serviceAuthorization, { queryConfig: { placeholderData: undefined } });
  usePopulate('insurance.plan', 'plan', serviceAuthorization, { queryConfig: { placeholderData: undefined } });
  const serviceAuthorizationPlanId = serviceAuthorization?.insurance?.plan?.id;
  const serviceAuthorizationPayerId = serviceAuthorization?.insurance?.plan?.payer?.id;

  const [inferredPlanId, setInferredPlanId] = useState(null);
  const { data: inferredPlanData } = useFindRecord(
    'plan',
    inferredPlanId,
    { queryConfig: { enabled: !serviceAuthorizationId && !!inferredPlanId, placeholderData: undefined } },
  );
  const inferredPayerId = inferredPlanData?.data?.data?.payer?.id;

  const canonicalPlanId = serviceAuthorizationPlanId || inferredPlanId;

  const payerId = serviceAuthorizationPayerId || inferredPayerId;
  const zcodeOptions = useZcodeOptions([feeScheduleProgramId], payerId);
  const placeOfServiceOptions = usePlaceOfServiceOptions([feeScheduleProgramId]);
  const procedureCodeOptions = useProcedureCodeOptions([feeScheduleProgramId], payerId);

  const serviceAuthorizationInvoiceInformation = useInvoiceSpend(
    get(serviceCase, 'service_authorization.id'),
  );

  const procedureCodeModifierOptions = useProcedureCodeModifierOptions(procedureCodeOptions);

  const procedureCodesWithModifiers = useMemo(
    () => {
      if (!isEmpty(procedureCodeOptions)) {
        const procedureCodesAndModifierRelationships = procedureCodeOptions.map((procedureCode) => {
          let newModifiers = [];
          if (!isEmpty(procedureCodeModifierOptions)) {
            newModifiers =
              procedureCodeModifierOptions.filter((modifier) => modifier.procedure_code?.id === procedureCode.id);
          }
          return {
            ...procedureCode,
            label: `${procedureCode.code} - ${procedureCode.description}`,
            procedure_code_modifiers: newModifiers,
            disabled: false,
          };
        });
        return procedureCodesAndModifierRelationships;
      }
      return [];
    },
    [procedureCodeOptions, procedureCodeModifierOptions],
  );

  useEffect(() => {
    fetchFeeSchedules({ id: feeScheduleId });
  }, [feeScheduleId]);

  useEffect(() => {
    if (!isEmpty(feeSchedule)) {
      fetchPaymentsInsurance(contactId, groupId);
      fetchInsurancePlanOptions({
        planIds: get(feeSchedule, 'plans', []).map((plan) => (plan.id)),
        planType: 'social',
        showEnrollmentRequired: true,
      }).then((response) => {
        setFSPlans(response.data.data);
      });
    }
  }, [feeSchedule]);

  useEffect(() => {
    if (!isEmpty(feeSchedule) && feeSchedule.insurance_required && !isEmpty(insurances)) {
      const planIds = insurances.map((insurance) => (
        get(insurance, 'relationships.plan.data.id', '')
      ));
      fetchInsurancePlanOptions({
        planIds,
        showEnrollmentRequired: true,
      }).then((response) => {
        setInsurancePlans(response.data.data);
      });
    }
  }, [feeSchedule, insurances]);

  useEffect(() => {
    if (!isEmpty(insurances)) {
      const planIds = insurances.map((insurance) => (
        get(insurance, 'relationships.plan.data.id', '')
      ));
      fetchInsurancePlanOptions({
        planIds,
        planType: 'social',
        showEnrollmentRequired: true,
      }).then((response) => {
        const socialPlans = response.data.data;
        const plansMatchingFeeSchedule = socialPlans.filter((plan) => {
          const feeSchedules = get(plan, 'relationships.fee_schedules.data', []);
          return feeSchedules.map((fs) => fs.id).includes(feeSchedule.id);
        });
        const candidatePlanIds = plansMatchingFeeSchedule.map((plan) => plan.id);
        const candidateInsurances = insurances.filter((insurance) => (
          candidatePlanIds.includes(get(insurance, 'relationships.plan.data.id'))
        ));
        setSocialInsurances(candidateInsurances);
      });
    }
  }, [insurances]);

  const insuranceExternalMemberId = useCallback(
    getInsuranceExternalId({ insurances, fsPlans, insurancePlans }),
    [insurances, fsPlans, insurancePlans],
  );

  return React.Children.map(children, (child) => (
    React.cloneElement(child, {
      insuranceExternalMemberId,
      insuranceRequired: feeSchedule.insurance_required,
      placeOfServiceOptions,
      zcodeOptions,
      procedureCodesWithModifiers,
      socialInsurances,
      serviceAuthorizationInvoiceInformation,
      serviceAuthorization,
      planId: canonicalPlanId,
      setInferredPlanId,
    })
  ));
};

PaymentsTrackServiceWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  contactId: PropTypes.string.isRequired,
  feeSchedule: PropTypes.object,
  feeScheduleId: PropTypes.string.isRequired,
  feeScheduleProgramId: PropTypes.string.isRequired,
  fetchFeeSchedules: PropTypes.func.isRequired,
  fetchPaymentsInsurance: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  insurances: PropTypes.array,
  serviceCase: PropTypes.object,
  serviceAuthorizationId: PropTypes.string,
};

PaymentsTrackServiceWrapper.defaultProps = {
  feeSchedule: {},
  insurances: [],
  serviceCase: {},
  serviceAuthorizationId: '',
};

const mapStateToProps = (state, ownProps) => {
  const programId = get(ownProps.serviceCase, 'program.id');
  const groupId = state.session.groupId;
  const program = get(state, 'groupsPrograms.data').find(({ id }) => id === programId);
  const feeScheduleId = get(program, 'fee_schedule_program.relationships.fee_schedule.data.id', '');
  const feeSchedule = get(state, `feeSchedules.data.${feeScheduleId}`, {});
  const feeScheduleProgramId = get(program, 'fee_schedule_program.id', '');
  const insurances = get(state, `insurances.data.${ownProps.contactId}`, []);
  const serviceAuthorizationId = get(ownProps.serviceCase, 'service_authorization.id');

  return {
    feeScheduleId,
    feeSchedule,
    feeScheduleProgramId,
    insurances,
    groupId,
    serviceAuthorizationId,
  };
};

export default connect(mapStateToProps, {
  fetchFeeSchedules: fetchFS,
  fetchPaymentsInsurance: fetchIsurances,
})(PaymentsTrackServiceWrapper);
