import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  BaseCard, BaseCardBody,
  InputField,
} from '@unite-us/ui';
import { TrackerContext, validations } from '@unite-us/client-utils';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useFindProvider, useUpdateProvider } from 'src/components/Organization/api/hooks/v1';
import { browserHistory } from 'common/utils/browserHistory';
import { EMPTY_DRAFT_FIELD } from 'src/components/Group/Programs/constants';
import { ORG_SETTINGS } from 'src/common/utils/EventTracker/utils/eventConstants';
import { Spinner } from 'src/common/spinners';
import { notifySomethingWentWrong } from '../api/hooks/v1/apiHookOptions';
import HoursOfOperationsEdit from '../components/HoursOfOperationsEdit';
import ButtonFormSubmit from '../components/ButtonFormSubmit';
import PhoneNumbersField from '../components/PhoneNumbersField';
import EmailAddressesField from '../components/EmailAddressesField';
import { RichTextInputField } from '../../Backoffice/form/RichTextInputField';
import normalizeHours from '../utils/normalizeHours';

const OrganizationEditForm = ({ currentProviderId }) => {
  const eventTracker = useContext(TrackerContext);
  const { updateRecord: updateProvider } = useUpdateProvider({
    onError: () => {
      notifySomethingWentWrong();
      browserHistory.push('/organization/settings/');
    },
  });

  const [saving, setSaving] = useState(false);
  const [formData, setFormData] = useState({
    populated: false,
    data: {},
  });
  const {
    data: {
      name,
      description,
      website_url,
      email_addresses = [{}],
      phone_numbers = [{}],
      hours,
    }, isFetching,
  } = useFindProvider({ providerId: currentProviderId });

  if (name && !formData.populated) {
    setFormData({
      data: {
        name,
        description,
        website_url,
        email_addresses,
        phone_numbers,
        hours: normalizeHours.groupByOpenHours(hours),
      },
      populated: true,
    });
  }

  const onSubmit = async (submittedData) => {
    eventTracker(ORG_SETTINGS.orgSaved);
    setSaving(true);

    const { data: { data: responseData } } = await updateProvider(currentProviderId, {
      // submittedData will overwrite these values if they exist, otherwise they will be cleared
      phone_numbers: [],
      email_addresses: [],
      ...submittedData,
      hours: normalizeHours.transformToApiFormat(submittedData.hours),
      website_url: submittedData.website_url || '',
    });

    setFormData({
      data: {
        name: responseData.name,
        description: responseData.description,
        website_url: responseData.website_url,
        email_addresses: responseData.email_addresses,
        phone_numbers: responseData.phone_numbers,
        hours: normalizeHours.transformApiToSingleLineFormat(responseData.hours),
      },
      populated: true,
    });

    setSaving(false);
    browserHistory.push('/organization/settings/');
  };

  if (isFetching || saving) {
    return (<Spinner center />);
  }

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={{
        ...formData.data,
      }}
      render={({
        handleSubmit,
        form: {
          mutators: { push },
        }, submitting, pristine,
      }) => (
        <form onSubmit={handleSubmit}>
          <BaseCard>
            <BaseCardBody className="p-8 pb-4">
              <div data-test-element="name">
                <Field
                  name="name"
                  validate={(value) => validations.isRequired(value)}
                >
                  {(params) => (
                    <InputField
                      autoFocus
                      id="org-name"
                      label="Organization Name"
                      required
                      {...params}
                    />
                  )}
                </Field>
              </div>
              <div className="pb-4" data-test-element="description">
                <Field
                  validate={(value) => validations.isRequired(value === EMPTY_DRAFT_FIELD ? null : value)}
                  name="description"
                >
                  {({ input }) => (
                    <RichTextInputField
                      id="org-description"
                      label="Organization Description"
                      labelClassName="block font-heavy-font text-text-blue leading-snug"
                      required
                      {...input}
                    />
                  )}
                </Field>
              </div>
              <div data-test-element="website">
                <Field
                  name="website_url"
                  validate={(value) => validations.isUrl(value)}
                >
                  {(params) => (
                    <InputField
                      id="org-website"
                      label={(<>Website URL <small className="font-medium-font">(e.g. https://www.uniteus.com)</small></>)}
                      {...params}
                    />
                  )}
                </Field>
              </div>
              <hr className="mb-6" />
              <PhoneNumbersField
                name="phone_numbers"
                label="Organization Phone Number"
                userPhoneTypes={false}
              />
              <hr className="mb-6" />
              <EmailAddressesField
                name="email_addresses"
                label="Organization Email Address"
              />
              <hr className="mb-6" />
              <HoursOfOperationsEdit
                name="hours"
                push={push}
              />
            </BaseCardBody>
          </BaseCard>
          <ButtonFormSubmit isSaveDisabled={submitting || pristine} />
        </form>
      )}
    />
  );
};

OrganizationEditForm.propTypes = {
  currentProviderId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  currentProviderId: state.session.groupId,
});

export default connect(mapStateToProps)(OrganizationEditForm);
