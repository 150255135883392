import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { updateEmployee } from 'actions/User';
import { validateReduxForm } from 'common/form';
import { validations } from '@unite-us/client-utils';
import {
  InputField,
  Button,
} from '@unite-us/ui';
import updateCurrentUserEmail from 'src/actions/User/User/Provider/updateCurrentUserEmail';
import updateCurrentEmployeeState from 'src/api/core/Employees/updateCurrentEmployeeState';

const EDIT_EMAIL_ADDRESS = 'editEmailAddress';

class EditEmailAddress extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(form) {
    const {
      currentEmployee,
    } = this.props;

    return this.props.updateCurrentUserEmail({
      userId: currentEmployee.user.id,
      employeeId: currentEmployee.id,
      email: form.employee.email,
    }).then((employeePayload) => {
      this.props.updateCurrentEmployeeState(employeePayload);
      this.props.closeModal();
    });
  }

  render() {
    const { fields: { employee: { email } }, handleSubmit, submitting } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)} className="content-with-actions">
        <div className="content-container">
          <InputField
            ref={this.props.registerField}
            id="user-email"
            label="Email"
            type="email"
            field={email}
            inline={false}
            validations={validations.isEmail}
          />
        </div>
        <div className="actions">
          <span className="action-item">
            <Button
              id="edit-email-cancel-btn"
              label="Cancel"
              onClick={this.props.closeModal}
              disabled={submitting}
              secondary
            />
          </span>
          <span className="action-item">
            <Button
              id="edit-email-save-btn"
              primary
              label="Save"
              onClick={handleSubmit(this.onSubmit)}
              disabled={submitting}
            />
          </span>
        </div>
      </form>
    );
  }
}

EditEmailAddress.propTypes = {
  employee: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  updateCurrentUserEmail: PropTypes.func.isRequired,
  updateCurrentEmployeeState: PropTypes.func.isRequired,
  closeModal: PropTypes.func,
  registerField: PropTypes.func.isRequired,
  currentEmployee: PropTypes.object.isRequired,
};

EditEmailAddress.defaultProps = {
  closeModal: () => { },
};

function mapStateToProps(state, ownProps) {
  const { currentEmployee } = state.globalState;

  return {
    initialValues: {
      employee: {
        email: ownProps.employee.email,
      },
    },
    groupId: state.session.groupId,
    currentEmployee,
  };
}

const fields = [
  'employee.email',
];

export default validateReduxForm(
  {
    form: EDIT_EMAIL_ADDRESS,
    fields,
  },
  mapStateToProps,
  { updateEmployee, updateCurrentUserEmail, updateCurrentEmployeeState },
)(EditEmailAddress);
