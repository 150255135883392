import { SELECT_CONTACT, CREATE_GROUP_CONTACT } from 'actions';
import Notifier from 'common/helpers/Notifier';
import { isHttpSuccess } from 'src/api/utils/httpStatus';
import { coreApi } from 'src/api/config';
import _ from 'lodash';
import { getGeocodedAddress } from 'src/components/Browse/Map/utils/geoCode';

const createAddresses = async (person, newAddresses) => {
  try {
    if (!newAddresses || !newAddresses.length) { return []; }

    const addresses = [];
    const promises = newAddresses.map(async(address) => {
      const geocodedAddress = await getGeocodedAddress(address);
      return coreApi.createRecord('address', { ...geocodedAddress, person });
    });
    const addressResults = await Promise.all(promises);
    addressResults.forEach((addressResponse) => {
      const status = _.get(addressResponse, 'status');
      if (status && !isHttpSuccess(status)) {
        return Notifier.handleErrors(addressResponse);
      }

      return addresses.push({ ..._.get(addressResponse, 'data.data') });
    });

    return addresses;
  } catch (error) {
    Notifier.handleErrors(error);
    return [];
  }
};

function createGroupContact({
  groupId,
  contact,
}) {
  return async (dispatch) => {
    let contact_preferences = { ...contact.contact_preferences };
    const voice_mail_ok = contact_preferences?.voice_mail_ok === 'true';
    contact_preferences = {
      ...contact_preferences,
      voice_mail_ok,
    }; // TODO: Remove this conversion of string to bool when form is converted to react-final-form
    const newContact = { ..._.omit(contact, ['contact_preferences']), provider: groupId };
    const newAddresses = newContact.addresses ? [...newContact.addresses] : [];
    newContact.addresses = undefined;
    newContact.household = { total: 1 };
    newContact.citizenship = 'undisclosed';
    const response = await coreApi.createRecord('person', newContact);

    if (!isHttpSuccess(response.status)) {
      Notifier.handleErrors(response);
      return false;
    }

    const person = response.data.data;

    await coreApi.createRecord('client_relationship', { person: person.id, provider: groupId });
    const createdAddresses = await createAddresses(person.id, newAddresses);

    person.addresses = createdAddresses;

    await coreApi.createRecord('contact_preference', {
      ...contact_preferences,
      person: person.id,
    });

    dispatch({
      type: CREATE_GROUP_CONTACT,
      payload: response,
    });

    dispatch({
      type: SELECT_CONTACT,
      id: person.id,
    });

    Notifier.dispatch(response.status, 'Client Successfully Created');
    return response;
  };
}

export default createGroupContact;
