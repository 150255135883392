import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { validations } from '@unite-us/client-utils';
import { SelectField } from '@unite-us/ui';
import { noop } from 'lodash';
import { sortReasonOptions } from './utils';

const ReferralReasonField = ({
  field,
  id,
  onChange,
  options,
  registerField,
}) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  return (
    <div className={`referral-reason-field ${isDropdownOpen ? 'h-175px' : ''}`}>
      <SelectField
        field={field}
        id={id}
        inline={false}
        label="Reason"
        onShowDropdownonChange={onChange}
        onShowDropdown={() => setDropdownOpen(true)}
        onHideDropdown={() => setDropdownOpen(false)}
        options={sortReasonOptions(options)}
        ref={registerField}
        required
        shouldSort={false}
        validations={validations.isRequired}
      />
    </div>
    );
};

ReferralReasonField.propTypes = {
  field: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.array,
  registerField: PropTypes.func,
};

ReferralReasonField.defaultProps = {
  onChange: noop,
  options: [],
  registerField: noop,
};

export default ReferralReasonField;
