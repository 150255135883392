const selectOptionsFromIds = (ids = [], options = []) => {
  const populatedValues = ids?.map((id) => options?.find((option) => {
    if (option.value) {
      return option.value === id.id;
    }
    return option.id === id.id;
  }));

  // remove undefined from populated values
  return populatedValues.reduce((valuesPopulated, currentValue) => {
    if (currentValue) {
      valuesPopulated.push(currentValue);
    }
    return valuesPopulated;
  }, []);
};

export default selectOptionsFromIds;
