import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useCreateRecord, useInvalidateQueries } from 'src/api/APIHooks';
import SurveyJSFormRenderer from '@unite-us/surveyjs/dist/components/Renderer/SurveyJSFormRenderer';
import buildResponses from '@unite-us/surveyjs/dist/components/Renderer/buildResponses';
import { get, isFunction } from 'lodash';
import Notifier from 'common/helpers/Notifier';
import featureFlag from 'common/utils/FeatureFlag/FeatureFlag';
import { crtb1121HideFieldInForm } from 'common/utils/FeatureFlags/flags';
import useFormSubmission from 'src/components/FormSubmissions/useFormSubmission';
import {
  Button,
  BaseCard,
  BaseCardHeader,
  BaseCardBody,
  Icon,
} from '@unite-us/ui';
import { browserHistory } from 'src/common/utils/browserHistory';
import {
  RequestorHeader,
  RiskDisplay,
} from './components';
import './ScreeningDetail.scss';

const contextTypesModel = {
  referral: 'referral',
  case: 'case',
  assistance_request: 'assistance_request',
  person: 'person',
  'Screenings::Screening': null,
};

const SUCCESS_MESSAGE = 'Screening Successfully Submitted.';

export function ScreeningDetail({
  enums,
  isMilitaryFocused,
  submissionId,
  isCaseManager,
  providerId,
  currentEmployee,
  afterSubmit,
  contextType,
  allowEdit,
  showRequestor,
  showScreeningResults,
  crtb1121HideFieldInFormEnabled,
}) {
  const surveyRef = useRef();
  const [isSaving, setIsSaving] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const { formSubmission, isFetching } = useFormSubmission({
    submissionId, providerId, contextType,
  });

  const invalidateQueries = useInvalidateQueries();

  const { createRecord: createFormSubmission } = useCreateRecord('form_submission', {
    api: 'coreApi',
  });

  if (!isFetching && crtb1121HideFieldInFormEnabled) {
    const featureFlagQuestion = formSubmission.form.configuration.pages[0].elements.find((e) => (
      e.name === 'crtb-1121-hide-field-in-form'
    ));

    if (featureFlagQuestion) {
      const questionIdToHide = featureFlagQuestion.html;
      formSubmission.form.configuration.pages = formSubmission.form.configuration.pages.map((page) => ({
        ...page,
        elements: page.elements.map((element) => {
          if (element.id === questionIdToHide) {
            return { ...element, visible: false };
          }
          return element;
        }),
      }));
    }
  }

  const surveyJSON = !isFetching ? {
    configuration: {
      title: formSubmission.form.name,
      description: formSubmission.form.description,
      ...formSubmission.form.configuration,
    },
  } : {};

  const handleSetSurvey = (survey) => {
    surveyRef.current = survey;
  };

  const handleOnCancel = () => {
    setEditMode(false);
  };

  const handleOnEditClick = () => {
    setEditMode(!editMode);
  };

  const handleOnSubmit = async () => {
    const survey = surveyRef.current;
    if (survey.completeLastPage() && !survey.hasErrors()) {
      const responses = buildResponses(surveyRef.current);
      setIsSaving(true);
      handleOnEditClick();

      let formSubmissionId;

      await createFormSubmission({
        responses,
        form: formSubmission.form.id,
        provider: providerId,
        contextType: contextTypesModel[contextType],
        context: formSubmission.context.id,
        submitter: currentEmployee.id,
      }, {
        mutationConfig: {
          onSuccess: (result) => {
            invalidateQueries('form_submission');
            formSubmissionId = result.data.data.id;
          },
        },
      }).then((response) => {
        Notifier.dispatch(response.status, SUCCESS_MESSAGE);
      });
      setIsSaving(false);

      switch (contextType) {
        case 'person':
          browserHistory.replace(`/screenings/all/${formSubmissionId}`);
          break;
        default:
          browserHistory.push('/dashboard/new/referrals');
      }
      if (isFunction(afterSubmit)) afterSubmit();
    }
  };

  const iconDiv = allowEdit ? (
    <div
      id="assessment-edit-btn"
      onClick={handleOnEditClick}
      role="button"
      onKeyDown={handleOnEditClick}
      tabIndex={0}
    >
      <Icon icon="IconPencil" style={{ svg: { width: '1em', height: '1em' } }} />
      <p className="assessment-edit-btn__text">Edit</p>
    </div>
  ) : '';

  const subheaderText = get(formSubmission, 'form.description');

  const title = (
    <span className="assessment-details-view__header">
      {get(formSubmission, 'form.name', '')}
      <br />
      {
        subheaderText && (
          <small className="assessment-details-view__subheader">
            {`(${subheaderText})`}
          </small>
        )
      }

    </span>
  );

  const requestorHeader = !isFetching && (
    <div className="screening-detail__requestor mt-4">
      <RequestorHeader
        contact={formSubmission.context}
        enums={enums}
        isMilitaryFocused={isMilitaryFocused}
        screening={formSubmission}
      />
    </div>
  );

  return (
    <div className="screening-detail">
      {showRequestor && requestorHeader}
      <div className="container-fluid assessment-details-view">
        <div className="row">
          <div className={`${allowEdit ? 'col-lg-8' : ''} mt-5 assessments-show`}>
            <BaseCard>
              <BaseCardHeader
                title={title}
              >
                {(!editMode) && iconDiv}
              </BaseCardHeader>
              <BaseCardBody withPadding>
                {isFetching && 'Loading...'}
                {isSaving && 'Saving...'}
                {(!isFetching && !isSaving) && (
                  <SurveyJSFormRenderer
                    formData={surveyJSON}
                    formSubmission={formSubmission}
                    editMode={editMode}
                    handleSetSurveyModel={handleSetSurvey}
                  />
                )}
                <div className="assessment-details-view__actions">
                  {
                    editMode && (
                      <Button
                        id="cancel-btn"
                        className="form-cancel-button"
                        label="Cancel"
                        onClick={handleOnCancel}
                      />
                    )
                  }
                  {
                    editMode && (
                      <Button
                        id="save-btn"
                        className="form-submit-button ml-one"
                        label="Save"
                        onClick={handleOnSubmit}
                        primary
                      />
                    )
                  }
                </div>
              </BaseCardBody>
            </BaseCard>
          </div>
          { showScreeningResults && !isFetching && (
            <div className="col-lg-4 mt-5 screening-detail__results">
              <RiskDisplay screening={formSubmission} isCaseManager={isCaseManager} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

ScreeningDetail.propTypes = {
  isCaseManager: PropTypes.bool.isRequired,
  enums: PropTypes.object,
  submissionId: PropTypes.string.isRequired,
  providerId: PropTypes.string.isRequired,
  isMilitaryFocused: PropTypes.bool,
  currentEmployee: PropTypes.object.isRequired,
  afterSubmit: PropTypes.func,
  contextType: PropTypes.string,
  allowEdit: PropTypes.bool,
  showRequestor: PropTypes.bool,
  showScreeningResults: PropTypes.bool,
  crtb1121HideFieldInFormEnabled: PropTypes.bool,
};

ScreeningDetail.defaultProps = {
  enums: {},
  isMilitaryFocused: false,
  afterSubmit: null,
  contextType: 'person',
  allowEdit: true,
  showRequestor: true,
  showScreeningResults: true,
  crtb1121HideFieldInFormEnabled: false,
};

const mapStateToProps = (state) => ({
  providerId: state.session.groupId,
  currentEmployee: state.globalState.currentEmployee,
  crtb1121HideFieldInFormEnabled: crtb1121HideFieldInForm(state),
});

export default connect(mapStateToProps)(featureFlag(ScreeningDetail));
