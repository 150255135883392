import React from 'react';
import PropTypes from 'prop-types';
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  Icon,
  InputField,
} from '@unite-us/ui';
import { RadioField } from 'components/Backoffice/form';
import 'src/pages/fee-schedules/_id/FeeSchedulePrograms/_fee_schedule_program_id/FeeScheduleProgramBaseCard.scss';

const STATE_OPTIONS = [
  { label: 'Active', value: 'active' },
  { label: 'Archived', value: 'archived' },
];

const FeeScheduleRejectionReasonForm = ({
  title,
  rejectionReason,
  onChangeHandler,
  index,
  showCrossOnForm,
  onCrossClickHandler,
  hasState,
}) => {
  const namespace = title.toLowerCase().split(' ').join('-');

  const handleRejectionReasonChange = (property, value) => {
    onChangeHandler({ ...rejectionReason, [property]: value }, index);
  };

  return (
    <div className="fee-schedule-base-card">
      <BaseCard noBorder>
        <BaseCardHeader
          title={title}
          noBorder
          className="px-4 font-bold"
        >
          { showCrossOnForm && (
            <Icon
              ariaLabel="cancel"
              className="text-text-blue fill-current mr-2"
              icon="IconCross"
              onClick={() => onCrossClickHandler(index)}
              size={12}
            />
          )}
        </BaseCardHeader>
        <BaseCardBody withPadding>
          <InputField
            id={`${namespace}__code`}
            label="Code"
            placeholder="Create code name..."
            type="text"
            value={rejectionReason.code}
            required
            onChange={(e) => handleRejectionReasonChange('code', e.target.value)}
          />
          <InputField
            id={`${namespace}__rejection-reason`}
            label="Rejection Reason"
            placeholder="Describe Rejection Reason..."
            type="text"
            value={rejectionReason.display_name}
            required
            onChange={(e) => handleRejectionReasonChange('display_name', e.target.value)}
          />
          {hasState && (
            <RadioField
              id={`${namespace}__state`}
              label="State"
              options={STATE_OPTIONS}
              value={rejectionReason.state ? rejectionReason.state : 'active'}
              onChange={(e) => handleRejectionReasonChange('state', e)}
            />
          )}
          <InputField
            id={`${namespace}__description`}
            label="Description"
            placeholder="Any additional descriptions or comments..."
            type="text"
            value={rejectionReason.description}
            onChange={(e) => handleRejectionReasonChange('description', e.target.value)}
          />
        </BaseCardBody>
      </BaseCard>
    </div>
  );
};

FeeScheduleRejectionReasonForm.propTypes = {
  title: PropTypes.string.isRequired,
  rejectionReason: PropTypes.object.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  showCrossOnForm: PropTypes.bool.isRequired,
  onCrossClickHandler: PropTypes.func.isRequired,
  hasState: PropTypes.bool.isRequired,
};

export default FeeScheduleRejectionReasonForm;
