import _ from 'lodash';
import { getMilitaryEnumDisplayName } from 'src/components/MilitaryInformation/utils';
import { generateClientData } from './index';

function getCurrentStatus(militaryAffiliation, contact, enums) {
  if (_.isEqual(militaryAffiliation, 'military_member_or_veteran')) {
    return getMilitaryEnumDisplayName(_.get(contact.military, 'current_status'), enums, 'current_status');
  }

  return undefined;
}

export default function filterMilitaryInfo(contact, enums, isMilitaryFocused) {
  if (!isMilitaryFocused) {
    return [];
  }

  const militaryAffiliation = _.get(contact.military, 'affiliation');
  const currentStatus = getCurrentStatus(militaryAffiliation, contact, enums);

  return [
    generateClientData('military affiliation', getMilitaryEnumDisplayName(militaryAffiliation, enums, 'affiliation')),
    generateClientData('current status', currentStatus),
  ];
}
