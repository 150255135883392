import React from 'react';
import { isEmpty } from 'lodash';
import { SelectField } from '@unite-us/ui';
import { addresses, arrays, validations } from '@unite-us/client-utils';
import PropTypes from 'prop-types';
import { getProgramOptions } from '../utils';

const validateReferralGroupSelect = (groupErrors) => {
  if (isEmpty(groupErrors)) {
    return null;
  }
  return groupErrors.map((error) => error);
};

const updateProgramField = (programField, groupErrors) => {
  if (!isEmpty(groupErrors)) {
    // eslint-disable-next-line no-param-reassign
    programField.touched = true;
  }

  return programField;
};

const ReferralProgramGroupSelect = (props) => {
  const {
    allowEmptyGroups,
    debouncedSearchNetworkGroups,
    hidden,
    index,
    onProgramGroupSelect,
    programField,
    selectedProgramIds,
    serviceAreaSupportForOrgsFlag,
    shouldSort,
    suggestedGroups,
    referral,
    originCoordinates,
    groupsOptionType,
    registerField,
    groupErrors,
  } = props;

  const onProgramSelect = (selected) => {
    onProgramGroupSelect(selected, index);
  };

  const placeHolderKey = serviceAreaSupportForOrgsFlag ? 'top' : 'closest';

  const getLegacyDraftPlaceholder = () => {
    let result = null;
    const receivingProvider = referral.referrals?.[index]?.receiving_provider;
    if (receivingProvider && !referral.program) {
      result = `Unspecified program | ${receivingProvider.name}`;
      if (receivingProvider.addresses?.length) {
        const primaryAddress = arrays.findPrimaryOrFirst(receivingProvider.addresses);
        const distanceObject = addresses.calculateDistance(primaryAddress, originCoordinates);
        result += ` (${distanceObject.distance})`;
      }
    }
    return result;
  };

  const legacyDraftPlaceholder = getLegacyDraftPlaceholder();

  return (
    <SelectField
      ref={registerField}
      required
      validations={[
        { func: (value) => (allowEmptyGroups ? null : validations.isRequired(value)) },
        { func: () => validateReferralGroupSelect(groupErrors) },
      ]}
      className={`referral-program-group-select${legacyDraftPlaceholder ? ' has-legacy-draft-placeholder' : ''}`}
      field={updateProgramField(programField, groupErrors)}
      hidden={hidden}
      hideLabel
      id={`select-field-program-group-${index}`}
      inline={false}
      label="program-group-select"
      labelKey="displayName"
      loadOptions={
          (search) => debouncedSearchNetworkGroups(search, groupsOptionType)
      }
      onChange={onProgramSelect}
      options={getProgramOptions({
        groups: suggestedGroups?.map((x, i) => ({ position: i + 1, ...x })),
        selectedProgramIds,
      })}
      placeholder={legacyDraftPlaceholder ?? 'Choose a program'}
      searchPlaceholderValue={`Displaying ${placeHolderKey} 50 - Type to search for more`}
      shouldSort={shouldSort}
      valueKey="id"
    />
  );
};

ReferralProgramGroupSelect.propTypes = {
    allowEmptyGroups: PropTypes.bool,
    groupErrors: PropTypes.array,
    programField: PropTypes.object.isRequired,
    hidden: PropTypes.bool,
    index: PropTypes.number.isRequired,
    onProgramGroupSelect: PropTypes.func.isRequired,
    registerField: PropTypes.func.isRequired,
    debouncedSearchNetworkGroups: PropTypes.func.isRequired,
    selectedProgramIds: PropTypes.array,
    shouldSort: PropTypes.bool,
    suggestedGroups: PropTypes.array.isRequired,
    serviceAreaSupportForOrgsFlag: PropTypes.bool,
    referral: PropTypes.object.isRequired,
    originCoordinates: PropTypes.array.isRequired,
    groupsOptionType: PropTypes.oneOf(['in-network', 'out-of-network']).isRequired,
  };

  ReferralProgramGroupSelect.defaultProps = {
    hidden: false,
    selectedProgramIds: [],
    shouldSort: false,
    serviceAreaSupportForOrgsFlag: false,
    allowEmptyGroups: false,
    groupErrors: [],
  };

export default ReferralProgramGroupSelect;
