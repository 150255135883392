import React from 'react';
import PropTypes from 'prop-types';
import { InputField } from '@unite-us/ui';
import { noop } from 'lodash';
import { validations } from '@unite-us/client-utils';

const EmailAddressField = (props) => (
  <InputField
    ref={props.registerField}
    {...props}
  />
);

EmailAddressField.propTypes = {
  label: PropTypes.string,
  registerField: PropTypes.func,
  type: PropTypes.string,
  validations: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.func,
  ]),
};

EmailAddressField.defaultProps = {
  label: 'Email',
  registerField: noop,
  type: 'email',
  validations: validations.isEmail,
};

export default EmailAddressField;
