import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { browserHistory } from 'common/utils/browserHistory';
import removeSelectedContact from 'common/display/ContactStepper/actions/RemoveSelectedContact';
import enforcedConsentRoute from 'common/utils/Navigation/enforcedConsentRoute';
import { DOMAIN_CONSTANTS } from 'src/common/constants';
import { isHttpError } from 'common/utils/Error';
import { getEnumsFromState } from 'common/utils/Enums';
import findCurrentGroup from 'common/utils/findCurrentGroup';
import { getGroup } from 'common/utils/stateHelpers';
import { fetchGroup } from 'actions/Group';
import _ from 'lodash';
import { validateReduxForm } from 'common/form';
import { validations } from '@unite-us/client-utils';
import { Button } from '@unite-us/ui';
import { OverlaySpinner, Spinner } from 'common/spinners';
import callOrLog from 'src/common/utils/callOrLog';
import { NEW_CLIENT } from 'common/utils/EventTracker/utils/eventConstants';
import { formatNewContactDataWithNoInsurance } from 'common/display/ContactStepper/utils';
import {
  createUpdateDeletePaymentsInsurances,
} from 'src/components/Insurance/utils/';
import createInsuranceEntry from 'src/components/Insurance/actions/createInsuranceEntry';
import PaymentsInformation from 'src/components/Insurance/PaymentsInformation';
import '../stylesheets/addContactForm.scss';
import Header from './Header';
import createContact from '../actions/CreateContact';
import retrieveSearchedContacts from '../actions/RetrieveSelectedContact';
import BasicInformation from './BasicInformation';
import SupplementalInformation from './SupplementalInformation';
import ContactInformation from './ContactInformation';
import MilitaryInformation from './MilitaryInformation';

const NOT_FOUND_HEADER_COPY = 'We did not find your client in our records.';
const REGULAR_HEADER_COPY = 'Please fill out your client\'s basic contact information below.';

class AddContactInformationFromNav extends Component {
  static restartContactForm() {
    browserHistory.push('/contacts/new/search');
  }

  constructor(props) {
    super(props);

    this.onCreateContact = this.onCreateContact.bind(this);
    this.initiatePaymentsEventTracking = this.initiatePaymentsEventTracking.bind(this);
  }

  componentDidMount() {
    if (!this.props.completeGroup) {
      this.props.fetchGroup(this.props.groupId);
    }
  }

  onCreateContact(values) {
    const {
      fields,
      groupId,
    } = this.props;

    return this.props.createContact({
      contact: formatNewContactDataWithNoInsurance({ fields, values }),
      groupId,
      showNotification: false,
    }).then((payload) => {
      if (!isHttpError(payload)) {
        const contact = _.wget(payload, 'data.data', {});
        const { id: contactId } = contact;
        callOrLog(() => this.context.eventTracker(NEW_CLIENT.createFromNav, null, {
          contact,
        }));

        createUpdateDeletePaymentsInsurances({
          values,
          contactId,
          groupId,
          createInsuranceEntry: this.props.createInsuranceEntry,
          paymentsEventTracking: this.initiatePaymentsEventTracking,
          showNotification: false,
        });

        enforcedConsentRoute({
          contact,
          itemType: DOMAIN_CONSTANTS.CONTACT,
          to: `/facesheet/${contact.id}`,
        });
        this.props.removeSelectedContact();
      }
    });
  }

  initiatePaymentsEventTracking(insurancesArr = []) {
    const updatedTypes = insurancesArr.reduce((insurances, currentInsurance) => {
      if (_.isEmpty(currentInsurance.plan_type)) {
        return insurances;
      }
      return [...insurances, currentInsurance.plan_type.toLowerCase()];
    }, []);

    const eventTracker = this.context.eventTracker;
    if (!_.isEmpty(updatedTypes)) {
      callOrLog(() => eventTracker(
        NEW_CLIENT.changedInsurance,
        { insurance_types: updatedTypes },
      ));
    }
  }

  render() {
    const {
      completeGroup,
      currentGroup,
      enums,
      fields,
      handleSubmit,
      header,
      location,
      mainHeader,
      registerField,
      submitting,
      untouch,
      values,
    } = this.props;
    const { query: { not_found } } = location;
    const subHeader = not_found ? `${NOT_FOUND_HEADER_COPY} ${REGULAR_HEADER_COPY}` : REGULAR_HEADER_COPY;

    return (
      <form
        onSubmit={handleSubmit(this.onCreateContact)}
        className="add-contact-information"
      >
        <OverlaySpinner text="Creating Client..." show={submitting} />
        <div className="row">
          <div className="referral-header col-md-12">
            <Header
              header={header}
              mainHeader={mainHeader}
              subHeader={subHeader}
            />
          </div>
        </div>

        <BasicInformation
          registerField={registerField}
          fields={fields}
          validations={validations}
          enums={enums}
        />

        <ContactInformation
          registerField={registerField}
          fields={fields}
          enums={enums}
          validations={validations}
        />
        {
          completeGroup ? (
            <SupplementalInformation
              completeGroup={completeGroup}
              currentGroup={currentGroup}
              enums={enums}
              fields={fields}
              registerField={registerField}
              untouch={untouch}
              values={values}
            />
          ) : <Spinner />
        }

        <footer className="referral-footer">
          <Button
            id="go-back-btn"
            onClick={AddContactInformationFromNav.restartContactForm}
            label="Go Back"
            style={{ marginRight: '10px' }}
          />
          <Button
            id="save-client-btn"
            onClick={handleSubmit(this.onCreateContact)}
            label="Save Client"
            primary
            disabled={submitting}
          />
        </footer>
      </form>
    );
  }
}

AddContactInformationFromNav.propTypes = {
  completeGroup: PropTypes.object,
  createContact: PropTypes.func.isRequired,
  createInsuranceEntry: PropTypes.func.isRequired,
  currentGroup: PropTypes.object.isRequired,
  enums: PropTypes.object,
  fetchGroup: PropTypes.func.isRequired,
  fields: PropTypes.object,
  groupId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func,
  header: PropTypes.string.isRequired,
  location: PropTypes.object,
  mainHeader: PropTypes.string,
  registerField: PropTypes.func.isRequired,
  removeSelectedContact: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  untouch: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

AddContactInformationFromNav.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {
    user,
    session,
    session: { groupId },
  } = state;

  const initialValues = {
    first_name: _.get(state.form, 'contact.first_name.value', ''),
    last_name: _.get(state.form, 'contact.last_name.value', ''),
    date_of_birth: _.get(state.form, 'contact.date_of_birth.value', ''),
    gender: 'undisclosed',
    marital_status: 'undisclosed',
    race: 'undisclosed',
    ethnicity: 'undisclosed',
    addresses: [undefined],
    insurance: [{}],
  };

  return {
    completeGroup: getGroup(state, groupId),
    currentGroup: findCurrentGroup(user, session),
    enums: getEnumsFromState(state),
    groupId,
    initialValues,
  };
}

export default validateReduxForm(
  {
    form: 'contact',
    fields: [
      ...BasicInformation.fields,
      ...ContactInformation.fields,
      ...MilitaryInformation.fields,
      ...PaymentsInformation.fields,
    ],
    destroyOnUnmount: true,
  },
  mapStateToProps,
  {
    createContact,
    createInsuranceEntry,
    fetchGroup,
    removeSelectedContact,
    retrieveSearchedContacts,
  },
)(AddContactInformationFromNav);
